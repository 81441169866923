import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../common/hooks";
import { AppDispatch } from "../../../../common/store";
import useAutocomplete from "../../../../hooks/useAutocomplete";
import { useOmTranslation } from "../../../../translations/OmTranslationContext";
import { FormName } from "../../../forms/IFormParams";
import { isPartner } from "../../../session/omSessionActions";
import { ColumnName } from "../../OfferAddEditGrid";
import { offerAddEditGridActions } from "../../offerAddEditGridSlice";

export interface IProductDiscount {
    id: number;
    name: string;
}
const initalProductDiscount: IProductDiscount = { id: 0, name: "0 %" };

interface IProductDiscountAutocomplete {
    // dialogMode: DialogMode;
    size: "small" | "medium";
    showLabel: boolean;
}
const ProductDiscountAutocomplete = ({
    // dialogMode,
    size,
    showLabel,
}: IProductDiscountAutocomplete) => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const [discountOptions, setDiscountOptions] = useState<Array<IProductDiscount>>([]);
    const editRow = useAppSelector((state) => state.offerAddEditGrid.editingRow);
    const getDiscount = () => {
        let allowedDiscount = 0;
        if (editRow?.product) {
            // für den Partner nehmen wir anderen Rabatt
            if (isPartner()) {
                allowedDiscount = editRow?.product.maxDiscountPartner;
            } else {
                // die Vertriebler kriegen maxDiscountInternal
                allowedDiscount = editRow?.product.maxDiscountInternal;
            }
        }
        return allowedDiscount;
    };

    const {
        value: productDiscount,
        setValue: setProductDiscount,
        isValid: productDiscountIsValid,
        hasError: productDiscountHasError,
        valueChangeHandler: productDiscountChangedHandler,
        inputBlurHandler: productDiscountBlurHandler,
        reset: resetProductDiscountInput,
    } = useAutocomplete<IProductDiscount>({
        formName: FormName.offerProductForm,
        fieldName: ColumnName.discount,
        validationCallback: (value: IProductDiscount) => {
            if (value?.id && value.id <= getDiscount() && value?.id >= 0) {
                return true;
            } else if (value.id === 0) {
                return true;
            }
            return false;
        },
    });

    //#endregion Component state variables
    //#region Fetch requests

    //#endregion Fetch requests
    //#region Component effects
    useEffect(() => {
        setProductDiscount(editRow?.discount ?? initalProductDiscount);
    }, [editRow]);

    //FIXME muss schauen ware dieser useEffect einen Dauerschleife auslöst
    useEffect(() => {
        if (editRow?.discount?.id !== productDiscount?.id) {
            AppDispatch(offerAddEditGridActions.SetEditRowDiscount(productDiscount));
        }
    }, [productDiscount]);

    useEffect(() => {
        const tempDiscountOptions: Array<IProductDiscount> = [initalProductDiscount];
        if (editRow?.product) {
            const discount = getDiscount();
            if (discount > 0) {
                for (let index = 1; index <= discount; index++) {
                    const element: IProductDiscount = { id: index, name: `${index.toString()} %` };
                    tempDiscountOptions.push(element);
                }
            } else {
                resetProductDiscountInput();
                setProductDiscount(tempDiscountOptions[0]);
                AppDispatch(offerAddEditGridActions.SetEditRowDiscount(tempDiscountOptions[0]));
            }
        }
        setDiscountOptions(tempDiscountOptions);
    }, [editRow?.product]);

    //#endregion Component effects
    //#region Functions
    const isDisabled = () => {
        const discount = getDiscount();
        if (discount > 0) {
            return false;
        }
        return true;
    };
    //#endregion Functions
    //#region Event handlers
    const handleDiscountChange = (event: any, data: any) => {
        productDiscountChangedHandler({
            event,
            newValue: data ?? initalProductDiscount,
            callback: (event, data) => {},
        });
        AppDispatch(offerAddEditGridActions.SetEditRowDiscount(data ?? initalProductDiscount));
    };

    //#endregion Event Handlers
    //#region Markup helper variables

    //#endregion Markup helper variables
    //#region Markup
    return (
        <Autocomplete
            disabled={isDisabled()}
            sx={{ width: "100%", paddingTop: "5px" }}
            size={size}
            id="group-dropdown"
            options={discountOptions}
            getOptionLabel={(option: IProductDiscount) => option.name ?? ""}
            isOptionEqualToValue={(option1: IProductDiscount, option2: IProductDiscount) =>
                option1.id === option2.id
            }
            onChange={handleDiscountChange}
            onBlur={(event) => {
                productDiscountBlurHandler(event);
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        sx={{
                            "& .MuiInputBase-input": {
                                //  textAlign: "end",
                                // paddingRight: "5px",
                            },
                        }}
                        {...params}
                        label={showLabel ? dictionary.product.discount : ""}
                        error={productDiscountHasError}
                        InputProps={{
                            ...params.InputProps,
                        }}
                    />
                );
            }}
            value={productDiscount}
        />
    );
    //#endregion Markup
};
export default ProductDiscountAutocomplete;
