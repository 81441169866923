import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useState } from "react";
import {
    FormControlLabel,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    MenuList,
    Radio,
} from "@mui/material";
import { useAppSelector } from "../../common/hooks";
import { AppDispatch } from "../../common/store";
import SearchField from "../../components/SearchField";
import { FilterOption } from "../../interfaces/IFieldOption";
import { offersApi } from "../../services/offersApi";
import { useOmTranslation } from "../../translations/OmTranslationContext";
import { offersActions } from "./offerListSlice";

const useFilterInfoTagText = (filterOption: FilterOption | undefined) => {
    const { dictionary } = useOmTranslation();
    let filterInfoTagText = "";
    switch (filterOption) {
        case FilterOption.createUser:
            filterInfoTagText = dictionary.offer.createdBy;
            break;
        case FilterOption.customer:
            filterInfoTagText = dictionary.customer.customerName;
            break;
        case FilterOption.customerNumber:
            filterInfoTagText = dictionary.customer.customerNumber;
            break;
        case FilterOption.offerNumber:
            filterInfoTagText = dictionary.offer.offerNumber;
            break;
        case FilterOption.offerStatus:
            filterInfoTagText = dictionary.offer.offerStatus;
            break;
        case FilterOption.offerType:
            filterInfoTagText = dictionary.offer.offerTitle;
            break;
        default:
            break;
    }
    return filterInfoTagText + ":";
};
/*https://localhost:5443/api/omoffer
?offernumber=1
&customername=testCustomer
&customernumber=112
&offertypeid=2
&offerstatusid=4
&createdbyuserid=6
&showallusers=false
&showclosedoffers=false
&orderby=CustomerName
&orderbydesc=false
&skiprows=0
&takerows=100000
 */
const OffersSearchField = () => {
    const { dictionary } = useOmTranslation();
    //const offersContext = useOffersContext();

    const offersState = useAppSelector((state) => state.offerList);

    const searchFieldChangedHandler = (searchText: any) => {
        // check if is number
        AppDispatch(offersActions.SetSearchText(searchText));
        if (searchText.length > 1 || searchText === "") {
            AppDispatch(
                offersApi.endpoints.GetOffers.initiate(undefined, {
                    subscribe: false,
                    forceRefetch: true,
                })
            );
        }
    };

    // FilterButton Variablen
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        AppDispatch(offersActions.SetSearchFilterOption(event.target.value as FilterOption));
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const FilterButton = (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{
                    borderRadius: "15%",
                    paddingRight: "unset",
                    margin: "unset",
                    marginRight: "-7px",
                    padding: "2px",
                }}
            >
                <FilterAltIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuList dense>
                    {/*                     <MenuItem>
                        <FormControlLabel
                            onClick={handleClose}
                            value="createUser"
                            control={
                                <Radio
                                    size="small"
                                    checked={offersContext?.filterValue === FilterOption.createUser}
                                    onChange={handleChange}
                                    value={FilterOption.createUser}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "createUser" }}
                                />
                            }
                            label={dictionary.offer.createdBy}
                        />
                    </MenuItem> */}
                    <MenuItem>
                        <FormControlLabel
                            onClick={handleClose}
                            value="customer"
                            control={
                                <Radio
                                    size="small"
                                    checked={
                                        offersState.offerSearchOption.filterOption ===
                                        FilterOption.customer
                                    }
                                    onChange={handleChange}
                                    value={FilterOption.customer}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "customer" }}
                                />
                            }
                            label={dictionary.customer.customerName}
                        />
                    </MenuItem>
                    <MenuItem>
                        <FormControlLabel
                            onClick={handleClose}
                            value="customerNumber"
                            control={
                                <Radio
                                    size="small"
                                    checked={
                                        offersState.offerSearchOption.filterOption ===
                                        FilterOption.customerNumber
                                    }
                                    onChange={handleChange}
                                    value={FilterOption.customerNumber}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "customerNumber" }}
                                />
                            }
                            label={dictionary.customer.customerNumber}
                        />
                    </MenuItem>
                    <MenuItem>
                        <FormControlLabel
                            onClick={handleClose}
                            value="offerNumber"
                            control={
                                <Radio
                                    size="small"
                                    checked={
                                        offersState.offerSearchOption.filterOption ===
                                        FilterOption.offerNumber
                                    }
                                    onChange={handleChange}
                                    value={FilterOption.offerNumber}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "offerNumber" }}
                                />
                            }
                            label={dictionary.offer.offerNumber}
                        />
                    </MenuItem>
                    {/*                     <MenuItem>
                        <FormControlLabel
                            onClick={handleClose}
                            value="offerStatus"
                            control={
                                <Radio
                                    size="small"
                                    checked={
                                        offersContext?.filterValue === FilterOption.offerStatus
                                    }
                                    onChange={handleChange}
                                    value={FilterOption.offerStatus}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "offerStatus" }}
                                />
                            }
                            label={dictionary.offer.offerStatus}
                        />
                    </MenuItem> 
                    <MenuItem>
                        <FormControlLabel
                            onClick={handleClose}
                            value="offerType"
                            control={
                                <Radio
                                    size="small"
                                    checked={offersContext?.filterValue === FilterOption.offerType}
                                    onChange={handleChange}
                                    value={FilterOption.offerType}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "offerType" }}
                                />
                            }
                            label={dictionary.offer.offerTitle}
                        />
                    </MenuItem>*/}
                </MenuList>
            </Menu>
        </InputAdornment>
    );
    return (
        <>
            <SearchField
                onSearchFieldChanged={searchFieldChangedHandler}
                searchFilterInfoText={useFilterInfoTagText(
                    offersState.offerSearchOption.filterOption
                )}
            >
                {FilterButton}
            </SearchField>
        </>
    );
};

export default OffersSearchField;
