import React, { useState } from "react";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useOmTranslation } from "../../../translations/OmTranslationContext";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { useAppSelector } from "../../../common/hooks";
import SelectedCustomerDetail from "../../customer/SelectCustomerDetail";
import CustomerSelectDialog from "../../customer/SelectCustomerDialog";

const HeaderCustomerInfo = () => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const customerState = useAppSelector((state) => state.customer);
    //#endregion Component state variables
    //#region Fetch requests

    //#endregion Fetch requests
    //#region Component effects

    //#endregion Component effects
    //#region Functions

    //#endregion Functions
    //#region Event handlers
    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    //#endregion Event Handlers
    //#region Markup helper variables

    //#endregion Markup helper variables
    //const ArrowDropUpCircleIcon = <ArrowDropDownCircleIcon sx={{ rotate: "180" }} />;
    const rotate = () => {
        return isOpen ? "rotate(180deg)" : "rotate(0deg)";
    };
    //#region Markup
    return (
        <React.Fragment>
            <Grid item xs={4}>
                <Stack direction={"row"} alignItems="center">
                    {/*                     {customerState.gwMainAddress.crmLocationGuidStr !== "" ? (
                        <IconButton onClick={handleClick}>
                            <ArrowDropDownCircleIcon
                                fontSize="medium"
                                sx={{
                                    transform: rotate,
                                }}
                            />
                        </IconButton>
                    ) : null} */}
                    <Typography sx={{ width: "180px" }} variant="body1">
                        {dictionary.customer.selectCustomer}
                    </Typography>
                    <Box>
                        <CustomerSelectDialog></CustomerSelectDialog>
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={7}>
                <Stack direction={"row"} alignItems="center" sx={{ height: "100%" }}>
                    {customerState.mainLocation.locationId > 0 ? (
                        <>
                            <Typography sx={{ width: "180px" }} variant="body1">
                                {dictionary.customer.customerName}:
                            </Typography>
                            <Typography variant="body1">
                                {customerState.mainLocation.customerName},{" "}
                                {customerState.mainLocation.street},{" "}
                                {customerState.mainLocation.town}
                            </Typography>
                        </>
                    ) : null}
                </Stack>
            </Grid>
            <Grid item xs={1}>
                <Stack direction={"row"} alignItems="center" justifyContent={"flex-end"}>
                    {customerState.mainLocation.locationId > 0 ? (
                        <IconButton onClick={handleClick}>
                            <ArrowDropDownCircleIcon
                                fontSize="medium"
                                sx={{
                                    transform: rotate,
                                }}
                            />
                        </IconButton>
                    ) : null}
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {isOpen ? (
                    <SelectedCustomerDetail
                        selectedCustomer={customerState.mainLocation}
                        selectedLocation={customerState.installLocation}
                    />
                ) : null}
            </Grid>
        </React.Fragment>
    );
    //#endregion Markup
};
export default HeaderCustomerInfo;

/*

return (
        <Box pt={1} pb={1}>
            <Stack
                p={1}
                direction={"row"}
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: "30px",
                    width: "100%",
                }}
            >
                <Stack direction={"row"} alignItems="center">
                    <Typography
                        sx={{
                            width: "250px",
                        }}
                        variant="body1"
                    >
                        {dictionary.customer.selectCustomer}
                    </Typography>
                    <Box sx={{ width: "100%" }}>
                        <CustomerSelectDialog></CustomerSelectDialog>
                    </Box>
                </Stack>
                <Stack direction={"row"} alignItems="center">
                    {customerState.gwMainAddress.crmLocationGuidStr !== "" ? (
                        <IconButton onClick={handleClick}>
                            <ArrowDropDownCircleIcon
                                fontSize="medium"
                                sx={{
                                    transform: rotate,
                                }}
                            />
                        </IconButton>
                    ) : null}
                </Stack>
            </Stack>
            {isOpen ? (
                <SelectedCustomerDetail
                    selectedGwCustomer={customerState.gwMainAddress}
                    selectedGwLocation={customerState.gwAlternativeAddress}
                />
            ) : null}
        </Box>
    );

*/
