import React, { useState, useContext, useEffect } from 'react';
import IBrand from '../Interfaces/Context/IBrand';
import IProviderProps from '../Interfaces/Props/IProviderProps';

const consense: IBrand = {
    primaryColor: "#EB6705",
    logo: "consense_logo",
    companyName: "Consense GmbH",
    privacyProtection: "https://www.consense.de/datenschutz",
    imprintUri: "https://www.consense.de/impressum",
    brand: "consense"
};

const byon: IBrand = {
    primaryColor: "#BC1123",
    logo: "byon_logo",
    companyName: "byon GmbH",
    privacyProtection: "https://www.byon.de/datenschutz",
    imprintUri: "https://www.byon.de/impressum",
    brand: "byon"
};

const espGroup: IBrand = {
    primaryColor: "#171C32",
    logo: "esp_logo",
    companyName: "ESP.GROUP GmbH",
    privacyProtection: "https://www.espgroup.de/datenschutz",
    imprintUri: "https://www.espgroup.de/impressum",
    brand: "ESP.GROUP"
};

let brand = byon;

if(window.location.href.indexOf("consense") !== -1) {
    brand = consense;
}
else if(window.location.href.indexOf("espgroup") !== -1) {
    brand = espGroup;
}
else if(window.location.href.indexOf("localhost") !== -1) {
    brand = espGroup;
}


const UXContext = React.createContext<IBrand>(brand);

export function useUX() {
    return useContext(UXContext);
}

export const UXProvider: React.FC<IProviderProps> = ({ children }) => {
    const [values] = useState<IBrand>(brand);

    /* 
    This approach is to slow so we can't use it at the moment...
    The default will be shown shortly and when the request to the backend is complete
    you see the right brand which is defined by it
    useEffect(() => {
        async function fetchBranding() {
            const defaultHeader = new Headers();
            defaultHeader.append('Access-Control-Allow-Origin', '*');
            defaultHeader.append('Content-Type', 'application/json');

            let response = await fetch(BackendRoutes.brandingConfig, {
                method: 'GET',
                headers: defaultHeader
            });
            response = await response.json();

            setValues(response);

            document.title = `${response.companyName} Customer Portal`;
        }

        fetchBranding();
    }, [setValues]);
    */
    
    useEffect(() => {
        document.title = `${values.brand} Portal`;
    });

    return <UXContext.Provider value={values}>{children}</UXContext.Provider>;
};