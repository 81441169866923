import React, { useState, useEffect } from 'react';

import { 
    Dialog, Autocomplete, DialogTitle, DialogActions,
    DialogContent, FormGroup, FormControlLabel, Switch, 
    Button, TextField
} from '@mui/material';

import { useLanguage } from '../../Contexts/LanguageContext';
import IUserRightsForm from '../../Interfaces/Props/IUserRightsForm';
import { IRole } from '../../Interfaces/User/IRoles';

interface ICheckedSwitches {
    [key:string|number]: boolean
}

const UserRightsForm = (props: IUserRightsForm) => {
    const { dictionary } = useLanguage();
    const [savedRights, setSavedRights] = useState<Array<string|number>>([]);
    const [checkedSwitches, setCheckedSwitches] = useState<ICheckedSwitches>({});
    const [rerender, setRerender] = useState(false);

    const switchRefs: Record<number, React.RefObject<HTMLButtonElement>> = {};

    const handleClose = () => {
        props.handleCancel();
    }

    useEffect(() => {
        if(props.presetRights && props.presetRights.length > 0) {
            let newCheckedSwitches: ICheckedSwitches = {};
            let savedRightsNew: Array<string|number> = [];
    
            for(let i=0; i<props.presetRights.length; i++) {
                newCheckedSwitches[props.presetRights[i]] = true;
                savedRightsNew.push(props.presetRights[i]);
            }
    
            setCheckedSwitches(newCheckedSwitches);
            setSavedRights(savedRightsNew);
        }
    }, [props]);

    const handleSubmit = () => {
        props.handleSubmit(savedRights);
    }

    const isChecked = (rgtId: string|number) => {
        if(checkedSwitches[rgtId] !== undefined) {
            return checkedSwitches[rgtId];
        }

        return false;
    }

    function handleRoleChange(event: React.SyntheticEvent<Element, Event>, data: IRole | null) {
        if(data !== null) {
            const roleRights = Object.keys(data.roleRights);

            let savedRightsNew = [];
            let newCheckedSwitches: ICheckedSwitches = {};

            for(let i=0; i<roleRights.length; i++) {
                const roleRightKey = roleRights[i];

                if(switchRefs[parseInt(roleRightKey)] !== undefined) {
                    newCheckedSwitches[roleRightKey] = true;

                    savedRightsNew.push(roleRightKey);
                }
            }

            setCheckedSwitches(newCheckedSwitches);
            setSavedRights(savedRightsNew);
        }
    }

    let showRoleSelection = false;
    if(props.isSuperUser && !props.isEdit) {
        showRoleSelection = true;
    }

    return (
        <Dialog 
            open={props.open}
            onClose={props.handleCancel}
            aria-labelledby="rights-form-title"
        >
            <DialogTitle id="rights-form-title">{dictionary.userForm.rights.assign_rights}</DialogTitle>
            <DialogContent>
                {
                    showRoleSelection &&
                    <Autocomplete 
                        style={{marginTop: '5px', marginBottom: '5px'}}
                        options={props.roles.map((role) => {
                            return {
                                roleId: role.roleId,
                                label: role.roleName,
                                roleRights: role.roleRights
                            }
                        })}
                        renderInput={(params) => <TextField {...params} label={dictionary.userForm.roles} />}
                        onChange={handleRoleChange}
                        isOptionEqualToValue={(option, value) => option.roleId === value.roleId}
                    />
                }
                <FormGroup>
                    {
                        props.defaultRights.map((rightData) => {
                            switchRefs[parseInt(''+rightData.rgtId)] = React.createRef<HTMLButtonElement>();

                            return (
                                <FormControlLabel 
                                    key={rightData.rgtId}
                                    control={
                                        <Switch 
                                            disabled={
                                                parseInt(''+rightData.parentId) !== 0 ? 
                                                    checkedSwitches[parseInt(''+rightData.parentId)] !== undefined ?
                                                    !checkedSwitches[parseInt(''+rightData.parentId)] 
                                                    : 
                                                    true
                                                :
                                                false
                                            }
                                            ref={switchRefs[parseInt(''+rightData.rgtId)]}
                                            checked={isChecked(parseInt(''+rightData.rgtId))}
                                            value={rightData.rgtId}
                                            onChange={(event) => {
                                                // the state variable is not allowed to change so
                                                // we save it in a new temp variable and manipulate that
                                                let savedRightsNew = savedRights;
                                                let newCheckedSwitches = checkedSwitches;

                                                newCheckedSwitches[event.target.value] = event.target.checked;

                                                // if the switch is turned on
                                                if(event.target.checked) {
                                                    // we put the right into a array which we will send 
                                                    // later to the backend
                                                    savedRightsNew.push(event.target.value);
                                                }
                                                else {
                                                    // if the switch was turned off
                                                    // we will filter for this switch and remove it from our list of active rights
                                                    const index = savedRightsNew.indexOf(event.target.value);
                                                    if (index !== -1) {
                                                        savedRightsNew.splice(index, 1);
                                                    }
                                                }

                                                // save the rights back into the state
                                                setSavedRights(savedRightsNew);
                                                setCheckedSwitches(newCheckedSwitches);
                                                setRerender(!rerender);
                                            }}
                                        />
                                    } 
                                    label={rightData.displayName} 
                                />
                            
                            );
                        })
                    }
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>{dictionary.userForm.apply}</Button>
                <Button onClick={handleClose}>{dictionary.userForm.cancel}</Button>
            </DialogActions>
        </Dialog>
    );
}
export default UserRightsForm;