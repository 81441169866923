import { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import moment from 'moment';
import parse from 'html-react-parser';
import { Sorting } from '@devexpress/dx-react-grid';

import { useLanguage } from '../Contexts/LanguageContext';
import PageHeader from '../Components/Header/PageHeader';
import FieldSet from '../Components/Form/FieldSet';
import AnalysisSettings from '../Components/Statistics/AnalysisSettings';
import BackendRoutes from '../Constants/BackendRoutes';
import { useAuth } from '../Contexts/AuthContext';
import StatsGrid from '../Components/Statistics/StatsGrid';
import IFieldSetData from '../Interfaces/Statistics/IFieldSetData';
import IFormData from '../Interfaces/Statistics/IFormData';
import IGridData from '../Interfaces/Statistics/IGridData';
import INumbers from '../Interfaces/Statistics/INumbers';
import INumbersRepsonse from '../Interfaces/Statistics/INumbersRepsonse';
import { pageMain, gridPage } from '../Theme/LayoutDefinitions';


const StatisticsPageMainSection = () => {
    const { dictionary } = useLanguage();
    const { getHeader } = useAuth();
    
    const [sorting, setSorting] = useState<Sorting[]>([]);
    const [fetchOnce, setFetchOnce] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [showNumberSelection, setShowNumberSelection] = useState(true);
    const [numbers, setNumbers] = useState<INumbers[]>([]);
    const [fieldsetData, setFieldsetData] = useState<IFieldSetData[]>([]);
    const [gridData, setGridData] = useState<IGridData>({
        data: [],
        columns: [],
        columnExtensions: [],
        totalCount: 0
    });

    const monthNameArray = [
        dictionary.stats.monthNames.Jan, dictionary.stats.monthNames.Feb, dictionary.stats.monthNames.Mrz, 
        dictionary.stats.monthNames.Apr, dictionary.stats.monthNames.Mai, dictionary.stats.monthNames.Jun, 
        dictionary.stats.monthNames.Jul, dictionary.stats.monthNames.Aug, dictionary.stats.monthNames.Sep,
        dictionary.stats.monthNames.Okt, dictionary.stats.monthNames.Nov, dictionary.stats.monthNames.Dez
    ];
    
    const [formData, setFormData] = useState<IFormData>({
        statsType: 'success_analytics',
        time:  'day',
        date: new Date(moment().toString()),
        week: {
            selectedValue: {
                week: moment().isoWeek(), year: moment().year(), optionName: 'KW ' + moment().isoWeek() + ' ' + moment().year()
            },
            calendarWeek: moment().isoWeek(),
            year: moment().year()
        },
        month: {
            selectedValue: { month: moment().month(), year: moment().year(), optionName: monthNameArray[moment().month()] + ' ' + moment().year() },
            month: moment().month(),
            year: moment().year()
        },
        format: 'web',
        selectedNumbers: []
    });

    useEffect(() => {
        if(formData.statsType === 'rawdata') {
            setSorting([
                { columnName: 'date', direction: 'asc' },
                { columnName: 'time', direction: 'asc' }
            ]);
        }
        else {
            setSorting([
                { columnName: 'interval', direction: 'asc' }
            ]);
        }
    }, [formData.statsType]);

    // make requests to the backend and do them only once
    useEffect(() => {
        async function fetchNumbers() {
            let response = await fetch(BackendRoutes.stats.numbers, {
                method: 'GET',
                headers: getHeader(),
            });

            let responseData: INumbersRepsonse = await response.json();

            setNumbers(responseData.numberData);

            setShowNumberSelection(false);
            if(responseData.numberData.length > 1) {
                setShowNumberSelection(true);
            }
        }

        if(fetchOnce) {
            fetchNumbers();
            setFetchOnce(false);
        }
    },[fetchOnce, setFetchOnce, getHeader]);

    return (
        <Box component='main' sx={pageMain}>
            <PageHeader 
                headerTitle={dictionary.stats.statsHeader}
            />
            <Box sx={gridPage}>
                <FieldSet
                    legendText={dictionary.stats.analyticsSettings}
                    width='99%'
                    isCollapsible={true}
                    isCollapsed={isCollapsed}
                    trackCollapsedState={setIsCollapsed}
                >
                    <AnalysisSettings 
                        fieldsetData={fieldsetData}
                        setFieldsetData={setFieldsetData}
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                        numbers={numbers}
                        showNumberSelection={showNumberSelection}
                        formData={formData}
                        setFormData={setFormData}
                        setGridData={setGridData}
                    />
                </FieldSet>
                <FieldSet
                    legendText={dictionary.stats.analytic}
                    width='99%'
                    isCollapsible={true}
                    isCollapsed={!isCollapsed}
                >
                    <StatsGrid 
                        data={gridData.data}
                        columns={gridData.columns}
                        totalCount={gridData.totalCount}
                        columnExtensions={gridData.columnExtensions}
                        noGridDataText={dictionary.noGridData}
                        sortText={dictionary.stats.sort}
                        sorting={sorting}
                        setSorting={setSorting}
                    />
                </FieldSet>
                <br/>
                <Typography variant="subtitle2" align="center">
                    {parse(dictionary.stats.statsInfo)}
                </Typography>
            </Box>
        </Box>
    )
}

export default StatisticsPageMainSection;
