import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { useAppSelector } from "../../../common/hooks";
import { uiRoutes } from "../../../configuration/offerMakerRoutes";
import { useOmTranslation } from "../../../translations/OmTranslationContext";
import { FormName } from "../../forms/IFormParams";

interface IOfferForm {
    salesWorker: "salesWorker";
    productType: "productType";
    contractDuration: "contractDuration";
    enteredEmail: "enteredEmail";
    contactPerson: "contactPerson";
    offerTitle: "offerTitle";
}

const OfferFooterInfo = () => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const navigate = useNavigate();
    const offerForm = useAppSelector((state) => state.forms[FormName.offerForm]);
    const [isValid, setIsValid] = useState<boolean>(false);

    //#endregion Component state variables
    //#region Fetch requests

    //#endregion Fetch requests
    //#region Component effects
    useEffect(() => {
        if (offerForm) {
            let validationArr: Array<boolean> = [];
            for (const [key, value] of Object.entries(offerForm.fields)) {
                let tempValue: any = { ...value };
                validationArr.push(tempValue.isValid);
            }
            setIsValid(validationArr.every((validation) => validation === true));
        }
    }, [offerForm]);
    //#endregion Component effects
    //#region Functions

    //#endregion Functions
    //#region Event handlers
    const cancelClickHandler = (event: any) => {
        navigate(uiRoutes.offers);
    };

    const saveClickHandler = (event: any) => {
        // navigate(uiRoutes.);
    };
    //#endregion Event Handlers
    //#region Markup helper variables

    //#endregion Markup helper variables
    //#region Markup
    return (
        <Stack
            p={1}
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
            sx={{
                height: "50px",
                width: "100%",
                // backgroundColor: "green",
                // borderColor: (theme) => theme.palette.primary.main,
                // borderStyle: "solid",
                // borderWidth: "1px",
            }}
        >
            <Stack direction={"row"} spacing={2}>
                <Button variant="contained" onClick={cancelClickHandler}>
                    {dictionary.cancel}
                </Button>
                <Button disabled={!isValid} variant="contained" onClick={() => undefined}>
                    {dictionary.save}
                </Button>
            </Stack>
            <Stack direction={"row"}>
                <Button disabled={!isValid} variant="contained" onClick={() => undefined}>
                    {dictionary.offer.offerPreview}
                </Button>
            </Stack>
            <Stack direction={"row"}>
                <Button disabled={!isValid} variant="contained" onClick={() => undefined}>
                    {dictionary.offer.submitOffer}
                </Button>
            </Stack>
        </Stack>
    );
    //#endregion Markup
};
export default OfferFooterInfo;
