import React, { useState } from "react";
import { useAppSelector } from "../../../common/hooks";
import { intialOfferTemplate, IOfferTemplate } from "../interfaces/IProductVersion";

type Props = {
    children?: React.ReactNode;
};
const OfferAutoSave = ({ children }: Props) => {
    //#region Component state variables
    const [newOffer, setNewOffer] = useState<IOfferTemplate>({ ...intialOfferTemplate });
    const offer = useAppSelector((state) => state.offer);
    const forms = useAppSelector((state) => state.forms);
    const addEditGrid = useAppSelector((state) => state.offerAddEditGrid);
    const customer = useAppSelector((state) => state.customer);
    //#endregion Component state variables
    //#region Fetch requests

    //#endregion Fetch requests
    //#region Component effects
    /*     useEffect(() => {
        const offerForm = forms[FormName.offerForm];
        const offerProductForm = forms[FormName.offerProductForm];

        setNewOffer((prevState) => {
            return { ...prevState, omOfferVersion.omOfferVersionGroups: {}};
        });
    }, [forms, addEditGrid, customer]);

    useEffect(() => {
        saveState(newOffer);
    }, [newOffer]); */

    /*     useEffect(() => {
        saveState(offer);
    }, [offer]); */
    //#endregion Component effects
    //#region Functions
    function loadState<T>(state: T): T | undefined {
        try {
            const serializedState = localStorage.getItem("state");

            if (serializedState === null) {
                return undefined;
            }

            return JSON.parse(serializedState);
        } catch (err) {
            return undefined;
        }
    }

    const saveState = (state: any) => {
        try {
            const serializedState = JSON.stringify(state);
            localStorage.setItem("state", serializedState);
        } catch (err) {
            // die
        }
    };
    //#endregion Functions
    //#region Event handlers

    //#endregion Event Handlers
    //#region Markup helper variables

    //#endregion Markup helper variables
    //#region Markup
    return <>{children}</>;
    //#endregion Markup
};
export default OfferAutoSave;
