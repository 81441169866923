import { 
    Grid, IconButton, Tooltip, InputAdornment
} from '@mui/material';

import { 
    Visibility, VisibilityOff
} from '@mui/icons-material';

import PasswordGenerator from './PasswordGenerator';
import { useLanguage } from '../../Contexts/LanguageContext';
import TextField from '../Form/TextField';
import FieldSet from '../Form/FieldSet';
import IPasswordFields from '../../Interfaces/Props/IPasswordFields';

const PasswordFields = (props: IPasswordFields) => {
    const { dictionary } = useLanguage();

    return (
        <Grid container rowSpacing={0} columnSpacing={1}>
            <Grid item xs={6}>
                <FieldSet
                    legendText={props.isEdit ? dictionary.userForm.setNewPassword : dictionary.userForm.setPassword}
                    checkboxToggle={true}
                    isCollapsible={true}
                    isCollapsed={!props.useSetPassword}
                    width='100%'
                    checkboxName='passwordSelection'
                    handleCheckboxChange={() => {
                        props.setDisablePasswordFields(!props.disablePasswordFields);
                        props.setUseSetPassword(!props.useSetPassword);
                        props.setUsePasswordLink(!props.usePasswordLink);
                        
                        props.setFormValues({
                            ...props.formValues,
                            sendUserDataByMail: !props.formValues.sendUserDataByMail
                        });
                    }}
                >
                    <Grid container rowSpacing={0} columnSpacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                required={true}
                                inputId='password'
                                autoComplete='new-password'
                                title={dictionary.userForm.password}
                                value={props.formValues.password}
                                errorText={props.formErrors.password}
                                type={props.showPassword ? "text" : "password"}
                                disabled={props.disablePasswordFields}
                                onChange={(event) => props.handleChange(event, 'password')}
                                endAdornment={(
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={props.handleClickShowPassword}
                                            onMouseDown={props.handleMouseDownPassword}
                                        >
                                            {
                                                props.showPassword ? 
                                                <Tooltip title={dictionary.userForm.hidePassword}> 
                                                    <Visibility /> 
                                                </Tooltip>
                                                : 
                                                <Tooltip title={dictionary.userForm.showPassword}>
                                                    <VisibilityOff />
                                                </Tooltip>
                                            }
                                        </IconButton>
                                        <PasswordGenerator 
                                            formValues={props.formValues}
                                            setFormValues={props.setFormValues}
                                            passwordField='password'
                                            repeatPasswordField='repeatPassword'
                                        />
                                    </InputAdornment>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required={true}
                                inputId='repeatPassword'
                                autoComplete='new-password'
                                title={dictionary.userForm.repeatPassword}
                                value={props.formValues.repeatPassword}
                                errorText={props.formErrors.repeatPassword}
                                type='password'
                                disabled={props.disablePasswordFields}
                                onChange={(event) => props.handleChange(event, 'repeatPassword')}
                                endAdornment={(
                                    <InputAdornment position="end">
                                        <div style={{width: '85px'}}>&nbsp;</div>
                                    </InputAdornment>
                                )}
                            />
                        </Grid>
                    </Grid>
                </FieldSet>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <FieldSet
                            legendText={dictionary.userForm.userSetsPassword}
                            checkboxToggle={true}
                            isCollapsible={true}
                            isCollapsed={!props.usePasswordLink}
                            width='100%'
                            checkboxName='sendPasswordLink'
                            handleCheckboxChange={() => {
                                props.setDisablePasswordFields(!props.disablePasswordFields);
                                props.setUseSetPassword(!props.useSetPassword);
                                props.setUsePasswordLink(!props.usePasswordLink);

                                props.setFormValues({
                                    ...props.formValues,
                                    sendUserDataByMail: !props.formValues.sendUserDataByMail
                                });
                            }}
                        >
                            {dictionary.userForm.userSetPasswordDesctiption}
                        </FieldSet>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PasswordFields;