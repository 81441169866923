import { omApi } from './omApi';
import { getJwtHeader } from '../features/session/omSessionActions';
import { ILocation } from '../interfaces/customer/ILocation';
import { offerMakerRoutes } from '../configuration/offerMakerRoutes';
import { IProduct, IProductQueryParams } from '../interfaces/product/IProduct';


const productApiTags = ['product', 'products'];
export const productApi = omApi.enhanceEndpoints({ addTagTypes: productApiTags }).injectEndpoints({

    endpoints: (builder) => ({
        GetProducts: builder.query<Array<IProduct>, IProductQueryParams>({
            query: (params: any) => ({
                url: offerMakerRoutes.omarticle(params),
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['product'],
        }),
        GetProductById: builder.query<IProduct, IProductQueryParams>({
            query: (params: any) => ({
                url: offerMakerRoutes.omarticleById(params),
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['product'],
        }),
    }),
});


export const {
    useGetProductsQuery,
    useGetProductByIdQuery,
} = productApi;


/*             transformResponse: (response) =>
            {
                if (response)
                {
                    AppDispatch(customerActions.SetCustomer(response as ICustomer));
                }
                return response as ICustomer | Promise<ICustomer>;
            }, */




/* const [trigger, result] = productApi.endpoints.GetCustomerByGwGuid.useLazyQuery();

productApi.endpoints.GetCustomerByGwGuid.initiate(
    selectedCustomers.crmLocationGuidStr,
    { subscribe: false, forceRefetch: true }
) */