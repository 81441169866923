import IBackendRoutes from '../Interfaces/IBackendRoutes';

// we use an empty base url in the production environment
let baseUrl = "";

// check if we are in the development environment if this is the case
// we change the base url to the url where the development backend can
// be reached
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
{
    // if the url should be changed do not forget the tailing /
    baseUrl = "https://web-cluster-entwicklung-int.byon.net/cg_portal/";
}

const routes: IBackendRoutes = {
    login: baseUrl + "login",
    userInfo: baseUrl + "userinfo",
    menu: baseUrl + "menu",
    user: baseUrl + "users",
    roles: baseUrl + "roles",
    usercolumns: baseUrl + "usercolumns",
    rights: baseUrl + "rights",
    clients: baseUrl + "clients",
    customers: baseUrl + "customers",
    changeusersettings: baseUrl + "changeusersettings",
    brandingConfig: baseUrl + "branding/config",
    billing: baseUrl + "billing",
    billingColumns: baseUrl + "billing/columns",
    billingDownload: baseUrl + "billing/download",
    provision: baseUrl + "provision",
    provisionColumns: baseUrl + "provision/columns",
    provisionDownload: baseUrl + "provision/download",
    report: baseUrl + "report",
    reportColumns: baseUrl + "report/columns",
    reportDownload: baseUrl + "report/download",
    reseller: baseUrl + "reseller",
    resellerColumns: baseUrl + "reseller/columns",
    resellerDownload: baseUrl + "reseller/download",
    resellerNews: baseUrl + "resellernews",
    resellerNewsColumns: baseUrl + "resellernews/columns",
    resellerNewsDownload: baseUrl + "resellernews/download",
    badgeCounter: baseUrl + "badgecounter",
    resetBadgeCounter: baseUrl + "resetbadgecounter",
    stats: {
        numbers: baseUrl + "stats/numbers",
        analyse: baseUrl + "stats/analyse",
        pdf: baseUrl + "stats/pdf",
        csv: baseUrl + "stats/csv",
    },
    otp: {
        jwt: baseUrl + "otp/jwt",
        verify: baseUrl + "otp/verify",
        update: baseUrl + "otp/update",
    },
    captcha: baseUrl + 'captcha',
    resetPassword: baseUrl + 'resetpassword',
    checkSession: baseUrl + 'checksession',
    checkuser: baseUrl + 'checkuser'
};


export { baseUrl, routes };
export default routes;
