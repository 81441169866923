
import { useState, useMemo, createContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { byonLight, byonDark, consenseLight, consenseDark, espGroupLight, espGroupDark } from './byonThemes';
import IThemeChanger from '../Interfaces/Props/IThemeChanger';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

const ThemeChanger = (props: IThemeChanger) => {
    const [mode, setMode] = useState<'light' | 'dark'>('light');

    const colorMode = useMemo(() => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
    }),[]);


    let themeToUse = mode === 'light' ? byonLight : byonDark;
    if(window.location.href.indexOf("consense") !== -1) {
        themeToUse = mode === 'light' ? consenseLight : consenseDark;
    }
    else if(window.location.href.indexOf("espgroup") !== -1) {
        themeToUse = mode === 'light' ? espGroupLight : espGroupDark;
    }
    else if(window.location.href.indexOf("localhost") !== -1) {
        themeToUse = mode === 'light' ? espGroupLight : espGroupDark;
    }
  
    const theme = useMemo(() => createTheme(themeToUse), [themeToUse]);
  
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                {props.children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default ThemeChanger;