import { omApi } from './omApi';
import { getJwtHeader } from '../features/session/omSessionActions';
import { ILocation } from '../interfaces/customer/ILocation';
import { offerMakerRoutes } from '../configuration/offerMakerRoutes';
import { IProductGroup } from '../features/offer/interfaces/IProductGroup';


const productCategorieApiTags = ['categorie', 'categories'];
export const productCategorieApi = omApi.enhanceEndpoints({ addTagTypes: productCategorieApiTags }).injectEndpoints({

    endpoints: (builder) => ({
        GetCategories: builder.query<Array<IProductGroup>, void>({
            query: () => ({
                url: offerMakerRoutes.omarticleCategorie,
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['categorie'],
        }),
        GetCategorieById: builder.query<IProductGroup, string | number>({
            query: (categorieId: any) => ({
                url: offerMakerRoutes.omarticleCategorieById(categorieId),
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['categorie'],
        }),
    }),
});


export const {
    useGetCategoriesQuery,
    useGetCategorieByIdQuery,
} = productCategorieApi;


/*             transformResponse: (response) =>
            {
                if (response)
                {
                    AppDispatch(customerActions.SetCustomer(response as ICustomer));
                }
                return response as ICustomer | Promise<ICustomer>;
            }, */




/* const [trigger, result] = productCategorieApi.endpoints.GetCustomerByGwGuid.useLazyQuery();

productCategorieApi.endpoints.GetCustomerByGwGuid.initiate(
    selectedCustomers.crmLocationGuidStr,
    { subscribe: false, forceRefetch: true }
) */