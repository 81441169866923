import { Grid } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import BackendRoutes from "../Constants/BackendRoutes";
import TextField from './Form/TextField';
import Image from './Image';
import { useLanguage } from '../Contexts/LanguageContext';
// import { useMediaQueryData } from '../Contexts/MediaQueryContext';

const Captcha = (props: any) => {
    const { dictionary, toUpperCase } = useLanguage();
    const [ captcha, setCaptcha ] = useState('');
    const [ loading, setLoading ] = useState(true);
    const [ fetchOnce, setFetchOnce ] = useState(true);

    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const fetchCaptcha = useCallback(async (reload: boolean) => {
        setLoading(true);

        const defaultHeader = new Headers();
        defaultHeader.append('Access-Control-Allow-Origin', '*');
        defaultHeader.append('Content-Type', 'application/json');
        

        let url = BackendRoutes.captcha;
        if(reload && props.formValues.guid !== '') {
            url = BackendRoutes.captcha + '/' + props.formValues.guid;
        }

        let response = await fetch(url, {
            method: 'GET',
            headers: defaultHeader,
        });

        let responseData: any = await response.json();

        setCaptcha(responseData.imgData);
        props.setCaptchaGuid(responseData.guid);
        setFetchOnce(false);
        setLoading(false);
    }, [props]);

    useEffect(() => {
        if(fetchOnce) {
            fetchCaptcha(false);
        }
    }, [fetchOnce, fetchCaptcha]);

    const getNewCaptcha = () => {
        props.clearCaptcha();
        fetchCaptcha(true);
    }

    let leftGridSize = 7,
        rightGridSize = 5;

    if(isMobile || isTablet) {
        leftGridSize = 8;
        rightGridSize = 4;
    }

    return (
        <Grid container rowSpacing={0} columnSpacing={0} sx={{marginTop: '15px'}}>
            <Grid item xs={leftGridSize}>
                <Image 
                    loading={loading}
                    src={captcha}
                    showReload={true}
                    reloadCode={dictionary.reloadCode}
                    getNewCaptcha={getNewCaptcha}
                    alt="captcha"
                />
            </Grid>
            <Grid item xs={rightGridSize}>
                <TextField 
                    required={true}
                    inputId='captchVerify'
                    title={toUpperCase('Code')}
                    value={props.formValues.captcha}
                    errorText={props.formErrors.captcha}
                    onChange={(event: any) => props.handleChange(event, 'captcha')}
                />
            </Grid>
        </Grid>
    );
}

export default Captcha;