import {
    FormControl, InputLabel, Select, FormHelperText 
} from '@mui/material';

import ISelectField from '../../Interfaces/Props/ISelectField';

const SelectField = (props: ISelectField) => {
    return (
        <FormControl className='field language' fullWidth>
            <InputLabel id={props.inputId+'Label'} htmlFor={props.inputId} required={props.required}>{props.title}</InputLabel>
            <Select  
                style={{marginTop: '5px'}}
                id={props.inputId}
                labelId={props.inputId+'Label'}
                aria-describedby={props.inputId+'ErrorHelper'}
                required={props.required}
                value={props.value}
                onChange={props.onChange}
                fullWidth 
                native
                variant="standard"
                disableUnderline
            >
            {
                props.options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))
            }
            </Select>
            <FormHelperText id={props.inputId+'ErrorHelper'} error>{props.errorText}</FormHelperText>
        </FormControl>
    );
};

export default SelectField;