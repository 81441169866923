import { useState /*, useEffect, useCallback*/ } from 'react';
import Paper from '@mui/material/Paper';
import {
    GroupingState, CustomGrouping, DataTypeProvider, SearchState,
    SelectionState
} from '@devexpress/dx-react-grid';
import {
    Grid, VirtualTable, TableHeaderRow, TableGroupRow,
    Toolbar, TableColumnVisibility, ColumnChooser,
    SearchPanel, TableSelection
} from '@devexpress/dx-react-grid-material-ui';
import { 
    LanguageTypeProvider, YesNoTypeProvider, PeriodProvider, FileTypeProvider, 
    FileReadFormatterProvider
} from './GridDataProvider';
import IGroupingGrid from '../../Interfaces/Props/IGroupingGrid';
import { useLanguage } from '../../Contexts/LanguageContext';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadSelection from './DownloadSelection';
import CustomerSelection from './CustomerSelection';
import { ItemCounter } from './ItemCounter';
import { gridPaper } from '../../Theme/LayoutDefinitions';

//import { useAuth } from '../../Contexts/AuthContext';
//import BackendRoutes from '../../Constants/BackendRoutes';

/**
 * Get the row id or key
 * 
 * @param any row 
 * 
 * @returns string | number
 */
const getRowId = (row: any): string | number => {
    if(row.id) {
        return row.id;
    }
    
    if(row.key) {
        return row.key;
    }

    return '';
};

/**
 * Format the time value
 * @param string value 
 * @returns string
 */
const formatTime = (value: string) => {
    const year  = value.substring(0,4);
    const month = value.substring(4,6);

    return month + '-' + year;
}

/**
 * Build the header of the grouping in a specific way
 * 
 * @param TableGroupRow.ContentProps props 
 * @returns JSX.Element
 */
const GroupCellContent = (props: TableGroupRow.ContentProps) => {
    const { dictionary } = useLanguage();

    const row = props.row;
    const column = props.column;
    // @ts-ignore
    const notReadFiles = row.collapsedRows.filter((collapsedRow) => collapsedRow.fileSeen === false);
    // @ts-ignore
    const filesOutsideOfBadgeRange = row.collapsedRows.filter((collapsedRow) => collapsedRow.isFileOutsideOfBadgeRange === true);
    // @ts-ignore
    const filesInsideOfBadgeRange = row.collapsedRows.filter((collapsedRow) => collapsedRow.isFileOutsideOfBadgeRange === false);
   
    let rowValue = row.value;
    let inActive = null;

    if(column.name === 'company') {
        let splitData: Array<string> = rowValue.split('<');
        
        if(splitData.length > 1) {
            rowValue = splitData[0];
            inActive = (splitData[1] === 'N' ? <strong>&nbsp;({dictionary.diabled})</strong> : null);
        }
    }

    let spanSyle = undefined;
    if(filesOutsideOfBadgeRange.length > 0 && notReadFiles.length > 0 && filesInsideOfBadgeRange.length === 0) {
        spanSyle = {
            fontWeight: 'bold'
        }
    }
    else if(notReadFiles.length > 0) {
        spanSyle = {
            color: 'darkblue',
            fontWeight: 'bold'
        }
    }

    return (
        <span style={spanSyle}>
            {
                column.name === 'period' &&
                <strong>{column.title}: </strong>
            }
            {
                column.name === 'period' ? formatTime(row.value) : rowValue
            }
            {inActive}
        </span>
    );
};

/**
 * Get the child groups
 * 
 * @param rows 
 * @returns 
 */
const getChildGroups = (rows: any) => {
    return rows.map((row: any) => ({ key: row.key, childRows: row.items }));
};

// @ts-ignore
const GridRootComponent = props => <Grid.Root {...props} style={{ height: "100%" }} />;

const GroupingGrid = (props: IGroupingGrid) => {
    const { dictionary } = useLanguage();
    const [grouping] = useState(props.defaultGrouping);
    const [expandedGroups, setExpandedGroups] = useState<Array<string>>([]);
    const [selection, setSelection] = useState<Array<string|number>>([]);

    const [languageColumns] = useState(['language']);
    const [yesNoColumns] = useState(['isActive']);
    const [periodColumns] = useState(['period']);
    const [fileTypeColumns] = useState(['fileType']);
    const [actionColumns] = useState(['actionId']);
    const [fileReadColumn] = useState(['fileName']);

    //const [loadingOnce, setLoadingOnce] = useState(true);
    //const { getHeader } = useAuth();

    const DownloadAcionFormatter = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
        return (
            <div>
                <IconButton onClick={() => props.download && props.download(row, false)}><FileDownloadIcon /></IconButton>
                { 
                    row.fileType === 'PDF' ?
                    <IconButton onClick={() => props.download && props.download(row, true)}><PreviewIcon /></IconButton>
                    :
                    null
                }
            </div>
        );
    }

    const DownloadAcionTypeProvider = (props: any) => (
        <DataTypeProvider
            formatterComponent={DownloadAcionFormatter}
            {...props}
        />
    );

    const EditActionFormatter = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
        if(row.allowEdit) {
            return (
                <div>
                    <IconButton onClick={() => props.handleEdit && props.handleEdit(row)}><EditOutlinedIcon /></IconButton>
                    <IconButton onClick={() => props.handleDelete && props.handleDelete(row)}><ClearOutlinedIcon /></IconButton>
                </div>
            );
        }
        else {
            return (
                <div>
                    <IconButton href='#/settings' ><EditOutlinedIcon /></IconButton>
                </div>
            );
        }
    };
    
    const EditActionTypeProvider = (props: any) => (
        <DataTypeProvider
            formatterComponent={EditActionFormatter}
            {...props}
        />
    );

    let gridNoDataText = props.noGridDataText;
    if(props.customerCount && props.customerCount > 10 && props.rows.length === 0 && (props.selectedCustomers && props.selectedCustomers.length === 0)) {
        gridNoDataText = props.noGridCustomerSelected;
    }
    
    return (
        <Paper sx={gridPaper} elevation={0}>
            <Grid
                rows={props.rows}
                columns={props.columns}
                getRowId={getRowId}
                rootComponent={GridRootComponent}
            >
                <SearchState 
                    onValueChange={props.setSearchValue}
                />
                {
                    props.selectByRowClick &&
                    <SelectionState
                        selection={selection}
                        onSelectionChange={setSelection}
                    />
                }
                {
                    props.useLanguageFormater && <LanguageTypeProvider for={languageColumns} />
                }
                {
                    props.useYesNoFormater && <YesNoTypeProvider for={yesNoColumns} />
                }
                {
                    props.useEditActionFormater && <EditActionTypeProvider for={actionColumns} />
                }
                {
                    props.usePeriodFormater && <PeriodProvider for={periodColumns} />
                }
                {
                    props.useFileTypeFormatter && <FileTypeProvider for={fileTypeColumns} />
                }
                {
                    props.useDownloadActionFormater && <DownloadAcionTypeProvider for={actionColumns} />
                }
                {
                    props.useFilesReadFormater && <FileReadFormatterProvider for={fileReadColumn} />
                }
                <GroupingState 
                    grouping={grouping}
                    expandedGroups={expandedGroups}
                    onExpandedGroupsChange={setExpandedGroups}
                />
                <CustomGrouping
                    getChildGroups={getChildGroups}
                    grouping={grouping}
                    expandedGroups={expandedGroups}
                />
                <VirtualTable 
                    columnExtensions={props.columnExtensions || undefined}
                    messages={{
                        noData: gridNoDataText
                    }}
                />
                {
                    props.selectByRowClick &&
                    <TableSelection
                        selectByRowClick
                    />
                }
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={props.hiddenColumns}
                />
                <TableGroupRow
                    contentComponent={GroupCellContent}
                />
                <ItemCounter />
                <Toolbar />
                {
                    props.useDownloadSelection &&
                    <DownloadSelection 
                        downloadSelected={() => {
                            if(props.downloadSelectedFiles) {
                                props.downloadSelectedFiles(selection);
                            }
                            setSelection([]);
                        }}
                        tooltipTitle={dictionary.downloadSelection}
                        activSelection={selection}
                    />
                }
                {
                    props.customerCount && props.customerCount > 10 ?
                    <CustomerSelection 
                        selectedCustomers={props.selectedCustomers}
                        setSelectedCustomers={props.setSelectedCustomers}
                        customers={props.customers ? props.customers : []}
                    />
                    :
                    <SearchPanel 
                        messages={{
                            searchPlaceholder: dictionary.search
                        }}
                    />
                }
                <ColumnChooser 
                    messages={{
                        showColumnChooser: dictionary.showColumn
                    }}
                />
            </Grid>
        </Paper> 
    );
}

export default GroupingGrid;