import { omApi } from './omApi';
import { getJwtHeader } from '../features/session/omSessionActions';
import { ILocation } from '../interfaces/customer/ILocation';
import { offerMakerRoutes } from '../configuration/offerMakerRoutes';


const locationApiTags = ['locations', 'location'];
export const locationApi = omApi.enhanceEndpoints({ addTagTypes: locationApiTags }).injectEndpoints({

    endpoints: (builder) => ({
        GetLocationIdByGwGuid: builder.query<any, string>({
            query: (gwGuid: string) => ({
                url: offerMakerRoutes.locationGetLocationId(gwGuid),
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['location'],
            /*             transformResponse: (response, meta, arg) =>
                        {
                            if (response)
                            {
                                let tempResponse: any = { ...response };
                                let messages = ["hallo hier sieht ihr Location Fehler Meldungen", "Das ist die zweite FehlerMeldung"];
            
                                tempResponse.messages = messages;
                                return tempResponse;
                            }
                            return response;
                        } */
        }),
        GetLocationById: builder.query<ILocation, string>({
            query: (locationId: string) => ({
                url: offerMakerRoutes.locationById(locationId),
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['location'],
        }),
    }),
});


export const {
    useGetLocationIdByGwGuidQuery,
    useGetLocationByIdQuery,
} = locationApi;


/*             transformResponse: (response) =>
            {
                if (response)
                {
                    AppDispatch(customerActions.SetCustomer(response as ICustomer));
                }
                return response as ICustomer | Promise<ICustomer>;
            }, */




/* const [trigger, result] = locationApi.endpoints.GetCustomerByGwGuid.useLazyQuery();

locationApi.endpoints.GetCustomerByGwGuid.initiate(
    selectedCustomers.crmLocationGuidStr,
    { subscribe: false, forceRefetch: true }
) */