// Need to use the React-specific entry point to import createApi
import { CreateApi, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import type ICarrier from '../interfaces/ICarrier';
import { offerMakerRoutes, offerMakerBaseUrl, baseUrl } from '../configuration/offerMakerRoutes';
import { useAuth } from '../../Contexts/AuthContext';
import { AppDispatch } from '../common/store';
import { loginApi } from './loginApi';
import { readCookie } from '../features/session/omSessionActions';


export interface ILoginInfo
{
    success: boolean;
    jwt: string;
}


export const portalApi = createApi({
    reducerPath: 'portalApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
    }),
    tagTypes: ['LoginInfo'],
    endpoints: (builder) => ({
        getLoginInfo: builder.query<ILoginInfo, void>({
            query: () =>
            {
                const currentUser = readCookie("currentUser");
                return {
                    url: baseUrl + "offermaker",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${currentUser?.jwt}`
                    }
                };
            },
            providesTags: ['LoginInfo'],
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            )
            {
                const data = await queryFulfilled;
                if (data && data.data.success == false)
                {
                    dispatch(loginApi.endpoints.omLogin.initiate(data.data.jwt));
                }
            },
        }),
    }),
});

export const { useGetLoginInfoQuery } = portalApi;

