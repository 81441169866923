import { useEffect } from 'react';
import { store } from "../../common/store";
import { IJwtData } from "../../interfaces/IJwtData";
import { loginApi } from '../../services/loginApi';
import { jwtDecode } from "./jwtDecode";

const initBeforeUnLoad = (isLoggedin: boolean, jwtToken: string) =>
{
    window.onbeforeunload = (event) =>
    {
        if (isLoggedin)
        {
            store.dispatch(loginApi.endpoints.omLogout.initiate(jwtToken));
        };
    };

};

export function useLogout()
{
    const state = store.getState().session;

    window.onload = function ()
    {
        initBeforeUnLoad(state.isLoggedin, state.jwtToken);
    };

    useEffect(() =>
    {
        initBeforeUnLoad(state.isLoggedin, state.jwtToken);
    }, [state.isLoggedin]);
}

export function readCookie(name: any): any
{
    let result = document.cookie.match(new RegExp(name + "=([^;]+)"));
    result && (result = JSON.parse(result[1]));
    return result;
}

export function getJwtHeader()
{
    return {
        "authorization": `Bearer ${store.getState().session.jwtToken}`,
    };
}

export function getJwtData(jwtToken: string): IJwtData | undefined
{
    if (jwtToken == undefined || jwtToken == '')
    {
        return undefined;
    }
    var decodedJwt = jwtDecode(jwtToken);
    return decodedJwt as IJwtData;
}

export function getOmUserRoles(jwtToken: string): Array<string>
{
    const jwtData = getJwtData(jwtToken);
    if (jwtData?.role !== undefined)
    {
        return jwtData?.role;
    }
    return [];
}


export function isOmSalesWorker(): boolean
{
    const session = store.getState().session;
    const sessionStr = session.omUserRoles.join(',');
    if (sessionStr.includes("OmSalesWorker"))
    {
        return true;
    }
    return false;
}

export function isPartner(): boolean
{
    const session = store.getState().session;
    const sessionStr = session.omUserRoles.join(',');
    if (sessionStr.includes("Partner"))
    {
        return true;
    }
    return false;
}

export function isOmSalesOperations(): boolean
{
    const session = store.getState().session;
    const sessionStr = session.omUserRoles.join(',');
    if (sessionStr.includes("OmSalesOperations"))
    {
        return true;
    }
    return false;
}

export function isOmConsulting(): boolean
{
    const session = store.getState().session;
    const sessionStr = session.omUserRoles.join(',');
    if (sessionStr.includes("OmConsulting"))
    {
        return true;
    }
    return false;
}

export function isJwtValid(): boolean
{
    let jwtToken = store.getState().session.jwtToken;
    jwtToken = jwtToken.trim();
    if (jwtToken !== undefined || jwtToken !== '')
    {
        const parsedJwt = jwtDecode(jwtToken);
        if (parsedJwt != undefined && parsedJwt.exp < Date.now())
        {
            return true;
        }
    }
    return false;
}

export function getOmUser(token?: string)
{
    let jwtToken = '';
    if (token !== undefined)
    {
        jwtToken = token;
    }
    else
    {
        jwtToken = store.getState().session.jwtToken;
    }

    if (jwtToken !== undefined || jwtToken !== '')
    {
        var decodedJwt: IJwtData = jwtDecode(jwtToken);
        if (decodedJwt == undefined)
        {
            return undefined;
        }
        return { Id: decodedJwt.Id, email: decodedJwt.email, sub: decodedJwt.sub, family_name: decodedJwt.family_name, given_name: decodedJwt.given_name };
    }
    return undefined;
}

export function getLanguage()
{
    const currentUser = readCookie("currentUser");
    const lang = currentUser?.language;
    return lang ? lang : "DE";
}

const omSessionActions =
{
    // hooks
    useLogout,
    // functions
    getJwtData, isJwtValid,
    readCookie, getJwtHeader,
    getOmUser, getOmUserRoles,
    isOmSalesWorker, isOmSalesOperations, isOmConsulting,
    getLanguage
};

export default omSessionActions;




/* Beispiel jwtToken

{
  "Id": "279",
  "email": "iqbal.jamshed@byon.de",
  "sub": "ijamshed",
  "family_name": "Jamshed",
  "given_name": "Iqbal",
  "iss": "CubisWeb",
  "aud": "CubisWeb",
  "jti": "af25779e-6757-4050-b77f-30a04191d428",
  "sid": "62197",
  "role": [
    "User",
    "OmSalesWorker",
    "OmSalesOperations",
    "OmConsulting"
  ],
  "nbf": 1664454283,
  "exp": 1664475883,
  "iat": 1664454283
}

*/



