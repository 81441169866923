export default interface IFieldOption
{
    id: number;
    name: string;
    //checked: boolean;
}

export interface IFieldOptionExtended extends IFieldOption
{
    checked: boolean;
}

export enum FilterOption
{
    none = "",
    offerNumber = "offerNumber",
    offerType = "offerType",
    offerStatus = "offerStatus",
    customer = "customer",
    customerNumber = "customerNumber",
    createUser = "createUser",
    salesUser = "salesUser",
}

export const initialFieldOptionData: IFieldOption = {
    id: 0,
    name: "",
};

export const initialFieldOptionExtendedData: IFieldOptionExtended = {
    checked: false,
    id: 0,
    name: "",
};


export function isFieldOptionNotNullOrUndefined(value: IFieldOption | IFieldOptionExtended)
{
    if (value === undefined)
    {
        return false;
    }
    if ((value?.id !== null || value?.name !== null) && (value?.id !== undefined || value?.name !== undefined))
    {
        return true;
    }
    return false;
}


export function isFieldOptionEmpty(value: IFieldOption | IFieldOptionExtended)
{
    if ((value.id === 0 || (value.id + "") === "0") && value.name === "")
    {
        return true;
    }
    return false;
}