import React, { useRef, useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';

import BackendRoutes from "../Constants/BackendRoutes";
import IProviderProps from '../Interfaces/Props/IProviderProps';
import IAuthContext from '../Interfaces/Context/IAuthContext';
import { useStateCallback } from '../Components/useStateCallback';

function readCookie(name: string) {
    let result = document.cookie.match(new RegExp(name + "=([^;]+)"));
    result && (result = JSON.parse(result[1]));
    return result;
}

function setCookie(cname: string, cvalue: any, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + JSON.stringify(cvalue) + ";" + expires + ";path=/";
}

function deleteCookie(name: string) {
    if (readCookie(name)) {
        document.cookie = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
}

const AuthContext = React.createContext<IAuthContext>({
    isLoggedIn: false,
    setIsLoggedIn: (isLoggedIn: React.SetStateAction<boolean>) => {},
    currentUser: null,
    login: (username: string, password: string) => {},
    readCookie: readCookie,
    deleteCookie: deleteCookie,
    setCurrentUser: (currentUser: React.SetStateAction<{}>) => {},
    loginError: '',
    setCookie: setCookie,
    maxWaitTime: 0,
    waitTime: 0,
    setWaitTime: (waitTime: React.SetStateAction<number>) => {},
    runTimer: false,
    setRunTimer: (runTimer: React.SetStateAction<boolean>) => {},
    setloginError: (loginError: React.SetStateAction<string>) => {},
    getHeader: () => new Headers()
});

export const AuthProvider: React.FC<IProviderProps> = ({ children }) => {
    const [currentUser, setCurrentUser] = useStateCallback({});
    const [loginError, setloginError] = useState("");
    const [maxWaitTime, setMaxWaitTime] = useState(0);
    const [waitTime, setWaitTime] = useState(0);
    const [runTimer, setRunTimer] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [sessionCheck, setSessionCheck] = useState(false);
    const [checkSessionOnce, setCheckSessionOnce] = useState(true);

    const isFirstRender = useRef(true);

    const navigate = useNavigate();

    async function login(username: string, password: string) {
        try {
            const loginHeader = new Headers();

            loginHeader.append("Access-Control-Allow-Origin", "*");

            const formData = new FormData();
            formData.append("username", username);
            formData.append("password", password);

            const loginResponse = await fetch(BackendRoutes.login, {
                method: "POST",
                body: formData,
                headers: loginHeader,
            });
            const loginData = await loginResponse.json();

            if (loginData.success && loginData.loginSuccess === false) {
                if (loginData.error) {
                    setloginError("loginWaitTime");
                    setWaitTime(loginData.waitTime);
                    setMaxWaitTime(loginData.maxWaitTime);
                    setRunTimer(true);
                    setIsLoggedIn(false);
                } 
                else {
                    setloginError("login_error");
                    setIsLoggedIn(false);
                    setRunTimer(false);
                }
            } else if (loginData.success === false && loginData.loginSuccess === false) {
                setloginError("login_error");
                setRunTimer(false);
                setIsLoggedIn(false);
            } else {
                setRunTimer(false);

                const defaultHeader = new Headers();
                defaultHeader.append("Access-Control-Allow-Origin", "*");
                defaultHeader.append("Content-Type", "application/json");
                defaultHeader.append("Authorization", "Bearer " + loginData.jwt);

                const userInfoResponse = await fetch(BackendRoutes.userInfo, {
                    method: "GET",
                    headers: defaultHeader,
                });
                const userInfoData = await userInfoResponse.json();
                userInfoData.jwt = loginData.jwt;

                setCurrentUser(userInfoData);
                setCookie("currentUser", userInfoData, 5);
                setIsLoggedIn(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * Create the header which we need to send with our request to the backend.
     *
     * @returns Header-object
     */
    const getHeader = useCallback(() => {
        const defaultHeader = new Headers();
        defaultHeader.append("Access-Control-Allow-Origin", "*");
        defaultHeader.append("Content-Type", "application/json");
        // @ts-ignore
        defaultHeader.append("Authorization", "Bearer " + currentUser.jwt);
        return defaultHeader;
    }, [currentUser]);

    const checkSession = useCallback(async () => {
        const checkSessionResponse = await fetch(BackendRoutes.checkSession, {
            method: 'GET',
            headers: getHeader()
        });
        const checkSessionData = await checkSessionResponse.json();

        if(checkSessionData && !checkSessionData.success) {
            deleteCookie('currentUser');
            setCurrentUser({});
            setIsLoggedIn(false);
            navigate('/');
        }

        setCheckSessionOnce(false);
    }, [getHeader, navigate, setCurrentUser]);

    useEffect(() => {
        const cookieData = readCookie("currentUser");

        if(cookieData) {
            // @ts-ignore
            setCurrentUser(cookieData, (state) => {
                if(!sessionCheck) {
                    setSessionCheck(true);
                }
            });

            setIsLoggedIn(true);
        }
    }, [sessionCheck, setCurrentUser]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // toggle flag after first render/mounting
            return;
        }

        if(checkSessionOnce && sessionCheck) {
            checkSession();
        }
    }, [checkSession, sessionCheck, checkSessionOnce]);
    

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                setIsLoggedIn,
                currentUser,
                login,
                readCookie,
                deleteCookie,
                setCurrentUser,
                loginError,
                setCookie,
                maxWaitTime,
                waitTime,
                setWaitTime,
                runTimer,
                setRunTimer,
                setloginError,
                getHeader,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    return useContext(AuthContext);
}