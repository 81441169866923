
export interface ILocation
{

    locationId: number;
    crmLocationGuid: string;
    customerName: string;
    customerName2: string;
    locationTypeId: number;
    street: string;
    zipcode: string;
    town: string;
    country: string;
    currencyId: number;
    taxId: string;
    invoicePaymentTermId: number;
    erpnumber: number;
    erpclient: string;
    activationDate: string;
    deactivationDate: string;
    createUserId: number;
    createDate: string;
    modifyUserId: number;
    modifyDate: string;
}

export const initialLocation: ILocation = {
    locationId: 0,
    crmLocationGuid: "",
    customerName: "",
    customerName2: "",
    locationTypeId: -1,
    street: "",
    zipcode: "",
    town: "",
    country: "",
    currencyId: -1,
    taxId: "",
    invoicePaymentTermId: -1,
    erpnumber: -1,
    erpclient: "",
    activationDate: "",
    deactivationDate: "",
    createUserId: -1,
    createDate: "",
    modifyUserId: -1,
    modifyDate: "",
};