import React from 'react'
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals'

import App from './App'

const container = document.getElementById('root');
const root = createRoot(container!);

// StrictMode is responsible for dual calls of an useEffect which should only be called once
// if this happens and we have to check our logic disable the strict mode
// most of the time this double call of useEffect should show an error in the logik of the side
// @see https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
const useStrictMode = true;
if(useStrictMode) {
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}
else {
    root.render(
        <App />
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
