import TextField from "../../Components/Form/TextField";
import PageHeader from "../../Components/Header/PageHeader";
import { useUX } from "../../Contexts/UXContext";
import ITextField from '../../Interfaces/Props/ITextField';
import IFieldSet from '../../Interfaces/Props/IFieldSet';
import { fieldset, legend, legendSpan } from '../../Components/Form/FieldSetDefinitions';

// types
export type IOmTextField = ITextField;
export type IOmFieldSet = IFieldSet;

// components
const OmPageHeader = PageHeader;
const useOmUX = useUX;
const OmTextField = TextField;
const omFieldset = fieldset;
const omLegend = legend;
const omlegendSpan = legendSpan;


export { OmPageHeader, useOmUX, OmTextField, omFieldset, omLegend, omlegendSpan };