export const omDeutsch = {
    error: {
        error: "Fehler",
        unexpectedError: 'Ein unerwarteter Fehler ist aufgetreten.',
        generalError: "Es gibt einen Fehler beim Bearbeiten der Anfrage. Bitte den Support kontaktieren.",
        offerHasNoPositionsAdded: 'Ihr Angebot beinhaltet keine Positionen, Bitte fügen Sie welche ein. ',
    },
    offer: {
        activeOffers: "Aktive Angebote",
        newOffer: "Neues Angebot",
        editOffer: "Angebot bearbeiten",
        offerDetail: "Angebot Details",
        offerNumber: "Angebotsnummer",
        offerTitle: "Titel Angebot",
        offerStatus: "Angebot Status",
        offerType: "Angebot Typ",
        offerSum: "Wert des Angebot",
        createdBy: "Erstellt von",
        createdOn: "Erstellt am",
        versionNumber: "Versionsnummer",
        salesWorker: "Vertriebsmitarbeiter",
        duration: "Laufzeit (Monate)",
        offerPreview: "Angebotsvorschau",
        submitOffer: "Angebot abgeben",
        salesPartnerChangeDate: "Änderungsdatum ab dem ein neuer Vertriebspartner in cubis eingetragen wird",
        contact: {
            contact: "Kontakt",
            contactTitle: "Anrede",
            contactPerson: "Ansprech Partner",
            email: "Email",
        }

    },
    product: {
        buyShort: "K",
        article: "Artikel",
        description: "Beschreibung",
        product: "Produkt",
        productType: "Produkt Art",
        position: "Position",
        positionType: "Position Typ",
        productGroup: "Artikelgruppe",
        amount: "Menge",
        price: "Einzelpreis",
        discount: "Rabatt",
        grossPrice: "Gesamtpreis",
        productAdd: "Produkt hinzufügen",
        productEdit: "Produkt Bearbeiten",
        productInAllGroups: "Produkt in allen Produktgruppen",
        productInGroup: (groupName: string) => `Produkt in der Gruppe ${groupName}`,
        deleteProduct: 'Produkt löschen',
        deleteProductDesc: (productName: string) => `Möchten Sie das Produkt ${productName} wirklich löschen?`,
        productPriceIsNull: "Für den ausgewählten Artikel gibt es Kein Preiseintrag in den Datenbank für diesen Kaufart.",
        productGroupIsNull: "Bitte wählen Sie eine Gruppe für den ausgewählten Artikel.",
    },
    customer: {
        customerName: "Kundenname",
        customerNumber: "Kundennummer",
        searchCustomer: "Kunden suchen",
        selectCustomer: "Kunde Wählen",
        selectedCustomer: "Gewählter Kunde",

    },
    location: {
        selectedLocation: "Gewählter Standort",
        searchLocation: "Standort suchen",
        alternativeInstallationLocation: "abweichender Installationsort",
    },
    new: "Neue",
    noMatch: 'Keine Treffer',
    noResult: 'Keine Ergebnisse gefunden.',
    search: "Suchen...",
    showColumn: "Spalten ein- bzw. ausblenden",
    save: "Speichern",
    cancel: "Abbrechen",
    apply: "Übernehmen",
    reset: "Zurücksetzen",
    formNotValid: "Das Formular ist nicht korrekt ausgefüllt.",
    yes: "Ja",
    no: "nein",
    ok: "Ok",
    action: "Aktion",
    noOptionsText: "keine Treffer",
    openText: "öffnen",
    closeText: "schließen",
    clearText: "leeren",
    allUsers: "Alle Benutzer",
    messages: "Meldungen",
    remove: "löschen",
};