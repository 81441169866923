import {
    Toolbar, AppBar, Typography, IconButton
} from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import IPageHeader from '../../Interfaces/Props/IPageHeader';

const PageHeader = (props: IPageHeader) => {
    return (
        <AppBar color="transparent" position="static" elevation={0} style={{marginBottom: '10px'}}>
            <Toolbar>
                <h3>{props.headerTitle}</h3>
                {
                    props.showAddButtonAndSearch && 
                    <>
                        <IconButton onClick={props.addClick}><AddCircleOutlinedIcon fontSize="large" /></IconButton>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        />
                    </>
                }
            </Toolbar>
        </AppBar>
    );
};

export default PageHeader;