import { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useUX } from '../Contexts/UXContext'
import { useLanguage } from '../Contexts/LanguageContext';
import byon_logo from '../Theme/Assets/byon-logo.svg';
import consense_logo from '../Theme/Assets/consense_logo.png';
import esp_logo from '../Theme/Assets/esp-logo.png';
import TextField from '../Components/Form/TextField';
import Captcha from '../Components/Captcha';
import BackendRoutes from "../Constants/BackendRoutes";
import IResponse from '../Interfaces/IResponse';
import { 
    shadowContainer, innerContainer, loginForm, loginErrorBox,
    resetPasswordMarginTop, resetPasswordError
} from '../Theme/LayoutDefinitions';

const ForgotPasswordPage = () => {
    const { companyName, primaryColor, logo } = useUX();
    const { dictionary, toUpperCase } = useLanguage();
    const [ resetSuccessful, setResetSuccessful ] = useState(false);
    const [ resetError, setResetError ] = useState(false);
    const [ captchError, setCaptchError ] = useState(false);
    const [ usernameError, setUsernameError ] = useState(false);
    const [ resetCounter, setResetCounter ] = useState(0);
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        username: '',
        captcha: '',
        guid: ''
    });

    const [formChanged, setFormChanged] = useState({
        username: false,
        captcha: false
    });

    const [formErrors, setFormErrors] = useState({
        username: '',
        captcha: ''
    });

    const checkForm = () => {
        if(formValues.username === '') {
            setFormErrors((prevState) => ({
                ...prevState,
                userName: dictionary.errorText.emptyMail
            }));

            return false;
        }
        else if(formValues.captcha === '') {
            setFormErrors((prevState) => ({
                ...prevState,
                captcha: dictionary.errorText.emptyCode
            }));

            return false;
        }

        return true;
    };

    useEffect(() => {
        if(formChanged.username) {
            if(formValues.username === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    username: dictionary.errorText.emptyUserName
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    username: ''
                }));
            }
        }

        if(formChanged.captcha) {
            if(formValues.captcha === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    captcha: dictionary.errorText.emptyCode
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    captcha: ''
                }));
            }
        }
    }, [setFormErrors, formValues, dictionary, formChanged]);

    let defaultColor = primaryColor;
    if(defaultColor === undefined) {
        defaultColor = '#bc1123';
    }

    let defaultCompanyName = companyName;
    if(defaultCompanyName === undefined) {
        defaultCompanyName = 'byon GmbH';
    }

    let defaultLogo = logo;
    if(defaultLogo === undefined) {
        defaultLogo = 'byon_logo';
    }

    let logoToUse = byon_logo;
    if(defaultLogo === 'consense_logo') {
        logoToUse = consense_logo;
    }
    else if(defaultLogo === 'esp_logo') {
        logoToUse = esp_logo;
    }

    const handleChange = (event: any, fieldName: string) => {
        setFormValues({
            ...formValues,
            [fieldName]: event.target.value
        });
        setFormChanged({
            ...formChanged,
            [fieldName]: true
        });
    };

    const setCaptchaGuid = (guid: string) => {
        setFormValues({
            ...formValues,
            guid: guid
        });
    }

    const clearCaptcha = () => {
        setFormValues({
            ...formValues,
            guid: '',
            captcha: ''
        });
        setFormErrors((prevState) => ({
            ...prevState,
            captcha: ''
        }));
        setFormChanged({
            ...formChanged,
            captcha: false
        });
    }
    
    const resetPassword = async () => {
        if(checkForm()) {
            let counter = resetCounter;
            setResetSuccessful(false);
            setResetError(false);
            setCaptchError(false);
            setUsernameError(false);

            const defaultHeader = new Headers();
            defaultHeader.append('Access-Control-Allow-Origin', '*');
            defaultHeader.append('Content-Type', 'application/json');

            let response = await fetch(BackendRoutes.resetPassword, {
                method: 'POST',
                headers: defaultHeader,
                body: JSON.stringify(formValues)
            });
            let responseData: IResponse = await response.json();

            if(responseData.success) {
                setResetSuccessful(true);
            }
            else {
                setResetSuccessful(false);
                setResetError(true);

                if(responseData.captach !== undefined && responseData.captach === false) {
                    setCaptchError(true);
                }
                else if(responseData.username !== undefined && responseData.username === false) {
                    setUsernameError(true);
                }
            }

            counter++;
            setResetCounter(counter);
        }
    }

    const backToLoginPage = () => {
        navigate('/');
    };

    return (
        <Box sx={shadowContainer}>
            <Box sx={innerContainer}>
                {
                    // css class: login__content
                }
                <Box>
                    <img 
                        src={logoToUse} 
                        alt={`${defaultCompanyName} logo`} 
                        style={{
                            width: '200px'
                        }}
                    />
                </Box>
                {
                    // page content here
                }
                <Box sx={loginForm}>
                    {resetError && (
                        <Box sx={[
                            ...(Array.isArray(loginErrorBox) ? loginErrorBox : [loginErrorBox]), 
                            ...(Array.isArray(resetPasswordError) ? resetPasswordError : [resetPasswordError])
                        ]}>
                            {
                                captchError && dictionary.captcha.checkCode
                            }
                            {
                                usernameError && dictionary.captcha.usernameUnknown
                            }
                        </Box>
                    )}
                    {
                        resetSuccessful ?
                        <Box sx={resetPasswordMarginTop}>
                            {dictionary.captcha.successMessage}
                            <br /><br />
                            <Button 
                                variant="contained" 
                                className='btn btn__primary main' 
                                style={{ backgroundColor: defaultColor }} 
                                onClick={backToLoginPage}
                            >
                                {dictionary.otp.backToLogin}
                            </Button>
                        </Box>
                        :
                        <Box sx={resetPasswordMarginTop}>
                            {dictionary.captcha.infoMessage}
                            <TextField
                                required={true}
                                sx={{marginTop: '20px'}}
                                inputId='username'
                                title={toUpperCase(dictionary.username)}
                                value={formValues.username}
                                errorText={formErrors.username}
                                onChange={(event: any) => handleChange(event, 'username')}
                            />
                            <Captcha 
                                formValues={formValues}
                                formErrors={formErrors}
                                handleChange={handleChange}
                                clearCaptcha={clearCaptcha}
                                setCaptchaGuid={setCaptchaGuid}
                            />
                            <br />
                            <Button
                                variant="contained" 
                                className='btn'
                                onClick={() => resetPassword()}
                                disabled={resetCounter > 4 ? true : false}
                            >
                                {dictionary.captcha.resetPassword}
                            </Button>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default ForgotPasswordPage;