import { Box } from '@mui/material';

import IErrorPage from '../../Interfaces/otp/Props/IErrorPage';
import { useLanguage } from '../../Contexts/LanguageContext';

const OtpErrorPage = (props: IErrorPage) => {
    const { dictionary } = useLanguage();

    return (
        <Box>
            {dictionary.otp[props.errorData]}
        </Box>
    )
}

export default OtpErrorPage;