import IImage from '../Interfaces/Props/IImage';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

const Image = (props: IImage) => {
    return (
        <Box sx={{marginTop: '25px', textAlign: 'center'}}>
            {
                props.loading !== undefined && props.loading ?
                <CircularProgress />
                :
                <Box component="img" src={props.src} alt={props.alt} />
            }
            {
                props.showReload !== undefined && props.showReload ?
                <IconButton
                    aria-label="reload captcha"
                    onClick={props.getNewCaptcha}
                    sx={{marginLeft: '15px', marginRight: '10px', marginTop: '-30px' }}
                >
                    <Tooltip title={props.reloadCode}>
                        <CachedIcon />
                    </Tooltip>
                </IconButton>
                :
                null
            }
        </Box>
    );
}

export default Image;