import { Box, Typography } from '@mui/material';

import { useUX } from '../Contexts/UXContext'
import { useLanguage } from '../Contexts/LanguageContext'
import { pageMain, pageMainContent } from '../Theme/LayoutDefinitions';

const HomePage = () => {
    const { brand } = useUX();
    const { dictionary } = useLanguage();
  
    return (
        <Box component='main' sx={pageMain}>
            <Typography variant='h3' sx={{fontWeight: 'bold'}}>{dictionary.welcome.replace('{brand}', brand)}</Typography>
            <Box component='p' sx={pageMainContent}>
            </Box>
        </Box>
    )
}

export default HomePage
