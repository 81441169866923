import { SxProps, Theme } from '@mui/material/styles';

// is used in ./Layout.tsx
const squareLeft: SxProps<Theme> = {
    position: 'relative',
    float: 'left',
    height: '100vh',
    width: '50%',
    backgroundColor: (theme) => theme.palette.primary.main,
    zIndex: 1000,
    top: 0,
    left: 0
}

// is used in ./Layout.tsx
const squareRight: SxProps<Theme> = {
    position: 'relative',
    float: 'right',
    height: '100vh',
    width: '50%',
    backgroundColor: (theme) => theme.palette.AppCustomColors.backgroundColor,
    zIndex: 1000,
    top: 0,
    left: 0
}

// is used in ./Layout.tsx
const mainContainer: SxProps<Theme> = {
    position: 'absolute',
    top: 0,
    margin: '0 auto',
    width: '100%',
    height: '100vh',
    paddingTop: '2rem',
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingBottom: '2rem',
    backgroundColor: 'transparent',
    zIndex: 1001,
}

// is used in ./Layout.tsx
const loginContentContainer: SxProps<Theme> = {
    margin: 0,
    paddingTop: 'clamp(40px, 4vw, 60px)',
    paddingBottom: 'clamp(75px, 15vw, 250px)',
    height: '92.5vh',
    backgroundColor: '#fff'
}

// is used in ./Layout.tsx
const contentContainer: SxProps<Theme> = {
    margin: '0 auto',
    width: '100%',
    height: '92.5vh',
    backgroundColor: '#fff'
}

// is used in ./LayoutHeader.tsx
const layoutHeader: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1rem 2.778rem',
    position: 'relative',
    paddingTop: '1px',
    '& svg': {
        maxWidth: '1.388rem',
        maxHeight: '1.388rem'
    }
}

// is used in ./LayoutHeader.tsx
const layoutHeaderMedia: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    paddingTop: '1px',
    margin: '1rem 5%',
    '& svg': {
        maxWidth: '1.388rem',
        maxHeight: '1.388rem'
    }
}

// is used in ./LayoutHeader.tsx
const userButtonHidden: SxProps<Theme> = {
    border: 'none',
    padding: 0,
    background: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    display: 'none',
    '&:hover': {
        opacity: 0.5
    }
}

// is used in ./LayoutHeader.tsx
const userButtonShown: SxProps<Theme> = {
    display: 'block',
    '&:not(.active) ~ ul': {
        maxHeight: 0,
        overflow: 'hidden',
        visibility: 'hidden',
        opacity: 0,
        transform: 'scaleY(0)'
    }
}

// is used in ./LayoutHeader.tsx
const links: SxProps<Theme> = {
    padding: 0,
    listStyleType: 'none',
    display: 'flex',
    gap: '1.388rem',
    '& a': {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap'
    },
    '> li:last-child': {
        opacity: 0.5
    },
    '& svg': {
        display: 'none'
    }
}

// is used in ./LayoutHeader.tsx
const linksMedia: SxProps<Theme> = {
    transformOrigin: 'top left',
    position: 'absolute',
    right: 0,
    display: 'block',
    top: '1.4rem',
    transition: 'linear 0.1s all',
    maxHeight: '300px',
    '& svg': {
        display: 'block'
    },
    '> li': {
        opacity: '1 !important'
    }
}

const imprintContainer: SxProps<Theme> = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingBottom: '10px',
    margin: '0 auto',
    zIndex: 1001,
}

// is used in Pages/Login.tsx
const imprint: SxProps<Theme> = {
    textAlign: 'center',
    '& a': {
        fontSize: '12px',
        marginLeft: '5px'
    }
}

// is used in Pages/Login.tsx
const shadowContainer: SxProps<Theme> = {
    width: '45%',
    maxWidth: '975px',
    margin: '0 auto',
    paddingTop: 'clamp(30px, 3vw, 65px)',
    paddingBottom: 'clamp(30px, 3vw, 50px)',
    boxShadow: '0 10px 50px rgba(188, 17, 35, 0.2)',
    borderRadius: '15px'
}

// is used in Pages/Login.tsx
const innerContainer: SxProps<Theme> = {
    width: '90%',
    maxWidth: '775px',
    margin: '0 auto',
    alignItems: 'center'
}

// is used in Pages/Login.tsx
const pageContentContainer: SxProps<Theme> = {
    position: 'relative',
    marginTop: '20px'
}

// is used in Pages/Login.tsx
const loginForm: SxProps<Theme> = {
    position: 'relative',
    marginTop: '35px',
    '& .field': {
        marginBottom: '1.3rem',
        '& label': {
            fontWeight: 600,
            opacity: 0.7,
            display: 'inline-block',
            marginBottom: '5px'
        },
        '& input, select': {
            width: '100%',
            display: 'block',
            padding: '14px 12px',
            border: '2px solid #eae9e7',
            fontSize: '17px',
            background: '#fffbfa',
            borderRadius: '6px',
            '&:placeholder': {
                color: '#8c8b93'
            }
        },
        '& select': {
            color: '#8c8b93',
            margin: '10px 0px'
        }
    },
    '& button.btn': {
        display: 'block',
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
        color: '#fff',
        border: 0,
        borderRadius: '6px',
        padding: '15px 12px',
        fontSize: '17px',
        marginBottom: '1rem',
        boxShadow: '0 10px 50px rgba(15, 15, 38, 0.4)',
        transition: 'all 0.3s',
        '&:hover': {
            background: theme => theme.palette.primary.main,
            boxShadow: '0 10px 50px rgba(15, 15, 38, 0.4)'
        }
    }
}

// is used in Pages/Login.tsx
const loginErrorBox: SxProps<Theme> = {
    color: '#fff',
    background: '#bc1123',
    borderRadius: '6px',
    padding: '15px 12px',
    fontSize: '17px',
    marginBottom: '1rem',
    boxShadow: '0 10px 50px rgba(188, 17, 35, 0.4)'
}

const pageMain: SxProps<Theme> = {
    margin: '2rem 2.7rem 2rem 2.7rem',
    height: '77.5vh',
    width: '100%',
    maxWidth: '88.5%'
}

const pageMainContent: SxProps<Theme> = {
    height: '450px',
    width: '100%'
}

const gridPage: SxProps<Theme> = {
    height: '650px',
    width: '100%',
    overflow: 'auto'
}

const gridPaper: SxProps<Theme> = {
    height: '100%', 
    position: 'relative', 
    zIndex: '1'
}

// otp settings
const resetPasswordMarginTop: SxProps<Theme> = {
    marginTop: '35px'
}

// otp settings
const resetPasswordError: SxProps<Theme> = {
    marginTop: '20px',
    marginBottom: '20px'
}

// otp settings
const oneTimePassLoading: SxProps<Theme> = {
    textAlign: 'center',
    height: '300px',
    lineHeight: '300px',
    margin: 'auto',
    width: '50%',
    padding: '10px'
}

const loadingGridPage: SxProps<Theme> = {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '50%', 
    left: '50%',
    margin: '0px 0 0 -40px'
}

export { 
    squareLeft, squareRight, mainContainer, contentContainer, shadowContainer,
    innerContainer, pageContentContainer, loginForm, imprint, loginErrorBox,
    layoutHeader, userButtonHidden, userButtonShown, links, layoutHeaderMedia,
    linksMedia, loginContentContainer, pageMain, pageMainContent, resetPasswordMarginTop,
    resetPasswordError, oneTimePassLoading, gridPage, loadingGridPage, gridPaper,
    imprintContainer
};