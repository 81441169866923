import { getOmTranslation } from "../../../translations/OmTranslationContext";
import { ColumnName, initialProductRow, IProductRow } from "../OfferAddEditGrid";

/* export interface INewOfferGrid
{
    rows: Array<IProductRow>;
    columns: Array<{ name: string, title: string; }>;
    editingRowIds: Array<any>;
    addedRows: Array<any>;
    rowChanges: Object;
    sorting: Array<{ columnName: string, direction: 'asc' | 'desc'; }>;
    grouping: Array<any>;
    expandedGroups: Array<any>;
    selection: Array<any>;
    expandedRowIds: Array<number>;
    filters: Array<any>;
    currentPage: number;
    pageSize: number;
    pageSizes: Array<number>;
    columnOrder: Array<string>;
    columnWidths: Array<{
        columnName: string; width: number; type?: string;
    }>;
    tableColumnExtensions: Array<{ columnName: string, width: number, align?: string; }>;
} */


export interface IOfferGridState
{
    rows: Array<IProductRow>;
    editingRow: IProductRow;

}
export const initialNewOfferGrid: IOfferGridState = {
    rows: [],
    editingRow: initialProductRow
}


/* 
export const initialNewOfferGrid: INewOfferGrid = {
    rows: [],
    columns: [
        { name: ColumnName.position, title: getOmTranslation().product.position },
        { name: ColumnName.product, title: getOmTranslation().product.product },
        { name: ColumnName.productGroup, title: getOmTranslation().product.productGroup },
        { name: ColumnName.amount, title: getOmTranslation().product.amount },
        { name: ColumnName.price, title: getOmTranslation().product.price },
        { name: ColumnName.discount, title: getOmTranslation().product.discount },
        { name: ColumnName.totalPrice, title: getOmTranslation().product.grossPrice },
    ],
    editingRow: null;
    sorting: [],
    grouping: [],
    expandedGroups: [],
    selection: [],
    expandedRowIds: [],
    filters: [],
    currentPage: 0,
    pageSize: 10,
    pageSizes: [5, 10, 15],
    columnOrder: [
        ColumnName.position,
        ColumnName.product,
        ColumnName.productGroup,
        ColumnName.amount,
        ColumnName.price,
        ColumnName.discount,
        ColumnName.totalPrice,
    ],
    columnWidths: [
        { columnName: 'prefix', width: 80 },
        { columnName: 'firstName', width: 130 },
        { columnName: 'lastName', width: 130 },
        { columnName: 'position', width: 170 },
        { columnName: 'state', width: 125 },
        { columnName: 'birthDate', width: 115 },
    ],
    editingRowIds: [],
    addedRows: [],
    rowChanges: {},
    tableColumnExtensions: [
        { columnName: ColumnName.position, width: 150 },
        { columnName: ColumnName.product, width: 200 },
        { columnName: ColumnName.productGroup, width: 180 },
        { columnName: ColumnName.amount, width: 100 },
        { columnName: ColumnName.price, width: 150 },
        { columnName: ColumnName.discount, width: 100 },
        { columnName: ColumnName.totalPrice, width: 150 }
    ]
};
 */
