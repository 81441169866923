
import Box from '@mui/material/Box';
import ILayout from '../Interfaces/Props/ILayout';
import LayoutHeader from './LayoutHeader';
import LayoutMenu from './LayoutMenu';
import { useAuth } from "../Contexts/AuthContext";
import { useUX } from '../Contexts/UXContext';
import { useLanguage } from '../Contexts/LanguageContext';
import { useMediaQueryData } from '../Contexts/MediaQueryContext';
import { 
    squareLeft, squareRight, mainContainer, contentContainer, loginContentContainer,
    imprint, imprintContainer
} from './LayoutDefinitions';

const Layout = (props: ILayout) => {
    const { observe } = useMediaQueryData();
    const { imprintUri, privacyProtection } = useUX();
    const { isLoggedIn } = useAuth();
    const { dictionary, toUpperCase } = useLanguage();

    return (
        <Box ref={observe} sx={{
            position: 'relative',
            overflow: 'hidden'
        }}>
            {
                // colored square on the left side
            }
            <Box sx={squareLeft} />
            {
                // colored square on the right side
            }
            <Box sx={squareRight} />
            <Box sx={{
                clear: 'both'
            }}/>
            {
                // main container
            }
            <Box sx={mainContainer}>
                {
                    // content container
                }
                <Box sx={isLoggedIn ? contentContainer  : loginContentContainer} style={isLoggedIn ? {padding: '1px', position: 'relative'} : undefined}>
                    {
                        isLoggedIn && <LayoutHeader />
                    }
                    {props.children}
                    {
                        isLoggedIn && <LayoutMenu />
                    }
                    {
                        // imprint and other things
                    }
                </Box>
            </Box>
            <Box sx={imprintContainer}>
                <Box sx={imprint}>
                    <a href={imprintUri} target='_blank' rel='noreferrer' className='link' style={{color: '#FFFFFF'}}>
                        {toUpperCase(dictionary.imprint)}
                    </a>
                    <a href={privacyProtection} target='_blank' rel='noreferrer' className='link'>
                        {toUpperCase(dictionary.data_protection)}
                    </a>
                </Box>
            </Box>
        </Box>
    );   
};

export default Layout;