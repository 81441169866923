import React, { useContext } from "react";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useDimensions from "react-cool-dimensions";

import IMediaQueryContext from '../Interfaces/Context/IMediaQueryContext';
import IProviderProps from '../Interfaces/Props/IProviderProps';

const MediaQueryContext = React.createContext<IMediaQueryContext>({
    isDesktop: false,
    isTablet: false,
    isMobile: false,
    width: 0,
    height: 0,
    observe: (element?: HTMLElement | null | undefined) => {},
    unobserve: () => {}
});

export const MediaQueryProvider: React.FC<IProviderProps> = ({ children }) => {
    const { observe, unobserve, width, height } = useDimensions();

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <MediaQueryContext.Provider
            value={{
                isDesktop,
                isTablet,
                isMobile,
                width,
                height,
                observe,
                unobserve
            }}
        >
            {children}
        </MediaQueryContext.Provider>
    );
};

export function useMediaQueryData() {
    return useContext(MediaQueryContext);
}