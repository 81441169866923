import { CircularProgress } from "@mui/material";
import Error from "../components/Error";

interface IWithLoading {
    query: any;
    children: any;
}

const WithLoading = (props: IWithLoading) => {
    /*     const isLoading = useAppSelector((state) => {
        return Object.values(state.omApi.queries).some((query) => {
            return query && query.status === QueryStatus.pending;
        });
    }); */

    return (
        <div>
            {props.query.isError && <Error />}
            {props.query.isLoading && props.query.isFetching && <CircularProgress />}
            {props.query.data && props.children}
        </div>
    );
};

export default WithLoading;
