import { omApi } from './omApi';
import { getJwtHeader } from '../features/session/omSessionActions';
import { offerMakerRoutes } from '../configuration/offerMakerRoutes';
import { IGwLocation } from '../interfaces/customer/IGwLocation';
import { getCustomersQueryParams } from './filters/customersQueryFilter';


const gwAddressApiTags = ['locations', 'gwaddress'];
export const gwAddressApi = omApi.enhanceEndpoints({ addTagTypes: gwAddressApiTags }).injectEndpoints({

    endpoints: (builder) => ({
        SearchMainGwAddressByCustomerName: builder.query<Array<IGwLocation>, void>({
            query: () => ({
                url: offerMakerRoutes.address,
                params: { ...getCustomersQueryParams() },
                method: "GET",
                headers: getJwtHeader(),
                skipCache: true,
            }),

            providesTags: (result, args) =>
            {
                if (result)
                {
                    return [
                        ...result.map(({ crmLocationGuid }) => crmLocationGuid?.toString()),
                        'gwaddress',
                    ];
                }
                return ['gwaddress'];
            }
        }),
        SearchGwAddressByCustomerName: builder.query<Array<IGwLocation>, void>({
            query: () => ({
                url: offerMakerRoutes.address,
                params: { ...getCustomersQueryParams() },
                method: "GET",
                headers: getJwtHeader(),
                skipCache: true,
            }),

            providesTags: (result, args) =>
            {
                if (result)
                {
                    return [
                        ...result.map(({ crmLocationGuid }) => crmLocationGuid?.toString()),
                        'gwaddress',
                    ];
                }
                return ['gwaddress'];
            }
        }),
    }),
});


export const {
    useSearchGwAddressByCustomerNameQuery,
    useSearchMainGwAddressByCustomerNameQuery,
} = gwAddressApi;


/*             transformResponse: (response) =>
            {
                if (response)
                {
                    AppDispatch(customerActions.SetCustomer(response as ICustomer));
                }
                return response as ICustomer | Promise<ICustomer>;
            }, */




/* const [trigger, result] = gwAddressApi.endpoints.GetCustomerByGwGuid.useLazyQuery();

gwAddressApi.endpoints.GetCustomerByGwGuid.initiate(
    selectedCustomers.crmLocationGuidStr,
    { subscribe: false, forceRefetch: true }
) */