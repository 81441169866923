import { 
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@mui/material';
import { useLanguage } from '../../Contexts/LanguageContext';
import IDialog from '../../Interfaces/Props/IDialog';

const DeleteDialog = (props: IDialog) => {
    const { dictionary } = useLanguage();

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogTitle id="alert-dialog-title">
                {props.message.header}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <>{props.message.main}</>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleDelete}>{dictionary.yes}</Button>
                <Button onClick={props.handleClose}>{dictionary.no}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;