import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../common/hooks";
import { AppDispatch } from "../../../../common/store";
import useAutocomplete from "../../../../hooks/useAutocomplete";
import { IProduct, IProductQueryParams } from "../../../../interfaces/product/IProduct";
import { useGetLanguagesQuery } from "../../../../services/languageApi";
import { productApi } from "../../../../services/productApi";
import { useGetCategoriesQuery } from "../../../../services/productCategorieApi";
import { useOmTranslation } from "../../../../translations/OmTranslationContext";
import { FieldName, FormName } from "../../../forms/IFormParams";
import { IProductGroup } from "../../interfaces/IProductGroup";
import { ColumnName, IProductRow } from "../../OfferAddEditGrid";
import { offerAddEditGridActions } from "../../offerAddEditGridSlice";
import ProductFilter from "./ProductFilter";

interface IProductAutocomplete {
    productRow: IProductRow | null;
    size: "small" | "medium";
    showLabel: boolean;
    onProductChange?: (product: IProduct | null) => void;
    onGroupChange?: (group: IProductGroup | null) => void;
}
const ProductAutocomplete = ({
    productRow,
    size,
    showLabel,
    onProductChange,
    onGroupChange,
}: IProductAutocomplete) => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const offerForm = useAppSelector((state) => state.forms[FormName.offerForm]);
    //const duration = parseInt(offerForm.fields[FieldName.contractDuration].value.value);
    const contractDuration = parseInt(offerForm.fields[FieldName.contractDuration].value.value);
    const [productGroup, setProductGroup] = useState<IProductGroup | null>(null);
    const {
        value: product,
        setValue: setProduct,
        isValid: productIsValid,
        hasError: productHasError,
        valueChangeHandler: productChangedHandler,
        inputBlurHandler: productBlurHandler,
        reset: resetProductInput,
    } = useAutocomplete<IProduct>({
        formName: FormName.offerProductForm,
        fieldName: ColumnName.product,
        validationCallback: (product: IProduct) => {
            if (product && product.omArticleId && product.omArticleId > 0) {
                return true;
            }
            return false;
        },
    });

    //#endregion Component state variables
    //#region Fetch requests
    const languageResult = useGetLanguagesQuery();
    const [fetchProducts, fetchProductsResult] = productApi.endpoints.GetProducts.useLazyQuery();
    const [fetchProductById, fetchProductByIdResult] =
        productApi.endpoints.GetProductById.useLazyQuery();
    const productGroupsResult = useGetCategoriesQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const editRow = useAppSelector((state) => state.offerAddEditGrid.editingRow);
    /*     const productGroupsResult = useGetProductsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    }); */
    //#endregion Fetch requests
    //#region Component effects
    useEffect(() => {
        if (productRow) {
            if (productRow.product) {
                getProducts(productRow.product.internalName);
                setProduct(productRow.product);
            }
        }
    }, []);

    useEffect(() => {
        if (fetchProductByIdResult?.data) {
            AppDispatch(
                offerAddEditGridActions.SetEditRow({
                    ...editRow,
                    productName: fetchProductByIdResult?.data?.label ?? "",
                    product: fetchProductByIdResult?.data ?? null,
                })
            );
        }
    }, [fetchProductByIdResult.data]);

    useEffect(() => {
        if (editRow?.product && editRow?.isPurchase === false) {
            getProductById(
                editRow?.product?.omArticleId,
                contractDuration,
                editRow?.product?.omArticleCategoryId
            );
        } else if (editRow?.product && editRow?.isPurchase) {
            getProductById(editRow?.product?.omArticleId, 0, editRow?.product?.omArticleCategoryId);
        }
    }, [contractDuration, editRow?.isPurchase]);

    //#endregion Component effects
    //#region Functions
    const getLabel = (): string => {
        if (showLabel) {
            if (productGroup) {
                return dictionary.product.productInGroup(productGroup.name);
            }
            return dictionary.product.productInAllGroups;
        }
        return "";
    };
    const getProducts = (searchText: string) => {
        if (searchText.length > 1 || searchText === "") {
            const params: IProductQueryParams = {
                languageid: 1,
                durationmonth: contractDuration,
                articleinternalname: searchText,
            };
            if (productGroup) {
                params.articlecategoryid = productGroup.id;
            }
            fetchProducts(params);
        }
    };

    const getProductById = (id: number, duration: number, articlecategoryid: number) => {
        const params: IProductQueryParams = {
            id,
            languageid: 1,
            durationmonth: duration,
            articlecategoryid,
        };
        fetchProductById(params);
    };
    //#endregion Functions
    //#region Event handlers
    const handleProductChange = (event: any, data: IProduct | null) => {
        productChangedHandler({
            event,
            newValue: data ?? null,
            callback: (event, data) => {},
        });
        const productGroup = productGroupsResult.data
            ? productGroupsResult?.data.find((g) => g.id === data?.omArticleCategoryId)
            : null;
        AppDispatch(
            offerAddEditGridActions.SetEditRow({
                ...editRow,
                productName: data?.label ?? "",
                groupName: productGroup?.name ?? "",
                product: data ?? null,
                group: productGroup ?? null,
            })
        );
    };

    const handleFilterChange = (group: IProductGroup | null) => {
        setProductGroup(group);
    };

    const handleTextChange = (event: any) => {
        const searchText = event?.target?.value;
        getProducts(searchText);
    };

    //#endregion Event Handlers
    //#region Markup helper variables

    //#endregion Markup helper variables
    //#region Markup
    return (
        <Autocomplete
            sx={{ width: "100%", paddingTop: "5px" }}
            size={size}
            id="product-dropdown"
            noOptionsText={dictionary.noOptionsText}
            openText={dictionary.openText}
            closeText={dictionary.closeText}
            clearText={dictionary.clearText}
            filterOptions={(x) => x}
            loading={fetchProductsResult.isLoading || fetchProductsResult.isFetching}
            disablePortal={false}
            options={fetchProductsResult?.data ?? []}
            getOptionLabel={(option: IProduct) => option.label ?? ""}
            isOptionEqualToValue={(option: IProduct, value: IProduct) =>
                option.omArticleId === value.omArticleId
            }
            onChange={handleProductChange}
            onBlur={(event) => {
                productBlurHandler(event);
            }}
            getOptionDisabled={(option: IProduct) => option.price <= 0}
            /*             renderOption={(props: object, option: IProduct, state: object) => (
                <Stack sx={{ justifyContent: "flex-start" }} {...props}>
                    {option.label}
                </Stack>
            )} */
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={getLabel()}
                    error={productHasError}
                    onChange={handleTextChange}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <ProductFilter onFilterChange={handleFilterChange} />,
                    }}
                />
            )}
            value={product}
        />
    );
    //#endregion Markup
};
export default ProductAutocomplete;
