import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Alert, IconButton, Stack, Switch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { parseInput } from "../../../common/functions";
import { useAppSelector } from "../../../common/hooks";
import { AppDispatch } from "../../../common/store";
import useTypedInput from "../../../hooks/useNumberInput";
import { productApi } from "../../../services/productApi";
import { useOmTranslation } from "../../../translations/OmTranslationContext";
import { FieldName, FormName } from "../../forms/IFormParams";
import { ColumnName, initialProductRow, IProductRow } from "../OfferAddEditGrid";
import { offerAddEditGridActions } from "../offerAddEditGridSlice";
import FreeTextProductInput from "./product/FreeTextProductInput";
import PositionTypeAutocomplete from "./product/PositionTypeAutocomplete";
import ProductAutocomplete from "./product/ProductAutocomplete";
import ProductDiscountAutocomplete from "./product/ProductDiscountAutocomplete";
import { DialogMode } from "./ProductAddEditDialog";

interface IProductAddEditForm {
    dialogMode: DialogMode;
    row: IProductRow | null;
}
const ProductAddEditFormNew = ({ row: productRow, dialogMode }: IProductAddEditForm) => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const [message, setMessage] = useState<string | null>(null);
    const priceInputRef = useRef<any>(null);
    const [isPriceInputFocused, setIsPriceInputFocused] = useState<boolean>(false);
    const [editedDescription, setEditedDescription] = useState<string>("");
    const [isEditDescription, setIsEditDescription] = useState<boolean>(false);
    const [isPositionFreeText, setIsPositionFreeText] = useState<boolean>(false);
    const offerForm = useAppSelector((state) => state.forms[FormName.offerForm]);
    const contractDuration = parseInt(offerForm?.fields[FieldName.contractDuration].value.value);
    const editRow = useAppSelector((state) => state.offerAddEditGrid.editingRow);

    const [fetchProducts, fetchProductsResult] = productApi.endpoints.GetProducts.useLazyQuery();
    const [fetchProductById, fetchProductByIdResult] =
        productApi.endpoints.GetProductById.useLazyQuery();

    const {
        value: productAmount,
        setValue: setProductAmount,
        isValid: productAmountIsValid,
        hasError: productAmountHasError,
        valueChangeHandler: productAmountChangedHandler,
        inputBlurHandler: productAmountBlurHandler,
        reset: resetProductAmountInput,
    } = useTypedInput<number>({
        formName: FormName.offerProductForm,
        fieldName: ColumnName.amount,
        validationCallback: (value: number) => value > 0,
    });

    const {
        value: productPrice,
        setValue: setProductPrice,
        isValid: productPriceIsValid,
        hasError: productPriceHasError,
        valueChangeHandler: productPriceChangedHandler,
        inputBlurHandler: productPriceBlurHandler,
        reset: resetProductPriceInput,
    } = useTypedInput<number>({
        validationCallback: (value: number) => value > 0,
    });

    const { value: productTotalPrice, setValue: setProductTotalPrice } = useTypedInput<number>({
        validationCallback: (value: number) => value > 0,
    });
    //#endregion Component state variables
    //#region Fetch requests
    //#endregion Fetch requests
    //#region Component effects

    useEffect(() => {
        setProductAmount(0);
        if (dialogMode === DialogMode.new) {
            AppDispatch(offerAddEditGridActions.SetEditRow(initialProductRow));
        }
        if (dialogMode === DialogMode.edit) {
            AppDispatch(offerAddEditGridActions.SetEditRow(productRow ?? initialProductRow));
        }
    }, []);

    useEffect(() => {
        setProductAmount(editRow?.amount ?? 0);
    }, [editRow]);

    useEffect(() => {
        if (editRow?.positionType?.id === 4) {
            setIsPositionFreeText(true);
        } else {
            setIsPositionFreeText(false);
        }
    }, [editRow?.positionType]);

    useEffect(() => {
        AppDispatch(offerAddEditGridActions.SetEditRowTotalPrice(productTotalPrice));
    }, [productTotalPrice]);

    useEffect(() => {
        let discountedPrice = 0;
        if (editRow?.amount && editRow?.product?.price) {
            const totalPrice = editRow?.amount * editRow?.product?.price;
            if (editRow?.discount && editRow?.discount?.id > 0) {
                const temp = (totalPrice / 100) * editRow?.discount?.id;
                discountedPrice = totalPrice - temp;
            } else {
                discountedPrice = totalPrice;
            }
        }

        if (editRow?.product === null || editRow?.product?.price) {
            setMessage(null);
        } else {
            setMessage(dictionary.product.productPriceIsNull);
        }

        if (editRow?.group || editRow?.product === null) {
            setMessage(null);
        } else {
            setMessage(dictionary.product.productGroupIsNull);
        }
        setProductPrice(editRow?.product?.price ?? 0);
        setProductTotalPrice(discountedPrice);
        setEditedDescription(editRow?.product?.description ?? "");
    }, [editRow?.product, editRow?.group, editRow?.discount, editRow?.amount]);

    /*     useEffect(() => {
        if (editRow?.product && editRow?.group) {
            setMessage(null);
        } else {
            setMessage(dictionary.product.productGroupIsNull);
        }
    }, [editRow?.group, editRow?.product]); */

    /*     useEffect(() =>
    {
        
        if (editRow?.product?.allowPurchase && !editRow?.isPurchase) {
            AppDispatch(
                offerAddEditGridActions.SetEditRow({
                    ...editRow,
                    isPurchase: editRow?.product?.allowPurchase,
                })
            );
        }
    }, [editRow?.product?.allowPurchase]); */
    //#endregion Component effects
    //#region Functions
    const getSwitchChecked = (): boolean => {
        if (contractDuration === 0 || editRow?.isPurchase) {
            return true;
        }
        return false;
    };
    const getSwitchDisabled = (): boolean => {
        if (contractDuration === 0) {
            if (editRow?.product?.allowRent) {
                return false;
            }
        } else {
            if (editRow?.product?.allowPurchase) {
                return false;
            }
        }
        return true;
    };

    const getFormattedPrice = () => {
        if (isPriceInputFocused) {
            return productPrice ?? 0;
        }
        return new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
        }).format(productPrice ?? 0);
    };
    const formattedPrice = useMemo(() => getFormattedPrice(), [productPrice]);
    //#endregion Functions
    //#region Event handlers
    const handleIsPurchaseProductChange = (event: any) => {
        AppDispatch(
            offerAddEditGridActions.SetEditRow({
                ...editRow,
                isPurchase: event?.target?.checked,
            })
        );
    };

    const handleAmountChange = (event: any) => {
        productAmountChangedHandler(event);
        //const amount = parseInt(event?.target?.value);
        const amount = event?.target?.value;
        AppDispatch(
            offerAddEditGridActions.SetEditRow({
                ...editRow,
                amount: amount ?? 0,
            })
        );
    };

    const handlePriceChange = (event: any) => {
        if (isPositionFreeText) {
            productPriceChangedHandler(event);
            // const price = parseFloat(event?.target?.value);

            const price: any = parseInput(event?.target?.value);
            const product = { ...editRow?.product };
            if (product && price) {
                AppDispatch(
                    offerAddEditGridActions.SetEditRow({
                        ...editRow,
                        product: {
                            price: price.number ?? 0,
                            omArticleId: product.omArticleId ?? 0,
                            internalName: product.internalName ?? "",
                            omManufacturerId: product.omManufacturerId ?? 0,
                            omArticleCategoryId: editRow?.group?.id ?? 0,
                            articleNumber: product.articleNumber ?? "",
                            maxDiscountInternal: product.maxDiscountInternal ?? 0,
                            maxDiscountPartner: product.maxDiscountPartner ?? 0,
                            remarks: product.remarks ?? "",
                            productId: product.productId ?? 0,
                            label: product.label ?? "",
                            description: product.description ?? "",
                            allowPurchase: product.allowPurchase ?? true,
                            allowRent: product.allowRent ?? true,
                        },
                    })
                );
            }
        }
    };

    const handlePriceInputFocusChange = (event: any) => {
        if (priceInputRef) {
            priceInputRef?.current?.select();
        }
        setIsPriceInputFocused(true);
    };
    const handlePriceInputOnBlurChange = (event: any) => {
        productPriceBlurHandler(event);
        setIsPriceInputFocused(false);
    };

    //#endregion Event Handlers
    //#region Markup helper variables

    const SaveButton = (product: any) => {
        return (
            <IconButton
                onClick={(event: any) => {
                    event.ignore = true;
                    if (editRow?.product) {
                        AppDispatch(
                            offerAddEditGridActions.SetEditRow({
                                ...editRow,
                                product: { ...editRow?.product, description: editedDescription },
                            })
                        );
                    }
                    setIsEditDescription(false);
                }}
                sx={{}}
            >
                <SaveIcon />
            </IconButton>
        );
    };

    const EditButton = (product: any) => {
        return (
            <IconButton
                onClick={(event: any) => {
                    event.ignore = true;
                    setIsEditDescription(true);
                }}
                sx={{}}
            >
                <EditIcon />
            </IconButton>
        );
    };
    //#endregion Markup helper variables
    //#region Markup
    return (
        <MuiGrid container spacing={1}>
            <MuiGrid item xs={12}>
                <FormGroup>
                    {message ? (
                        <Stack p={0} sx={{ width: "100%", paddingBottom: "12px" }}>
                            <Alert severity="warning">{message}</Alert>
                        </Stack>
                    ) : null}
                    <PositionTypeAutocomplete
                        productRow={productRow}
                        size="medium"
                        showLabel={true}
                    />
                </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={12}>
                <FormGroup>
                    {isPositionFreeText ? (
                        <FreeTextProductInput
                            productRow={productRow}
                            size="medium"
                            showLabel={true}
                        />
                    ) : (
                        <ProductAutocomplete
                            productRow={productRow}
                            size="medium"
                            showLabel={true}
                        />
                    )}
                </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={2}>
                <FormGroup>
                    <TextField
                        size="small"
                        margin="dense"
                        id="amount-input"
                        name={ColumnName.amount}
                        label={dictionary.product.amount}
                        value={productAmount || ""}
                        onChange={handleAmountChange}
                        onBlur={productAmountBlurHandler}
                        error={productAmountHasError}
                    />
                </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={3}>
                <FormGroup>
                    <NumericFormat
                        inputRef={priceInputRef}
                        sx={{
                            "& .MuiInputBase-input": {
                                textAlign: "end",
                                paddingRight: "5px",
                            },
                        }}
                        displayType="input"
                        type="text"
                        suffix={isPriceInputFocused ? "" : " €"}
                        value={formattedPrice}
                        customInput={TextField}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        onChange={handlePriceChange}
                        onFocus={handlePriceInputFocusChange}
                        onBlur={handlePriceInputOnBlurChange}
                        label={dictionary.product.price}
                        name={ColumnName.price}
                        size="small"
                        margin="dense"
                        id="price-input"
                        disabled={!isPositionFreeText}
                    />

                    {/* <TextField
                        inputProps={
                            {
                                //  inputComponent: CurrencyInput,
                                // inputMode: "decimal",
                                //pattern: `/(\d)(?=(\d{3})+(?!\d))/`,
                            }
                        }
                        sx={{
                            "& .MuiInputBase-input": {
                                textAlign: "end",
                                paddingRight: "5px",
                            },
                        }}
                        disabled={!isPositionFreeText}
                        size="small"
                        margin="dense"
                        id="price-input"
                        name={ColumnName.price}
                        label={dictionary.product.price}
                        value={getFormattedPrice()}
                        onChange={handlePriceChange}
                        onFocus={handlePriceInputFocusChange}
                        onBlur={handlePriceInputOnBlurChange}
                    /> */}
                </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={3}>
                <FormGroup>
                    <TextField
                        sx={{
                            "& .MuiInputBase-input": {
                                textAlign: "end",
                                paddingRight: "5px",
                                fontWeight: "bold",
                            },
                        }}
                        disabled
                        size="small"
                        margin="dense"
                        id="totalPrice-input"
                        name={ColumnName.totalPrice}
                        label={dictionary.product.grossPrice}
                        value={new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                        }).format(productTotalPrice ?? 0)}
                    />
                </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={3}>
                <FormGroup sx={{ paddingTop: "3px" }}>
                    <ProductDiscountAutocomplete size="small" showLabel={true} />
                </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={1}>
                <Stack
                    direction="column"
                    alignItems={"center"}
                    sx={{ height: "100%", justifyContent: "center" }}
                >
                    <Switch
                        disabled={getSwitchDisabled()}
                        checked={getSwitchChecked()}
                        onChange={handleIsPurchaseProductChange}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                </Stack>
            </MuiGrid>

            <MuiGrid item xs={12}>
                <FormGroup>
                    <TextField
                        label={dictionary.product.description}
                        hiddenLabel
                        multiline
                        maxRows={5}
                        value={editedDescription}
                        disabled={!isEditDescription}
                        InputProps={{
                            endAdornment: (
                                <Stack>
                                    {editRow?.product?.description ? (
                                        isEditDescription ? (
                                            <SaveButton />
                                        ) : (
                                            <EditButton />
                                        )
                                    ) : null}
                                </Stack>
                            ),
                        }}
                        onChange={(event: any) => setEditedDescription(event?.target?.value)}
                    />
                    {/*<Box
                        sx={{
                            minHeight: "80px",
                            width: "100%",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            display: "flex",
                        }}
                    >
                        {editRow?.product && (
                            <MuiGrid item xs={12}>
                                <Stack direction={"row"}>
                                    <Typography variant="body1">
                                        {editRow?.product.description}
                                    </Typography>
                                </Stack>
                            </MuiGrid>
                        )}
                    </Box> */}
                </FormGroup>
            </MuiGrid>
        </MuiGrid>
    );
    //#endregion Markup
};
export default React.memo(ProductAddEditFormNew);
