import IOffer, { IOfferResult } from '../interfaces/IOffer';
import { omApi } from './omApi';
import { getJwtHeader } from '../features/session/omSessionActions';
import IOfferType from '../interfaces/offer/IOfferType';
import ICreateUser from '../interfaces/user/ICreateUser';
import IFieldOption from '../interfaces/IFieldOption';
import { AppDispatch } from '../common/store';
import { getCustomersQueryParams } from './filters/customersQueryFilter';
import dateFormat from "dateformat";
import { ILocation } from '../interfaces/customer/ILocation';
import { ICustomer } from '../interfaces/customer/ICustomer';
import { customerActions } from '../features/customer/customerSlice';
import { IGwLocation } from '../interfaces/customer/IGwLocation';
import { offerMakerRoutes } from '../configuration/offerMakerRoutes';


const customerApiTags = ['customers', 'customer'];
export const customerApi = omApi.enhanceEndpoints({ addTagTypes: customerApiTags }).injectEndpoints({

    endpoints: (builder) => ({
        GetCustomerIdByGwGuid: builder.query<any, { gwguid: string, salespartnerchangedate?: string; }>({
            query: (props) => ({
                url: offerMakerRoutes.customerIdByGuid(props.gwguid, props.salespartnerchangedate),
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['customer'],
            /*             transformResponse: (response) =>
                        {
                            if (response)
                            {
                                AppDispatch(customerActions.SetCustomer(response as ICustomer));
                            }
                            return response as ICustomer | Promise<ICustomer>;
                        }, */
        }),
        GetCustomerById: builder.query<ICustomer, string>({
            query: (customerId: string) => ({
                url: offerMakerRoutes.customerById(customerId),
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['customer'],
        }),
    }),
});


export const {
    useGetCustomerByIdQuery,
    useGetCustomerIdByGwGuidQuery,
} = customerApi;





/* const [trigger, result] = customerApi.endpoints.GetCustomerByGwGuid.useLazyQuery();

customerApi.endpoints.GetCustomerByGwGuid.initiate(
    selectedCustomers.crmLocationGuidStr,
    { subscribe: false, forceRefetch: true }
) */