import { ILocation } from "./ILocation";

export interface ICustomer
{
    customerId: number;
    crmCustomerGuid: string;
    customerName: string;
    customerName2: string;
    crmCustomerCategory: string;
    crmResponsibleMobile: string;
    crmResponsible: string;
    locationId: number;
    partnerId: number;
    subPartnerId: number;
    activationDate: string;
    deactivationDate: string;
    createUserId: number;
    createDate: string;
    modifyUserId: number;
    modifyDate: string;
    location?: ILocation;
}

export const initialCustomer =
{
    customerId: -1,
    crmCustomerGuid: "",
    customerName: "",
    customerName2: "",
    crmCustomerCategory: "",
    crmResponsibleMobile: "",
    crmResponsible: "",
    locationId: -1,
    partnerId: -1,
    subPartnerId: -1,
    activationDate: "",
    deactivationDate: "",
    createUserId: -1,
    createDate: "",
    modifyUserId: -1,
    modifyDate: "",
};