import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, Stack, TextField, Typography } from "@mui/material";
import { useAppSelector } from "../../../common/hooks";
import useAutocomplete from "../../../hooks/useAutocomplete";
import IFieldOption, {
    initialFieldOptionData,
    isFieldOptionEmpty,
    isFieldOptionNotNullOrUndefined,
} from "../../../interfaces/IFieldOption";
import { useGetAllSalesUsersQuery } from "../../../services/userApi";
import { useOmTranslation } from "../../../translations/OmTranslationContext";
import { FieldName, FormName } from "../../forms/IFormParams";

const HeaderOfferInfo = () => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const session = useAppSelector((state) => state.session);
    const offersState = useAppSelector((state) => state.offerList);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const {
        value: salesWorker,
        setValue: setSalesWorker,
        isValid: salesWorkerIsValid,
        hasError: salesWorkerHasError,
        valueChangeHandler: salesWorkerChangedHandler,
        inputBlurHandler: salesWorkerBlurHandler,
        reset: resetSalesWorkerInput,
    } = useAutocomplete<IFieldOption>({
        fieldName: FieldName.salesWorker,
        formName: FormName.offerForm,
        validationCallback: (value: IFieldOption) => {
            return isFieldOptionNotNullOrUndefined(value) && !isFieldOptionEmpty(value);
        },
    });

    let formIsValid = false;

    if (salesWorkerIsValid) {
        formIsValid = true;
    }

    //#endregion Component state variables
    //#region Fetch requests
    const salesUsersResult = useGetAllSalesUsersQuery();
    //#endregion Fetch requests
    //#region Component effects
    useEffect(() => {
        if (salesUsersResult?.data) {
            const selectedUser = salesUsersResult?.data.find(
                (option) => option.id === parseInt(session.omUser?.Id ?? "0")
            );
            if (selectedUser) {
                setSalesWorker(selectedUser);
            }
        }
    }, [salesUsersResult?.data]);

    /*     useEffect(() => {
        let tempParams: IFormParams = initialFormParams;
        if (salesWorkerHasError) {
            tempParams.hasError = true;
        } else if (salesWorkerIsValid) {
            tempParams.isValid = true;
        }
        if (formParams.reset) {
            resetSalesWorkerInput();
        }
    }, [formParams.reset, salesWorkerIsValid, salesWorkerHasError]); */
    //#endregion Component effects
    //#region Functions

    //#endregion Functions
    //#region Event handlers

    //#endregion Event Handlers
    //#region Markup helper variables

    //#endregion Markup helper variables
    //#region Markup

    return (
        <React.Fragment>
            <Grid item xs={4}>
                <Stack direction={"row"} alignItems="center">
                    <Typography sx={{ width: "180px" }} variant="body1">
                        {dictionary.offer.offerNumber}:
                    </Typography>
                    <Typography sx={{}} variant="body1">
                        {"t2"}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack direction={"row"} alignItems="center">
                    <Typography sx={{ width: "180px" }} variant="body1">
                        {dictionary.offer.versionNumber}:
                    </Typography>
                    <Typography variant="body1">{"t4"}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={5}>
                <Stack direction={"row"} alignItems="center" justifyContent={"flex-end"}>
                    <Typography sx={{ width: "200px" }} pr="10px" variant="body1">
                        {dictionary.offer.salesWorker}:
                    </Typography>
                    {/* {showDropdown ? ( */}
                    <Stack spacing={2} sx={{ minWidth: "250px" }}>
                        <Autocomplete
                            disabled={salesUsersResult?.data?.length === 1 ? true : false}
                            size="small"
                            id="product-type-dropdown"
                            forcePopupIcon={
                                salesUsersResult?.data?.length && salesUsersResult?.data?.length > 1
                                    ? true
                                    : false
                            }
                            options={salesUsersResult?.data ?? []}
                            getOptionLabel={(option) => option?.name ?? ""}
                            isOptionEqualToValue={(option: IFieldOption, value: IFieldOption) =>
                                option?.id === value?.id
                            }
                            onChange={(event: any, newValue: any) => {
                                salesWorkerChangedHandler({
                                    event,
                                    newValue,
                                    callback: (event, newValue) => {
                                        let tempState: IFieldOption =
                                            isFieldOptionNotNullOrUndefined(newValue)
                                                ? {
                                                      id: newValue?.id,
                                                      name: newValue?.name,
                                                  }
                                                : initialFieldOptionData;
                                        setSalesWorker(tempState);
                                    },
                                });
                            }}
                            onBlur={(event) => {
                                salesWorkerBlurHandler(
                                    event,
                                    () => !salesWorkerHasError && setShowDropdown(false)
                                );
                            }}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        error={salesWorkerHasError}
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                        onBlur={() => {}}
                                    />
                                );
                            }}
                            value={salesWorker}
                        />
                    </Stack>
                    {/*  ) : (
                        <Chip
                            sx={{
                                minWidth: "100px",
                                maxWidth: "250px",
                                "& .MuiChip-root": {
                                    borderRadius: "15%",
                                },
                                "& .MuiChip-icon": {
                                    marginLeft: "unset",
                                },
                                "& .MuiChip-label": {
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                },
                                borderRadius: "5px",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                            }}
                            size="medium"
                            color={salesWorkerHasError ? "error" : "default"}
                            variant="outlined"
                            onClick={() => {
                                if (salesUsersResult?.data && salesUsersResult?.data.length > 1) {
                                    setShowDropdown(true);
                                }
                            }}
                            label={<Typography variant="body1">{salesWorker?.name}</Typography>}
                        />
                        ) }*/}
                </Stack>
            </Grid>
        </React.Fragment>
    );
    //#endregion Markup
};
export default HeaderOfferInfo;

/*
 
     return (
        <Stack
            p={1}
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
            sx={{
                height: "50px",
                width: "100%",
            }}
        >
            <Stack direction={"row"}>
                <Typography variant="body1">{dictionary.offer.offerNumber}:</Typography>
                <Typography pl="10px" variant="body1">
                    {"t2"}
                </Typography>
            </Stack>
            <Stack direction={"row"}>
                <Typography variant="body1"> {dictionary.offer.versionNumber}:</Typography>
                <Typography pl="10px" variant="body1">
                    {"t4"}
                </Typography>
            </Stack>
            <Stack direction={"row"} alignItems="center">
                <Typography pr="10px" variant="body1">
                    {dictionary.offer.salesWorker}:
                </Typography>
                {showDropdown ? (
                    <Stack spacing={2} sx={{ minWidth: "250px", maxWidth: "300px" }}>
                        <Autocomplete
                            size="small"
                            id="product-type-dropdown"
                            forcePopupIcon={true}
                            options={salesUsersResult?.data ?? []}
                            getOptionLabel={(option) => option?.name ?? ""}
                            isOptionEqualToValue={(option: IFieldOption, value: IFieldOption) =>
                                option?.id === value?.id
                            }
                            onChange={(event: any, newValue: any) => {
                                salesWorkerChangedHandler({
                                    event,
                                    newValue,
                                    callback: (event, newValue) => {
                                        let tempState: IFieldOption =
                                            isFieldOptionNotNullOrUndefined(newValue)
                                                ? {
                                                      id: newValue?.id,
                                                      name: newValue?.name,
                                                  }
                                                : initialFieldOptionData;
                                        setSalesWorker(tempState);
                                    },
                                });
                            }}
                            onBlur={(event) => {
                                salesWorkerBlurHandler(
                                    event,
                                    () => !salesWorkerHasError && setShowDropdown(false)
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    error={salesWorkerHasError}
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                    onBlur={() => {}}
                                />
                            )}
                            value={salesWorker}
                        />
                    </Stack>
                ) : (
                    <Chip
                        sx={{
                            minWidth: "100px",
                            maxWidth: "250px",
                            "& .MuiChip-root": {
                                borderRadius: "15%",
                            },
                            "& .MuiChip-icon": {
                                marginLeft: "unset",
                            },
                            "& .MuiChip-label": {
                                paddingLeft: "8px",
                                paddingRight: "8px",
                            },
                            borderRadius: "5px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                        }}
                        size="medium"
                        color={salesWorkerHasError ? "error" : "default"}
                        variant="outlined"
                        onClick={() => {
                            if (salesUsersResult?.data && salesUsersResult?.data.length > 1) {
                                setShowDropdown(true);
                            }
                        }}
                        label={<Typography variant="body1">{salesWorker?.name}</Typography>}
                    />
                )}
            </Stack>
        </Stack>
    );
 
 */
