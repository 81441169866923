import { 
    Button, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle
} from '@mui/material';
import IDialog from '../../Interfaces/Props/IDialog';

// Informs the user that he failed to update the userdata
const AlertDialog = (props: IDialog) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.message.header}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message.main}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertDialog;