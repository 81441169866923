import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { useOmTranslation } from "../../../../translations/OmTranslationContext";
import { ColumnName, IProductRow } from "../../OfferAddEditGrid";
import { useAppSelector } from "../../../../common/hooks";
import { AppDispatch } from "../../../../common/store";
import { offerAddEditGridActions } from "../../offerAddEditGridSlice";
import useTypedInput from "../../../../hooks/useNumberInput";
import { IProduct } from "../../../../interfaces/product/IProduct";
import ProductFilter from "./ProductFilter";
import { IProductGroup } from "../../interfaces/IProductGroup";

interface IFreeTextProductInput {
    productRow: IProductRow | null;
    size: "small" | "medium";
    showLabel: boolean;
}
const FreeTextProductInput = ({ productRow, size, showLabel }: IFreeTextProductInput) => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const editRow = useAppSelector((state) => state.offerAddEditGrid.editingRow);
    const [productGroup, setProductGroup] = useState<IProductGroup | null>(null);

    const {
        value: freeTextProduct,
        setValue: setFreeTextProduct,
        isValid: freeTextProductIsValid,
        hasError: freeTextProductHasError,
        valueChangeHandler: freeTextProductChangedHandler,
        inputBlurHandler: freeTextProductBlurHandler,
        reset: resetFreeTextProductInput,
    } = useTypedInput<string>({
        validationCallback: (input: string) => {
            if (input && input !== "") {
                return true;
            }
            return false;
        },
    });

    //#endregion Component state variables
    //#region Fetch requests
    //#endregion Fetch requests
    //#region Component effects
    useEffect(() => {
        if (productRow) {
            if (productRow.product) {
                setFreeTextProduct(productRow.product.label);
            }
            if (productRow.group) {
                setProductGroup(productRow.group);
            }
        }
    }, []);

    //#endregion Component effects
    //#region Functions
    const getLabel = (): string => {
        if (showLabel) {
            if (productGroup) {
                return dictionary.product.productInGroup(productGroup.name);
            }
            return dictionary.product.productInAllGroups;
        }
        return "";
    };
    //#endregion Functions
    //#region Event handlers
    const handlePositionTypeChange = (event: any) => {
        freeTextProductChangedHandler(event);
        const value = event?.target?.value;
        let product: IProduct | null = null;

        if (productRow && productRow.product) {
            product = { ...productRow.product, internalName: value, label: value };
        } else {
            product = {
                omArticleId: 0,
                internalName: value,
                omManufacturerId: 0,
                omArticleCategoryId: 0,
                articleNumber: "",
                maxDiscountInternal: 0,
                maxDiscountPartner: 0,
                remarks: "",
                productId: 0,
                label: value,
                description: value,
                price: 1,
                allowPurchase: true,
                allowRent: true,
            };
        }
        AppDispatch(
            offerAddEditGridActions.SetEditRow({
                ...editRow,
                productName: product?.label ?? "",
                product: product,
            })
        );
    };

    const handleFilterChange = (group: IProductGroup | null) => {
        setProductGroup(group);
        AppDispatch(
            offerAddEditGridActions.SetEditRow({
                ...editRow,
                group: group,
                groupName: group?.name ?? "",
            })
        );
    };

    //#endregion Event Handlers
    //#region Markup helper variables

    //#endregion Markup helper variables
    //#region Markup
    return (
        <TextField
            sx={{
                width: "100%",
                paddingTop: "5px",
                "& .MuiOutlinedInput-input": { paddingLeft: "12px" },
            }}
            size={size}
            margin="dense"
            id="freeTextProduct-input"
            name={ColumnName.product}
            //  label={showLabel ? dictionary.product.product : ""}
            label={getLabel()}
            value={editRow.productName}
            onChange={handlePositionTypeChange}
            onBlur={freeTextProductBlurHandler}
            error={freeTextProductHasError}
            InputProps={{
                startAdornment: <ProductFilter onFilterChange={handleFilterChange} />,
            }}
        />
    );
    //#endregion Markup
};
export default FreeTextProductInput;
