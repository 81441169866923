import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Column, Grouping } from "@devexpress/dx-react-grid";
import { Table } from "@devexpress/dx-react-grid-material-ui";

import styles from "./OfferContainer.module.scss";
import { OmPageHeader } from "../../common/imports";
import { useLocation, useParams } from "react-router-dom";
import { useGetOfferByIdQuery } from "../../services/offersApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import Error from "../../components/Error";
import WithLoading from "../../components/WithLoading";

const Edit = () => {
    const { offerId } = useParams();
    const location = useLocation();

    const getOfferById = useGetOfferByIdQuery(offerId ? offerId : skipToken, {
        refetchOnMountOrArgChange: true,
    });

    let content: any = undefined;
    return (
        <main className={styles.root}>
            <div style={{ width: "100%" }}>
                <OmPageHeader headerTitle={"Offer Edit: " + offerId} />
            </div>
            <div style={{ width: "100%" }}>
                <WithLoading query={getOfferById}>Offer bearbeiten</WithLoading>
            </div>
        </main>
    );
};

export default Edit;
