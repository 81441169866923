import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOmSession } from "../../interfaces/IOmSession";


// Define the initial state using that type
const initialState: IOmSession = {
    omUser: undefined,
    omUserRoles: [],
    jwtToken: '',
    isLoggedin: false,
    theme: "light",
    language: "de",
};

const omSessionSlice = createSlice({
    name: 'omSession',
    initialState: initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        SetCredentials: (state, action: PayloadAction<IOmSession>) =>
        {
            state.omUser = action.payload.omUser;
            state.omUserRoles = action.payload.omUserRoles;
            state.jwtToken = action.payload.jwtToken;
            state.isLoggedin = action.payload.isLoggedin;
            state.language = action.payload.language;
        },
        logOut: (state, action: PayloadAction<undefined>) =>
        {
            state.omUser = undefined;
            state.omUserRoles = [];
            state.jwtToken = '';
            state.isLoggedin = false;
            state.language = "DE";
        },
    }
});

export const omSessionStoreActions = omSessionSlice.actions;
export default omSessionSlice.reducer;