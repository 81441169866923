import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Chip } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";
import { functionIsDefined } from "../common/functions";
import { useOmTranslation } from "../translations/OmTranslationContext";

export interface ISearchField {
    onSearchFieldChanged: (text: string) => void;
    onSearchFieldReset?: () => void;
    focused: boolean;
    children?: React.ReactNode;
    placeHolderText?: string;
    style?: React.CSSProperties;
    searchFilterInfoText?: string;
}

SearchField.defaultProps = {
    focused: false,
};

function SearchField(props: ISearchField): JSX.Element {
    const [searchTerm, setSearchTerm] = useState("");
    const [hideFocus, setHideFocus] = useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>();

    //const app: IApp = useAppSelector((state) => state.app);
    const { dictionary } = useOmTranslation();

    // delay Search to happen for a while
    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            props.onSearchFieldChanged(searchTerm);
        }, 600);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    /*     React.useEffect(() => {
        inputRef.current?.focus();
    }, [props.focused]); */

    const handleClearInput = () => {
        if (functionIsDefined(props.onSearchFieldReset)) {
            // @ts-ignore
            props.onSearchFieldReset();
        }
        setSearchTerm("");
        inputRef.current?.focus();
    };

    const handleFocusInput = () => {
        if (hideFocus) {
            setHideFocus(false);
        } else {
            setHideFocus(true);
        }
    };
    const FilterInfoTag = (
        <InputAdornment position="start">
            <Chip
                sx={{
                    minWidth: "60px",
                    maxWidth: "200px",
                    "& .MuiChip-root": {
                        borderRadius: "15%",
                    },
                    "& .MuiChip-icon": {
                        marginLeft: "unset",
                    },
                    "& .MuiChip-label": {
                        paddingLeft: "10px",
                        paddingRight: "0px",
                    },
                    borderRadius: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                }}
                size="medium"
                icon={<SearchIcon />}
                label={props.searchFilterInfoText}
            />
        </InputAdornment>
    );

    return (
        <Box
            sx={{
                "& > :not(style)": { m: 1 },
                "& .MuiBox-root": {
                    width: "100%",
                    justifyContent: "end",
                    margin: "unset",
                    //  display: "block",
                },
                width: "100%",
                // margin: "20px",

                display: "contents",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    width: "100%",
                    //margin: "10px",
                }}
            >
                <TextField
                    //onFocus={() => handleFocusInput()}
                    //focused={props.focused}
                    inputRef={inputRef}
                    size="small"
                    sx={{
                        //backgroundColor: "#3d3d3d",
                        backgroundColor: (theme) => theme.palette.background.paper,
                        width: "70%",
                        maxWidth: "500px",
                        //margin: "10px",
                        "& .css-ihdtdm": {
                            display: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            top: 0,
                            paddingLeft:
                                props.searchFilterInfoText !== undefined ? "5px" : "inherit",
                        },
                        "& .MuiOutlinedInput-root": {
                            paddingLeft:
                                props.searchFilterInfoText !== undefined ? "5px" : "inherit",
                        },
                    }}
                    id="input-with-icon-textfield"
                    placeholder={props.placeHolderText ? props.placeHolderText : dictionary.search}
                    InputProps={{
                        startAdornment:
                            props.searchFilterInfoText === undefined ? (
                                <SearchIcon />
                            ) : (
                                FilterInfoTag
                            ),
                        endAdornment: (
                            <>
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clear input"
                                        onClick={handleClearInput}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{
                                            borderRadius: "15%",
                                            paddingRight: "unset",
                                            margin: "unset",
                                            marginRight: "-7px",
                                            padding: "2px",
                                            // outline: "none !important",
                                            "&:focus-visible": {
                                                outline: "0",
                                            },
                                        }}
                                    >
                                        {searchTerm.length > 0 && <ClearIcon />}
                                    </IconButton>
                                </InputAdornment>
                                {props.children !== undefined && props.children}
                            </>
                        ),
                    }}
                    variant="outlined"
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    value={searchTerm}
                />
            </Box>
        </Box>
    );
}

export default SearchField;
