export interface IGwLocation
{
    crmLocationGuid: string,
    crmLocationGuidStr: string,
    customerName: string,
    customerName2: string,
    street: string,
    zipcode: string,
    town: string,
    country: string,
    erpnumber: string,
    erpclient: string,
    category: string,
    responsible: string,
    salesPartner: string,
}

export const initialGwAddress: IGwLocation = {
    crmLocationGuid: "",
    crmLocationGuidStr: "",
    customerName: "",
    customerName2: "",
    street: "",
    zipcode: "",
    town: "",
    country: "",
    erpnumber: "",
    erpclient: "",
    category: "",
    responsible: "",
    salesPartner: "",
};