import React, { useContext, useState } from "react";
import IOffer from "../../interfaces/IOffer";

export interface OffersContextInterface {
    searchText: string;
    setSearchText: (value: string) => void;
    /*  filterValue: FilterOption;
    setFilterValue: (value: FilterOption) => void; */
    isBtnReloadUsersDisabled: boolean;
    setBtnReloadUsersDisabled: (value: boolean) => void;
    filteredOffers: Array<IOffer>;
    setFilteredOffers: (offers: Array<IOffer>) => void;
    queryParams: Record<string, string>;
    setQueryParams: (record: Record<string, string>) => void;
    /* offerTypeFilterOption: IFieldOption | any;
    setOfferTypeFilterOption: (value: IFieldOption | any) => void;
    offerStatusFilterOption: IFieldOption;
    setOfferStatusFilterOption: (value: IFieldOption | any) => void;
    offerCreateUserFilterOption: IFieldOption | any;
    setOfferCreateUserFilterOption: (value: IFieldOption | any) => void; */
}

const OffersContext = React.createContext<OffersContextInterface | null>(null);
export function useOffersContext() {
    return useContext(OffersContext);
}

type Props = {
    children?: React.ReactNode;
};

export const OffersContextProvider = ({ children }: Props) => {
    const [searchText, setSearchText] = useState<string>("");
    //const [filterValue, setFilterValue] = useState<FilterOption>(FilterOption.offerNumber);
    const [isBtnReloadUsersDisabled, setBtnReloadUsersDisabled] = React.useState<boolean>(false);
    const [filteredOffers, setFilteredOffers] = React.useState<Array<IOffer>>([]);
    const [queryParams, setQueryParams] = React.useState<Record<string, string>>({});

    /*     const [offerTypeFilterOption, setOfferTypeFilterOption] =
        useState<IFieldOption>(initialFilterDataOption);
    const [offerStatusFilterOption, setOfferStatusFilterOption] =
        useState<IFieldOption>(initialFilterDataOption);
    const [offerCreateUserFilterOption, setOfferCreateUserFilterOption] =
        useState<IFieldOption>(initialFilterDataOption); */

    return (
        <OffersContext.Provider
            value={{
                searchText,
                setSearchText,
                // filterValue,
                //setFilterValue,
                isBtnReloadUsersDisabled,
                setBtnReloadUsersDisabled,
                filteredOffers,
                setFilteredOffers,
                queryParams,
                setQueryParams,
                /*    offerTypeFilterOption,
                setOfferTypeFilterOption,
                offerStatusFilterOption,
                setOfferStatusFilterOption,
                offerCreateUserFilterOption,
                setOfferCreateUserFilterOption, */
            }}
        >
            {children}
        </OffersContext.Provider>
    );
};
