import IOffer from "../interfaces/IOffer";
import { baseUrl } from "../../Constants/BackendRoutes";
import { IProductQueryParams } from "../interfaces/product/IProduct";
let offerMakerBaseUrl = "";
//const baseUrl = baseUrl;
//const offerMakerBaseUrl = "https://localhost:5443/api/";
//const baseUrl = "https://web-cluster-entwicklung-int.byon.net/cg_portal/";

// check if we are in the development environment if this is the case
// we change the base url to the url where the development backend can
// be reached
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
{
    // if the url should be changed do not forget the tailing /
    offerMakerBaseUrl = "https://localhost:5443/api/";
}
else if (baseUrl === "https://web-cluster-entwicklung-int.byon.net/cg_portal/")
{
    offerMakerBaseUrl = "https://cubis-dev.consense.de:5443/api/";
}
else
{
    offerMakerBaseUrl = "https://cubis-dev.consense.de:5443/api/";
}

const offerMakerRoutes = {

    // auth
    login: "auth/loginwithmailjwt", // [GET] Login über ein JWT das die EMail-Adresse als "email" enthält
    logout: "auth/logout", // [POST] Beendet die aktuelle Session. In der cubis DB wird in der Login Tabelle ein Logout Timestamp eingetragen.

    // customer
    customerById: (customerId: string) => `customer/${customerId}`, // [GET] Liefert ein Customer-Objekt zur angegebenen CustomerID
    customerIdByGuid: (gwguid: string, salespartnerchangedate?: string) =>
    {
        if (salespartnerchangedate)
        {
            return `customer/getcustomerid?gwguid=${gwguid}&salespartnerchangedate=${salespartnerchangedate}`;
        }
        return `customer/getcustomerid?gwguid=${gwguid}`;
    }, // [GET] Sucht über die GeneisWorld GGUID nach einem Customer

    // gwaddress
    address: "gwaddress", // [GET] Liefert eine Liste aller byon Kunden und Interessenten direkt aus der genesisWorld Datenbank.

    // language
    language: "language", // [GET] Liefert alle in cubis vorhandenen Language-Datensätze zurück
    languageById: (languageId: string) => `language/${languageId}`, // [GET] Liefert einen Language-Datensatz zur angegebenen LanguageID zurück

    // location
    locationById: (locationId: string) => `location/${locationId}`, // [GET] Liefert ein Location-Objekt zur angegebenen LocationID
    locationGetLocationId: (gwguid: string) => `location/getlocationid?gwguid=${gwguid}`, // [GET] Sucht über die GeneisWorld GGUID nach einer Location

    //omarticle
    omarticle: ({ languageid, durationmonth, articlecategoryid, articleinternalname, }: IProductQueryParams) =>
    {
        let url = `omarticle?languageid=${languageid}&durationmonth=${durationmonth}`;
        if (articlecategoryid)
        {
            url += `&articlecategoryid=${articlecategoryid}`;
        }
        if (articleinternalname)
        {
            url += `&articleinternalname=${articleinternalname}`;
        }
        return url;
    }, // [GET] Liefert eine Liste aller OM_Article Einträge.
    omarticleById: ({ id, languageid, durationmonth }: IProductQueryParams) =>
    {
        if (id && languageid && durationmonth >= 0)
        {
            return `omarticle/${id}?languageid=${languageid}&durationmonth=${durationmonth}`;
        }
        return "";
    }, // [GET] Liefert eine Liste aller OM_Article Einträge.

    // omarticlecategorie
    omarticleCategorie: "omarticlecategorie", // [GET] Liefert alle in der Datenbank vorhandenen aktiven OM_ArticleCategorie-Datensätze für ein Dropdown
    omarticleCategorieById: (categorieId: string) => `omarticlecategorie/${categorieId}`, // [GET] Liefert einen speziellen OmArticleCategory-Datensatz

    // omoffer
    omoffer: "omoffer", // [POST, GET] Liefert eine Liste aller gespeicherten Angebote für die Anzeige in einer Übersicht zurück oder legt einen neuen Datensatz an
    omofferCreatedByUser: `omoffer/createdbyuser`, // [GET] Liefert eine Liste aller User die ein Angebot erstellt haben zurück
    omofferById: (offerId: string) => `omoffer/${offerId}`, // [PUT, GET] Liefert ein einzelnes OmOffer Objekt zurück oder ändert einen vorhandenen Datensatz

    // omoffergrouptype
    omofferGroupTypeById: (omofferGroupTypeId: string) => `omoffergrouptype/${omofferGroupTypeId}`, // [GET] Liefert einen speziellen OmOfferGroupType-Datensatz
    omofferGroupTypeByOfferTypeId: (omofferTypeId: string) => `omoffergrouptype/offertype/${omofferTypeId}`, // [GET] Liefert alle OmOfferGroupType zu einem OfferType-Datensatz

    // omofferstatus
    omofferStatus: "omofferstatus", // [GET] Liefert alle in der Datenbank vorhandenen aktiven OmOfferStatus-Datensätze für ein Dropdown

    // omoffertype
    omofferType: "omoffertype", // [GET] Liefert alle in der Datenbank vorhandenen aktiven OmOffertype-Datensätze für ein Dropdown
    omofferTypeById: (offerTypeId: string) => `omoffertype/${offerTypeId}`, // [GET] Liefert einen speziellen OmOffertype-Datensatz

    // omofferversion
    omofferVersion: "omofferversion", // [GET] Liefert eine Liste aller OM_OfferVersion Einträge
    omofferVersionById: (offerVersionId: string) => `omofferversion/${offerVersionId}`, // [GET] Liefert ein über seine ID ausgwählten OM_OfferVersion Datensatz aus der Datenbank
    omofferVersionFullOfferById: (omOfferId: string, versionNumber?: string, language?: string) => // [GET] Liefert ein einzelnes OmOffer Objekt mit der aktuellsten Version und allen verknüpften Daten zurück
    {
        if (versionNumber && language)
        {
            return `omofferversion/fulloffer/${omOfferId}?versionnumber=${versionNumber}&languageid=${language}`;
        }
        else
        {
            // Versionsnummer für die die Daten ausgegeben werden sollen. 0 oder keine Angabe beduten die höchste Versionsnummer
            // Sprache in der die Label und Description der Artikel ausgegeben werden soll. Falls 0 oder fehlt wird die in der OfferVersion gespeicherte Sprache verwendet
            return `omofferversion/fulloffer/${omOfferId}?versionnumber=0&languageid=0`;
        }
    },
    omofferVersionNewOfferByOfferTypeId: (omoffertypeid: string) => `omofferversion/newoffer?omoffertypeid=${omoffertypeid}`, // [GET] Liefert ein leeres Angebot zu einem OM_OfferType zurück
    omofferVersionFullVersion: `omofferversion/fullversion`, // [PUT] Speichert eine komplette Angebotsversion

    // ompositiontype
    ompositionType: "ompositiontype", // [GET] Liefert alle in der Datenbank vorhandenen aktiven OmPositiontype-Datensätze für ein Dropdown
    ompositionTypeById: (ompositionTypeId: string) => `ompositiontype/${ompositionTypeId}`, // [GET] Liefert einen speziellen OmPositionType-Datensatz

    // omsalespartnerdisplaytype
    omsalesPartnerDisplayType: "omsalespartnerdisplaytype", // [GET] Liefert alle in der Datenbank vorhandenen aktiven OmSalesPartnerDisplayType-Datensätze für ein Dropdown
    omsalesPartnerDisplayTypeById: (omsalesPartnerDisplayTypeId: string) => `omsalespartnerdisplaytype/${omsalesPartnerDisplayTypeId}`, // [GET] Liefert einen speziellen OmSalesPartnerDisplayType-Datensatz

    // user
    user: "user", // [GET] Listet alle gespeicherten Benutzer auf
    salesUser: "user/salesuser", // [GET] Liefert alle Vertriebsuser mit UserID und FullName
    userById: (userId: string) => `user/${userId}`, // [GET] Liefert einen User-Datensatz zur angegebenen UserID zurück
};

const uiRoutes = {
    error: "/offermaker/error",
    offers: "/offermaker/offers",
    newOffer: "/offermaker/offers/new",
    offerDetail: (id: string) => { return id; },
    offerEdit: (id: string) => { return `${id}/edit`; },
};

export { uiRoutes, baseUrl, offerMakerRoutes, offerMakerBaseUrl };