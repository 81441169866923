import { useState, useEffect } from 'react';
import { Column } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@mui/material';

import PageHeader from '../Components/Header/PageHeader';
import BackendRoutes from '../Constants/BackendRoutes';
import TreeGrid from '../Components/Grids/TreeGrid';
import LoadingGridData from '../Components/Grids/LoadingGridData';
import { useAuth } from '../Contexts/AuthContext';
import { useLanguage } from '../Contexts/LanguageContext';
import IColumnsResponse from '../Interfaces/IColumnsResponse';
import IFileData from '../Interfaces/IFileData';
import { pageMain, gridPage } from '../Theme/LayoutDefinitions';

const ResellerNewsPageMainSection = () => {
    const { dictionary } = useLanguage();
    const { currentUser, getHeader } = useAuth();
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState<Array<Column>>([]);
    const [hiddenColumns, setHiddenColumns] = useState<Array<string>>([]);
    const [columnExtensions, setColumnExtensions] = useState<Array<Table.ColumnExtension>>([]);
    const [fetchOnce, setFetchOnce] = useState(true);

    useEffect(() => {
        // set loading to true so that the grid will have a loading spinner
        setLoading(true);

        // fetch the columns and the hidden columns for the grid
        // we can't get them while we also get the data for the grid
        async function fetchColumns() {
            let response = await fetch(BackendRoutes.resellerNewsColumns, {
                method: 'GET',
                headers: getHeader(),
            });

            let responseData: IColumnsResponse = await response.json();

            setColumns(responseData.columns);
            setHiddenColumns(responseData.hiddenColumns);
            setColumnExtensions(responseData.columnExtensions);
            setLoading(false);
        }

        if(fetchOnce) {
            fetchColumns();
            setFetchOnce(false);
        }

    }, [setLoading, getHeader, fetchOnce]);

    const downloadFile = (rowData: IFileData, viewFile: string) => {
        let downloadUrl = BackendRoutes.resellerNewsDownload + '/' + rowData.fileHash + '?jwt=' + currentUser.jwt;
        
        if(viewFile) {
            downloadUrl += '&showfile=true';
        }

        window.open(downloadUrl, '_blank');
    };

    const downloadSelectedFiles = (ids: string[]) => {
        const downloadUrl = BackendRoutes.resellerNewsDownload + '/' + ids.join(',') + '?jwt=' + currentUser.jwt;
        window.open(downloadUrl, '_blank');
    }

    return (
        <Box component='main' sx={pageMain}>
            <PageHeader 
                headerTitle={dictionary.reseller_news}
            />
            <Box sx={gridPage}>
                {
                    loading ?
                    <LoadingGridData />
                    :
                    <TreeGrid 
                        columns={columns}
                        URL={BackendRoutes.resellerNews}
                        currentUser={currentUser}
                        categroyColumn={'fileName'}
                        noGridDataText={dictionary.noGridData}
                        columnExtensions={columnExtensions}
                        downloadSelectedFiles={downloadSelectedFiles}
                        download={downloadFile}
                        hiddenColumns={hiddenColumns}
                    />
                }
            </Box>
        </Box>
    )
}

export default ResellerNewsPageMainSection;