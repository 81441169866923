import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, ListSubheader, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { createContext, forwardRef, useContext, useEffect, useRef } from "react";
import { VariableSizeList } from "react-window";
import { ILocation } from "../../interfaces/customer/ILocation";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const LISTBOX_PADDING = 8; // px

function renderRow(props: any) {
    //#region Component state variables
    //#endregion Component state variables

    //#region Fetch requests
    //#endregion Fetch requests

    //#region Component effects
    //#endregion Component effects

    //#region Event handlers
    //#endregion Event Handlers

    //#region Markup helper variables
    //#endregion Markup helper variables

    //#region Markup
    //#endregion Markup

    const { data, index, style } = props;
    const dataSet: Array<ILocation> | any = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty("group")) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Typography component="li" {...dataSet[0]} key={dataSet[0]?.id} noWrap style={inlineStyle}>
            <Box
                component="span"
                sx={{
                    width: 14,
                    height: 14,
                    flexShrink: 0,
                    borderRadius: "3px",
                    mr: 1,
                    mt: "2px",
                    fontSize: "13px",
                    textAlign: "center",
                    color: "white",
                    verticalAlign: "text-top",
                }}
            >
                {/* {dataSet[1]?.customerName ? "P" : ""} */}
            </Box>
            {dataSet[1]?.customerName
                ? dataSet[1]?.customerName +
                  (dataSet[1]?.customerName2 != null && dataSet[1]?.customerName2.leghth > 0
                      ? ` - ${dataSet[1]?.customerName2}`
                      : "") +
                  (dataSet[1]?.town != null ? ` - ${dataSet[1]?.town}` : "") +
                  (dataSet[1]?.street != null ? ` - ${dataSet[1]?.street}` : "") +
                  (dataSet[1]?.erpnumber != null ? ` - (${dataSet[1]?.erpnumber})` : "")
                : ""}
        </Typography>
    );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = useRef<VariableSizeList>(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const AutocompleteListbox: any = forwardRef<HTMLDivElement>((props: any, ref) => {
    //#region Component state variables
    const { children, ...other } = props;
    const itemData: any = [];
    children.forEach((item: any) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;
    const gridRef = useResetCache(itemCount);
    //#endregion Component state variables

    //#region Functions
    const getChildSize = (child: any) => {
        if (child.hasOwnProperty("group")) {
            return 48;
        }
        return itemSize;
    };
    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a: any, b: any) => a + b, 0);
    };
    //#endregion Functions

    //#region Markup
    return (
        <div style={{ zIndex: 1000 }} ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
    //#endregion Markup
});

export default AutocompleteListbox;
