import { useEffect, useState } from 'react';
import { generateKey } from '../common/functions';
import { AppDispatch } from '../common/store';
import { formsActions } from '../features/forms/formsSlice';
import { FieldName, FormName, initialFormField } from '../features/forms/IFormParams';
import { IFormProps } from './useAutocomplete';

const useInput = (props: IFormProps) =>
{
    const [inputId, setInputId] = useState("");
    const [enteredValue, setEnteredValue] = useState('');
    const [isTouched, setIsTouched] = useState(false);

    const valueIsValid = props.validationCallback(enteredValue);
    const hasError = !valueIsValid && isTouched;


    useEffect(() =>
    {
        if (inputId === "")
        {
            const key = generateKey(12);
            if (key !== "")
            {
                setInputId(key);
            }
        }

        return () =>
        {
            if (inputId.length > 0 && props.fieldName && props.fieldName !== FieldName.none && props.formName && props.formName !== FormName.none)
            {
                let tempField1 = { ...initialFormField };
                tempField1.id = inputId;
                AppDispatch(formsActions.UnsetField(tempField1));
            }
        };
    }, []);


    useEffect(() =>
    {
        if (inputId !== "" && props.fieldName && props.fieldName !== FieldName.none && props.formName && props.formName !== FormName.none)
        {
            let tempField = { ...initialFormField };
            tempField.id = inputId;
            tempField.fieldName = props.fieldName;
            tempField.formName = props.formName;
            tempField.hasError = hasError;
            tempField.isValid = valueIsValid;
            tempField.value = enteredValue;
            AppDispatch(formsActions.SetField(tempField));
        }
    }, [enteredValue, valueIsValid, hasError, inputId]);

    const valueChangeHandler = (event: any) =>
    {
        setEnteredValue(event.target.value);
    };

    const inputBlurHandler = (event: any) =>
    {
        setIsTouched(true);
    };

    const reset = () =>
    {
        setEnteredValue('');
        setIsTouched(false);
    };

    return {
        inputId,
        value: enteredValue,
        setValue: setEnteredValue,
        isValid: valueIsValid,
        hasError,
        valueChangeHandler,
        inputBlurHandler,
        reset
    };
};

export default useInput;
