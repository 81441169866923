import { HashRouter, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./Contexts/AuthContext";
import { UXProvider } from "./Contexts/UXContext";
import { LanguageProvider } from "./Contexts/LanguageContext";
import { MediaQueryProvider } from "./Contexts/MediaQueryContext";
import ProtectedRoute from "./Theme/ProtectedRoute";
import Layout from './Theme/Layout';
import ThemeChanger from './Theme/ThemeChanger';
import HomePage from "./Pages/HomePage";
import InvoicePage from "./Pages/InvoicePage";
import ProvInvoicePage from "./Pages/ProvInvoicePage";
import ReportsPage from "./Pages/ReportsPage";
import ResellerPage from "./Pages/ResellerPage";
import UsersPage from "./Pages/UsersPage";
import SettingsPage from "./Pages/SettingsPage";
import ResellerNewsPage from "./Pages/ResellerNewsPage";
import Login from "./Pages/Login";
import StatisticsPage from "./Pages/StatisticsPage";
import OneTimePassword from "./Pages/OneTimePassword";
import ErrorPage from "./Pages/ErrorPage";
import OfferMakerPage from "./Pages/OfferMakerPage";
import ForgotPasswordPage from './Pages/ForgotPasswordPage';
import './Theme/Css/App.css';

// NOTE convert js /jsx to ts / tsx files
//  -> https://www.sitepoint.com/how-to-migrate-a-react-app-to-typescript/

// @see https://reactrouter.com/en/main/router-components/hash-router
// it has to be a hash router if it is a browser router it will not
// work when it is placed in a public folder of an framework like laminas
function App() {
    return (
        <HashRouter>
            <MediaQueryProvider>
                <ThemeChanger>
                    <UXProvider>
                        <AuthProvider>
                            <LanguageProvider>
                                <Layout>
                                    <Routes>
                                        <Route path="/" element={<Login />} />
                                        <Route path="/otp/:token" element={<OneTimePassword />} />
                                        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
                                        <Route
                                            path="/welcome"
                                            element={<ProtectedRoute outlet={<HomePage />} />}
                                        />
                                        <Route
                                            path="/invoices"
                                            element={<ProtectedRoute outlet={<InvoicePage />} />}
                                        />
                                        <Route
                                            path="/prov_invoices"
                                            element={<ProtectedRoute outlet={<ProvInvoicePage />} />}
                                        />
                                        <Route
                                            path="/reports"
                                            element={<ProtectedRoute outlet={<ReportsPage />} />}
                                        />
                                        <Route
                                            path="/reseller"
                                            element={<ProtectedRoute outlet={<ResellerPage />} />}
                                        />
                                        <Route
                                            path="/reseller_news"
                                            element={<ProtectedRoute outlet={<ResellerNewsPage />} />}
                                        />
                                        <Route
                                            path="/users"
                                            element={<ProtectedRoute outlet={<UsersPage />} />}
                                        />
                                        <Route
                                            path="/settings"
                                            element={<ProtectedRoute outlet={<SettingsPage />} />}
                                        />
                                        <Route
                                            path="/statistics"
                                            element={<ProtectedRoute outlet={<StatisticsPage />} />}
                                        />
                                        <Route
                                            path="/offermaker/*"
                                            element={<ProtectedRoute outlet={<OfferMakerPage />} />}
                                        />
                                        <Route path="*" element={<ErrorPage />} />
                                    </Routes>
                                </Layout>
                            </LanguageProvider>
                        </AuthProvider>
                    </UXProvider>
                </ThemeChanger>
            </MediaQueryProvider>
        </HashRouter>
    );
}

export default App;
