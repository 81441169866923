import { useEffect, useState } from 'react';
import { generateKey } from '../common/functions';
import { AppDispatch } from '../common/store';
import { formsActions } from '../features/forms/formsSlice';
import { FieldName, FormName, initialFormField } from '../features/forms/IFormParams';
import { IFormProps } from './useAutocomplete';


const NUMBER = "number";
const STRING = "string";
const BOOLEAN = "boolean";

function useTypedInput<Type extends number | string | boolean>(props: IFormProps)
{
    const [inputId, setInputId] = useState("");
    const [enteredValue, setEnteredValue] = useState<Type>("" as Type);
    const [isTouched, setIsTouched] = useState(false);

    const valueIsValid = props.validationCallback(enteredValue);
    const hasError = !valueIsValid && isTouched;


    /*     const getType = (value: Type | null) =>
        {
            if (typeof value === STRING)
            {
                return STRING;
            }
            else if (typeof value === NUMBER)
            {
                return NUMBER;
            }
            return null;
        }; */

    useEffect(() =>
    {
        if (inputId === "")
        {
            const key = generateKey(12);
            if (key !== "")
            {
                setInputId(key);
            }
        }

        return () =>
        {
            if (inputId.length > 0 && props.fieldName && props.fieldName !== FieldName.none && props.formName && props.formName !== FormName.none)
            {
                let tempField1 = { ...initialFormField };
                tempField1.id = inputId;
                AppDispatch(formsActions.UnsetField(tempField1));
            }
        };
    }, []);


    useEffect(() =>
    {
        if (inputId !== "" && props.fieldName && props.fieldName !== FieldName.none && props.formName && props.formName !== FormName.none)
        {
            let tempField = { ...initialFormField };
            tempField.id = inputId;
            tempField.fieldName = props.fieldName;
            tempField.formName = props.formName;
            tempField.hasError = hasError;
            tempField.isValid = valueIsValid;
            tempField.value = enteredValue;
            AppDispatch(formsActions.SetField(tempField));
        }
    }, [enteredValue, valueIsValid, hasError, inputId]);

    const valueChangeHandler = (event: any) =>
    {
        setEnteredValue(event.target.value);
    };

    const inputBlurHandler = (event: any) =>
    {
        setIsTouched(true);
    };

    const reset = () =>
    {
        if (typeof enteredValue === NUMBER)
        {
            setEnteredValue(1 as Type);
        }
        else if (typeof enteredValue === BOOLEAN)
        {
            setEnteredValue(false as Type);
        }
        else if (typeof enteredValue === STRING)
        {
            setEnteredValue('' as Type);
        }
        else
        {
            // setEnteredValue(null);
            setEnteredValue('' as Type);
        }
        setIsTouched(false);
    };

    return {
        inputId,
        value: enteredValue,
        setValue: setEnteredValue,
        isValid: valueIsValid,
        hasError,
        valueChangeHandler,
        inputBlurHandler,
        reset
    };
};

export default useTypedInput;
