import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterOption, IFieldOptionExtended, initialFieldOptionExtendedData } from "../../interfaces/IFieldOption";
import { IOfferList, IOrderBy, IPagination, ISearchOption } from "../../interfaces/offer/IOfferState";


const initialOfferSearchOption: ISearchOption = {
    searchText: "",
    filterOption: FilterOption.offerNumber,
};
const intialPagination: IPagination = {
    orderBy: { field: "createDate", sort: "desc" },
    pageNumber: 1,
    rowsPerPage: 1,
};

// Define the initial state using that type
const initialState: IOfferList = {
    pagination: intialPagination,
    offerSearchOption: initialOfferSearchOption,
    offerTypeFilterOption: initialFieldOptionExtendedData,
    offerStatusFilterOption: initialFieldOptionExtendedData,
    offerCreateUserFilterOption: initialFieldOptionExtendedData,
    offerSalesUserFilterOption: initialFieldOptionExtendedData,
};

const offerListSlice = createSlice({
    name: 'offersState',
    initialState: initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        SetSearchText: (state, action: PayloadAction<string>) =>
        {
            state.offerSearchOption.searchText = action.payload;
        },
        SetSearchFilterOption: (state, action: PayloadAction<FilterOption>) =>
        {
            state.offerSearchOption.filterOption = action.payload;
        },
        SetOfferTypeFilterOption: (state, action: PayloadAction<IFieldOptionExtended>) =>
        {
            state.offerTypeFilterOption = action.payload;
        },
        SetOfferStatusFilterOption: (state, action: PayloadAction<IFieldOptionExtended>) =>
        {
            state.offerStatusFilterOption = action.payload;
        },
        SetOfferCreateUserFilterOption: (state, action: PayloadAction<IFieldOptionExtended>) =>
        {
            state.offerCreateUserFilterOption = action.payload;
        },
        SetOfferSalesUserFilterOption: (state, action: PayloadAction<IFieldOptionExtended>) =>
        {
            state.offerSalesUserFilterOption = action.payload;
        },

        SetPageSize: (state, action: PayloadAction<number>) =>
        {
            state.pagination.rowsPerPage = action.payload;
        },

        SetPageNumber: (state, action: PayloadAction<number>) =>
        {
            state.pagination.pageNumber = action.payload;
        },

        SetOrderBy: (state, action: PayloadAction<IOrderBy>) =>
        {
            state.pagination.orderBy = action.payload;
        },

        ResetFilterOptions: (state, action: PayloadAction<void>) =>
        {
            const tempState: IOfferList = {
                offerSearchOption: { searchText: state.offerSearchOption.searchText, filterOption: state.offerSearchOption.filterOption },
                offerTypeFilterOption: initialFieldOptionExtendedData,
                offerStatusFilterOption: initialFieldOptionExtendedData,
                offerCreateUserFilterOption: initialFieldOptionExtendedData,
                offerSalesUserFilterOption: initialFieldOptionExtendedData,
                pagination: {
                    orderBy: { field: "createDate", sort: "desc" },
                    pageNumber: 1,
                    rowsPerPage: state.pagination.rowsPerPage,
                }
            };
            return tempState;
        },

    }
});

export const offersActions = offerListSlice.actions;
export default offerListSlice.reducer;


/*                 offersContext?.setOfferTypeFilterOption((prevState: IFieldOption) => {
                    let tempState: IFieldOption =
                        checkBox.checked == true ? prevState : initialFilterDataOption;
                    return {
                        ...tempState,
                        checked: checkBox.checked,
                    };
                }); */