import { useState, useEffect, useCallback } from 'react';
import { Button, Box } from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SettingsIcon from '@mui/icons-material/Settings'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import ReceiptIcon from '@mui/icons-material/Receipt'
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom'

import { 
    menuButton, blur, MenuButtonCloseAnimation, MenuButtonOpenAnimation,
    menuContainer, menuElement, menuClose, menuOpen
} from './LayoutMenuDefinitions';
import { useUX } from '../Contexts/UXContext';
import { useAuth } from '../Contexts/AuthContext'
import IMenu from '../Interfaces/Menu/IMenu';
import BackendRoutes from '../Constants/BackendRoutes';

/**
 * Get the icon which will be shown in the menu
 * 
 * @param {string} iconName 
 * 
 * @returns {JSX.Element|string}
 */
function getIcon(iconName: string) {
    switch(iconName) {
        case 'EqualizerIcon': return <EqualizerIcon />;
        case 'AnalyticsIcon': return <AnalyticsIcon />;
        case 'PeopleAltIcon': return <PeopleAltIcon />;
        case 'SettingsIcon': return <SettingsIcon />;
        case 'StickyNote2Icon': return <StickyNote2Icon />;
        case 'PrivacyTipIcon': return <PrivacyTipIcon />;
        case 'ReceiptIcon': return <ReceiptIcon />;
        case 'LoyaltyIcon': return <LoyaltyIcon />;
        case 'NewspaperIcon': return <NewspaperIcon />;
        case 'StatsIcon': return <InsertChartIcon />;
        default: return '';
    }
}

/**
 * Get the link for the imprint and privacy protection
 * 
 * @param {string} menuItemId 
 * @param {string} imprint 
 * @param {string} privacyProtection 
 * 
 * @returns {string}
 */
function getSpecialLink(menuItemId: string, imprint: string, privacyProtection: string) {
    // @TODO: chenge it so that it is dynamic
    if(menuItemId === 'impressum') {
        return imprint;
    }
    else if(menuItemId === 'privacyProtection') {
        return privacyProtection;
    }
    
    return '';
}

interface IBadgeData {
    billing: number,
    billing_provisioning: number,
    reseller: number,
    reseller_news: number,
    [key: string]: number
}

const LayoutMenu = () => {
    const { imprintUri, privacyProtection } = useUX();
    const { currentUser } = useAuth();
    const [isMenu, setIsMenu] = useState(false);
    const [badgeData, setBadgeData] = useState<IBadgeData>({
        billing: 0,
        billing_provisioning: 0,
        reseller: 0,
        reseller_news: 0
    });
    const [menuData, setMenuData] = useState<IMenu>({
        menu: [],
        sessionLanguage: ''
    });

    let menuAnimation = MenuButtonCloseAnimation;
    let menuClosed = menuClose;
    if(isMenu) {
        menuAnimation = MenuButtonOpenAnimation;
        menuClosed = menuOpen;
    }

    const fetchMenu = useCallback(async () => {
        // create the header which we need to send with our request to the backend
        const defaultHeader = new Headers();
        defaultHeader.append('Access-Control-Allow-Origin', '*');
        defaultHeader.append('Content-Type', 'application/json');
        defaultHeader.append('Authorization', 'Bearer ' + currentUser.jwt);

        // fire the fetch request to the backend
        let response = await fetch(BackendRoutes.menu, {
            method: 'GET',
            headers: defaultHeader
        });
        let responseData: IMenu = await response.json();

        setMenuData(responseData);
    }, [currentUser])

    // use an effect to load the data from the backend
    useEffect(() => {
        fetchMenu();
    }, [fetchMenu])

    const fetchBadgeCount = useCallback(async () => {
        if(currentUser.showBadgeCounter === 'Y') {
            // create the header which we need to send with our request to the backend
            const defaultHeader = new Headers();
            defaultHeader.append('Access-Control-Allow-Origin', '*');
            defaultHeader.append('Content-Type', 'application/json');
            defaultHeader.append('Authorization', 'Bearer ' + currentUser.jwt);

            // fire the fetch request to the backend
            let response = await fetch(BackendRoutes.badgeCounter, {
                method: 'GET',
                headers: defaultHeader
            });
            let responseData = await response.json();

            setBadgeData(responseData);
        }
        else {
            setBadgeData({
                billing: 0,
                billing_provisioning: 0,
                reseller: 0,
                reseller_news: 0
            });
        }
    }, [currentUser]);

    useEffect(() => {
        if(isMenu) {
            fetchBadgeCount();
        }
        else {
            setBadgeData({
                billing: 0,
                billing_provisioning: 0,
                reseller: 0,
                reseller_news: 0
            });
        }
    }, [fetchBadgeCount, isMenu]);

    const isPartner = (menuItemId: string, isPartner: string) => {
        if(
            isPartner === 'N' && 
            (menuItemId === 'billing_provisioning' || menuItemId === 'reseller' || menuItemId === 'reseller_news')
        ) {
            return false;
        }

        return true;
    }

    return (
        <Box>
            {isMenu && <Box sx={blur} onClick={() => setIsMenu(false)} />}
            <Button 
                sx={[
                    ...(Array.isArray(menuButton) ? menuButton : [menuButton]), 
                    ...(Array.isArray(menuAnimation) ? menuAnimation : [menuAnimation])
                ]}
                onClick={() => setIsMenu(!isMenu)}
            >
                <span></span>
                <span></span>
            </Button>
            <Box 
                sx={[
                    ...(Array.isArray(menuContainer) ? menuContainer : [menuContainer]), 
                    ...(Array.isArray(menuClosed) ? menuClosed : [menuClosed])
                ]}
            >
                <Box component='ul' onClick={() => setIsMenu(false)}>
                    {
                        menuData.menu !== undefined ? 
                            menuData.menu.map((menuItem) => {
                                return (
                                    <Box component='li' key={menuItem.menuItemId} sx={menuElement}>
                                        {
                                            menuItem.menuItemId !== 'impressum' && menuItem.menuItemId !== 'privacyProtection' ?
                                            <Link 
                                                to={'/' + menuItem.route}
                                                onClick={(event) => {
                                                    if(!isPartner(menuItem.menuItemId, menuItem.isPartner)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                style={{
                                                    pointerEvents: !isPartner(menuItem.menuItemId, menuItem.isPartner) ? 'none' : 'auto',
                                                    opacity: !isPartner(menuItem.menuItemId, menuItem.isPartner) ? 0.5 : 1
                                                }}
                                            >
                                                <Badge badgeContent={badgeData[menuItem.menuItemId] ? parseInt('' + badgeData[menuItem.menuItemId]) : 0} color="info">
                                                    {getIcon(menuItem.iconCls)}
                                                </Badge>
                                                {menuItem.text}
                                            </Link>
                                            :
                                            <a href={getSpecialLink(menuItem.menuItemId, imprintUri, privacyProtection)} target='_blank' rel='noreferrer'>
                                                {getIcon(menuItem.iconCls)}
                                                {menuItem.text}
                                            </a>
                                        }
                                    </Box>
                                );
                            })
                        :
                        null
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default LayoutMenu;