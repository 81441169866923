import { useEffect, useRef, useState } from "react";



export function useComponentHeight(ref: any)
{
    const [height, setHeight] = useState(1);
    useEffect(() =>
    {
        if (ref.current)
        {
            const { current } = ref;
            const boundingRect = current.getBoundingClientRect();
            const { width, height } = boundingRect;
            setHeight(Math.round(height));
        }
    }, [ref, height]);

    return height;
}