
import { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { Column, Grouping } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import PageHeader from '../Components/Header/PageHeader';
import BackendRoutes from '../Constants/BackendRoutes';
import LoadingGridData from '../Components/Grids/LoadingGridData';
import GroupingGrid from '../Components/Grids/GroupingGrid';
import { useAuth } from '../Contexts/AuthContext';
import { useLanguage } from '../Contexts/LanguageContext';
import IColumnsResponse from '../Interfaces/IColumnsResponse';
import IFileData from '../Interfaces/IFileData';
import ICustomerData from '../Interfaces/User/ICustomerData';
import { pageMain, gridPage } from '../Theme/LayoutDefinitions';

const ProvInvoicePageMainSection = () => {
    const { dictionary } = useLanguage();
    const { currentUser, getHeader } = useAuth();
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState<Array<Column>>([]);
    const [hiddenColumns, setHiddenColumns] = useState<Array<string>>([]);
    const [columnExtensions, setColumnExtensions] = useState<Array<Table.ColumnExtension>>([]);
    const [fetchOnce, setFetchOnce] = useState(true);
    const [defaultGrouping, setDefaultGrouping] = useState<Array<Grouping>>([{ columnName: 'period' }, { columnName: 'company' }]);
    const [rowData, setRawData] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const [selectedCustomers, setSelectedCustomers] = useState<Array<ICustomerData>>([]);
    const [customers, setCustomers] = useState<Array<ICustomerData>>([]);
    const [customerCount, setCustomerCount] = useState(0);
    const [loadingOnce, setLoadingOnce] = useState(true);
    const [getGridData, setGetGridData] = useState(false);

    const fetchCustomers = useCallback(async () => {
        setCustomers([]);

        let response = await fetch(BackendRoutes.customers, {
            method: 'GET',
            headers: getHeader()
        });

        let responseData = await response.json();

        // @ts-ignore
        setCustomers(responseData.customersData);
        setCustomerCount(responseData.customersData.length);

        setLoadingOnce(false);
        setGetGridData(true);
    }, [getHeader]);

    useEffect(() => {
        if(loadingOnce) {
            fetchCustomers();
        }
    }, [fetchCustomers, loadingOnce]);

    // use an effect to load the data from the backend
    useEffect(() => {
        // fetch the columns and the hidden columns for the grid
        // we can't get them while we also get the data for the grid
        async function fetchColumns() {
            // set loading to true so that the grid will have a loading spinner
            setLoading(true);

            let response = await fetch(BackendRoutes.provisionColumns, {
                method: 'GET',
                headers: getHeader(),
            });

            let responseData: IColumnsResponse = await response.json();

            /*const groupConfig = defaultGrouping.map(columnGrouping => ({
                selector: columnGrouping.columnName,
                isExpanded: true,
            }));

            response  = await fetch(BackendRoutes.provision + `?group=${JSON.stringify(groupConfig)}`, {
                method: 'GET',
                headers: getHeader(),
            });

            let rowData: any = await response.json();

            setRawData(rowData.data);*/
            setColumns(responseData.columns);
            setHiddenColumns(responseData.hiddenColumns);
            setColumnExtensions(responseData.columnExtensions);
            setDefaultGrouping(responseData.defaultGrouping);
            setLoading(false);
            setFetchOnce(false);
        }

        if(fetchOnce) {
            fetchColumns();
        }
    }, [setLoading, getHeader, fetchOnce]);

    // use an effect to load the data when there was a change to the 
    // search parameter
    useEffect(() => {
        async function fetchGridData() {
            const groupConfig = defaultGrouping.map(columnGrouping => ({
                selector: columnGrouping.columnName,
                isExpanded: true,
            }));

            let filter = columns.reduce((acc: Array<string>, { name }) => {
                acc.push(`["${name}", "contains", "${encodeURIComponent(searchValue)}"]`);
                return acc;
            }, []).join(',"or",');
          
            if (columns.length > 1) {
                filter = `[${filter}]`;
            }

            let customers = selectedCustomers.reduce((accumulator: Array<string | number>, { id }) => {
                accumulator.push(id);
                return accumulator;
            }, []).join(',');

            let customerQuery = '';
            if(customers !== '') {
                customerQuery = `&customers=${customers}`;
            }
            else if(customerCount > 10) {
                customerQuery = '&customers=-1';
            }

            let url = BackendRoutes.provision + `?group=${JSON.stringify(groupConfig)}${customerQuery}`;
            if(searchValue !== '') {
                url = BackendRoutes.provision + `?group=${JSON.stringify(groupConfig)}${customerQuery}&filter=${filter}`;
            }

            let response  = await fetch(url, {
                method: 'GET',
                headers: getHeader(),
            });

            let rowData: any = await response.json();
            
            setRawData(rowData.data);
        }

        if(getGridData) {
            fetchGridData();
        }
    }, [getHeader, searchValue, defaultGrouping, columns, customerCount, selectedCustomers, getGridData]);

    const downloadFile = (rowData: IFileData, viewFile:boolean) => {
        let downloadUrl = BackendRoutes.provisionDownload + '/' + rowData.fileHash + '?jwt=' + currentUser.jwt;

        if(viewFile) {
            downloadUrl += '&showfile=true';
        }

        window.open(downloadUrl, '_blank');
    };

    const downloadSelectedFiles = (ids: Array<string | number>) => {
        const downloadUrl = BackendRoutes.provisionDownload + '/' + ids.join(',') + '?jwt=' + currentUser.jwt;
        window.open(downloadUrl, '_blank');
    }

    return (
        <Box component='main' sx={pageMain}>
            <PageHeader 
                headerTitle={dictionary.provInvoices}
            />
            <Box sx={gridPage}>
            {
                loading ?
                <LoadingGridData /> 
                :
                <GroupingGrid 
                    columns={columns}
                    rows={rowData}
                    hiddenColumns={hiddenColumns}
                    columnExtensions={columnExtensions}
                    defaultGrouping={defaultGrouping}
                    noGridCustomerSelected={dictionary.noGridCustomerSelected}
                    noGridDataText={dictionary.noGridData}
                    useDownloadActionFormater={true}
                    usePeriodFormater={true}
                    useFileTypeFormatter={true}
                    useFilesReadFormater={true}
                    selectByRowClick={true}
                    useDownloadSelection={true}
                    download={downloadFile}
                    downloadSelectedFiles={downloadSelectedFiles}
                    setSearchValue={setSearchValue}
                    selectedCustomers={selectedCustomers}
                    setSelectedCustomers={setSelectedCustomers}
                    customerCount={customerCount}
                    setCustomerCount={setCustomerCount}
                    customers={customers}
                />
            }
            </Box>
        </Box>
    )
}

export default ProvInvoicePageMainSection
