import { useState, useEffect } from 'react';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
    IconButton, Collapse, Box
} from '@mui/material';
import IFieldSet from '../../Interfaces/Props/IFieldSet';
import { fieldset, legend, legendSpan } from './FieldSetDefinitions';

const FieldSet = (props: IFieldSet) => {
    const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed);

    // we have to use useEffect to update a state which should change
    // when the prop is changed
    useEffect(() => {
        setIsCollapsed(props.isCollapsed);
    }, [props.isCollapsed]); 

    const handleCheckboxClick = () => {
        setIsCollapsed(!isCollapsed);
    }

    const expand = () => {
        setIsCollapsed(false);
        
        if(props.trackCollapsedState !== undefined) {
            props.trackCollapsedState(false);
        }
    }

    const collapse = () => {
        setIsCollapsed(true);

        if(props.trackCollapsedState !== undefined) {
            props.trackCollapsedState(true);
        }
    }

    return (
        <Box 
            component='fieldset' 
            sx={fieldset} 
            style={{ width: props.width }}
        >
            <Box component='legend' sx={legend}>
                <Box component='span' sx={legendSpan}>
                    {
                        props.isCollapsible ?
                            props.checkboxToggle ?
                                <input 
                                    type='checkbox' 
                                    name={props.checkboxName} 
                                    checked={!isCollapsed}
                                    onClick={(e) => handleCheckboxClick()}
                                    onChange={(e) => props.handleCheckboxChange && props.handleCheckboxChange()}
                                />
                            :
                            isCollapsed ?
                                <IconButton size='small' onClick={(e) => expand()}>
                                    <ArrowDropDown fontSize="inherit"/>
                                </IconButton>
                            :
                                <IconButton size='small' onClick={(e) => collapse()}>
                                    <ArrowDropUp fontSize="inherit"/>
                                </IconButton>
                        :
                        null
                    }
                    &nbsp;{props.legendText}&nbsp;&nbsp;
                </Box>
            </Box>
            <Collapse in={!isCollapsed}>
                {props.children}
            </Collapse>
        </Box>
    );
};

export default FieldSet;