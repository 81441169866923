import { useLanguage } from '../../Contexts/LanguageContext';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

// @see: https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/data-formatting/

// Render the column in a different manner 
const LanguageFormatter = ({ value }) => {
    const { dictionary } = useLanguage();

    // We use the value which is given by the data from the grid and change it in
    // a different format. In this case we change the short form of a langauge to
    // the long version of it
    return (
        <>
            {value === 'DE' ? dictionary.languages.german : dictionary.languages.english}
        </>
    );
};

// Render the column in a different manner 
const YesNoFormatter = ({ value }) => {
    const { dictionary } = useLanguage();

    // We use the value which is given by the data from the grid and change it in
    // a different format. In this case we change the short form of a of yes and no
    // to the long version of it
    return (
        <>
            {value === 'Y' ? dictionary.yes : dictionary.no}
        </>
    );
};

const PeriodFormater = ({ value }) => {
    // 202201
    const year  = value.substring(0,4);
    const month = value.substring(4,6);

    return (
        <>
            {month}-{year}
        </>
    );
};

const FileTypeFormatter = ({ value }) => {
    switch(value) {
        case 'PDF':
            return (
                <>
                   .pdf
                </>
            );
        case 'CSV':
            return (
                <>
                   .csv
                </>
            );
        case 'XLS':
            return (
                <>
                    .xlsx
                </>
            );
        default:
            return value;
    }
};

const FileReadFormatter = ({ value, row }) => {

    if((row.fileSeen !== undefined && row.fileSeen) || row.isDirectory) {
        return <>{value}</>;
    }

    if((row.isFileOutsideOfBadgeRange !== undefined && row.isFileOutsideOfBadgeRange) || row.isDirectory) {
        return <b>{value}</b>;
    }

    return <b style={{ color: 'darkblue' }}>{value}</b>;
};
  
const FileReadFormatterProvider = props => (
    <DataTypeProvider
        formatterComponent={FileReadFormatter}
        {...props}
    />
);

const LanguageTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={LanguageFormatter}
        {...props}
    />
);

const YesNoTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={YesNoFormatter}
        {...props}
    />
);

const PeriodProvider = props => (
    <DataTypeProvider
        formatterComponent={PeriodFormater}
        {...props}
    />
);

const FileTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={FileTypeFormatter}
        {...props}
    />
);

export {
    LanguageTypeProvider, 
    YesNoTypeProvider,
    PeriodProvider,
    FileTypeProvider,
    FileReadFormatterProvider
};