import { store } from "../../common/store";
import { FilterOption } from "../../interfaces/IFieldOption";
import { IOrderBy } from "../../interfaces/offer/IOfferState";
import { getQueryParams, removeQueryParam, setQueryParam } from "./queryParams";



export const getCustomersQueryParams = (): {} =>
{
    return getQueryParams(() =>
    {
        const customerState = store.getState().customer;

        if (customerState.customerSearchOption.searchText.length >= 0)
        {
            setQueryParam("customername", customerState.customerSearchOption.searchText);
        }
        else
        {
            removeQueryParam("customername");
        }
        if (customerState.customerSearchOption.onlyMainLocation)
        {
            setQueryParam("onlymainlocation", true);
        } else
        {
            setQueryParam("onlymainlocation", false);
        }
        /*         if (customerState.customerSearchOption.rowsPerPage > 0)
                {
                    setQueryParam("takerows", customerState.customerSearchOption.rowsPerPage);
                } else
                {
                    removeQueryParam("takerows");
                } */
    });
};