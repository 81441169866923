import { omApi } from './omApi';
import { getJwtHeader } from '../features/session/omSessionActions';
import { ILocation } from '../interfaces/customer/ILocation';
import { offerMakerRoutes } from '../configuration/offerMakerRoutes';


const languageApiTags = ['language', 'languages'];
export const languageApi = omApi.enhanceEndpoints({ addTagTypes: languageApiTags }).injectEndpoints({

    endpoints: (builder) => ({
        GetLanguages: builder.query<Array<any>, void>({
            query: () => ({
                url: offerMakerRoutes.language,
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['language'],
        }),
        GetLanguageById: builder.query<any, string | number>({
            query: (languageId: any) => ({
                url: offerMakerRoutes.languageById(languageId),
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['language'],
        }),
    }),
});


export const {
    useGetLanguagesQuery,
    useGetLanguageByIdQuery,
} = languageApi;


/*             transformResponse: (response) =>
            {
                if (response)
                {
                    AppDispatch(customerActions.SetCustomer(response as ICustomer));
                }
                return response as ICustomer | Promise<ICustomer>;
            }, */




/* const [trigger, result] = languageApi.endpoints.GetCustomerByGwGuid.useLazyQuery();

languageApi.endpoints.GetCustomerByGwGuid.initiate(
    selectedCustomers.crmLocationGuidStr,
    { subscribe: false, forceRefetch: true }
) */