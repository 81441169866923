import { Box, Collapse, Typography } from "@mui/material";
import { omFieldset, omLegend, omlegendSpan } from "../common/imports";

interface ISimpleFieldSet {
    legendText: string;
    children: any;
    size: "small" | "normal";
}

const SimpleFieldSet = (props: ISimpleFieldSet) => {
    const fieldsetHeight = props.size == "small" ? "100%" : "";
    return (
        <Box
            component="fieldset"
            sx={omFieldset}
            style={{ padding: "10px", height: fieldsetHeight }}
        >
            {props.size == "small" && props.legendText !== "" && (
                <Box component="legend" sx={omLegend} style={{ padding: "unset" }}>
                    <Box
                        component="span"
                        sx={omlegendSpan}
                        style={{ position: "relative", top: "0px", height: "3px" }}
                    >
                        &nbsp;&nbsp;{props.legendText}&nbsp;&nbsp;
                    </Box>
                </Box>
            )}
            {props.size == "normal" && props.legendText !== "" && (
                <Box component="legend" sx={omLegend} style={{ position: "relative", top: "8px" }}>
                    <Box
                        component="span"
                        sx={omlegendSpan}
                        style={{ position: "relative", top: "0px", height: "3px" }}
                    >
                        <Typography variant="h6" noWrap sx={{ lineHeight: "unset" }}>
                            {props.legendText}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Collapse style={{ padding: "unset" }} in={true}>
                {props.children}
            </Collapse>
        </Box>

        /*         <fieldset style={{ padding: "10px", height: fieldsetHeight }}>
            {props.size == "small" ? (
                <legend style={{ padding: "unset" }}>
                    <span style={{ position: "relative", top: "0px", height: "3px" }}>
                        &nbsp;&nbsp;{props.legendText}&nbsp;&nbsp;
                    </span>
                </legend>
            ) : (
                <legend>
                    <span style={{ position: "relative", top: "8px" }}>
                        <Typography variant="h6" noWrap sx={{ lineHeight: "unset" }}>
                            {props.legendText}
                        </Typography>
                    
                    </span>
                </legend>
            )}

            <Collapse style={{ padding: "unset" }} in={true}>
                {props.children}
            </Collapse>
        </fieldset> */
    );
};

export default SimpleFieldSet;

/*
    border:  1px solid #ccc; --> muss aus legend css weg
    border-bottom: 0;
    border-radius: 5px 5px 0 0;
    padding: 0 18px;
    position:relative;
    top: -10px;

*/
