import { createTheme, Theme, PaletteOptions } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';
import { alpha } from "@mui/material/styles";
import { useEffect, useState } from 'react';


export const darkColorPalette: PaletteOptions | any = {

    mode: "dark",
    primary: {
        main: "#bd1224",
    },
    secondary: {
        main: "#f5f5f5",
    },
    background: {
        default: "#282828",
        paper: "#333333",
    },
    success: {
        main: green[800],
    },
    tonalOffset: 0.2,
    contrastThreshold: 3,
    text: {},
    divider: "rgba(255, 255, 255, 0.12)",
    action: {},
    color1: "",
    color2: "",
    color3: "",
    color4: "",
    color5: "#3d3d3d",
};

export const lightColorPalette: PaletteOptions | any = {
    mode: "light",
    primary: {
        main: "#bd1224",
    },
    secondary: {
        main: "#0a0a0a",
    },
    background: {
        default: "#f5f5f5",
        paper: "#ffffff",
    },
    success: {
        main: green[800],
    },
    action: {},
    color1: "#f1f1f1",
    color2: "#fafafa",
    color3: "#e5e5e5",
    color4: "#e5e5e5",
    color5: "#c2c2c2",
};


const theme: Theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#bd1224",
        },
        secondary: {
            main: "#0a0a0a",
        },
        background: {
            default: "#f5f5f5",
            paper: "#ffffff",
        },
        success: {
            main: green[800],
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                //  size: 'small',
                disableRipple: true,
            },
            styleOverrides: {
                // Name of the slot
                root: {
                    "&:focus": {
                        outlineOffset: "2px",
                        outline: `3px solid ${alpha(darkColorPalette.primary.main, 0.50)}`,
                    },
                },
            },
        },
        MuiButton:
        {
            defaultProps: {
                size: 'small',
                disableRipple: true,
            },
            styleOverrides: {
                // Name of the slot
                root: {
                    "&:focus": {
                        outlineOffset: "2px",
                        outline: `3px solid ${alpha(darkColorPalette.primary.main, 0.50)}`,
                    },
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                },
            },
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                },
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                },
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    "&:focus": {
                        outlineOffset: "2px",
                        outline: `3px solid ${alpha(darkColorPalette.primary.main, 0.50)}`,
                    },
                },
                sizeSmall: {
                    marginLeft: 4,
                    marginRight: 4,
                    padding: 12,
                },
            },
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                },
            },
        },
        MuiListItem: {
            defaultProps: {
                dense: true,
            },
            styleOverrides: {
                root: {
                },
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                    "&:focus": {
                        outlineOffset: "2px",
                        outline: `3px solid ${alpha(darkColorPalette.primary.main, 0.50)}`,
                    },
                },
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                },
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                margin: 'dense',
                size: 'small',
            },
            styleOverrides: {
                root: {
                    backgroundColor: "#282828",
                    "&:focus": {
                        outlineOffset: "2px",
                        outline: `3px solid ${alpha(darkColorPalette.primary.main, 0.50)}`,
                    },
                },
            },
        },
        MuiToolbar: {
            defaultProps: {
                variant: 'dense',
            },
            styleOverrides: {
                root: {
                },
            },
        },
        MuiTab: {
            defaultProps: {
            },
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                },

            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: "#474747",
                },
            },
        },
        MuiSwitch: {
            defaultProps: {
            },
            styleOverrides: {
                root: {
                    "&:focus": {
                        outlineOffset: "2px",
                        outline: `3px solid ${alpha(darkColorPalette.primary.main, 0.50)}`,
                    },
                },
            },
        }
    }
});

export const byonLightTheme = theme;

const useByonTheme = (themeMode: "dark" | "light") =>
{
    const [data, setData] = useState<Theme>(theme);

    useEffect(() =>
    {
        if (themeMode === "dark")
        {
            theme.palette = darkColorPalette;
            setData(theme);
        }
        else
        {
            theme.palette = lightColorPalette;
            setData(theme);
        }
    }, [themeMode]);

    return data;
};

export default useByonTheme;