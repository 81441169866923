import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Button, IconButton, InputAdornment, Box } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import parse from 'html-react-parser';

import TextField from '../../Components/Form/TextField';
import { useLanguage } from '../../Contexts/LanguageContext';
import BackendRoutes from '../../Constants/BackendRoutes';
import IUserDataPage from '../../Interfaces/otp/Props/IUserDataPage';
import { resetPasswordMarginTop } from '../../Theme/LayoutDefinitions';


const UserDataPage = (props: IUserDataPage) => {
    const navigate = useNavigate();
    const { dictionary } = useLanguage();

    const [showPassword, setShowPassword] = useState(false);
    const [updateSuccessful, setUpdateSuccessful] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [formChanged, setFormChanged] = useState({
        password: false,
        repeatPassword: false
    });

    const [formValues, setFormValues] = useState({
        token: props.token,
        password: '',
        repeatPassword: ''
    });

    const [formErrors, setFormErrors] = useState({
        password: '',
        repeatPassword: ''
    });

    const handleChange = (event: any, fieldName: string) => {
        setFormValues({
            ...formValues,
            [fieldName]: event.target.value
        });
        setFormChanged({
            ...formChanged,
            [fieldName]: true
        });
    }

    useEffect(() => {
        // eslint-disable-next-line
        const passwordRegEx   = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\@\.\,\?\!\_\-\+\%\/\#\&\%\*])[\da-zA-Z\@\.\,\?\!\_\-\+\%\/\#\&\%\*\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]{12,20}$/;

        if(formChanged.password) {
            if(formValues.password === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    password: dictionary.errorText.emptyPassword
                }));
            }
            else if(!passwordRegEx.test(formValues.password)) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    password: dictionary.errorText.passwordWeak
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    password: ''
                }));
            }
        }

        if(formChanged.repeatPassword) {
            if(formValues.repeatPassword === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: dictionary.errorText.emptyRepeatPassword
                }));
            }
            else if(!passwordRegEx.test(formValues.repeatPassword)) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: dictionary.errorText.passwordWeak
                }));
            }
            else if(formValues.repeatPassword !== formValues.password) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: dictionary.errorText.passwordCompareError
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: ''
                }));
            }
        }
    }, [setFormErrors, formValues, dictionary, formChanged]);

    const checkForm = () => {
        // eslint-disable-next-line
        const passwordRegEx   = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\@\.\,\?\!\_\-\+\%\/\#\&\%\*])[\da-zA-Z\@\.\,\?\!\_\-\+\%\/\#\&\%\*\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]{12,20}$/;

        if(formValues.password === '') {
            setFormErrors((prevState) => ({
                ...prevState,
                password: dictionary.errorText.emptyPassword
            }));

            return false;
        }
        else if(!passwordRegEx.test(formValues.password)) {
            setFormErrors((prevState) => ({
                ...prevState,
                password: dictionary.errorText.passwordWeak
            }));

            return false;
        }
        else if(formValues.repeatPassword === '') {
            setFormErrors((prevState) => ({
                ...prevState,
                repeatPassword: dictionary.errorText.emptyRepeatPassword
            }));

            return false;
        }
        else if(!passwordRegEx.test(formValues.repeatPassword)) {
            setFormErrors((prevState) => ({
                ...prevState,
                repeatPassword: dictionary.errorText.passwordWeak
            }));

            return false;
        }
        else if(formValues.repeatPassword !== formValues.password) {
            setFormErrors((prevState) => ({
                ...prevState,
                repeatPassword: dictionary.errorText.passwordCompareError
            }));

            return false;
        }

        return true;
    };

    async function update() {
        const defaultHeader = new Headers();
        defaultHeader.append('Access-Control-Allow-Origin', '*');
        defaultHeader.append('Content-Type', 'application/json');
        
        let response = await fetch(BackendRoutes.otp.update, {
            method: 'PUT',
            headers: defaultHeader,
            body: JSON.stringify(formValues)
        });
        let responseData = await response.json();

        if(responseData.success) {
            setUpdateSuccessful(true);
        }
    }

    const handleSubmit = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        
        if(checkForm()) {
            update();
        }
    };

    const backToLoginPage = () => {
        navigate('/');
    };

    const handleKeyPress = (event: any) => {
        if(event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    return (
        <Box>
            {
                updateSuccessful ?
                <Box sx={resetPasswordMarginTop}>
                    {parse(dictionary.otp.updateSuccess.replace('{loginName}', props.loginName))}
                    <br /><br /><br/>
                    <Button 
                        variant="contained"
                        className='btn'
                        onClick={backToLoginPage}
                    >
                        {dictionary.otp.backToLogin}
                    </Button>
                </Box>
                :
                <Box sx={resetPasswordMarginTop}>
                    {parse(dictionary.otp.infoText.replace('{loginName}', props.loginName))}
                    <TextField
                        sx={{marginTop: '35px'}}
                        required={true}
                        inputId='password'
                        title={dictionary.userForm.password}
                        value={formValues.password}
                        errorText={formErrors.password}
                        type={showPassword ? "text" : "password"}
                        onChange={(event: any) => handleChange(event, 'password')}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )}
                    />
                    <TextField
                        required={true}
                        inputId='repeatPassword'
                        title={dictionary.userForm.repeatPassword}
                        value={formValues.repeatPassword}
                        errorText={formErrors.repeatPassword}
                        type='password'
                        onChange={(event: any) => handleChange(event, 'repeatPassword')}
                        onKey={handleKeyPress}
                    />
                    <Button 
                        variant="contained" 
                        className='btn' 
                        onClick={handleSubmit}
                    >
                        {dictionary.userForm.save}
                    </Button>
                </Box>
            }
        </Box>
    );
}

export default UserDataPage;