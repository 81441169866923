import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CloseIcon from "@mui/icons-material/Close";
import {
    Autocomplete,
    autocompleteClasses,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Popper,
    Switch,
    TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { isNotNullOrUndefined } from "../../common/functions";
import { AppDispatch } from "../../common/store";
import { ICustomer } from "../../interfaces/customer/ICustomer";
import { IGwLocation, initialGwAddress } from "../../interfaces/customer/IGwLocation";
import { ILocation } from "../../interfaces/customer/ILocation";
import { customerApi } from "../../services/customerApi";
import { gwAddressApi } from "../../services/gwAddressApi";
import { locationApi } from "../../services/locationApi";
import { useOmTranslation } from "../../translations/OmTranslationContext";
import CustomerErrorDetail from "./CustomerErrorDetail";
import { customerActions } from "./customerSlice";
import SelectedCustomerDetail from "./SelectCustomerDetail";
import SelectCustomerDropdownList from "./SelectCustomerDropdownList";

export interface IDialogErrorResult {
    status: number;
    data: { id: number; messages: Array<string>; needsChangeDate: boolean };
    crmLocationGuidStr: string;
}

export interface IMessageResult {
    status?: number;
    data: { id: number; messages: Array<string>; needsChangeDate: boolean };
    crmLocationGuidStr?: string;
    setMessages?: (props: IMessageResult | null) => void;
}

const CustomerSelectDialog = (props: any) => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    //const customerState = useAppSelector((state) => state.customer);
    const [isDifferentInstallLocation, setIsDifferentInstallLocation] = useState(false);
    const [selectedGwCustomer, setSelectedGwCustomer] = useState<IGwLocation | null>(null);
    const [selectedGwLocation, setSelectedGwLocation] = useState<IGwLocation | null>(null);

    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);

    const [customerGetCustomerIdError, setCustomerGetCustomerIdError] =
        useState<IDialogErrorResult | null>(null);
    const [messages, setMessages] = useState<IMessageResult | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    //#endregion

    //#region Fetch Requests

    const [fetchGwMainAddress, fetchGwMainAddressResult] =
        gwAddressApi.endpoints.SearchMainGwAddressByCustomerName.useLazyQuery();

    const [fetchGwAlternativeAddress, fetchGwAlternativeAddressResult] =
        gwAddressApi.endpoints.SearchGwAddressByCustomerName.useLazyQuery();

    const [fetchCustomerGetCustomerId, fetchCustomerGetCustomerIdResult] =
        customerApi.endpoints.GetCustomerIdByGwGuid.useLazyQuery();

    // sobald wir den CustomerId haben, müssen wir Customer von Cubis holen.
    const [fetchCustomer, fetchCustomerResult] =
        customerApi.endpoints.GetCustomerById.useLazyQuery();

    const [fetchLocationGetLocationId, fetchLocationGetLocationIdResult] =
        locationApi.endpoints.GetLocationIdByGwGuid.useLazyQuery();

    // sobald wir den LocationId haben, müssen wir Location von Cubis holen.
    const [fetchLocation, fetchLocationResult] =
        locationApi.endpoints.GetLocationById.useLazyQuery();

    //#endregion

    //#region Component Effects
    useEffect(() => {
        if (selectedGwCustomer?.crmLocationGuidStr !== undefined) {
            fetchCustomerGetCustomerId({ gwguid: selectedGwCustomer?.crmLocationGuidStr }, false);

            AppDispatch(customerActions.SetSearchText(selectedGwCustomer?.customerName));
        }
        if (!isNotNullOrUndefined(selectedGwCustomer)) {
            setSelectedGwLocation(null);
        }
    }, [selectedGwCustomer]);

    useEffect(() => {
        if (fetchCustomerGetCustomerIdResult?.error) {
            setMessages({
                setMessages: setMessages,
                crmLocationGuidStr: selectedGwCustomer?.crmLocationGuidStr ?? "",
                ...(fetchCustomerGetCustomerIdResult?.error as any),
            });
        }
        if (fetchCustomerGetCustomerIdResult?.data) {
            if (fetchCustomerGetCustomerIdResult?.data?.id > 0) {
                fetchCustomer(fetchCustomerGetCustomerIdResult?.data?.id);
            }
        }
    }, [
        fetchCustomerGetCustomerIdResult.data,
        fetchCustomerGetCustomerIdResult.error,
        fetchCustomerGetCustomerIdResult.isFetching,
        fetchCustomerGetCustomerIdResult.isLoading,
    ]);

    useEffect(() => {
        if (fetchCustomerResult.data) {
            setSelectedCustomer(fetchCustomerResult.data);
        } else {
            setSelectedCustomer(null);
        }
    }, [fetchCustomerResult.data]);

    useEffect(() => {
        if (fetchLocationResult.data) {
            setSelectedLocation(fetchLocationResult.data);
        } else {
            setSelectedLocation(null);
        }
    }, [fetchLocationResult.data]);

    useEffect(() => {
        if (selectedGwLocation) {
            fetchLocationGetLocationId(selectedGwLocation?.crmLocationGuidStr ?? "");
        }
    }, [selectedGwLocation]);

    // Result von locationId speichern
    useEffect(() => {
        if (fetchLocationGetLocationIdResult.data) {
            if (fetchLocationGetLocationIdResult?.data?.id > 0) {
                fetchLocation((fetchLocationGetLocationIdResult?.data as any)?.id);
            }
            if (fetchLocationGetLocationIdResult?.data?.messages?.length > 0) {
                setMessages({
                    setMessages: setMessages,
                    data: { ...(fetchLocationGetLocationIdResult?.data as any) },
                });
            }
        }
    }, [fetchLocationGetLocationIdResult.data]);

    useEffect(() => {
        if (isDifferentInstallLocation === false) {
            setSelectedGwLocation(null);
        }
    }, [isDifferentInstallLocation]);

    useEffect(() => {
        if (isOpen === true) {
            setSelectedGwCustomer(null);
            setSelectedGwLocation(null);
            AppDispatch(customerActions.ResetCustomerSearchOptions());
        }
    }, [isOpen]);
    //#endregion

    //#region Event Handlers
    const handleApply = () => {
        /*         if (selectedGwCustomer) {
            AppDispatch(customerActions.SetGwMainLocation(selectedGwCustomer));
        }
        if (selectedGwLocation) {
            AppDispatch(customerActions.SetGwInstallLocation(selectedGwLocation));
        } else if (selectedGwCustomer) {
            AppDispatch(customerActions.SetGwInstallLocation(selectedGwCustomer));
        } */
        if (selectedCustomer) {
            AppDispatch(customerActions.SetCustomer(selectedCustomer));
            if (isDifferentInstallLocation === false && selectedCustomer?.location) {
                AppDispatch(customerActions.SetInstallLocation(selectedCustomer?.location));
            } else if (selectedLocation) {
                AppDispatch(customerActions.SetInstallLocation(selectedLocation));
            }
        }
        if (selectedLocation && isDifferentInstallLocation) {
            AppDispatch(customerActions.SetInstallLocation(selectedLocation));
        }
        handleClose();
    };

    const handleClose = (event?: {} | undefined, reason?: "backdropClick" | "escapeKeyDown") => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setIsOpen(false);
            setSelectedGwCustomer(null);
            setSelectedGwLocation(null);
            AppDispatch(customerActions.ResetCustomerSearchOptions());
            setIsDifferentInstallLocation(false);
            AppDispatch(
                gwAddressApi.util.updateQueryData(
                    "SearchMainGwAddressByCustomerName",
                    undefined,
                    (draft) => {
                        return [];
                    }
                )
            );
            AppDispatch(
                gwAddressApi.util.updateQueryData(
                    "SearchGwAddressByCustomerName",
                    undefined,
                    (draft) => {
                        return [];
                    }
                )
            );
        }
    };

    const handleSwitchChange = (event: any) => {
        const checkBox = event.target;
        if (checkBox) {
            setIsDifferentInstallLocation(checkBox.checked);
        }
    };

    const mainLocationSearchChangeHandler = (searchText: string) => {
        AppDispatch(customerActions.SetSearchText(searchText));
        if (searchText.length > 1 || searchText === "") {
            fetchGwMainAddress();
        }
    };

    const alternativeLocationSearchChangeHandler = (searchText: string) => {
        AppDispatch(customerActions.SetSearchText(searchText));
        if (searchText.length > 1 || searchText === "") {
            fetchGwAlternativeAddress();
        }
    };

    const handleMainLocationChange = (event: any, data: any) => {
        setSelectedGwCustomer(data);
    };

    const handleAlternativeLocationChange = (event: any, data: any) => {
        setSelectedGwLocation(data);
    };

    //#endregion
    //#region Functions

    const isButtonDisabled = () => {
        if (isDifferentInstallLocation && selectedCustomer && selectedLocation) {
            return false;
        } else if (isDifferentInstallLocation === false && selectedCustomer) {
            return false;
        }
        return true;
    };

    const renderLabel = (option: any) => {
        return option.customerName
            ? (option?.customerName != null ? `${option?.customerName}` : "") +
                  (option?.street != null ? ` - ${option?.street}` : "") +
                  (option?.zipcode != null ? ` - ${option?.zipcode}` : "") +
                  (option?.town != null ? ` - ${option?.town}` : "")
            : "";
    };
    //#endregion Functions
    //#region Markup Helper Variables
    const StyledPopper = styled(Popper)({
        [`& .${autocompleteClasses.listbox}`]: {
            boxSizing: "border-box",
            "& ul": {
                padding: 0,
                margin: 0,
            },
        },
    });
    //#endregion

    //#region Markup
    return (
        <>
            {messages ? <CustomerErrorDetail {...messages} /> : null}
            <IconButton onClick={() => setIsOpen(true)}>
                <AccountBoxIcon />
            </IconButton>
            {/*             <Button variant="contained" color="primary" onClick={() => setIsOpen(true)}>
                {dictionary.customer.selectCustomer}
            </Button> */}
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="rights-form-title"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="rights-form-title">
                    {dictionary.customer.selectCustomer}
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose()}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <>
                        <Box>{dictionary.customer.searchCustomer}:</Box>

                        <Autocomplete
                            sx={{
                                backgroundColor: "#fffbfa",
                            }}
                            noOptionsText={dictionary.noOptionsText}
                            openText={dictionary.openText}
                            closeText={dictionary.closeText}
                            clearText={dictionary.clearText}
                            filterOptions={(x) => x}
                            loading={
                                fetchGwMainAddressResult.isLoading ||
                                fetchGwMainAddressResult.isFetching
                            }
                            disablePortal={false}
                            id="combo-box-demo"
                            options={fetchGwMainAddressResult?.data ?? [initialGwAddress]}
                            onChange={handleMainLocationChange}
                            getOptionLabel={(option) => renderLabel(option)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                    onChange={(e) => {
                                        mainLocationSearchChangeHandler(e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        AppDispatch(customerActions.SetOnlyMainLocation(true));
                                    }}
                                />
                            )}
                            isOptionEqualToValue={(option: IGwLocation, value: IGwLocation) =>
                                option.crmLocationGuid === value.crmLocationGuid
                            }
                            // @ts-ignore
                            renderOption={(props, option, { selected }) => [
                                props,
                                option,
                                selected,
                            ]}
                            PopperComponent={StyledPopper}
                            ListboxComponent={SelectCustomerDropdownList}
                            value={selectedGwCustomer}
                        />

                        <Box sx={{ paddingTop: "10px" }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={
                                            !isDifferentInstallLocation
                                                ? !isNotNullOrUndefined(selectedGwCustomer)
                                                : false
                                        }
                                        checked={isDifferentInstallLocation}
                                        onChange={(event) => handleSwitchChange(event)}
                                    />
                                }
                                label={dictionary.location.alternativeInstallationLocation}
                            />
                        </Box>

                        {isDifferentInstallLocation && (
                            <Box sx={{ paddingTop: "10px" }}>
                                <Box>{dictionary.location.searchLocation}:</Box>
                                <Autocomplete
                                    sx={{
                                        backgroundColor: "#fffbfa",
                                    }}
                                    noOptionsText={dictionary.noOptionsText}
                                    openText={dictionary.openText}
                                    closeText={dictionary.closeText}
                                    clearText={dictionary.clearText}
                                    filterOptions={(x) => x}
                                    loading={
                                        fetchGwAlternativeAddressResult.isLoading ||
                                        fetchGwAlternativeAddressResult.isFetching
                                    }
                                    disablePortal={false}
                                    id="combo-box-demo"
                                    options={
                                        fetchGwAlternativeAddressResult?.data ?? [initialGwAddress]
                                    }
                                    onChange={handleAlternativeLocationChange}
                                    getOptionLabel={(option) => renderLabel(option)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e) => {
                                                alternativeLocationSearchChangeHandler(
                                                    e.target.value
                                                );
                                            }}
                                            onFocus={(e) => {
                                                AppDispatch(
                                                    customerActions.SetOnlyMainLocation(false)
                                                );
                                            }}
                                            sx={{}}
                                        />
                                    )}
                                    isOptionEqualToValue={(
                                        option: IGwLocation,
                                        value: IGwLocation
                                    ) => option.crmLocationGuid === value.crmLocationGuid}
                                    // @ts-ignore
                                    renderOption={(props, option, { selected }) => [
                                        props,
                                        option,
                                        selected,
                                    ]}
                                    PopperComponent={StyledPopper}
                                    ListboxComponent={SelectCustomerDropdownList}
                                    value={selectedGwLocation}
                                />
                            </Box>
                        )}
                        <SelectedCustomerDetail
                            selectedCustomer={selectedCustomer?.location ?? null}
                            selectedLocation={selectedLocation}
                        />
                    </>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isButtonDisabled()} onClick={() => handleApply()}>
                        {dictionary.apply}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
    //#endregion
};

export default CustomerSelectDialog;

/*    const {
        data,
        isLoading,
        isError,
        isFetching,
        refetch: fetchGwMainAddress,
    } = useSearchGwMainAddressByCustomerNameQuery(undefined, {
        refetchOnMountOrArgChange: true,
    }); */

//#region Component state variables

//#endregion Component state variables
//#region Fetch requests

//#endregion Fetch requests
//#region Component effects

//#endregion Component effects
//#region Functions

//#endregion Functions
//#region Event handlers

//#endregion Event Handlers
//#region Markup helper variables

//#endregion Markup helper variables
//#region Markup

//#endregion Markup
