import React, { useEffect } from "react";
import { Autocomplete, Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { isNotNullOrUndefined, isObject, isValidInputString } from "../../../common/functions";
import { useAppSelector } from "../../../common/hooks";
import useAutocomplete from "../../../hooks/useAutocomplete";
import useInput from "../../../hooks/useInput";
import IFieldOption, {
    isFieldOptionEmpty,
    isFieldOptionNotNullOrUndefined,
} from "../../../interfaces/IFieldOption";
import { useGetOfferTypeQuery } from "../../../services/offersApi";
import { useOmTranslation } from "../../../translations/OmTranslationContext";
import { FieldName, FormName } from "../../forms/IFormParams";

interface IContractDuration {
    id: number;
    name: string;
    value: number;
}

const HeaderOfferTypeAndDurationInfo = () => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const offer = useAppSelector((state) => state.offer);
    const contractDurationValues: Array<IContractDuration> = [
        { id: 1, name: "Kauf", value: 0 },
        { id: 2, name: "1", value: 1 },
        { id: 3, name: "12", value: 12 },
        { id: 4, name: "24", value: 24 },
        { id: 5, name: "36", value: 36 },
        { id: 6, name: "48", value: 48 },
        { id: 7, name: "60", value: 60 },
    ];

    const {
        value: productType,
        setValue: setProductType,
        isValid: productTypeIsValid,
        hasError: productTypeHasError,
        valueChangeHandler: productTypeChangedHandler,
        inputBlurHandler: productTypeBlurHandler,
        reset: resetProductTypeInput,
    } = useAutocomplete<IFieldOption>({
        fieldName: FieldName.productType,
        formName: FormName.offerForm,
        validationCallback: (value: IFieldOption) => {
            return isFieldOptionNotNullOrUndefined(value) && !isFieldOptionEmpty(value);
        },
    });

    const {
        value: contractDuration,
        setValue: setContractDuration,
        isValid: contractDurationIsValid,
        hasError: contractDurationHasError,
        valueChangeHandler: contractDurationChangedHandler,
        inputBlurHandler: contractDurationBlurHandler,
        reset: resetContractDurationInput,
    } = useAutocomplete<IContractDuration>({
        fieldName: FieldName.contractDuration,
        formName: FormName.offerForm,
        validationCallback: (value: IContractDuration) => {
            return (
                isFieldOptionNotNullOrUndefined(value as any) &&
                value.value >= -1 &&
                !isNaN(value.value)
            );
        },
    });

    const {
        value: offerTitle,
        isValid: offerTitleIsValid,
        hasError: offerTitleHasError,
        valueChangeHandler: offerTitleChangedHandler,
        inputBlurHandler: offerTitleBlurHandler,
        reset: resetOfferTitleInput,
    } = useInput({
        fieldName: FieldName.offerTitle,
        formName: FormName.offerForm,
        validationCallback: (value: string) => isValidInputString(value),
    });
    //#endregion Component state variables
    //#region Fetch requests
    const offerTypeResult = useGetOfferTypeQuery();
    //#endregion Fetch requests
    //#region Component effects
    useEffect(() => {
        /* if (offerTypeResult?.data) {
            const selectedProductType = offerTypeResult?.data.find(
                (option) => option.id === parseInt(offer.productTypeInfo.productTypeId ?? "0")
            );
            if (selectedProductType) {
                setProductType(selectedProductType);
            }
        } */
    }, [offerTypeResult?.data]);
    //#endregion Component effects
    //#region Functions
    /*     const mapOfferTypeOptions = (data: IFieldOption[] | undefined): Array<IProductTypeInfo> => {
        if (offerTypeResult?.data !== undefined) {
            const mapResult: Array<IProductTypeInfo> = offerTypeResult?.data.map((ot) => {
                return { productTypeName: ot.name, productTypeId: ot.id.toString() };
            });
            return mapResult;
        }
        return [];
    };
    const getContractDurationValue = () => {
        const duration = offer.contractInfo.contractDuration;
        const value = contractDurationValues.find((cd) => cd.value === duration);
        if (value) {
            return value;
        }
        return { id: -1, label: duration.toString(), value: duration };
    }; */
    //#endregion Functions
    //#region Event handlers
    const handleOfferTypeChange = (event: any, data: any) => {
        productTypeChangedHandler({
            event,
            newValue: data,
            callback: (event, data) => {
                // AppDispatch(offerActions.SetProductTypeInfo(data));
            },
        });
    };

    const handleContractDurationChange = (event: any, data: any) => {
        contractDurationChangedHandler({
            event,
            newValue: isNotNullOrUndefined(data) ? data : 0,
            callback: (event, data) => {
                if (isObject(data) && isNotNullOrUndefined(data)) {
                    // AppDispatch(offerActions.SetContractInfo({ contractDuration: data.value }));
                } else {
                    let duration = 0;
                    if (!isNotNullOrUndefined(data)) {
                        duration = 0;
                    } else {
                        duration = parseInt(data);
                    }
                    // AppDispatch(offerActions.SetContractInfo({ contractDuration: duration }));
                }
            },
        });
    };
    //#endregion Event Handlers
    //#region Markup helper variables
    //#endregion Markup helper variables
    //#region Markup
    return (
        <React.Fragment>
            <Grid item xs={4}>
                <Stack direction={"row"} alignItems="center">
                    <Box sx={{ width: "180px" }}>
                        <Typography variant="body1">{dictionary.product.productType}:</Typography>
                    </Box>
                    <Stack spacing={2} sx={{ width: "200px" }}>
                        <Autocomplete
                            size="small"
                            id="product-type-dropdown"
                            forcePopupIcon={true}
                            options={offerTypeResult?.data ?? []}
                            getOptionLabel={(option) => option?.name}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            onChange={handleOfferTypeChange}
                            onBlur={(event) => {
                                productTypeBlurHandler(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    error={productTypeHasError}
                                    // helperText="testt"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                            value={productType}
                        />
                    </Stack>
                </Stack>
            </Grid>

            <Grid item xs={3}>
                <Stack direction={"row"} alignItems="center">
                    <Typography sx={{ width: "180px" }} variant="body1">
                        {dictionary.offer.duration}:
                    </Typography>
                    <Stack sx={{ width: "160px" }}>
                        <Autocomplete
                            size="small"
                            freeSolo
                            id="contract-duration-dropdown"
                            forcePopupIcon={true}
                            options={contractDurationValues}
                            getOptionLabel={(option: any) => option.name ?? ""}
                            onChange={handleContractDurationChange}
                            onBlur={(event) => {
                                contractDurationBlurHandler(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={contractDurationHasError}
                                    //helperText="testt"
                                    InputProps={{
                                        ...params.InputProps,
                                        inputMode: "numeric",
                                    }}
                                />
                            )}
                            value={contractDuration as any}
                        />
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={5}>
                <Stack direction={"row"} alignItems="center" justifyContent={"flex-end"}>
                    <Typography sx={{ width: "200px" }} variant="body1">
                        {dictionary.offer.offerTitle}:
                    </Typography>
                    <TextField
                        id="offerTitle"
                        //label={dictionary.offer.contact.contactPerson}
                        variant="outlined"
                        size="small"
                        onChange={offerTitleChangedHandler}
                        onBlur={offerTitleBlurHandler}
                        value={offerTitle}
                        error={offerTitleHasError}
                    />
                </Stack>
            </Grid>
        </React.Fragment>
    );
    //#endregion Markup
};
export default HeaderOfferTypeAndDurationInfo;

/*

 return (
        <Stack
            p={1}
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
            sx={{
                height: "50px",
                width: "100%",
            }}
        >
            <Stack direction={"row"} alignItems="center">
                <Typography variant="body1">{dictionary.product.productType}:</Typography>
                <Typography pl="10px" variant="body1">
                    <Stack pl="10px" spacing={2} sx={{ width: 280 }}>
                        <Autocomplete
                            size="small"
                            id="product-type-dropdown"
                            forcePopupIcon={true}
                            options={offerTypeResult?.data ?? []}
                            getOptionLabel={(option) => option?.name}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            onChange={handleOfferTypeChange}
                            onBlur={(event) => {
                                productTypeBlurHandler(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    error={productTypeHasError}
                                    // helperText="testt"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                            value={productType}
                        />
                    </Stack>
                </Typography>
            </Stack>

            <Stack direction={"row"} alignItems="center">
                <Typography variant="body1">{dictionary.offer.duration}:</Typography>
                <Stack pl="10px" spacing={2} sx={{ width: 150 }}>
                    <Autocomplete
                        size="small"
                        freeSolo
                        id="contract-duration-dropdown"
                        forcePopupIcon={true}
                        options={contractDurationValues}
                        getOptionLabel={(option: any) => option.name ?? ""}
                        onChange={handleContractDurationChange}
                        onBlur={(event) => {
                            contractDurationBlurHandler(event);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={contractDurationHasError}
                                //helperText="testt"
                                InputProps={{
                                    ...params.InputProps,
                                    inputMode: "numeric",
                                }}
                            />
                        )}
                        value={contractDuration as any}
                    />
                </Stack>
            </Stack>
        </Stack>
    );

*/
