import IOffer, { IOfferResult } from '../interfaces/IOffer';
import { omApi } from './omApi';
import omSessionActions, { getJwtHeader } from '../features/session/omSessionActions';
import IOfferType from '../interfaces/offer/IOfferType';
import IOfferStatus from '../interfaces/offer/IOfferStatus';
import ICreateUser from '../interfaces/user/ICreateUser';
import { IFieldOptionExtended } from '../interfaces/IFieldOption';
import { AppDispatch, store } from '../common/store';
import { getOffersQueryParams } from './filters/offersQueryFilter';
import dateFormat from "dateformat";
import { getOmTranslation } from '../translations/OmTranslationContext';
import { offerMakerRoutes } from '../configuration/offerMakerRoutes';


const offersApiTags = ['offers', 'offer'];
export const offersApi = omApi.enhanceEndpoints({ addTagTypes: offersApiTags }).injectEndpoints({

    endpoints: (builder) => ({
        GetOffers: builder.query<IOfferResult, void>({
            query: () => ({
                url: offerMakerRoutes.omoffer,
                params: { ...getOffersQueryParams() },
                method: "GET",
                headers: getJwtHeader(),
                skipCache: true,
            }),
            transformResponse: (response: IOfferResult) =>
            {
                if (response && response?.offers.length > 0)
                {
                    let offers = response?.offers.map((offer) =>
                    {
                        return { ...offer, createDate: dateFormat(offer.createDate, "dd.mm.yyyy HH:MM:ss") };
                    });
                    return { ...response, offers: offers };
                }
                return response as any;

            },

            /*             onStart({ id, ...patch }, { dispatch, context })
                        {
                            // When we start the request, just immediately update the cache
                            AppDispatch(offersApi.util.updateQueryResult("GetOffers"[]);
                        }, */
            // providesTags: (result, args) => [],
            providesTags: (result, args) =>
            {
                if (result)
                {
                    return [
                        ...result.offers.map(({ omOfferId }) => (omOfferId.toString())),
                        'offers',
                    ];
                }
                return ['offers'];
            }


        }),

        /*         GetOffers: builder.query<Array<IOffer>, string | void>({
                    query: (filterQuery) => ({
                        url: filterQuery != undefined ? "omoffer" + filterQuery : "omoffer",
                        method: "GET",
                        headers: getJwtHeader(),
                    }),
                    // providesTags: ['offers'],
                    providesTags: (result) =>
                        result
                            ? [
                                ...result.map(({ omOfferId }) => ({ type: 'Posts' as const, id: omOfferId })),
                                { type: 'offers', id: 'LIST' },
                            ]
                            : [{ type: 'offers', id: 'LIST' }],
                }), */
        /*         GetOffers: builder.query<Array<IOffer>, void>({
                    query: () => ({
                        url: "OmOffer",
                        method: "GET",
                        headers: getJwtHeader(),
                    }),
                    providesTags: ['offers'],
                }), */
        GetOfferById: builder.query<Array<IOffer>, string>({
            query: (offerId: string) => ({
                url: offerMakerRoutes.omofferById(offerId),
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['offer'],
        }),
        GetOfferType: builder.query<Array<IFieldOptionExtended>, void>({
            query: () => ({
                url: offerMakerRoutes.omofferType,
                method: "GET",
                headers: getJwtHeader(),
            }),
            transformResponse: (response: Array<IFieldOptionExtended>) =>
            {
                if (response && response.length > 0)
                {
                    return response.map((option) =>
                    {
                        return { ...option, checked: false };
                    });
                }
                return response;

            },
            providesTags: ['omoffertype'],
        }),
        GetOfferStatus: builder.query<Array<IFieldOptionExtended>, void>({
            query: () => ({
                url: offerMakerRoutes.omofferStatus,
                method: "GET",
                headers: getJwtHeader(),
            }),
            transformResponse: (response: Array<IFieldOptionExtended>) =>
            {
                if (response && response.length > 0)
                {
                    return response.map((option) =>
                    {
                        return { ...option, checked: false };
                    });
                }
                return response;

            },
            providesTags: ['omofferstatus'],
        }),
        GetOfferCreateUser: builder.query<Array<IFieldOptionExtended>, void>({
            query: () => ({
                url: offerMakerRoutes.omofferCreatedByUser,
                method: "GET",
                headers: getJwtHeader(),
            }),
            transformResponse: (response: Array<IFieldOptionExtended>) =>
            {
                if (response && response.length > 0)
                {
                    const isNotOmSalesWorker = omSessionActions.isOmSalesOperations() || omSessionActions.isOmConsulting();
                    const newResult = response.map((option) =>
                    {
                        return { ...option, checked: false };
                    });
                    if (isNotOmSalesWorker)
                    {
                        const allUserArr: Array<IFieldOptionExtended> = [
                            {
                                checked: false,
                                id: -1,
                                name: getOmTranslation().allUsers,
                            },
                        ];
                        return allUserArr.concat(newResult);
                    }
                    else
                    {
                        return newResult;
                    }
                }
                return response;
            },
            providesTags: ['omoffercreateuser'],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetOffersQuery, useGetOfferByIdQuery,
    useGetOfferTypeQuery, useGetOfferStatusQuery,
    useGetOfferCreateUserQuery, /* useGetOffersWithParamsQuery, */
} = offersApi;



/**
 * resetApiState — In case you want to wipe your whole API’s cached data, 
 * dispatch this action: store.dispatch(api.util.resetApiState());
 */