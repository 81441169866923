import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { useAppSelector } from "../../../../common/hooks";
import { AppDispatch } from "../../../../common/store";
import useAutocomplete from "../../../../hooks/useAutocomplete";
import IFieldOption from "../../../../interfaces/IFieldOption";
import { useGetPositionTypesQuery } from "../../../../services/positionTypeApi";
import { useOmTranslation } from "../../../../translations/OmTranslationContext";
import { FormName } from "../../../forms/IFormParams";
import { ColumnName, IProductRow } from "../../OfferAddEditGrid";
import { offerAddEditGridActions } from "../../offerAddEditGridSlice";

interface IPositionTypeGroupAutocomplete {
    productRow: IProductRow | null;
    size: "small" | "medium";
    showLabel: boolean;
}
const PositionTypeAutocomplete = ({
    productRow,
    size,
    showLabel,
}: IPositionTypeGroupAutocomplete) => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const editRow = useAppSelector((state) => state.offerAddEditGrid.editingRow);

    const {
        value: positionType,
        setValue: setPositionType,
        isValid: positionTypeIsValid,
        hasError: positionTypeHasError,
        valueChangeHandler: positionTypeChangedHandler,
        inputBlurHandler: positionTypeBlurHandler,
        reset: resetPositionTypeInput,
    } = useAutocomplete<IFieldOption>({
        formName: FormName.offerProductForm,
        fieldName: ColumnName.positionType,
        validationCallback: (option: IFieldOption) => {
            if (option && option.id && option.id > 0) {
                return true;
            }
            return false;
        },
    });

    //#endregion Component state variables
    //#region Fetch requests
    const positionTypeResult = useGetPositionTypesQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    //#endregion Fetch requests
    //#region Component effects

    useEffect(() => {
        if (productRow) {
            if (productRow.positionType) {
                setPositionType(productRow.positionType);
            }
        } else if (positionTypeResult?.data) {
            setPositionType(positionTypeResult?.data[0]);
        }
    }, [positionTypeResult?.data]);

    useEffect(() => {
        if (positionType) {
            AppDispatch(offerAddEditGridActions.SetEditRowPositionType({ ...positionType }));
        }
    }, [positionType]);

    //#endregion Component effects
    //#region Functions
    //#endregion Functions
    //#region Event handlers
    const handlePositionTypeChange = (event: any, data: any) => {
        positionTypeChangedHandler({
            event,
            newValue: data ?? null,
            callback: (event, data) => {},
        });
        /*         AppDispatch(
            offerAddEditGridActions.SetEditRow({
                ...editRow,
                positionType: data ?? null,
            })
        ); */
    };

    //#endregion Event Handlers
    //#region Markup helper variables

    //#endregion Markup helper variables
    //#region Markup
    return (
        <Autocomplete
            sx={{ width: "100%", paddingTop: "5px" }}
            size={size}
            id="positionType-dropdown"
            options={positionTypeResult?.data ?? []}
            getOptionLabel={(option: IFieldOption) => option.name ?? ""}
            onChange={handlePositionTypeChange}
            onBlur={(event) => {
                positionTypeBlurHandler(event);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={showLabel ? dictionary.product.positionType : ""}
                    error={positionTypeHasError}
                    InputProps={{
                        ...params.InputProps,
                    }}
                />
            )}
            value={positionType}
        />
    );
    //#endregion Markup
};
export default PositionTypeAutocomplete;
