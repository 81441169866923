import { useEffect, useState } from "react";
import { useAppSelector } from "../common/hooks";
import { AppDispatch } from "../common/store";
import { FieldName, FormName } from "../features/forms/IFormParams";
import { IProductRow } from "../features/offer/OfferAddEditGrid";
import { offerAddEditGridActions } from "../features/offer/offerAddEditGridSlice";
import { IProductQueryParams } from "../interfaces/product/IProduct";
import { productApi } from "../services/productApi";

const usePriceChangeHandler = (props: any) => {
    const [inputId, setInputId] = useState("");

    const [editingRows, setEditingRows] = useState<Array<IProductRow>>([]);
    const offerForm = useAppSelector((state) => state.forms[FormName.offerForm]);
    const offerAddEditGrid = useAppSelector((state) => state.offerAddEditGrid);
    const [fetchProductById, fetchProductByIdResult] =
        productApi.endpoints.GetProductById.useLazyQuery();

    async function getProductById(
        id: number,
        duration: number,
        articlecategoryid: number,
        row: IProductRow
    ) {
        const params: IProductQueryParams = {
            id,
            languageid: 1,
            durationmonth: duration,
            articlecategoryid,
        };
        const fetchResult = await fetchProductById(params).unwrap();
        if (fetchResult && row.product?.omArticleId === fetchResult.omArticleId) {
            AppDispatch(
                offerAddEditGridActions.SetRow({
                    ...row,
                    productName: fetchResult?.label ?? "",
                    product: fetchResult ?? null,
                })
            );
        }
    }

    useEffect(() => {
        setEditingRows(offerAddEditGrid.rows);
        const rows: Array<IProductRow> = offerAddEditGrid.rows;
        const durationField = offerForm?.fields[FieldName.contractDuration];
        rows.forEach(async (row) => {
            const duration = row?.isPurchase ? 0 : durationField?.value?.value ?? 0;
            if (row.product?.omArticleId) {
                await getProductById(
                    row.product?.omArticleId,
                    duration,
                    row.product?.omArticleCategoryId,
                    row
                );
            }
        });
        return () => {};
    }, [offerForm?.fields[FieldName.contractDuration]]);

    return {
        inputId,
    };
};

export default usePriceChangeHandler;
