import { Navigate, RouteProps } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext'

export type ProtectedRouteProps = {
    outlet: JSX.Element;
} & RouteProps;

// NOTE multiple examples can be found here -> https://stackoverflow.com/questions/47747754/how-to-rewrite-the-protected-private-route-using-typescript-and-react-router-4
export default function ProtectedRoute({ outlet }: ProtectedRouteProps) {
    const { currentUser } = useAuth();

    if(currentUser && currentUser.success) {
        return outlet;
    } 
    else {
        return <Navigate to='/' replace />;
    }
};