import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import {
    Template, TemplatePlaceholder, Plugin, TemplateConnector,
} from '@devexpress/dx-react-core';
import { 
    Autocomplete, TextField, ListSubheader, Typography, useMediaQuery, 
    autocompleteClasses, Popper, Checkbox, Box, Chip
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useLanguage } from '../../Contexts/LanguageContext';
import ICustomerSelection from '../../Interfaces/Props/ICustomerSelection';

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING = 8; // px

// @ts-ignore
function renderRow(props) {
    const { data, index, style } = props;

    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }
 
    return (
        <Typography component="li" {...dataSet[0]} key={dataSet[0].id} noWrap style={inlineStyle}>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={dataSet[2]}
            />
            <Box
                component="span"
                sx={{
                    width: 14,
                    height: 14,
                    flexShrink: 0,
                    borderRadius: '3px',
                    mr: 1,
                    mt: '2px',
                    fontSize: '13px',
                    textAlign: 'center',
                    color: 'white',
                    verticalAlign: 'text-top'
                }}
                style={{ backgroundColor: dataSet[1].color }}
            >
                {dataSet[1].isPartner ? 'P' : ''}
            </Box>
            {dataSet[1].label}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>((props, ref) =>  {
    // @ts-ignore
    const { children, ...other } = props;
    // @ts-ignore
    const itemData = [];

    // @ts-ignore
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
  
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;
  
    // @ts-ignore
    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }
  
        return itemSize;
    };
  
    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        // @ts-ignore
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };
  
    const gridRef = useResetCache(itemCount);
  
    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={
                        // @ts-ignore
                        itemData
                    }
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(
                        // @ts-ignore
                        itemData[index]
                    )}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    // @ts-ignore
    children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0
        },
    },
});

const CustomerSelection = (props: ICustomerSelection) => {
    const { dictionary } = useLanguage();
    const [search, setSearch] = useState('');

    // @ts-ignore
    const handleCustomerChange = (event, data) => {
        if(props.setSelectedCustomers) {
            props.setSelectedCustomers(data);
        }
    }

    // @see: https://popper.js.org/docs/v2/modifiers/prevent-overflow/
    // @see: https://mui.com/material-ui/react-popper/#scroll-playground
    return (
        <Plugin name="CustomerSelection">
            <Template name="toolbarContent">
                <TemplatePlaceholder />
                <TemplateConnector>
                    {() => (
                        <React.Fragment>
                            <Autocomplete 
                                multiple
                                disableCloseOnSelect
                                disableListWrap
                                limitTags={1}
                                noOptionsText={dictionary.noOptionsText}
                                openText={dictionary.openText}
                                closeText={dictionary.closeText}
                                clearText={dictionary.clearText}
                                defaultValue={[]}
                                inputValue={search}
                                value={props.selectedCustomers}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            width: 800
                                        }
                                    },
                                    popper: {
                                        sx: {
                                            width: '800px!important'
                                        },
                                        popperOptions: {
                                            placement: 'bottom-start',
                                            modifiers: [
                                                {
                                                    name: 'preventOverflow',
                                                    enabled: true,
                                                    options: {
                                                        altAxis: true,
                                                        altBoundary: true,
                                                        tether: true,
                                                        rootBoundary: 'document'
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                }}
                                style={{marginTop: '5px', marginBottom: '5px', marginRight: '10px', width: '400px'}}
                                PopperComponent={StyledPopper}
                                // @ts-ignore
                                ListboxComponent={ListboxComponent}
                                filterOptions={(options: any, state: any) => {
                                    if(state.inputValue === '') {
                                        return options;
                                    }

                                    return options.filter(
                                        (option: any) => 
                                            option.erpNumber.indexOf(state.inputValue) !== -1 ||
                                            option.cubisNumber.indexOf(state.inputValue) !== -1 ||
                                            option.label.toLowerCase().indexOf(state.inputValue.toLowerCase()) !== -1
                                    );
                                }}
                                options={props.customers ? props.customers : []}
                                onClose={(e) => setSearch("")}
                                renderInput={(params) => <TextField {...params} label={dictionary.userForm.customer} onChange={(e) => setSearch(e.target.value)} />}
                                // @ts-ignore
                                renderOption={(props, option, { selected }) => [props, option, selected]}
                                isOptionEqualToValue={
                                    // @ts-ignore
                                    (option, value) => option.id === value.id 
                                }
                                getOptionDisabled={(option) => {
                                    // @ts-ignore
                                    return option.disabled;
                                }}
                                onChange={handleCustomerChange}
                                renderTags={(tagValue, getTagProps) => {
                                    return tagValue.map((option, index) => (
                                        <Chip
                                            {...getTagProps({ index })} 
                                            label={
                                                // @ts-ignore
                                                option.label
                                            } 
                                            style={{
                                                // @ts-ignore
                                                border: '2px solid ' + (option.color !== '' ? option.color : '#EBEBEB'),
                                                width: '250px',
                                                boxSizing: 'border-box',
                                            }}
                                        />
                                    ));
                                }}
                            />
                        </React.Fragment>
                    )}
                </TemplateConnector>
            </Template>
        </Plugin>
    );
};

export default CustomerSelection;