import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IFieldOption from "../../interfaces/IFieldOption";
import { IOmSession } from "../../interfaces/IOmSession";
import { IAddEditOffer } from "./interfaces/IAddEditOffer";
import { IContractInfo, initialContractInfo } from "./interfaces/IContractInfo";
import { ICustomerInfo, initialCustomerInfo } from "./interfaces/ICustomerInfo";
import { initialNewOfferGrid } from "./interfaces/INewOfferGrid";
import { initialOfferInfo, IOfferInfo } from "./interfaces/IOfferInfo";
import { initialProductTypeInfo, IProductTypeInfo } from "./interfaces/IProductTypeInfo";
import { initialProductRow, IProductRow } from "./OfferAddEditGrid";



const offerAddEditGridSlice = createSlice({
    name: 'offerAddEditGridSlice',
    initialState: initialNewOfferGrid,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        SetRows: (state, action: PayloadAction<Array<IProductRow>>) =>
        {
            state.rows = [...action.payload];
        },
        SetRow: (state, action: PayloadAction<IProductRow>) =>
        {
            const rows: Array<IProductRow> = [...state.rows];
            const foundRowIndex = rows.findIndex((row) => row.rowId === action.payload.rowId);
            if (foundRowIndex !== -1)
            {
                const row = rows[foundRowIndex];
                if (row.product?.omArticleId === action.payload.product?.omArticleId)
                {
                    rows[foundRowIndex] = action.payload;
                }
            }
            else
            {
                rows.push(action.payload);
            }
            state.rows = [...rows];
        },
        RemoveRow: (state, action: PayloadAction<IProductRow>) =>
        {
            var newState = state.rows.filter((row) => row.rowId !== action.payload.rowId);
            state.rows = [...newState];
        },
        SetEditRow: (state, action: PayloadAction<IProductRow>) =>
        {
            state.editingRow = { ...action.payload };
        },
        SetEditRowDiscount: (state, action: PayloadAction<IFieldOption>) =>
        {
            state.editingRow.discount = { ...action.payload };
        },
        SetEditRowPositionType: (state, action: PayloadAction<IFieldOption>) =>
        {
            state.editingRow.positionType = { ...action.payload };
        },
        UnsetEditRow: (state, action: PayloadAction<void>) =>
        {
            state.editingRow = initialProductRow;
        },
        SetEditRowTotalPrice: (state, action: PayloadAction<number>) =>
        {
            state.editingRow.totalPrice = action.payload;
        },

        /*  SetSortingChange: (state, action: PayloadAction<Array<{ columnName: string, direction: 'asc' | 'desc'; }>>) =>
         {
             console.log("offerAddEditGridSlice::SetSortingChange: ", action.payload);
             state.sorting = [...action.payload];
         },
         SetSelectionChange: (state, action: PayloadAction<any>) =>
         {
             console.log("offerAddEditGridSlice::SetSelectionChange: ", action.payload);
             state.selection = [...action.payload];
         },
         SetExpandedRowIdsChange: (state, action: PayloadAction<any>) =>
         {
             console.log("offerAddEditGridSlice::SetExpandedRowIdsChange: ", action.payload);
             state.expandedRowIds = [...action.payload];
         },
         SetGroupingChange: (state, action: PayloadAction<any>) =>
         {
             console.log("offerAddEditGridSlice::SetGroupingChange: ", action.payload);
             state.grouping = [...action.payload];
         },
         SetExpandedGroupsChange: (state, action: PayloadAction<any>) =>
         {
             console.log("offerAddEditGridSlice::SetExpandedGroupsChange: ", action.payload);
             state.expandedGroups = [...action.payload];
         },
         SetFiltersChange: (state, action: PayloadAction<any>) =>
         {
             console.log("offerAddEditGridSlice::SetFiltersChange: ", action.payload);
             state.filters = [...action.payload];
         },
         SetCurrentPageChange: (state, action: PayloadAction<any>) =>
         {
             console.log("offerAddEditGridSlice::SetCurrentPageChange: ", action.payload);
             state.currentPage = action.payload;
         },
         SetPageSizeChange: (state, action: PayloadAction<any>) =>
         {
             console.log("offerAddEditGridSlice::SetPageSizeChange: ", action.payload);
             state.pageSize = action.payload;
         },
         SetColumnOrderChange: (state, action: PayloadAction<any>) =>
         {
             console.log("offerAddEditGridSlice::SetColumnOrderChange: ", action.payload);
             state.columnOrder = action.payload;
         },
         SetColumnWidthsChange: (state, action: PayloadAction<any>) =>
         {
             console.log("offerAddEditGridSlice::SetColumnWidthsChange: ", action.payload);
             state.columnWidths = action.payload;
         }, */
    }
});

export const offerAddEditGridActions = offerAddEditGridSlice.actions;
export default offerAddEditGridSlice.reducer;