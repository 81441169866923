import { IProduct } from "../../../interfaces/product/IProduct";
import { IOffer, IOfferVersion } from "./IOffer";


export interface IOfferPosition 
{
    omOfferPositionId: number,
    omArticleId: number,
    omPositionTypeId: number,
    positionNumber: number,
    deviantDurationMonth: number,
    deviantSalesPrice: number,
    deviantPurchasePrice: number,
    deviantLabel: string,
    deviantText: string,
    discountPercent: number,
    additionalText: string,
    omArticle: IProduct | null,
}


export interface IOfferVersionGroup 
{
    omOfferVersionGroupId: number,
    omOfferGroupTypeDescription: string,
    omOfferVersionId: number,
    omOfferGroupTypeId: number,
    discountPercent: number,
    positionNumber: number,
    omOfferPosition: Array<IOfferPosition>,
}
export interface IOfferVersionExtended extends IOfferVersion
{
    omOfferVersionGroups: Array<IOfferVersionGroup>;
}

export interface IOfferTemplate
{
    omOfferId: number,
    offerNumber: string,
    omOfferTypeId: number,
    omOfferStatusId: number,
    customerId: number,
    forecastId: number,
    omOfferVersion: IOfferVersionExtended;
}




export const intialOfferTemplate: IOfferTemplate = {
    omOfferId: 0,
    offerNumber: "",
    omOfferTypeId: 0,
    omOfferStatusId: 0,
    customerId: 0,
    forecastId: 0,
    omOfferVersion: {
        omOfferVersionId: 0,
        versionNumber: 0,
        omOfferId: 0,
        omOfferStatusId: 0,
        defaultDurationMonth: 0,
        locationId: 0,
        contactSalutation: "",
        contactName: "",
        contactEmail: "",
        partnerId: 0,
        subPartnerId: 0,
        omSalesPartnerDisplayTypeId: 0,
        installLocationId: 0,
        discountPercent: 0,
        languageId: 0,
        contact: "",
        offerDate: "",
        deviantLeadingBlock: "",
        deviantTrailingBlock: "",
        omOfferVersionGroups: []
    }
};


export const intialOfferVersionGroup: IOfferVersionGroup = {
    omOfferVersionGroupId: 0,
    omOfferGroupTypeDescription: "",
    omOfferVersionId: 0,
    omOfferGroupTypeId: 0,
    discountPercent: 0,
    positionNumber: 0,
    omOfferPosition: [],
};

export const intialOfferPosition: IOfferPosition = {
    omOfferPositionId: 0,
    omArticleId: 0,
    omPositionTypeId: 0,
    positionNumber: 0,
    deviantDurationMonth: 0,
    deviantSalesPrice: 0,
    deviantPurchasePrice: 0,
    deviantLabel: "",
    deviantText: "",
    discountPercent: 0,
    additionalText: "",
    omArticle: null,
};