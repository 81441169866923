import { useState, useEffect, createRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Column, Grouping } from "@devexpress/dx-react-grid";
import { Table } from "@devexpress/dx-react-grid-material-ui";

import styles from "./OffersContainer.module.scss";
import { OmPageHeader, OmTextField } from "../../common/imports";
import { Autocomplete, Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { useOmTranslation } from "../../translations/OmTranslationContext";
//import SearchCustomerAddressDropdown from "../customer/CustomerDropdown";
import CustomerSelectDialog from "../customer/SelectCustomerDialog";
import OfferHeader from "./components/OfferHeader";
import HeaderOfferInfo from "./components/HeaderOfferInfo";
import HeaderCustomerInfo from "./components/HeaderCustomerInfo";
import HeaderOfferTypeAndDurationInfo from "./components/HeaderOfferTypeAndDurationInfo";
import HeaderContractInfo from "./components/OfferFooterInfo";
import OfferBody from "./OfferBody";
import OfferAutoSave from "./components/OfferAutoSave";
import OfferFooterInfo from "./components/OfferFooterInfo";
import OfferExitConfirmation from "./components/OfferExitConfirmation";
import HeaderContactPersonInfo from "./components/HeaderContactPersonInfo";
import { IFormParams, initialFormParams } from "../forms/IFormParams";
import OfferAddEditGrid from "./OfferAddEditGrid";
import useOfferObjectChangeHandler from "../../hooks/useOfferObjectChangeHandler";

const New = () => {
    const ref = createRef<HTMLElement>();

    return (
        <OfferExitConfirmation>
            <OfferAutoSave>
                <Box sx={{ flexGrow: 1, width: "90%", height: "90%", margin: "50px" }}>
                    <Grid container spacing={1}>
                        <Grid container item spacing={0}>
                            <HeaderOfferInfo />
                        </Grid>
                        <Grid container item spacing={0}>
                            <HeaderCustomerInfo />
                        </Grid>
                        <Grid container item spacing={0}>
                            <HeaderContactPersonInfo />
                        </Grid>
                        <Grid container item spacing={0}>
                            <HeaderOfferTypeAndDurationInfo />
                        </Grid>
                        <Grid container item spacing={0}>
                            <OfferAddEditGrid parentRef={ref} />
                        </Grid>
                        <Grid container item spacing={0}>
                            <OfferFooterInfo />
                        </Grid>
                    </Grid>
                </Box>
            </OfferAutoSave>
        </OfferExitConfirmation>
    );
};

export default New;
