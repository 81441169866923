import { useUX } from "../Contexts/UXContext";
//import { useLanguage } from "../contexts/LanguageContext";
import styles from "./OfferMakerSection.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { AppDispatch } from "./common/store";
import { portalApi } from "./services/portalApi";
import { Box, CircularProgress, Grid, CssBaseline } from "@mui/material";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import {
    Link,
    Router,
    Route,
    Routes,
    useMatch,
    useLocation,
    Navigate,
    useNavigate,
    useParams,
} from "react-router-dom";
import ProtectedRoute from "../Theme/ProtectedRoute";
import Error from "./components/Error";
import { ErrorBoundary } from "react-error-boundary";
import OffersContainer from "./features/offerList/OffersContainer";
import New from "./features/offer/New";
import { useAppSelector } from "./common/hooks";
import { omSessionStoreActions } from "./features/session/omSessionSlice";
import jwtInfo, { isJwtValid } from "./features/session/omSessionActions";
import { uiRoutes } from "./configuration/offerMakerRoutes";
import Edit from "./features/offer/Edit";
import Detail from "./features/offer/Detail";
import { useOmLoginQuery } from "./services/loginApi";
import { useLogout } from "./features/session/omSessionActions";
import useByonTheme, { byonLightTheme } from "./configuration/byonTheme";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { OmTranslationProvider } from "./translations/OmTranslationContext";
import { pageMain } from "../Theme/LayoutDefinitions";

const OfferMakerSection = () => {
    useLogout();
    const theme = useByonTheme("light");
    const location = useLocation();
    const navigate = useNavigate();
    const omSession = useAppSelector((state) => state.session);
    const { brand, primaryColor } = useUX();
    //const { dictionary } = useLanguage();
    const [refetch, setRefetch] = React.useState(true);

    const portalApiResult = portalApi.useGetLoginInfoQuery();
    const loginApiResult = useOmLoginQuery(
        portalApiResult.isSuccess && portalApiResult.data.success
            ? portalApiResult.data?.jwt
            : skipToken
    );

    React.useEffect(() => {
        if (!isJwtValid()) {
            portalApiResult.refetch();
        } else {
            if (location.pathname == "/offermaker") {
                navigate(uiRoutes.offers);
            }
        }
    }, [location.pathname]);

    React.useEffect(() => {
        if (portalApiResult.isError || loginApiResult.isError) {
            navigate(uiRoutes.error);
        } else {
            if (loginApiResult.data?.jwt) {
                AppDispatch(
                    omSessionStoreActions.SetCredentials({
                        isLoggedin: true,
                        jwtToken: loginApiResult?.data?.jwt ? loginApiResult?.data?.jwt : "",
                        omUser: jwtInfo.getOmUser(loginApiResult?.data?.jwt),
                        omUserRoles: jwtInfo.getOmUserRoles(loginApiResult?.data?.jwt),
                        language: jwtInfo.getLanguage(),
                    })
                );
                navigate(uiRoutes.offers);
            }
        }
    }, [
        portalApiResult.isLoading || loginApiResult.isLoading,
        portalApiResult.isError || loginApiResult.isError,
        loginApiResult.data?.jwt,
    ]);

    return (
        <OmTranslationProvider>
            <Box
                component="main"
                sx={{
                    overflow: "auto",
                    display: "flex",
                    justifyContent: "center",
                    flexGrow: 1,
                    alignItems: "center",
                }}
            >
                <ErrorBoundary FallbackComponent={Error}>
                    {portalApiResult.isLoading || loginApiResult.isLoading ? (
                        <CircularProgress sx={{ color: primaryColor }} />
                    ) : (
                        <Routes>
                            <Route path="/offers">
                                <Route
                                    index
                                    element={<ProtectedRoute outlet={<OffersContainer />} />}
                                />
                                <Route path="new" element={<ProtectedRoute outlet={<New />} />} />
                            </Route>
                            <Route path="/offers/:offerId">
                                <Route index element={<ProtectedRoute outlet={<Detail />} />} />
                                <Route path="edit" element={<ProtectedRoute outlet={<Edit />} />} />
                            </Route>
                            <Route path="error" element={<ProtectedRoute outlet={<Error />} />} />
                        </Routes>
                    )}
                </ErrorBoundary>
            </Box>
        </OmTranslationProvider>
    );
};

export default OfferMakerSection;
