import React, { useEffect } from "react";
import { Autocomplete, Grid, Stack, TextField, Typography } from "@mui/material";
import { useOmTranslation } from "../../../translations/OmTranslationContext";
import { isValidInputString } from "../../../common/functions";
import useAutocomplete from "../../../hooks/useAutocomplete";
import useInput from "../../../hooks/useInput";
import { FieldName, FormName } from "../../forms/IFormParams";

const HeaderContactPersonInfo = () => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const mailRegEx =
        /^(")?(?:[^\."\s])(?:(?:[\.])?(?:[\w\-!#$%&'*+/=?^_`{|}~]))*\1@(\w[\-\w]*\.){1,5}([A-Za-z]){2,10}$/;
    const enUserTitles = [
        { id: 1, label: "Mr." },
        { id: 2, label: "Mrs." },
    ];
    const deUserTitles = [
        { id: 1, label: "Frau" },
        { id: 2, label: "Herr" },
    ];

    const offerLanguages = [
        { id: 1, label: "Deutsch" },
        { id: 2, label: "English" },
    ];

    const UserTitles = [
        { id: 1, label: "Frau" },
        { id: 2, label: "Herr" },
        { id: 3, label: "Mr." },
        { id: 4, label: "Mrs." },
    ];

    const {
        value: userTitle,
        setValue: setUserTitle,
        isValid: userTitleIsValid,
        hasError: userTitleHasError,
        valueChangeHandler: userTitleChangedHandler,
        inputBlurHandler: userTitleBlurHandler,
        reset: resetUserTitleInput,
    } = useAutocomplete<{ id: number; label: string }>({
        fieldName: FieldName.userTitle,
        formName: FormName.offerForm,
        validationCallback: (value: { id: number; label: string }) => {
            if (value.id && value.id >= 1 && value.id <= 4) {
                return true;
            }
            return false;
        },
    });

    const {
        value: contactPerson,
        isValid: contactPersonIsValid,
        hasError: contactPersonHasError,
        valueChangeHandler: contactPersonChangedHandler,
        inputBlurHandler: contactPersonBlurHandler,
        reset: resetContactPersonInput,
    } = useInput({
        fieldName: FieldName.contactPerson,
        formName: FormName.offerForm,
        validationCallback: (value: string) => isValidInputString(value),
    });

    const {
        value: enteredEmail,
        isValid: enteredEmailIsValid,
        hasError: emailInputHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmailInput,
    } = useInput({
        fieldName: FieldName.enteredEmail,
        formName: FormName.offerForm,
        validationCallback: (value: string) => mailRegEx.test(value),
    });

    let formIsValid = false;

    if (contactPersonIsValid && enteredEmailIsValid) {
        formIsValid = true;
    }

    if (formIsValid) {
    }
    //#endregion Component state variables
    //#region Fetch requests

    //#endregion Fetch requests
    //#region Component effects
    useEffect(() => {
        setUserTitle(UserTitles[1]);
    }, []);
    //#endregion Component effects
    //#region Functions

    //#endregion Functions
    //#region Event handlers
    const handleUserTitleChange = (event: any, data: any) => {
        userTitleChangedHandler({
            event,
            newValue: data ? data : UserTitles[1],
            callback: (event, data) => {},
        });
    };
    //#endregion Event Handlers
    //#region Markup helper variables

    //#endregion Markup helper variables
    //#region Markup
    return (
        <React.Fragment>
            <Grid container item xs={12}>
                <Stack direction={"row"} alignItems="center">
                    <Typography sx={{ width: "180px" }} variant="body1">
                        {dictionary.offer.contact.contact}:
                    </Typography>
                    <Stack sx={{ width: "200px" }}>
                        <Autocomplete
                            size="small"
                            freeSolo
                            id="contract-duration-dropdown"
                            forcePopupIcon={true}
                            options={UserTitles}
                            getOptionLabel={(option: any) =>
                                option.label !== undefined ? option.label : ""
                            }
                            onChange={handleUserTitleChange}
                            onBlur={userTitleBlurHandler}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={dictionary.offer.contact.contactTitle}
                                    error={userTitleHasError}
                                    //helperText="testt"
                                    InputProps={{
                                        ...params.InputProps,
                                        inputMode: "numeric",
                                    }}
                                    onBlur={() => {}}
                                />
                            )}
                            value={userTitle}
                        />
                    </Stack>
                </Stack>
                <Stack pl="10px" direction={"row"} alignItems="center">
                    <TextField
                        id="contactName"
                        label={dictionary.offer.contact.contactPerson}
                        variant="outlined"
                        size="small"
                        onChange={contactPersonChangedHandler}
                        onBlur={contactPersonBlurHandler}
                        value={contactPerson}
                        error={contactPersonHasError}
                    />
                </Stack>
                <Stack pl="10px" direction={"row"} alignItems="center">
                    <TextField
                        type="email"
                        id="contactEmail"
                        label={dictionary.offer.contact.email}
                        variant="outlined"
                        size="small"
                        onChange={emailChangeHandler}
                        onBlur={emailBlurHandler}
                        value={enteredEmail}
                        error={emailInputHasError}
                    />
                </Stack>
            </Grid>
            {/* < Grid item xs={4}>
                <Stack direction={"row"} alignItems="center">
                    <TextField
                        id="contactName"
                        label={dictionary.offer.contact.contactPerson}
                        variant="outlined"
                        size="small"
                        onChange={contactPersonChangedHandler}
                        onBlur={contactPersonBlurHandler}
                        value={contactPerson}
                        error={contactPersonHasError}
                    />
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack direction={"row"} alignItems="center">
                    <TextField
                        type="email"
                        id="contactEmail"
                        label={dictionary.offer.contact.email}
                        variant="outlined"
                        size="small"
                        onChange={emailChangeHandler}
                        onBlur={emailBlurHandler}
                        value={enteredEmail}
                        error={emailInputHasError}
                    />
                </Stack>
            </Grid> */}
        </React.Fragment>
    );
    //#endregion Markup
};
export default HeaderContactPersonInfo; /*

/* 

 return (
        <Stack
            p={1}
            direction={"row"}
            alignItems="center"
            justifyContent="flex-start"
            sx={{
                height: "50px",
                width: "100%",
            }}
        >
            <Stack direction={"row"} alignItems="center">
                <Typography variant="body1">{dictionary.offer.contact.contact}:</Typography>
                <Stack pl="10px" sx={{ width: 150 }}>
                    <Autocomplete
                        size="small"
                        freeSolo
                        id="contract-duration-dropdown"
                        forcePopupIcon={true}
                        options={userTitleValues}
                        getOptionLabel={(option: any) =>
                            option.label !== undefined ? option.label : ""
                        }
                        // onChange={handleContractDurationChange}
                        onBlur={() => {}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={dictionary.offer.contact.contactTitle}
                                //error={durationError}
                                //helperText="testt"
                                InputProps={{
                                    ...params.InputProps,
                                    inputMode: "numeric",
                                    /* type: "search",
                                    endAdornment: (
                                        <React.Fragment>
                                            {<CircularProgress color="inherit" size={20} />}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ), */
/* }}
                                onBlur={() => {}}
                            />
                        )}
                        // value={getContractDurationValue()}
                    />
                </Stack>
            </Stack>
            <Stack pl="10px" direction={"row"} alignItems="center">
                <TextField
                    id="contactName"
                    label={dictionary.offer.contact.contactPerson}
                    variant="outlined"
                    size="small"
                    onChange={contactPersonChangedHandler}
                    onBlur={contactPersonBlurHandler}
                    value={contactPerson}
                    error={contactPersonHasError}
                />
            </Stack>
            <Stack pl="10px" direction={"row"} alignItems="center">
                <TextField
                    type="email"
                    id="contactEmail"
                    label={dictionary.offer.contact.email}
                    variant="outlined"
                    size="small"
                    onChange={emailChangeHandler}
                    onBlur={emailBlurHandler}
                    value={enteredEmail}
                    error={emailInputHasError}
                />
            </Stack>
        </Stack>
    );

*/
