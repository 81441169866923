import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, alpha, Stack, Chip, Typography, Badge } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import {
    DataGridPro,
    deDE,
    enUS,
    GridColDef,
    GridColumnHeaderParams,
    GridRowId,
    GridRowEditStopParams,
    GridRowEditStopReasons,
    GridCellEditStopParams,
    GridCellEditStopReasons,
    MuiEvent,
    GridCellEditStartParams,
    GridRowEditStartParams,
    GridRowEditStartReasons,
    GridCellEditStartReasons,
} from "@mui/x-data-grid-pro";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOmTranslation } from "../../translations/OmTranslationContext";
import { useAppSelector } from "../../common/hooks";
import { useOffersContext } from "../offerList/OffersContext";
import { useComponentHeight } from "../../common/useComponentHeight";
import { AppDispatch } from "../../common/store";
import { DialogMode, ProductAddEditDialog } from "./components/ProductAddEditDialog";
import ProductAutocomplete from "./components/product/ProductAutocomplete";
import { IProduct } from "../../interfaces/product/IProduct";
import { offerAddEditGridActions } from "./offerAddEditGridSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import usePriceChangeHandler from "../../hooks/usePriceChangeHandler";
import ProfileDeleteDialog from "./components/ProductDeleteDialog";
import { isNotNullOrUndefined } from "../../common/functions";
import ProductDiscountAutocomplete, {
    IProductDiscount,
} from "./components/product/ProductDiscountAutocomplete";
import OfferDiscountAutocomplete from "./components/OfferDiscountAutocomplete";
import { FieldName, FormName } from "../forms/IFormParams";
import IFieldOption from "../../interfaces/IFieldOption";
import { IProductGroup } from "./interfaces/IProductGroup";

export interface IProductRow {
    rowId: string;
    isPurchase: boolean;
    positionType: IFieldOption | null;
    productName: string;
    groupName: string;
    amount: number;
    totalPrice: number;
    discount: IProductDiscount | null;
    group: IProductGroup | null;
    product: IProduct | null;
}

export const initialProductRow: IProductRow = {
    rowId: "",
    isPurchase: false,
    positionType: null,
    productName: "",
    groupName: "",
    amount: 0,
    discount: null,
    totalPrice: 0,
    group: null,
    product: null,
};

export enum ColumnName {
    position = "position",
    product = "product",
    productGroup = "productGroup",
    amount = "amount",
    price = "price",
    discount = "discount",
    totalPrice = "totalPrice",
    positionType = "positionType",
}

export interface IOfferAddEditGrid {
    parentRef: any;
}

const GridFooterComponent = ({ products }: any) => {
    const { dictionary } = useOmTranslation();
    const offer = useAppSelector((state) => state.offer);
    const offerAddEditGrid = useAppSelector((state) => state.offerAddEditGrid);
    const customer = useAppSelector((state) => state.customer);
    const offerForm = useAppSelector((state) => state.forms[FormName.offerForm]);
    const offerProductForm = useAppSelector((state) => state.forms[FormName.offerProductForm]);
    const [offerSum, setOfferSum] = useState<number>(0);

    React.useEffect(() => {
        const discount = offerForm?.fields[FieldName.discount]?.value?.id ?? 0;
        let totalPrice = 0;
        let discountableTotalPrice = 0;
        let noneDiscountableTotalPrice = 0;

        products.forEach((row: IProductRow) => {
            totalPrice += row.totalPrice;
            if (
                row?.discount?.id === 0 &&
                row?.product &&
                (row?.product?.maxDiscountInternal > 0 || row?.product?.maxDiscountPartner > 0)
            ) {
                discountableTotalPrice += row.totalPrice;
            } else {
                noneDiscountableTotalPrice += row.totalPrice;
            }
        });
        if (discount > 0) {
            // const discountValue = (totalPrice / 100) * discount;
            //setOfferSum(totalPrice - discountValue);

            const discountValue = (discountableTotalPrice / 100) * discount;
            const sum = discountableTotalPrice - discountValue + noneDiscountableTotalPrice;
            setOfferSum(sum);
        } else {
            setOfferSum(totalPrice);
        }
    }, [offerForm?.fields[FieldName.discount], products]);
    const formattedTotal = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
    }).format(offerSum);
    return (
        <Stack
            spacing={2}
            alignItems={"center"}
            direction="row"
            sx={{ paddingTop: "10px", justifyContent: " flex-end" }}
        >
            <OfferDiscountAutocomplete />
            <Box>
                <Typography variant="h6">
                    {dictionary.offer.offerSum}: {formattedTotal}
                </Typography>
            </Box>
        </Stack>
    );
};

function OfferAddEditGrid(props: IOfferAddEditGrid) {
    const navigate = useNavigate();
    const { dictionary } = useOmTranslation();
    const omSession = useAppSelector((state) => state.session);
    const offersState = useAppSelector((state) => state.offerList);
    const offersContext = useOffersContext();
    const [allUsersSelected, setAllUsersSelected] = useState<boolean>(false);
    //  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [dialog, setDialog] = React.useState<DialogMode>(DialogMode.close);

    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const [profileAddDialogOpened, setProfileAddDialogOpened] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState<IProductRow | null>(null);
    // const offersList = useGetOffersQuery(undefined, { refetchOnMountOrArgChange: true });
    const forms = useAppSelector((state) => state.forms);
    const offerForm = forms[FormName.offerForm];
    const duration = offerForm?.fields[FieldName.contractDuration];

    const height = useComponentHeight(props.parentRef);

    const products: Array<IProductRow> = useAppSelector((state) => state.offerAddEditGrid.rows);
    usePriceChangeHandler(null);

    const CancelButton = (product: any) => {
        return (
            <Stack direction={"row"}>
                <IconButton
                    onClick={(event: any) => {
                        event.ignore = true;
                        setDialog(DialogMode.edit);
                        setSelectedProduct(product);
                    }}
                    sx={{}}
                >
                    <CancelIcon />
                </IconButton>
            </Stack>
        );
    };

    const SaveButton = (product: any) => {
        return (
            <Stack direction={"row"}>
                <IconButton
                    onClick={(event: any) => {
                        event.ignore = true;
                        setDialog(DialogMode.edit);
                        setSelectedProduct(product);
                    }}
                    sx={{}}
                >
                    <SaveIcon />
                </IconButton>
            </Stack>
        );
    };

    const EditButton = (product: any) => {
        return (
            <Stack direction={"row"}>
                <IconButton
                    onClick={(event: any) => {
                        event.ignore = true;
                        setDialog(DialogMode.edit);
                        setSelectedProduct(product);
                    }}
                    sx={{}}
                >
                    <EditIcon />
                </IconButton>
            </Stack>
        );
    };

    const DeleteButton = (productRow: IProductRow) => {
        return (
            <Stack direction={"row"}>
                <IconButton
                    onClick={(event: any) => {
                        event.ignore = true;
                        AppDispatch(offerAddEditGridActions.RemoveRow(productRow));
                    }}
                    sx={{}}
                >
                    <DeleteIcon />
                </IconButton>
            </Stack>
        );
    };

    const AddButton = () => {
        return (
            <Stack direction={"row"} justifyContent="center" sx={{ width: "100%" }}>
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                        setDialog(DialogMode.new);
                        setSelectedProduct(null);
                    }}
                >
                    {dictionary.new}
                </Button>
            </Stack>
        );
    };

    const columns: Array<GridColDef> = [
        {
            field: ColumnName.position,
            headerName: ColumnName.position,
            minWidth: 60,
            flex: 1,
            align: "right",
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.product.position,
            renderCell: (params) => {
                return 1 + products.indexOf(params.row);
                // return "test";
                // return (params.row as IOffer).createDate as any;
            },
        },
        {
            field: ColumnName.positionType,
            headerName: ColumnName.positionType,
            minWidth: 100,
            flex: 1,
            align: "left",
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.product.positionType,
            renderCell: (params) => {
                const row = params.row as IProductRow;
                return (
                    <>
                        <Badge
                            invisible={
                                !row.isPurchase || (duration && duration?.value?.value === 0)
                            }
                            badgeContent={dictionary.product.buyShort}
                            color="primary"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <Chip
                                sx={{
                                    minWidth: "30px",
                                    "& .MuiChip-root": {
                                        borderRadius: "15%",
                                    },
                                    "& .MuiChip-label": {
                                        paddingLeft: "5px",
                                        paddingRight: "5px",
                                    },
                                    marginRight: "10px",
                                    backgroundColor: (theme) => theme.palette.divider,
                                    height: "30px",
                                    borderRadius: "5px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                }}
                                size="small"
                                label={row.positionType?.name}
                            />
                        </Badge>
                    </>
                );
            },
        },
        {
            field: ColumnName.product,
            headerName: ColumnName.product,
            filterable: true,
            minWidth: 220,
            editable: true,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.product.product,
            renderCell: (params) => {
                return (params.row as IProductRow).productName as any;
            },
            renderEditCell: (params) => {
                return (
                    <ProductAutocomplete productRow={params.row} size="small" showLabel={false} />
                );
            },
        },
        {
            field: ColumnName.productGroup,
            headerName: ColumnName.productGroup,
            filterable: true,
            minWidth: 200,
            editable: true,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.product.productGroup,
            renderCell: (params) => {
                return (params.row as IProductRow).groupName as any;
            },
        },
        {
            field: ColumnName.amount,
            headerName: ColumnName.amount,
            minWidth: 100,
            editable: true,
            flex: 1,
            align: "right",
            renderHeader: (params: GridColumnHeaderParams) => dictionary.product.amount,
        },
        {
            field: ColumnName.price,
            headerName: ColumnName.price,
            minWidth: 120,
            editable: true,
            flex: 1,
            align: "right",
            renderHeader: (params: GridColumnHeaderParams) => dictionary.product.price,
            renderCell: (params) => {
                const price = (params.row as IProductRow).product?.price;
                const formattedPrice = new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                }).format(price ?? 0);
                return (
                    <>
                        {!isNotNullOrUndefined(price) || price === 0 ? (
                            <ErrorIcon sx={{ paddingRight: "5px" }} color="error" />
                        ) : null}
                        {formattedPrice}
                    </>
                );
            },
        },
        {
            field: ColumnName.discount,
            headerName: ColumnName.discount,
            minWidth: 100,
            editable: true,
            flex: 1,
            align: "right",
            renderHeader: (params: GridColumnHeaderParams) => dictionary.product.discount,
            renderCell: (params) => {
                const discount = (params.row as IProductRow).discount as any;
                return `${discount?.id} %`;
            },
        },
        {
            field: ColumnName.totalPrice,
            headerName: ColumnName.totalPrice,
            minWidth: 160,
            editable: false,
            flex: 1,
            align: "right",
            renderHeader: (params: GridColumnHeaderParams) => dictionary.product.grossPrice,
            renderCell: (params) => {
                const row = params.row as IProductRow;
                let discountedPrice = 0;
                if (row.product) {
                    const totalPrice = row.amount * row.product?.price;
                    if (row.discount && row?.discount?.id > 0) {
                        const discount = (totalPrice / 100) * row?.discount?.id;
                        discountedPrice = totalPrice - discount;
                    } else {
                        discountedPrice = totalPrice;
                    }
                }
                return new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                }).format(discountedPrice);
            },
        },
        {
            field: "action",
            headerName: "aktion",
            minWidth: 120,
            maxWidth: 180,
            flex: 0,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            editable: true,
            renderHeader: (params: GridColumnHeaderParams) => <AddButton />,
            renderCell: (params) => {
                return (
                    <>
                        <EditButton key="edit" {...params.row} />
                        <ProfileDeleteDialog {...params.row} />
                    </>
                );
            },
            renderEditCell: (params) => {
                return (
                    <>
                        <SaveButton key="save" {...params.row} />
                        <CancelButton key="cancel" {...params.row} />
                    </>
                );
            },
        },
    ];
    let localeText = enUS.components.MuiDataGrid.defaultProps.localeText;
    if (omSession.language === "de" || omSession.language === "DE") {
        localeText = deDE.components.MuiDataGrid.defaultProps.localeText;
    }
    localeText.noRowsLabel = dictionary.error.offerHasNoPositionsAdded;
    localeText.noResultsOverlayLabel = dictionary.noResult;
    localeText.errorOverlayDefaultLabel = dictionary.error.unexpectedError;
    return (
        <Box
            sx={{
                height: "400px",
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
            }}
        >
            <ProductAddEditDialog
                dialogMode={dialog}
                setDialogMode={setDialog}
                row={selectedProduct}
            />
            <DataGridPro
                // apiRef={apiRef}
                getRowId={(row) => row?.rowId}
                localeText={localeText}
                //hideFooter={true}
                rowsPerPageOptions={[1000]}
                sx={{
                    /*  backgroundColor: (theme) => theme.palette.background.paper,
                        "& .MuiDataGrid-overlay": {
                            top: "50px !important",
                        },
                        "& .MuiDataGrid-columnSeparator": {
                            visibility: "hidden",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            marginTop: "55px !important",
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                            padding: "0px",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor:
                               "#3d3d3d",
                            height: "53px !important",
                            minHeight: "unset !important",
                            maxHeight: "unset !important",
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor:
                                 "#3d3d3d",
                            borderTop: "1px solid",
                            borderColor: (theme) => theme.palette.divider,
                        },
                        "& .MuiIconButton-root": { borderRadius: "15%", padding: "5px" },
                        "& .MuiCheckbox-root": {
                            borderRadius: "15%",
                            padding: "5px",
                            outline: "none !important",
                        },
                        "& .cellCheckbox:focus": {
                            outline: "none !important",
                        }, */

                    "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                        fontWeight: "600",
                        width: "100%",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        outline: "none !important",
                        //  backgroundColor: (theme) => alpha(theme.palette.divider, 0.05),
                        borderRadius: "unset",
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: (theme) => alpha(theme.palette.divider, 0.05),
                    },
                    "& .MuiDataGrid-row--editing": {
                        backgroundColor: (theme) => alpha(theme.palette.divider, 0.05),
                    },
                    "& .MuiDataGrid-row": {
                        /*                             "&:hover": {
                                cursor: "pointer",
                            }, */
                    },
                    "& .MuiDataGrid-cell": {
                        outline: "none !important",
                    },
                    // height: `${height - 350}px`,
                    height: "100%",
                    overflowY: "auto",
                    border: "unset",
                }}
                editMode="row"
                // autoHeight
                //rowModesModel={{ 1: { mode: GridRowModes.Edit } }}
                experimentalFeatures={{ newEditingApi: true }}
                onCellEditStart={(params: GridCellEditStartParams, event: MuiEvent) => {
                    if (params.reason === GridCellEditStartReasons.cellDoubleClick) {
                        event.defaultMuiPrevented = true;
                    }
                }}
                onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
                    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                        event.defaultMuiPrevented = true;
                    }
                }}
                onRowEditStart={(params: GridRowEditStartParams, event: MuiEvent) => {
                    if (params.reason === GridRowEditStartReasons.cellDoubleClick) {
                        event.defaultMuiPrevented = true;
                    }
                }}
                onRowEditStop={(params: GridRowEditStopParams, event: MuiEvent) => {
                    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
                        event.defaultMuiPrevented = true;
                    }
                }}
                rowHeight={60}
                rows={products ?? []} //{offersContext?.filteredOffers ? offersContext?.filteredOffers : []}
                columns={columns}
                columnBuffer={2}
                checkboxSelection={false}
                componentsProps={{
                    row: {},
                    footer: { products },
                }}
                components={{
                    Footer: GridFooterComponent,
                }}
                // loading={offersList.isLoading || offersList.isFetching}
                disableSelectionOnClick
                disableColumnSelector
                hideFooterRowCount={false}
            />
        </Box>
    );
}

export default React.memo(OfferAddEditGrid);
