import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOmSession } from "../../interfaces/IOmSession";
import { IAddEditOffer } from "./interfaces/IAddEditOffer";
import { IContractInfo, initialContractInfo } from "./interfaces/IContractInfo";
import { ICustomerInfo, initialCustomerInfo } from "./interfaces/ICustomerInfo";
import { initialOfferInfo, IOfferInfo } from "./interfaces/IOfferInfo";
import { initialProductTypeInfo, IProductTypeInfo } from "./interfaces/IProductTypeInfo";
import { intialOfferTemplate, IOfferPosition, IOfferTemplate, IOfferVersionExtended, IOfferVersionGroup } from "./interfaces/IProductVersion";


// Define the initial state
const initialState: IOfferTemplate = intialOfferTemplate;

const offerSlice = createSlice({
    name: 'offerSlice',
    initialState: initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        SetOffer: (state, action: PayloadAction<IOfferTemplate>) =>
        {
            return { ...action.payload };
        },

        SetOfferVersion: (state, action: PayloadAction<IOfferVersionExtended>) =>
        {
            state.omOfferVersion = action.payload;
        },

        SetOfferVersionGroups: (state, action: PayloadAction<Array<IOfferVersionGroup>>) =>
        {
            state.omOfferVersion.omOfferVersionGroups = action.payload;
        },

        SetOfferVersionGroup: (state, action: PayloadAction<IOfferVersionGroup>) =>
        {
            const groupIndex = state.omOfferVersion.omOfferVersionGroups.findIndex((group) => group.omOfferVersionGroupId === action.payload.omOfferVersionGroupId);
            if (groupIndex !== -1)
            {
                state.omOfferVersion.omOfferVersionGroups[groupIndex] = action.payload;
            }
            else
            {
                state.omOfferVersion.omOfferVersionGroups.push(action.payload);
            }
        },

        RemoveOfferVersionGroup: (state, action: PayloadAction<IOfferVersionGroup>) =>
        {
            var newState = state.omOfferVersion.omOfferVersionGroups.filter((group) => group.omOfferVersionGroupId === action.payload.omOfferVersionGroupId);
            state.omOfferVersion.omOfferVersionGroups = [...newState];
        },
        // TODO ohne groupId 
        SetOfferPositions: (state, action: PayloadAction<Array<IOfferPosition>>) =>
        {

        },
        // TODO ohne groupId 
        SetOfferPosition: (state, action: PayloadAction<IOfferPosition>) =>
        {

        },
        SetOfferPositionsByGroup: (state, action: PayloadAction<{ groupId: number, offerPositions: Array<IOfferPosition>; }>) =>
        {
            const groupIndex = state.omOfferVersion.omOfferVersionGroups.findIndex((group) => group.omOfferVersionGroupId === action.payload.groupId);
            if (groupIndex !== -1)
            {
                state.omOfferVersion.omOfferVersionGroups[groupIndex].omOfferPosition = action.payload.offerPositions;
            }
        },
        SetOfferPositionByGroup: (state, action: PayloadAction<{ groupId: number, offerPosition: IOfferPosition; }>) =>
        {
            const groupIndex = state.omOfferVersion.omOfferVersionGroups.findIndex((group) => group.omOfferVersionGroupId === action.payload.groupId);
            if (groupIndex !== -1)
            {
                state.omOfferVersion.omOfferVersionGroups[groupIndex].omOfferPosition.push(action.payload.offerPosition);
            }
        },
    }
});

export const offerActions = offerSlice.actions;
export default offerSlice.reducer;