import { Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'

import { useUX } from '../Contexts/UXContext';
import { useLanguage } from '../Contexts/LanguageContext';
import byon_logo from '../Theme/Assets/byon-logo.svg';
import consense_logo from '../Theme/Assets/consense_logo.png';
import { 
    shadowContainer, innerContainer, loginForm
} from '../Theme/LayoutDefinitions';

const ErrorPage = () => {
    const { companyName, primaryColor, logo } = useUX();
    const { dictionary } = useLanguage();
    const navigate = useNavigate();

    let defaultColor = primaryColor;
    if(defaultColor === undefined) {
        defaultColor = '#bc1123';
    }

    let defaultCompanyName = companyName;
    if(defaultCompanyName === undefined) {
        defaultCompanyName = 'byon GmbH';
    }

    let defaultLogo = logo;
    if(defaultLogo === undefined) {
        defaultLogo = 'byon_logo';
    }

    const backToLoginPage = () => {
        navigate('/');
    };

    return (
        <Box sx={shadowContainer}>
            <Box sx={innerContainer}>
                {
                    // css class: login__content
                }
                <Box>
                    <img 
                        src={defaultLogo === 'byon_logo' ? byon_logo : consense_logo} 
                        alt={`${defaultCompanyName} logo`} 
                        style={{
                            width: '200px'
                        }}
                    />
                </Box>
                {
                    // page content here
                }
                <Box sx={loginForm} style={{textAlign: 'center'}}>
                    <br /><br /><br />
                    <h1>404 - Page not found</h1>
                    <br /><br /><br /><br />
                    <Button 
                        variant="contained" 
                        className='btn'
                        onClick={backToLoginPage}
                    >
                        {dictionary.otp.backToLogin}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ErrorPage;