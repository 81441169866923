import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { offerMakerBaseUrl } from '../configuration/offerMakerRoutes';

export const omApi = createApi({
    reducerPath: 'omApi',
    baseQuery: fetchBaseQuery({
        baseUrl: offerMakerBaseUrl,
        prepareHeaders: (headers, { getState }) =>
        {
            headers.set("Access-Control-Allow-Origin", "*");
            headers.set("Content-Type", "application/json");
            return headers;
        }
    }),
    endpoints: (builder) => ({})
});