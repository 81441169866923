import { useState } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { 
    layoutHeader, userButtonHidden, userButtonShown, links,
    layoutHeaderMedia, linksMedia, 
} from './LayoutDefinitions';
import byon_logo from './Assets/byon-logo.svg';
import consense_logo from './Assets/consense_logo.png';
import esp_logo from './Assets/esp-logo.png';
import { IconButton, useMediaQuery, List, ListItem, Box } from '@mui/material';
import Icon from './Icon';

import { useLanguage } from '../Contexts/LanguageContext';
import { useUX } from '../Contexts/UXContext'
import { useAuth } from '../Contexts/AuthContext'
// import { useMediaQueryData } from "../Contexts/MediaQueryContext";


const LayoutHeader = () => {
    // const { isDesktop, isTablet, isMobile } = useMediaQueryData();

    const matches = useMediaQuery('(max-width: 991px)');

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { currentUser, setCurrentUser, deleteCookie, setIsLoggedIn } = useAuth();
    const { companyName, logo } = useUX();
    const { dictionary } = useLanguage();
    const navigate = useNavigate()

    let defaultCompanyName = companyName;
    if(defaultCompanyName === undefined) {
        defaultCompanyName = 'byon GmbH';
    }

    let defaultLogo = logo;
    if(defaultLogo === undefined) {
        defaultLogo = 'byon_logo';
    }

    const handleLogout = () => {
        deleteCookie('currentUser');
        setCurrentUser({});
        navigate('/');
        setIsLoggedIn(false);
    }

    let logToUse = byon_logo;
    if(defaultLogo === 'consense_logo') {
        logToUse = consense_logo;
    }
    else if(defaultLogo === 'esp_logo') {
        logToUse = esp_logo;
    }

    return (
        <Box 
            component='header'
            sx={matches ? layoutHeaderMedia : layoutHeader}
        >
            <Link to='/'>
                <img 
                    src={logToUse} 
                    alt={`${defaultCompanyName} logo`}
                    style={{ width: '7rem' }}
                />
            </Link>
            <IconButton 
                sx={matches ? userButtonShown : userButtonHidden} 
                className={isMenuOpen ? 'active' : ''}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
                <Icon id='icon-user' />
            </IconButton>
            <List sx={matches ? linksMedia : links}>
                <ListItem>
                    <NavLink to='/' style={{display: 'none !important'}}>
                        {`${currentUser.firstName} ${currentUser.lastName}`}
                    </NavLink>
                </ListItem>
                <ListItem style={{ cursor: 'pointer' }} onClick={handleLogout}>
                    {dictionary.logout}
                </ListItem>
            </List>
        </Box>
    );
}

export default LayoutHeader;