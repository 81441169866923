import EditIcon from "@mui/icons-material/Edit";
import SyncIcon from "@mui/icons-material/Sync";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { alpha, Box, IconButton, Stack, Tooltip } from "@mui/material";
import {
    DataGridPro,
    deDE,
    enUS,
    GridColDef,
    GridColumnHeaderParams,
    GridColumnMenuContainer,
    GridFooterContainer,
    GridPagination,
    GridRowId,
    GridSortModel,
    SortGridMenuItems,
} from "@mui/x-data-grid-pro";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../common/hooks";
import { AppDispatch } from "../../common/store";
import { useComponentHeight } from "../../common/useComponentHeight";
import SimpleFieldSet from "../../components/SimpleFieldSet";
import { uiRoutes } from "../../configuration/offerMakerRoutes";
import IOffer from "../../interfaces/IOffer";
import { IOrderBy } from "../../interfaces/offer/IOfferState";
import { useGetOffersQuery } from "../../services/offersApi";
import { useOmTranslation } from "../../translations/OmTranslationContext";
import { offersActions } from "./offerListSlice";
import { useOffersContext } from "./OffersContext";
import OffersFilter from "./OffersFilter";
import OffersSearchField from "./OffersSearchField";

export interface IOffersTable {
    // elementsHeight: number;
    // offers: Array<IOffer> | undefined;
    parentRef: any;
}

interface IReloadUsersBtn {
    isLoading: boolean;
    refetch: () => void;
}
function GridFooterWithReloadUsersBtn(props: IReloadUsersBtn) {
    const offersList = useGetOffersQuery();
    //@ts-ignore
    const Pagination: any = GridPagination as any;

    return (
        <GridFooterContainer>
            <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="stretch"
                sx={{
                    height: "52px",
                    alignItems: "center",
                    flexGrow: 1,
                }}
            >
                <Box p={1} sx={{ display: "inline-flex" }}>
                    <Tooltip title={"Angebote neu laden"}>
                        <IconButton
                            aria-label="reloadOffers"
                            color="default"
                            onClick={() => {
                                offersList.refetch();
                            }}
                            disabled={offersList.isFetching}
                            sx={{
                                "&:hover": {
                                    backgroundColor: (theme) => `${theme.palette.background.paper}`,
                                },
                                padding: "3px",
                                marginLeft: "unset",
                            }}
                        >
                            {offersList.isFetching ? (
                                <SyncIcon
                                    sx={{
                                        animation: "spin 2s linear infinite",
                                        "@keyframes spin": {
                                            "0%": {
                                                transform: "rotate(360deg)",
                                            },
                                            "100%": {
                                                transform: "rotate(0deg)",
                                            },
                                        },
                                    }}
                                />
                            ) : (
                                <SyncIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                    <OffersFilter />
                </Box>
                <OffersSearchField />
            </Stack>
            <Pagination />
        </GridFooterContainer>
    );
}

const CustomColumnMenu = (props: any) => {
    const { hideMenu, currentColumn } = props;
    return (
        <GridColumnMenuContainer open={hideMenu} hideMenu={hideMenu} currentColumn={currentColumn}>
            <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
            {/* <GridFilterMenuItem onClick={hideMenu} column={currentColumn} /> */}
        </GridColumnMenuContainer>
    );
};

function OffersTable(props: IOffersTable) {
    const navigate = useNavigate();
    const { dictionary } = useOmTranslation();
    const omSession = useAppSelector((state) => state.session);
    const offersState = useAppSelector((state) => state.offerList);
    const offersContext = useOffersContext();
    const [allUsersSelected, setAllUsersSelected] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const [profileAddDialogOpened, setProfileAddDialogOpened] = React.useState(false);

    const offersList = useGetOffersQuery(undefined, { refetchOnMountOrArgChange: true });

    const height = useComponentHeight(props.parentRef);

    React.useEffect(() => {
        if (offersList.isSuccess) {
        } else if (offersList.isError) {
            offersContext?.setFilteredOffers([]);
            navigate(uiRoutes.error);
        }
    }, [offersList.data, offersList.isError]);

    const [selectedRow, setSelectedRow] = React.useState<number>();

    const DetailButton = (offer: any) => {
        const navigate = useNavigate();
        return (
            <IconButton
                aria-label="toggle password visibility"
                onClick={(event: any) => {
                    event.ignore = true;
                    navigate(uiRoutes.offerDetail(offer.omOfferId.toString()), {
                        state: { ...offer },
                    });
                }}
                sx={{}}
            >
                <VisibilityIcon />
            </IconButton>
        );
    };

    const EditButton = (offer: any) => {
        const navigate = useNavigate();
        return (
            <IconButton
                aria-label="toggle password visibility"
                onClick={(event: any) => {
                    event.ignore = true;
                    navigate(uiRoutes.offerEdit(offer.omOfferId.toString()), {
                        state: { ...offer },
                    });
                }}
                sx={{}}
            >
                <EditIcon />
            </IconButton>
        );
    };

    const columns: Array<GridColDef> = [
        {
            field: "offerNumber",
            headerName: "omOfferId",
            minWidth: 160,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.offer.offerNumber,
        },
        {
            field: "title",
            headerName: "offerTitle",
            minWidth: 180,
            editable: false,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.offer.offerTitle,
        },
        {
            field: "offerType",
            headerName: "offerType",
            minWidth: 180,
            editable: false,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.offer.offerType,
        },
        {
            field: "offerStatus",
            headerName: "offerStatus",
            minWidth: 160,
            editable: false,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.offer.offerStatus,
        },

        {
            field: "customer",
            headerName: "customer",
            minWidth: 180,
            editable: false,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.customer.customerName,
        },
        {
            field: "customerNumber",
            headerName: "customerNumber",
            minWidth: 160,
            editable: false,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.customer.customerNumber,
        },
        {
            field: "createUser",
            headerName: "createUser",
            minWidth: 180,
            editable: false,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.offer.createdBy,
        },
        {
            field: "createDate",
            headerName: "createDate",
            minWidth: 180,
            maxWidth: 220,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.offer.createdOn,
            renderCell: (params) => {
                return (params.row as IOffer).createDate as any;
            },
        },
        {
            field: "action",
            headerName: "aktion",
            minWidth: 120,
            maxWidth: 180,
            flex: 0,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params: GridColumnHeaderParams) => dictionary.action,
            renderCell: (params) => {
                return (
                    <Stack direction={"row"}>
                        {/*  <DetailButton {...params.row} /> */}
                        <EditButton {...params.row} />
                    </Stack>
                );
            },
        },
    ];
    const handleOnGridRowClick = (event: any) => {
        if (!event.ignore) {
            const offer = event.row as IOffer;
            navigate(uiRoutes.offerDetail(offer.omOfferId.toString()), {
                state: { ...offer },
            });
        }
    };
    let localeText = enUS.components.MuiDataGrid.defaultProps.localeText;
    if (omSession.language === "de" || omSession.language === "DE") {
        localeText = deDE.components.MuiDataGrid.defaultProps.localeText;
    }

    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCountState` from being undefined during the loading
    const [rowCount, setRowCount] = React.useState(offersList?.data?.count || 0);
    React.useEffect(() => {
        setRowCount((prevRowCount: any) =>
            offersList?.data?.count !== undefined ? offersList?.data?.count : prevRowCount
        );
    }, [offersList?.data?.count, setRowCount]);

    const pageSizeChangeHandler = (pageSize: number) => {
        AppDispatch(offersActions.SetPageSize(pageSize));
        AppDispatch(offersActions.SetPageNumber(1));
        offersList.refetch();
    };

    const pageChangeHandler = (page: number) => {
        AppDispatch(offersActions.SetPageNumber(page + 1));
        offersList.refetch();
    };

    const handleSortModelChange = (newModel: GridSortModel) => {
        AppDispatch(offersActions.SetOrderBy(newModel[0] as IOrderBy));
        offersList.refetch();
    };

    localeText.noRowsLabel = dictionary.noMatch;
    localeText.noResultsOverlayLabel = dictionary.noResult;
    localeText.errorOverlayDefaultLabel = dictionary.error.unexpectedError;
    return (
        <SimpleFieldSet legendText={dictionary.offer.activeOffers} size="normal">
            <Box sx={{}}>
                <DataGridPro
                    localeText={localeText}
                    hideFooter={false}
                    getRowId={(r) => r.omOfferId}
                    rowCount={rowCount}
                    rowsPerPageOptions={[1, 10, 25, 50]}
                    pagination
                    page={offersState.pagination.pageNumber - 1}
                    pageSize={offersState.pagination.rowsPerPage}
                    paginationMode="server"
                    onPageChange={(newPage) => pageChangeHandler(newPage)}
                    onPageSizeChange={(newPageSize) => pageSizeChangeHandler(newPageSize)}
                    sortingMode="server"
                    sortModel={
                        offersState.pagination.orderBy != undefined
                            ? [offersState.pagination.orderBy]
                            : []
                    }
                    onSortModelChange={handleSortModelChange}
                    sx={{
                        /*                         backgroundColor: (theme) => theme.palette.background.paper,
                        "& .MuiDataGrid-overlay": {
                            top: "50px !important",
                        },
                        "& .MuiDataGrid-columnSeparator": {
                            visibility: "hidden",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            marginTop: "55px !important",
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                            padding: "0px",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor:
                               "#3d3d3d",
                            height: "53px !important",
                            minHeight: "unset !important",
                            maxHeight: "unset !important",
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor:
                                 "#3d3d3d",
                            borderTop: "1px solid",
                            borderColor: (theme) => theme.palette.divider,
                        },
                        "& .MuiIconButton-root": { borderRadius: "15%", padding: "5px" },
                        "& .MuiCheckbox-root": {
                            borderRadius: "15%",
                            padding: "5px",
                            outline: "none !important",
                        },
                        "& .cellCheckbox:focus": {
                            outline: "none !important",
                        }, */
                        "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                            fontWeight: "600",
                        },
                        "& .MuiDataGrid-columnHeader": {
                            outline: "none !important",
                            backgroundColor: (theme) => alpha(theme.palette.divider, 0.05),
                            borderRadius: "unset",
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor: (theme) => alpha(theme.palette.divider, 0.05),
                        },
                        "& .MuiDataGrid-row": {
                            /*                             "&:hover": {
                                cursor: "pointer",
                            }, */
                        },
                        "& .MuiDataGrid-cell": {
                            outline: "none !important",
                        },
                        height: `${height - 150}px`,
                        overflowY: "auto",
                        border: "unset",
                    }}
                    rowHeight={50}
                    rows={offersList?.data ? (offersList?.data.offers as IOffer[]) : []} //{offersContext?.filteredOffers ? offersContext?.filteredOffers : []}
                    columns={columns}
                    columnBuffer={2}
                    checkboxSelection={false}
                    componentsProps={{
                        row: {},
                        footer: { isLoading: offersList.isLoading, refetch: offersList.refetch },
                    }}
                    components={{
                        ColumnMenu: CustomColumnMenu,
                        Footer: GridFooterWithReloadUsersBtn,
                    }}
                    loading={offersList.isLoading || offersList.isFetching}
                    disableSelectionOnClick
                    disableColumnSelector
                />
            </Box>
        </SimpleFieldSet>
    );
}

export default React.memo(OffersTable);
