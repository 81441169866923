import { useState } from 'react';
import { Button, Box } from '@mui/material';

import TextField from '../../Components/Form/TextField';
import { useLanguage } from '../../Contexts/LanguageContext';
import BackendRoutes from '../../Constants/BackendRoutes';
import IConfirmationPage from '../../Interfaces/otp/Props/IConfirmationPage';
import IOtpVerifyResponse from '../../Interfaces/otp/IOtpVerifyResponse';

const ConfirmationPage = (props: IConfirmationPage) => {
    const { dictionary } = useLanguage();
    const [ code, setCode ] = useState('');
    const [ errorData, setErrorData ] = useState('');
    const [ sendCounter, setSendCounter ] = useState(0);

    const handleChange = (event: any) => {
        setCode(event.target.value);
    };

    const defaultHeader = new Headers();
    defaultHeader.append('Access-Control-Allow-Origin', '*');
    defaultHeader.append('Content-Type', 'application/json');

    async function verify(code: string) {
        let counter = sendCounter;
        let response = await fetch(BackendRoutes.otp.verify, {
            method: 'POST',
            headers: defaultHeader,
            body: JSON.stringify({
                token: props.token,
                code: code
            })
        });
        let responseData: IOtpVerifyResponse = await response.json();

        if(responseData.success) {
            setErrorData('');
            props.setCodeConfirmation(true);
            props.setLoginName(responseData.loginName);
        }
        else {
            setErrorData(responseData.error);
        }

        counter++;
        setSendCounter(counter);
    }

    const handleSubmit = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        verify(code);
    };

    const handleKeyPress = (event: any) => {
        if(event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    return (
        <Box>
            {dictionary.otp.enterCode}<br/><br/>
            <TextField
                required={true}
                inputId='login'
                title={dictionary.otp.smsCode}
                value={code}
                errorText={dictionary.otp[errorData]}
                onChange={(event: any) => handleChange(event)}
                onKey={handleKeyPress}
            />
            <Button 
                variant="contained" 
                className='btn btn__primary main' 
                style={{ backgroundColor: props.defaultColor }} 
                onClick={handleSubmit}
                disabled={sendCounter >= 4 ? true : false}
            >
                {dictionary.otp.send}
            </Button>
        </Box>
    );
}

export default ConfirmationPage;