import IFieldOption, { IFieldOptionExtended } from "../../interfaces/IFieldOption";

export interface IFormRow
{
    id: string;
    [key: string]: IFormField | string;
}

export enum FieldName
{
    none = "",
    salesWorker = "salesWorker",
    productType = "productType",
    contractDuration = "contractDuration",
    enteredEmail = "enteredEmail",
    contactPerson = "contactPerson",
    offerTitle = "offerTitle",
    userTitle = "userTitle",
    discount = "discount"
}


export enum FormName
{
    none = "",
    offerForm = "offerForm",
    offerProductForm = "offerProductForm"
}



export interface IOfferFormField
{
    id: string;
    fieldName: FieldName;
    value: string | IFieldOption | IFieldOptionExtended | any;
    isValid: boolean;
    hasError: boolean;
    errorText: string;
}

export interface IFormField
{
    id: string;
    fieldName: FieldName;
    formName: FormName;
    value: string | IFieldOption | IFieldOptionExtended | any;
    isValid: boolean;
    hasError: boolean;
    errorText: string;
}


export const initialOfferFormField: IOfferFormField =
{
    id: "",
    fieldName: FieldName.none,
    value: "",
    isValid: false,
    hasError: false,
    errorText: "",
};

export const initialFormField: IFormField =
{
    id: "",
    fieldName: FieldName.none,
    formName: FormName.none,
    value: "",
    isValid: false,
    hasError: false,
    errorText: "",
};


export interface IOfferForm
{
    // fields: Array<IOfferFormField>;
    fields: { [key: string]: IOfferFormField; };
    isValid: boolean;
    reset: boolean;
}


export interface IForm
{
    formName: string;
    fields: { [key: string]: IFormField; };
    isValid: boolean;
    reset: boolean;
}


export interface IFormList
{
    [key: string]: IForm;
}


export interface IFormParams
{
    reset: boolean;
    isValid: boolean;
    hasError: boolean;
}

export const initialFormParams: IFormParams =
{
    reset: false,
    isValid: false,
    hasError: false,
};