import { Buffer } from "buffer";


// @ts-ignore
window.Buffer = Buffer;

function padString(input: string): string
{
    let segmentLength = 4;
    let stringLength = input.length;
    let diff = stringLength % segmentLength;

    if (!diff)
    {
        return input;
    }

    let position = stringLength;
    let padLength = segmentLength - diff;
    let paddedStringLength = stringLength + padLength;
    let buffer = Buffer.alloc(paddedStringLength);

    buffer.write(input);

    while (padLength--)
    {
        buffer.write("=", position++);
    }
    return buffer.toString();
}

function toBase64(base64url: string | Buffer): string
{
    base64url = base64url.toString();
    return padString(base64url)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
}


export function jwtDecode(jwtToken: string)
{
    if (jwtToken == undefined || jwtToken == '')
    {
        return undefined;
    }
    else
    {
        jwtToken = jwtToken.trim();
        const tokenDataArr = jwtToken.split(".");
        if (tokenDataArr.length > 0)
        {
            if (tokenDataArr[1] !== undefined || tokenDataArr[1] !== '')
            {
                const decodedJwt = Buffer.from(toBase64(tokenDataArr[1]), "base64").toString("utf8");
                const parsedJwt = JSON.parse(decodedJwt);
                if (parsedJwt != undefined)
                {
                    return parsedJwt;
                }
            }
        }
        return undefined;
    }
}