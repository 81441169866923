
import { CircularProgress, Box } from '@mui/material';
import ILoading from '../../Interfaces/otp/Props/ILoading';

import { oneTimePassLoading } from '../../Theme/LayoutDefinitions';

const Loading = (props: ILoading) => {
    return (
        <Box>
            <Box sx={oneTimePassLoading}>
                <CircularProgress />
            </Box>
        </Box>
    );
};

export default Loading;