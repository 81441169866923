import { store } from '../common/store';
import { offerMakerBaseUrl, offerMakerRoutes } from '../configuration/offerMakerRoutes';
import { omSessionStoreActions } from '../features/session/omSessionSlice';
import jwtInfo from '../features/session/omSessionActions';
import { omApi } from './omApi';
import { ILoginInfo } from './portalApi';

export const loginApi = omApi.injectEndpoints({
    endpoints: (builder) => ({
        omLogin: builder.query<ILoginInfo, string>({
            query: (authToken) => ({
                url: offerMakerRoutes.login,
                method: "GET",
                headers: {
                    "authorization": `Bearer ${authToken}`,
                },
                // responseHandler: (response) => response.text(),
            }),
            transformResponse: (response: any, meta, arg) => 
            {
                //api.dispatch(authActions.SetCredentials({ jwtToken: response?.data?.jwt, userName: currentUser, jwtExpiresAt: new Date(Date.now() + (6 * 60 * 3600 * 1000)).toLocaleString() }));
                if (response?.success && response?.data?.jwt)
                {
                    const jwt = response?.data?.jwt;
                    const omUser = jwtInfo.getOmUser(jwt);
                    const omUserRoles = jwtInfo.getOmUserRoles(jwt);
                    store.dispatch(omSessionStoreActions.SetCredentials({ jwtToken: jwt, omUser: omUser, omUserRoles: omUserRoles, isLoggedin: true, language: jwtInfo.getLanguage(), }));
                    console.log("jwtData: ", response);
                }
                else
                {
                    console.log("jwtData:response ", response);
                }
                return response;
            },
        }),
        omLogout: builder.query<ILoginInfo, string>({
            query: (authToken) => ({
                url: offerMakerRoutes.logout,
                method: "POST",
                body: {},
                headers: {
                    "authorization": `Bearer ${authToken}`,
                },
            }),
        }),
    }),
    overrideExisting: true,
});

export const { useOmLoginQuery, useOmLogoutQuery } = loginApi;