import {
    FormControl, InputLabel, Input, FormHelperText 
} from '@mui/material';

import ITextField from '../../Interfaces/Props/ITextField';

const TextField = (props: ITextField) => {
    return (
        <FormControl className='field name' fullWidth>
            <InputLabel htmlFor={props.inputId} required={props.required || false}>{props.title}</InputLabel>
            <Input 
                disableUnderline 
                fullWidth
                autoComplete={props.autoComplete || undefined}
                id={props.inputId} 
                aria-describedby={props.inputId+'ErrorHelper'} 
                type={props.type || 'text'}
                required={props.required || false}
                endAdornment={props.endAdornment || null}
                onChange={props.onChange || undefined}
                disabled={props.disabled || false}
                value={props.value} 
                inputProps={{
                    onKeyPress: props.onKey || undefined
                }}
            />
            <FormHelperText id={props.inputId+'ErrorHelper'} error>{props.errorText}</FormHelperText>
        </FormControl>
    );
};

export default TextField;