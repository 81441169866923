import React, { useContext, useState, useEffect } from 'react'
import { useAuth } from './AuthContext'
import ILanguageContext from '../Interfaces/Context/ILanguageContext';
import IDictionaray from '../Interfaces/Context/IDictionaray';
import IProviderProps from '../Interfaces/Props/IProviderProps';
import de from '../Data/deutsch.json'
import en from '../Data/english.json'

const LanguageContext = React.createContext<ILanguageContext>({
    dictionary: {},
    toUpperCase: (word: string) => '',
    changeLanguage: (lang: string) => {}
});

export function useLanguage() {
    return useContext(LanguageContext);
}

export const LanguageProvider: React.FC<IProviderProps> = ({ children }) => {
    const [dictionary, setDictionary] = useState<IDictionaray>(de);
    const { currentUser } = useAuth();

    const toUpperCase = (word: string) => {
        if (typeof word !== 'string') return ''
        return word.charAt(0).toUpperCase() + word.slice(1)
    }

    const changeLanguage = (lang: string) => {
        setDictionary((lang === 'de' || lang === 'DE') ? de : en);
    }

    useEffect(() => {
        if (currentUser && currentUser.success) {
            setDictionary((currentUser.language === 'de' || currentUser.language === 'DE') ? de : en)
        }
    }, [currentUser])
    
    return <LanguageContext.Provider value={{ dictionary, toUpperCase, changeLanguage }}>{children}</LanguageContext.Provider> 
};