import { 
    IconButton, Tooltip
} from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import IPasswordGenerator from '../../Interfaces/Props/IPasswordGenerator';
import { useLanguage } from '../../Contexts/LanguageContext';

const chrLower  = "abcdefghijklmnopqrstuvwxyz",
    chrUpper    = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    chrNumbers  = "123456789",
    chrSymbols  = ".,?!_-",
    maxLength   = 25,
    minLength   = 1;

/**
 * Public: Create password
 * 
 * length (optional): Password length. If value is not within
 * minLength/maxLength (defined in constructor), length will be adjusted
 * automatically.
 * 
 * characters (optional): The characters the password will be composed
 * of. Must contain at least one of the following: this.chrLower
 * this.chrUpper this.chrNumbers this.chrSymbols Use + to combine. You
 * can add your own sets of characters. If not at least one of the
 * constructor defined sets of characters is found, default set of
 * characters will be used.
 */
const create = (length: number, characters: string): string => {
    var _length = adjustLengthWithinLimits(length),
        _characters = secureCharacterCombination(characters),
        password = shufflePassword(assemblePassword(_characters, _length));

    return password;
};

// Private: Adjusts password length to be within limits.
const adjustLengthWithinLimits = (length: number): number => {
    if (!length || length < minLength) {
        return minLength;
    }
    else if (length > maxLength) {
        return maxLength;
    }
    else {
        return length;
    }
};

// Private: Make sure characters password is build of contains
// meaningful set of characters.
const secureCharacterCombination = (characters: string): string => {
    var defaultCharacters = chrLower + chrUpper + chrNumbers;

    if (!characters || trim(characters) === "") {
        return defaultCharacters;
    }
    else if (!containsAtLeast(characters, [chrLower, chrUpper, chrNumbers, chrSymbols])) {
        return defaultCharacters;
    }
    else {
        return characters;
    }
};

// Private: Assemble password using a string of characters the password
// will consist of.
const assemblePassword = (characters: string, length: number): string => {
    var randMax = chrNumbers.length,
        randMin = randMax - 4,
        index = random(0, characters.length - 1),
        password = "";

    for (var i = 0; i < length; i++) {
        var jump = random(randMin, randMax);
        index = ((index + jump) > (characters.length - 1) ? random(0, characters.length - 1) : index + jump);
        password += characters[index];
    }

    return password;
};

// Private: Shuffle password.
const shufflePassword = (password: string): string => {
    return password.split('').sort(function() {
        return 0.5 - Math.random()
    }).join('');
};

// Private: Checks if string contains at least one string in an array
const containsAtLeast = (string: string, strings: Array<string>): boolean => {
    for (let i = 0; i < strings.length; i++) {
        if (string.indexOf(strings[i]) !== -1) {
            return true;
        }
    }
    return false;
};
    
// Private: Returns a random number between min and max.
const random = (min: number, max: number): number => {
    return Math.floor((Math.random() * max) + min);
};

// Private: Trims a string (required for compatibility with IE9 or older)
const trim = (str: string): string => {
    if (typeof String.prototype.trim !== 'function') {
        return str.replace(/^\s+|\s+$/g, '');
    }
    else {
        return str.trim();
    }
};

const PasswordGenerator = (props: IPasswordGenerator) => {
    const { setFormValues, formValues, passwordField, repeatPasswordField } = props;

    const { dictionary } = useLanguage();

    const generatePassword = () => {
        const generatedPassword = 
            create(10, chrLower + chrUpper + chrNumbers + chrSymbols) + 
            create(1, chrLower) +  create(1, chrNumbers) +
            create(1, chrUpper) +  create(1, chrSymbols);

        setFormValues({
            ...formValues,
            [passwordField]: generatedPassword,
            [repeatPasswordField]: generatedPassword
        });
    };

    return (
        <IconButton 
            style={props.style} 
            onClick={() => generatePassword()}
        >
            <Tooltip title={dictionary.userForm.createPassword}>
                <PasswordIcon />
            </Tooltip>
        </IconButton>
    );
}

export default PasswordGenerator;