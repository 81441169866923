import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import useAutocomplete from "../../../hooks/useAutocomplete";
import { useOmTranslation } from "../../../translations/OmTranslationContext";
import { FieldName, FormName } from "../../forms/IFormParams";

interface IOfferDiscount {
    id: number;
    name: string;
}
const initalOfferDiscount: IOfferDiscount = { id: 0, name: `0 %` };

interface IOfferDiscountAutocomplete {
    // dialogMode: DialogMode;
    size: "small" | "medium";
    showLabel: boolean;
}
const OfferDiscountAutocomplete = () => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const [discountOptions, setDiscountOptions] = useState<Array<IOfferDiscount>>([]);

    const getDiscount = () => {
        let allowedDiscount = 5;
        return allowedDiscount;
    };

    const {
        value: offerDiscount,
        setValue: setOfferDiscount,
        isValid: offerDiscountIsValid,
        hasError: offerDiscountHasError,
        valueChangeHandler: offerDiscountChangedHandler,
        inputBlurHandler: offerDiscountBlurHandler,
        reset: resetOfferDiscountInput,
    } = useAutocomplete<IOfferDiscount>({
        formName: FormName.offerForm,
        fieldName: FieldName.discount,
        validationCallback: (value: IOfferDiscount) => {
            if (value?.id && value.id <= getDiscount() && value?.id >= 0) {
                return true;
            } else if (value.id === 0) {
                return true;
            }
            return false;
        },
    });

    //#endregion Component state variables
    //#region Fetch requests

    //#endregion Fetch requests
    //#region Component effects
    /*     useEffect(() => {
        setOfferDiscount(editRow?.discount ?? initalOfferDiscount);
    }, [editRow]); */

    /*    useEffect(() => {
        AppDispatch(
            offerAddEditGridActions.SetEditRow({
                ...editRow,
                discount: offerDiscount,
            })
        );
    }, [offerDiscount]); */

    useEffect(() => {
        const tempDiscountOptions: Array<IOfferDiscount> = [initalOfferDiscount];
        const discount = getDiscount();
        if (discount > 0) {
            for (let index = 1; index <= discount; index++) {
                const element: IOfferDiscount = { id: index, name: `${index.toString()} %` };
                tempDiscountOptions.push(element);
            }
        }
        resetOfferDiscountInput();
        setOfferDiscount(tempDiscountOptions[0]);
        setDiscountOptions(tempDiscountOptions);
    }, []);

    //#endregion Component effects
    //#region Functions
    const isDisabled = () => {
        const discount = getDiscount();
        if (discount > 0) {
            return false;
        }
        return true;
    };
    //#endregion Functions
    //#region Event handlers
    const handleDiscountChange = (event: any, data: any) => {
        offerDiscountChangedHandler({
            event,
            newValue: data ?? initalOfferDiscount,
            callback: (event, data) => {},
        });
    };

    //#endregion Event Handlers
    //#region Markup helper variables

    //#endregion Markup helper variables
    //#region Markup
    return (
        <Autocomplete
            disabled={isDisabled()}
            sx={{ width: "120px" }}
            size="small"
            id="group-dropdown"
            options={discountOptions}
            getOptionLabel={(option: IOfferDiscount) => option.name ?? ""}
            isOptionEqualToValue={(option1: IOfferDiscount, option2: IOfferDiscount) =>
                option1.id === option2.id
            }
            onChange={handleDiscountChange}
            onBlur={(event) => {
                offerDiscountBlurHandler(event);
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={dictionary.product.discount}
                        error={offerDiscountHasError}
                        InputProps={{
                            ...params.InputProps,
                        }}
                    />
                );
            }}
            value={offerDiscount}
        />
    );
    //#endregion Markup
};
export default OfferDiscountAutocomplete;
