import { configureStore } from '@reduxjs/toolkit';
import omSessionReducer from '../features/session/omSessionSlice';
import { portalApi } from '../services/portalApi';
import { omApi } from '../services/omApi';
import offerReducer from '../features/offer/offerSlice';
import offerAddEditGridReducer from '../features/offer/offerAddEditGridSlice';
import offerListReducer from '../features/offerList/offerListSlice';
import customerReducer from '../features/customer/customerSlice';
import formsReducer from '../features/forms/formsSlice';


export const store = configureStore({
    reducer: {
        [portalApi.reducerPath]: portalApi.reducer,
        [omApi.reducerPath]: omApi.reducer,
        session: omSessionReducer,
        offer: offerReducer,
        offerAddEditGrid: offerAddEditGridReducer,
        forms: formsReducer,
        offerList: offerListReducer,
        customer: customerReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(portalApi.middleware)
            .concat(omApi.middleware),
    devTools: true
});


// export Inferred types of store
export type RootStateType = ReturnType<typeof store.getState>;
export type AppDispatchType = typeof store.dispatch;

// export store.dipatch and RootState functions 
export const AppDispatch = store.dispatch;
export const AppRootState = store.getState;