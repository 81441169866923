import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import { 
    Visibility, VisibilityOff, LockReset
} from '@mui/icons-material';
import { 
    IconButton, Tooltip, InputAdornment
} from '@mui/material';

import { 
    shadowContainer, innerContainer, loginForm, loginErrorBox
} from '../Theme/LayoutDefinitions';
import TextField from '../Components/Form/TextField';
import { useAuth } from '../Contexts/AuthContext';
import { useUX } from '../Contexts/UXContext';
import { useLanguage } from '../Contexts/LanguageContext';
import byon_logo from '../Theme/Assets/byon-logo.svg';
import consense_logo from '../Theme/Assets/consense_logo.png';
import esp_logo from '../Theme/Assets/esp-logo.png';

// we use an empty from in the production environment
let defaultLoginFormState = {
    login: '',
    password: ''
};

// check if we are in the development environment if this is the case 
// we fill the form values with the a user to login faster
if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
    defaultLoginFormState = {
        login: 'byondeveloper@byon.de',
        password: 'testttesttest123T%'
    };
}

const Login = () => {
    const navigate = useNavigate();
    const { companyName, primaryColor, logo } = useUX();
    const { dictionary, toUpperCase } = useLanguage();
    const { currentUser, login, loginError, waitTime, setWaitTime, runTimer, setRunTimer, setloginError} = useAuth();

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    let defaultColor = primaryColor;
    if(defaultColor === undefined) {
        defaultColor = '#bc1123';
    }

    let defaultCompanyName = companyName;
    if(defaultCompanyName === undefined) {
        defaultCompanyName = 'byon GmbH';
    }

    let defaultLogo = logo;
    if(defaultLogo === undefined) {
        defaultLogo = 'byon_logo';
    }

    let logoToUse = byon_logo;
    if(defaultLogo === 'consense_logo') {
        logoToUse = consense_logo;
    }
    else if(defaultLogo === 'esp_logo') {
        logoToUse = esp_logo;
    }

    const [formValues, setFormValues] = useState(defaultLoginFormState);

    const [formErrors, setFormErrors] = useState({
        login: '',
        password: ''
    });

    const [formChanged, setFormChanged] = useState({
        login: false,
        password: false
    });

    const handleSubmit = (event: any) => {
        if(event) {
            event.preventDefault();
        }

        login(formValues.login, formValues.password)
    }

    const calcWaitTime = (waitTime: number) => {
        if(waitTime < 60) {
            if(waitTime < 10) {
                return '00:00:0' + waitTime;
            }

            return '00:00:' + waitTime;
        }

        let hoursString     = '',
            minutesString   = '',
            secondsString   = '',
            hours   = Math.floor(waitTime/3600),
            minutes = Math.floor((waitTime - (hours*3600))/60),
            seconds = waitTime - (minutes*60) - (hours*3600);

        hoursString = '' + hours;
        if(hours < 10) {
            hoursString = '0' + hours;
        }

        minutesString = '' + minutes;
        if(minutes < 10) {
            minutesString = '0' + minutes;
        }

        secondsString = '' + seconds;
        if(seconds < 10) {
            secondsString = '0' + seconds;
        }

        if(hours > 0) {
            return hoursString + ':' + minutesString + ':' + secondsString;
        }

        return '00:' + minutesString + ':' + secondsString;
    };

    const handleChange = (event: any, fieldName: string) => {
        setFormValues({
            ...formValues,
            [fieldName]: event.target.value
        });
        setFormChanged({
            ...formChanged,
            [fieldName]: true
        });
    }

    const handleKeyPress = (event: any) => {
        if(event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    useEffect(() => {
        if(formChanged.login) {
            if(formValues.login === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    login: dictionary.userForm.login_error
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    login: ''
                }));
            }
        }

        if(formChanged.password) {
            if(formValues.password === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    password: dictionary.userForm.password_error
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    password: ''
                }));
            }
        }
    }, [formValues, formChanged, setFormErrors, dictionary]);

    useEffect(() => {
        let timerId: any;

        if (runTimer) {
            timerId = setInterval(() => {
                setWaitTime((waitTime: number) => waitTime - 1);
            }, 1000);
        } 
        else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer, setWaitTime]);

    useEffect(() => {
        if (waitTime < 0 && runTimer) {
            setRunTimer(false);
            setloginError('');
            setWaitTime(0);
        }
    }, [waitTime, runTimer, setWaitTime, setRunTimer, setloginError]);

    let errorText = '';
    if(loginError === 'loginWaitTime' && runTimer) {
        errorText = dictionary.loginWaitTime.replace('{waitTime}', calcWaitTime(waitTime));
    }

    return currentUser && currentUser.success ? (
        <Navigate to={'/welcome'} replace={true}/>
    ) : (
        <Box sx={shadowContainer}>
            <Box sx={innerContainer}>
                {
                    // css class: login__content
                }
                <Box>
                    <img 
                        src={logoToUse} 
                        alt={`${defaultCompanyName} logo`} 
                        style={{
                            width: '200px'
                        }}
                    />
                </Box>
                {
                    // page content here
                }
                <Box sx={loginForm}>
                    {loginError && (
                        <Box sx={loginErrorBox}>
                            {
                                loginError === 'login_error' ?
                                dictionary[loginError]
                                :
                                errorText
                            }
                        </Box>
                    )}
                    <Box className='field name'>
                        <TextField
                            required={true}
                            inputId='login'
                            title={toUpperCase(dictionary.username)}
                            value={formValues.login}
                            errorText={formErrors.login}
                            onChange={(event: any) => handleChange(event, 'login')}
                        />
                    </Box>
                    <Box className='field password'>
                        <TextField
                            required={true}
                            inputId='password'
                            title={toUpperCase(dictionary.password)}
                            value={formValues.password}
                            errorText={formErrors.password}
                            type={showPassword ? "text" : "password"}
                            onChange={(event: any) => handleChange(event, 'password')}
                            onKey={handleKeyPress}
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {
                                            showPassword ? 
                                            <Tooltip title={dictionary.userForm.hidePassword}> 
                                                <Visibility /> 
                                            </Tooltip>
                                            : 
                                            <Tooltip title={dictionary.userForm.showPassword}>
                                                <VisibilityOff />
                                            </Tooltip>
                                        }
                                    </IconButton>
                                    <IconButton
                                        aria-label="forgot password"
                                        onClick={() => { navigate("/forgotpassword"); }}
                                    >
                                        <Tooltip title={dictionary.forgotPassword}> 
                                            <LockReset />
                                        </Tooltip>
                                    </IconButton>
                                </InputAdornment>
                            )}
                        />
                    </Box>
                    <Button 
                        variant="contained" 
                        className='btn'
                        onClick={handleSubmit}
                        disabled={runTimer}
                    >
                        {dictionary.login}
                    </Button>
                </Box>
            </Box>
        </Box>

        
    )
}

export default Login
