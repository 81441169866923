import { SxProps, Theme } from '@mui/material/styles';

const fieldset: SxProps<Theme> = {
    border:  '1px solid #ccc',
    borderRadius: '5px',
    padding: '25px',
    marginTop: '20px'
}

const legend: SxProps<Theme> = {
    border:  '1px solid #ccc',
    borderBottom: 0,
    borderRadius: '5px 5px 0 0',
    padding: '0 18px',
    position: 'relative',
    top: '-10px'
}

const legendSpan: SxProps<Theme> = {
    position: 'relative',
    top: '8px'
}

export {
    fieldset, legend, legendSpan
}