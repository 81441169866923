import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { generateKey } from "../../../common/functions";
import { useAppSelector } from "../../../common/hooks";
import { AppDispatch } from "../../../common/store";
import { useOmTranslation } from "../../../translations/OmTranslationContext";
import { FormName } from "../../forms/IFormParams";
import { ColumnName, IProductRow } from "../OfferAddEditGrid";
import { offerAddEditGridActions } from "../offerAddEditGridSlice";
import ProductAddEditFormNew from "./ProductAddEditFormNew";

export enum DialogMode {
    edit = "edit",
    new = "new",
    close = "close",
}
interface IProductAddEditDialog {
    dialogMode: DialogMode;
    setDialogMode: (value: DialogMode) => void;
    row: IProductRow | null;
}

//@ts-ignore
export const ProductAddEditDialog = ({ row, dialogMode, setDialogMode }: IProductAddEditDialog) => {
    const { dictionary } = useOmTranslation();
    const changedProductRow = useAppSelector((state) => state.offerAddEditGrid.editingRow);
    const offerProductForm = useAppSelector((state) => state.forms[FormName.offerProductForm]);

    const handelSaveChanges = () => {
        let rowId = "";
        if (dialogMode === DialogMode.new) {
            rowId = generateKey(12);
        } else {
            rowId = row?.rowId ?? generateKey(12);
        }
        AppDispatch(offerAddEditGridActions.SetRow({ ...changedProductRow, rowId }));
        setDialogMode(DialogMode.close);
        AppDispatch(offerAddEditGridActions.UnsetEditRow());
    };
    const handleClose = (event?: {} | undefined, reason?: "backdropClick" | "escapeKeyDown") => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setDialogMode(DialogMode.close);
            AppDispatch(offerAddEditGridActions.UnsetEditRow());
        }
    };
    const IsProductValid = () => {
        if (changedProductRow.positionType?.id === 4) {
            if (changedProductRow?.group) {
                return true;
            }
            return false;
        } else {
            return offerProductForm?.fields[ColumnName.product]?.isValid;
        }
    };

    const isFormValid =
        // offerProductForm?.fields[ColumnName.product]?.isValid &&  // for Product
        IsProductValid() &&
        offerProductForm?.fields[ColumnName.amount]?.isValid &&
        offerProductForm?.fields[ColumnName.discount]?.isValid &&
        changedProductRow?.product?.price &&
        changedProductRow?.product?.price > 0;

    return (
        <Dialog
            maxWidth={"md"}
            sx={{
                "& .MuiDialog-paper": {
                    width: "800px",
                },
            }}
            open={dialogMode !== DialogMode.close}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {dictionary.product.productAdd}
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <ProductAddEditFormNew dialogMode={dialogMode} row={row} />
            </DialogContent>
            <DialogActions>
                <Button disabled={!isFormValid} onClick={() => handelSaveChanges()} color="primary">
                    {dictionary.apply}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
