import { useState, useEffect } from 'react'
import { 
    Button, Grid, IconButton, Tooltip, FormControl, InputLabel,
    FormControlLabel, Switch, Box
} from '@mui/material';
import { 
    ManageAccounts, Badge
} from '@mui/icons-material';

import TextField from '../Form/TextField';
import SelectField from '../Form/SelectField';
import FieldSet from '../Form/FieldSet';
import { useLanguage } from '../../Contexts/LanguageContext';
import UserRightsForm from './UserRightForm';
import UserCustomerForm from './UserCustomerForm';
import PasswordFields from './PasswordFields';
import IUserForm from '../../Interfaces/Props/IUserForm';
import IFormData from '../../Interfaces/User/IFormData';
import IFormErrors from '../../Interfaces/User/IFormErrors';
import ICustomerData from '../../Interfaces/User/ICustomerData';
import BadgeFieldSet from '../Form/BadgeFieldSet';
import { loginForm } from '../../Theme/LayoutDefinitions';

const UserForm = (props: IUserForm) => {
    const { dictionary } = useLanguage();
    const [rightsFormOpen, setRightsFormOpen] = useState(false);
    const [customerFormOpen, setCustomerFormOpen] = useState(false);
    const [disablePasswordFields, setDisablePasswordFields] = useState(props.isEdit ? true : false);

    const [showPassword, setShowPassword] = useState(false);
    const [useSetPassword, setUseSetPassword] = useState(true);
    const [usePasswordLink, setUsePasswordLink] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    
    const [formChanged, setFormChanged] = useState({
        login: false,
        firstName: false,
        lastName: false,
        password: false,
        repeatPassword: false,
        language: false,
        isActive: false,
        mobilePhoneNumber: false,
        rights: false,
        customers: false
    });

    let showBadgeCounter = true;
    if(props.editData && props.editData.showBadgeCounter === 'N') {
        showBadgeCounter = false;
    }

    let sendNewFileNotification = false;
    if(props.editData && props.editData.sendNewFileNotification === 'Y') {
        sendNewFileNotification = true;
    }

    const [formValues, setFormValues] = useState<IFormData>({
        login: props.isEdit && props.editData ? props.editData.login : '',
        firstName: props.isEdit && props.editData ? props.editData.firstName : '',
        lastName: props.isEdit && props.editData ? props.editData.lastName : '',
        password: '',
        repeatPassword: '',
        language: props.isEdit && props.editData ? props.editData.language : 'DE',
        isActive: props.isEdit && props.editData ? props.editData.isActive : 'Y',
        mobilePhoneNumber: props.isEdit && props.editData ? props.editData.mobilePhoneNumber : '',
        rights: props.isEdit && props.editData ? props.editData.rights : [],
        customers: props.isEdit && props.editData ? props.editData.customers : [],
        clients: props.isEdit && props.editData ? props.editData.clients : [],
        sendUserDataByMail: false,
        changePassword: false,
        showBadgeCounter: props.isEdit && props.editData ? showBadgeCounter : true,
        sendNewFileNotification: props.isEdit && props.editData ? sendNewFileNotification : false
    });

    const [formErrors, setFormErrors] = useState<IFormErrors>({
        login: '',
        firstName: '',
        lastName: '',
        password: '',
        repeatPassword: '',
        language: '',
        isActive: '',
        mobilePhoneNumber: '',
        rights: false,
        customers: false
    });

    const languageOptions = [
        {value: 'DE', label: dictionary.languages.german},
        {value: 'EN', label: dictionary.languages.english}
    ];

    const assignRightsClick = (event: any) => {
        event.stopPropagation();
        setRightsFormOpen(true);
    }

    const assignCustomerClick = (event: any) => {
        event.stopPropagation();
        setCustomerFormOpen(true);
    }
    
    const checkForm = () => {
        // eslint-disable-next-line
        const mailRegEx     = /^(")?(?:[^\."\s])(?:(?:[\.])?(?:[\w\-!#$%&'*+/=?^_`{|}~]))*\1@(\w[\-\w]*\.){1,5}([A-Za-z]){2,10}$/,
            phoneNumRegEx   = /^[0-9]+$/,
            // eslint-disable-next-line
            passwordRegEx   = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\@\.\,\?\!\_\-\+\%\/\#\&\%\*])[\da-zA-Z\@\.\,\?\!\_\-\+\%\/\#\&\%\*\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]{12,20}$/;

        if(formValues.login === '') {
            setFormErrors((prevState) => ({
                ...prevState,
                userName: dictionary.errorText.emptyUserName
            }));

            return false;
        }
        else if(!mailRegEx.test(formValues.login)) {
            setFormErrors((prevState) => ({
                ...prevState,
                userName: dictionary.errorText.noValidMail
            }));

            return false;
        }
        else if(formValues.firstName === '') {
            setFormErrors((prevState) => ({
                ...prevState,
                firstName: dictionary.errorText.empytFirstName
            }));

            return false;
        }
        else if(formValues.lastName === '') {
            setFormErrors((prevState) => ({
                ...prevState,
                lastName: dictionary.errorText.emptyLastName
            }));

            return false;
        }
        else if(!disablePasswordFields && formValues.password === '') {
            setFormErrors((prevState) => ({
                ...prevState,
                password: dictionary.errorText.emptyPassword
            }));

            return false;
        }
        else if(!disablePasswordFields && !passwordRegEx.test(formValues.password)) {
            setFormErrors((prevState) => ({
                ...prevState,
                password: dictionary.errorText.passwordWeak
            }));

            return false;
        }
        else if(!disablePasswordFields && formValues.repeatPassword === '') {
            setFormErrors((prevState) => ({
                ...prevState,
                repeatPassword: dictionary.errorText.emptyRepeatPassword
            }));

            return false;
        }
        else if(!disablePasswordFields && !passwordRegEx.test(formValues.repeatPassword)) {
            setFormErrors((prevState) => ({
                ...prevState,
                repeatPassword: dictionary.errorText.passwordWeak
            }));

            return false;
        }
        else if(!disablePasswordFields && formValues.repeatPassword !== formValues.password) {
            setFormErrors((prevState) => ({
                ...prevState,
                repeatPassword: dictionary.errorText.passwordCompareError
            }));

            return false;
        }
        else if(
            formValues.mobilePhoneNumber === '' && usePasswordLink
        ) {
            setFormErrors((prevState) => ({
                ...prevState,
                mobilePhoneNumber: dictionary.errorText.noEmptyPhonenumber
            }));

            return false;
        }
        else if(
            (
                formValues.mobilePhoneNumber !== '' && !phoneNumRegEx.test(formValues.mobilePhoneNumber)
            ) && usePasswordLink
        ) {
            setFormErrors((prevState) => ({
                ...prevState,
                mobilePhoneNumber: dictionary.errorText.noValidPhonenumber
            }));

            return false;
        }
        else if(
            (
                formValues.mobilePhoneNumber !== '' && !formValues.mobilePhoneNumber.startsWith('0049') &&
                formValues.mobilePhoneNumber[0] === '0' && formValues.mobilePhoneNumber[1] === '0'
            ) && usePasswordLink
        ) {
            setFormErrors((prevState) => ({
                ...prevState,
                mobilePhoneNumber:  dictionary.otp.onlyGermanNumbers
            }));

            return false;
        }
        else if(formValues.rights.length === 0) {
            setFormErrors((prevState) => ({
                ...prevState,
                rights: true
            }));

            return false;
        }
        else if(formValues.customers.length === 0 && props.checkUser.hasMultipleCustomers === 'Y') {
            setFormErrors((prevState) => ({
                ...prevState,
                customers: true
            }));

            return false;
        }

        return true;
    }

    const handleSubmit = (event: any) => {
        event.stopPropagation();

        if(checkForm()) {
            props.saveButtonClick(formValues);
        }
        else {
            props.showErrorDialog(dictionary.errorText.formNotValid);
        }
    }

    useEffect(() => {
        // eslint-disable-next-line
        const mailRegEx     = /^(")?(?:[^\."\s])(?:(?:[\.])?(?:[\w\-!#$%&'*+/=?^_`{|}~]))*\1@(\w[\-\w]*\.){1,5}([A-Za-z]){2,10}$/,
            phoneNumRegEx   = /^[0-9]+$/,
            // eslint-disable-next-line
            passwordRegEx   = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\@\.\,\?\!\_\-\+\%\/\#\&\%\*])[\da-zA-Z\@\.\,\?\!\_\-\+\%\/\#\&\%\*\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]{12,20}$/;

        if(formChanged.login) {
            if(formValues.login === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    login: dictionary.errorText.emptyUserName
                }));
            }
            else if(!mailRegEx.test(formValues.login)) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    login: dictionary.errorText.noValidMail
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    login: ''
                }));
            }
        }

        if(formChanged.firstName) {
            if(formValues.firstName === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    firstName: dictionary.errorText.empytFirstName
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    firstName: ''
                }));
            }
        }

        if(formChanged.lastName) {
            if(formValues.lastName === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    lastName: dictionary.errorText.emptyLastName
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    lastName: ''
                }));
            }
        }

        if(formChanged.password) {
            if(formValues.password === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    password: dictionary.errorText.emptyPassword
                }));
            }
            else if(!passwordRegEx.test(formValues.password)) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    password: dictionary.errorText.passwordWeak
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    password: ''
                }));
            }
        }

        if(formChanged.repeatPassword) {
            if(formValues.repeatPassword === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: dictionary.errorText.emptyRepeatPassword
                }));
            }
            else if(!passwordRegEx.test(formValues.repeatPassword)) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: dictionary.errorText.passwordWeak
                }));
            }
            else if(formValues.repeatPassword !== formValues.password) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: dictionary.errorText.passwordCompareError
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: ''
                }));
            }
        }
        
        if(formChanged.mobilePhoneNumber && usePasswordLink) {
            if(formValues.mobilePhoneNumber === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    mobilePhoneNumber: dictionary.errorText.noEmptyPhonenumber
                }));
            }
            else if(formValues.mobilePhoneNumber !== '' && !phoneNumRegEx.test(formValues.mobilePhoneNumber)) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    mobilePhoneNumber: dictionary.errorText.noValidPhonenumber
                }));
            }
            else if(
                formValues.mobilePhoneNumber !== '' && !formValues.mobilePhoneNumber.startsWith('0049') &&
                formValues.mobilePhoneNumber[0] === '0' && formValues.mobilePhoneNumber[1] === '0'
            ) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    mobilePhoneNumber: dictionary.otp.onlyGermanNumbers
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    mobilePhoneNumber: ''
                }));
            }
        }
        else if(formChanged.mobilePhoneNumber) {
            if(formValues.mobilePhoneNumber !== '' && !phoneNumRegEx.test(formValues.mobilePhoneNumber)) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    mobilePhoneNumber: dictionary.errorText.noValidPhonenumber
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    mobilePhoneNumber: ''
                }));
            }
        }

        if(formChanged.rights) {
            if(formValues.rights.length === 0) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    rights: true
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    rights: false
                }));
            }
        }

        if(formChanged.customers && props.checkUser.hasMultipleCustomers === 'Y') {
            if(formValues.customers.length === 0) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    customers: true
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    customers: false
                }));
            }
        }

    }, [setFormErrors, formValues, dictionary, formChanged, disablePasswordFields, props.checkUser, usePasswordLink]);

    const handleChange = (event: any, fieldName: string) => {
        if(
            fieldName === 'isActive'
        ) {
            setFormValues({
                ...formValues,
                [fieldName]: event.target.checked ? 'Y' : 'N'
            });
        }
        else if(fieldName === 'showBadgeCounter' || fieldName === 'sendNewFileNotification') {
            setFormValues({
                ...formValues,
                [fieldName]: event.target.checked
            });
        }
        else {
            setFormValues({
                ...formValues,
                [fieldName]: event.target.value
            });
        }
        setFormChanged({
            ...formChanged,
            [fieldName]: true
        });
    }

    return (
        <Box sx={loginForm}>
            <Grid style={{width: '100%'}} container rowSpacing={0} columnSpacing={1}>
                <Grid item xs={12}>
                    <Grid container rowSpacing={0} columnSpacing={1}>
                        <Grid item xs={4}>
                            <TextField
                                required={true}
                                inputId='login'
                                autoComplete='username email'
                                title={dictionary.userForm.login}
                                value={formValues.login}
                                errorText={formErrors.login}
                                onChange={(event) => handleChange(event, 'login')}
                            />
                        </Grid>
                        <Grid style={{marginTop: '25px'}} item xs={4}>
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        checked={formValues.isActive === 'Y' ? true : false}
                                        onChange={(event) => handleChange(event, 'isActive')}
                                    />
                                }
                                label={dictionary.userForm.active}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container columnSpacing={1} >
                        <Grid item xs={4}>
                            <TextField
                                required={true}
                                inputId='firstname'
                                autoComplete='given-name'
                                title={dictionary.userForm.firstName}
                                value={formValues.firstName}
                                errorText={formErrors.firstName}
                                onChange={(event) => handleChange(event, 'firstName')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                required={true}
                                inputId='lastname'
                                autoComplete='family-name'
                                title={dictionary.userForm.lastName}
                                value={formValues.lastName}
                                errorText={formErrors.lastName}
                                onChange={(event) => handleChange(event, 'lastName')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container columnSpacing={1} >
                        <Grid item xs={4}>
                            <TextField
                                inputId='mobilePhoneNumber'
                                autoComplete='mobile'
                                required={usePasswordLink}
                                title={dictionary.userForm.phoneNumber}
                                value={formValues.mobilePhoneNumber}
                                errorText={formErrors.mobilePhoneNumber}
                                onChange={(event) => handleChange(event, 'mobilePhoneNumber')}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <SelectField 
                                required={true}
                                inputId='language'
                                title={dictionary.userForm.language}
                                value={formValues.language}
                                errorText={formErrors.language}
                                options={languageOptions}
                                onChange={(event) => handleChange(event, 'language')}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <FormControl className='field rights' fullWidth>
                                <InputLabel htmlFor='settings' shrink required>{dictionary.settings}</InputLabel>
                                <Grid id='settings' container rowSpacing={0} columnSpacing={1} style={{marginTop: '8px'}}>
                                    <Grid item xs={1}>
                                        <Tooltip title={dictionary.userForm.rights.assign_rights}>
                                            <IconButton 
                                                style={{padding: "12px 10px", margin: "10px 0px"}}
                                                onClick={assignRightsClick}
                                            >
                                                <ManageAccounts color={formErrors.rights ? 'error' : 'inherit'}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {
                                            props.checkUser.hasMultipleCustomers === 'Y' &&
                                            <Tooltip title={dictionary.userForm.assignCustomers}>
                                                <IconButton 
                                                    style={{padding: "12px 10px", margin: "10px 0px"}}
                                                    onClick={assignCustomerClick}
                                                >
                                                    <Badge color={formErrors.customers ? 'error' : 'inherit'}/>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <BadgeFieldSet 
                        showReset={false}
                        resetTime={''}
                        formValues={formValues}
                        handleChange={handleChange}
                        resetBadgeCount={async () => { }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {
                        props.isEdit ?
                            <FieldSet
                                legendText={"Passwort ändern"}
                                checkboxToggle={true}
                                isCollapsible={true}
                                isCollapsed={true}
                                width='100%'
                                checkboxName='updatePassword'
                                handleCheckboxChange={() => { 
                                    setDisablePasswordFields(!disablePasswordFields);

                                    setFormValues({
                                        ...formValues,
                                        changePassword: !formValues.changePassword
                                    });
                                }}
                            >
                                <PasswordFields 
                                    isEdit={props.isEdit}
                                    useSetPassword={useSetPassword}
                                    setUseSetPassword={setUseSetPassword}
                                    disablePasswordFields={disablePasswordFields}
                                    setDisablePasswordFields={setDisablePasswordFields}
                                    usePasswordLink={usePasswordLink}
                                    setUsePasswordLink={setUsePasswordLink}
                                    setFormValues={setFormValues}
                                    formValues={formValues}
                                    formErrors={formErrors}
                                    showPassword={showPassword}
                                    handleChange={handleChange}
                                    handleClickShowPassword={handleClickShowPassword}
                                    handleMouseDownPassword={handleMouseDownPassword}
                                />
                            </FieldSet>
                        :
                            <PasswordFields 
                                isEdit={props.isEdit}
                                useSetPassword={useSetPassword}
                                setUseSetPassword={setUseSetPassword}
                                disablePasswordFields={disablePasswordFields}
                                setDisablePasswordFields={setDisablePasswordFields}
                                usePasswordLink={usePasswordLink}
                                setUsePasswordLink={setUsePasswordLink}
                                setFormValues={setFormValues}
                                formValues={formValues}
                                formErrors={formErrors}
                                showPassword={showPassword}
                                handleChange={handleChange}
                                handleClickShowPassword={handleClickShowPassword}
                                handleMouseDownPassword={handleMouseDownPassword}
                            />
                    }
                </Grid>
                <Grid style={{marginTop: '15px'}} container item xs={12} justifyContent="flex-end">
                    <div> 
                        <Button variant="contained" onClick={handleSubmit}>{dictionary.userForm.save}</Button>
                        <Button variant="contained" style={{marginLeft: '10px'}} onClick={props.cancelButtonClick}>{dictionary.userForm.cancel}</Button>
                    </div>
                </Grid>
            </Grid>
            <UserRightsForm
                open={rightsFormOpen}
                isEdit={props.isEdit}
                isSuperUser={props.isAdmin}
                roles={props.roles}
                defaultRights={props.defaultRights}
                handleSubmit={(rightsArray: Array<string|number>) => {
                    setRightsFormOpen(false);
                   
                    setFormValues({
                        ...formValues,
                        rights: rightsArray
                    });

                    setFormErrors((prevState) => ({
                        ...prevState,
                        rights: false
                    }));
                }}
                handleCancel={() => {
                    setRightsFormOpen(false);
                }}
                presetRights={(formValues.rights)}
                editableRights={props.checkUser.rights}
            />
            {
                props.checkUser.hasMultipleCustomers === 'Y' &&
                <UserCustomerForm 
                    open={customerFormOpen}
                    clients={props.clients}
                    isAdmin={props.isAdmin}
                    presetClients={formValues.clients}
                    presetCustomers={formValues.customers}
                    submit={(customerData: Array<ICustomerData>) => {
                        setCustomerFormOpen(false);

                        setFormValues({
                            ...formValues,
                            customers: customerData.map((data) => data.id)
                        });

                        setFormErrors((prevState) => ({
                            ...prevState,
                            customers: false
                        }));
                    }}
                    handleClose={() => {
                        setCustomerFormOpen(false);
                    }}
                    handleCancel={() => {
                        setRightsFormOpen(false);
                    }}
                />
            }
        </Box>
    );
}

export default UserForm;