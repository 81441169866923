import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Button, Dialog, DialogTitle, Stack } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";
import { AppDispatch } from "../../../common/store";
import { useOmTranslation } from "../../../translations/OmTranslationContext";
import { IProductRow } from "../OfferAddEditGrid";
import { offerAddEditGridActions } from "../offerAddEditGridSlice";

export default function ProfileDeleteDialog(productRow: IProductRow) {
    const [open, setOpen] = React.useState(false);
    const { dictionary } = useOmTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleDelete = () => {
        AppDispatch(offerAddEditGridActions.RemoveRow(productRow));
        setOpen(false);
    };

    const button = (
        <IconButton aria-label="delete-product" onClick={handleClickOpen}>
            <DeleteOutlineIcon />
        </IconButton>
    );

    return (
        <>
            {button}
            <Dialog
                onClose={handleClose}
                aria-labelledby="profile-delete-dialog"
                open={open}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle>{dictionary.product.deleteProduct}</DialogTitle>
                <DialogContent sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
                    <Stack
                        direction="row"
                        spacing={0}
                        sx={{
                            display: "block",
                            padding: "unset",
                            marginTop: "10px",
                            marginBottom: "10px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "inline-flex",
                                alignSelf: "center",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-around",
                                height: "50px",
                            }}
                            p={0}
                        >
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{ padding: "unset", width: "100%" }}
                            >
                                {dictionary.product.deleteProductDesc(productRow.productName)}
                            </Typography>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDelete()} color="primary">
                        {dictionary.remove}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
