import React, { useContext, useState, useEffect } from "react";
import ITranslationContext, { DictionaryType } from "./ITranslationContext";
import { omDeutsch } from "./deutsch";
import { omEnglish } from "./english";
import { useAppSelector } from "../common/hooks";
import { store } from "../common/store";
import omSessionActions from "../features/session/omSessionActions";

interface IProviderProps {
    children?: React.ReactNode;
}

const OmTranslationContext = React.createContext<ITranslationContext>({
    dictionary: omDeutsch,
    toUpperCase: (word: string) => "",
    changeLanguage: (lang: string) => {},
});

export function useOmTranslation() {
    return useContext(OmTranslationContext);
}

export function getOmTranslation(): DictionaryType {
    let lang = "DE";
    try {
        const session = store.getState().session;
        lang = session.language;
    } catch (ex) {
        lang = "DE";
    } finally {
        if (lang === "DE" || lang === "de") {
            return omDeutsch;
        }
        return omEnglish;
    }
}

export const OmTranslationProvider: React.FC<IProviderProps> = (props: IProviderProps) => {
    const [dictionary, setDictionary] = useState<DictionaryType>(omDeutsch);

    const omSession = useAppSelector((state) => state.session);

    const toUpperCase = (word: string) => {
        if (typeof word !== "string") return "";
        return word.charAt(0).toUpperCase() + word.slice(1);
    };

    const changeLanguage = (lang: string) => {
        setDictionary(lang === "de" || lang === "DE" ? omDeutsch : omEnglish);
    };

    useEffect(() => {
        if (omSession.language === "de" || omSession.language === "DE") {
            setDictionary(omDeutsch);
        } else {
            setDictionary(omEnglish);
        }
    }, [omSession.language]);

    return (
        <OmTranslationContext.Provider value={{ dictionary, toUpperCase, changeLanguage }}>
            {props.children}
        </OmTranslationContext.Provider>
    );
};
