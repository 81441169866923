import React, { useState } from 'react';
import FieldSet from '../Form/FieldSet';
import { 
    TextField, Autocomplete, IconButton,
    Radio, RadioGroup, FormControlLabel, Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLanguage } from '../../Contexts/LanguageContext';
import IStatisticsNumber from '../../Interfaces/Props/IStatisticsNumber';

const StatisticsNumber = (props: IStatisticsNumber) => {
    const { dictionary } = useLanguage();
    const [showPicker, setShowPicker] = useState({
        all: true,
        extension: false
    });

    const data = props.data;

    /**
     * Handle the selection if all extensions should be analysed or only a few
     * 
     * @param {React.ChangeEvent<HTMLInputElement>} event 
     * @param {string} value 
     */
    const handleReportSelection = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if(value === 'all') {
            setShowPicker({
                all: true,
                extension: false
            });

            handleExtensionSelection(undefined, []);
        }
        else if(value === 'extensions') {
            setShowPicker({
                all: false,
                extension: true
            });
        }
    };

    /**
     * Build the list of extensions which can be selected
     * 
     * @returns array<string>
     */
    const buildExtensions = () => {
        const extensionArray = [];

        // get the length of the range number
        let padLength = data.rangeStart.length;
        // if the end of the range is longer as the start we will use the 
        // end for the length to add zero's in front of the numbers
        if(padLength < data.rangeEnd.length) {
            padLength = data.rangeEnd.length;
        }

        for(let i=parseInt(data.rangeStart); i<=parseInt(data.rangeEnd); i++) {
            let number = '' + i;
            extensionArray.push(number.padStart(padLength, '0'));
        }

        return extensionArray;
    };

    /**
     * Remove the number from the list of selected numbers
     * 
     * @param {number} key 
     */
    const removeNumber = (key: number) => {
        const newData = props.completeData.filter((d) => d.key !== key);
        props.setFieldsetData(newData);

        // get all the selected numbers expect the number we want to remove
        const newSelectedNumbers = props.formData.selectedNumbers.filter((num) => num.id !== key);
        // update the form data
        props.setFormData({
            ...props.formData,
            selectedNumbers: [
                ...newSelectedNumbers
            ]
        })
    };

    /**
     * Handle the onChange event of the drop down to select the numbers
     * which should be analysed 
     * 
     * @param {React.SyntheticEvent} event 
     * @param {object} value 
     */
    const handleExtensionSelection = (event: React.SyntheticEvent<Element, Event> | undefined, value: string[]) => {
        // get all selected numbers expect the one we are working on
        const newSelectedNumbers = props.formData.selectedNumbers.filter((num) => num.id !== props.data.key);
        // get the selected number which we want to change
        const numberDataToUpdate = props.formData.selectedNumbers.filter((num) => num.id === props.data.key);

        // update the selected extensions
        if(numberDataToUpdate.length > 0) {
            numberDataToUpdate[0].extensions = value;
        }

        // update the form data
        props.setFormData({
            ...props.formData,
            selectedNumbers: [
                ...newSelectedNumbers,
                numberDataToUpdate[0]
            ]
        })
    };

    return (
        <FieldSet
            legendText={data.number + (data.rangeStart !== '' && data.rangeEnd !== '' ? ' (' + data.rangeStart + ' - ' + data.rangeEnd + ')' : '')}
            width='100%'
        >
            <Grid style={{width: '100%'}} container rowSpacing={0} columnSpacing={1}>
                <Grid item xs={11}>
                    <RadioGroup
                        row
                        aria-labelledby="rangeSelection"
                        defaultValue="all"
                        name="report_selection"
                        onChange={handleReportSelection}
                    >
                        <FormControlLabel value="all" control={<Radio />} label="Gesamten Bereich auswerten" />
                        <FormControlLabel value="extensions" control={<Radio disabled={!props.data.isRange}/>} label="Bestimmte Nebenstellen auswerten" />
                    </RadioGroup>
                    {
                        showPicker.extension &&
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            noOptionsText={dictionary.noOptionsText}
                            openText={dictionary.openText}
                            closeText={dictionary.closeText}
                            clearText={dictionary.clearText}
                            id="extension_selection"
                            sx={{ width: 800 }}
                            options={buildExtensions()}
                            renderInput={(params) => <TextField {...params} label="Nebenstellen auswahl" />}
                            onChange={handleExtensionSelection}
                        />
                    }
                </Grid>
                {
                    props.isRemoveable !== undefined && props.isRemoveable ?
                    <Grid item xs={1}>
                        <IconButton aria-label="delete" color="primary" onClick={() => removeNumber(data.key)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    :
                    null
                }
            </Grid>
        </FieldSet>
    );
};

export default StatisticsNumber;