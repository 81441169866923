import React, { useState, useEffect, useCallback } from 'react';
import { 
    Radio, RadioGroup, FormControlLabel, FormLabel, TextField, Grid, 
    Autocomplete, Button
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useLanguage } from '../../Contexts/LanguageContext';
import { useAuth } from '../../Contexts/AuthContext';
import StatisticsNumber from './StatisticsNumber';
import BackendRoutes from '../../Constants/BackendRoutes';
import FieldSet from '../Form/FieldSet';
import IAnalysisSettings from '../../Interfaces/Props/IAnalysisSettings';
import IAnalyseResponse from '../../Interfaces/Statistics/IAnalyseResponse';
import INumbers from '../../Interfaces/Statistics/INumbers';
import { IWeekSelectedValue, IMonthSelectedValue } from '../../Interfaces/Statistics/IFormData';
import AlertDialog from '../User/AlertDialog';

const AnalysisSettings = (props: IAnalysisSettings) => {
    const { getHeader } = useAuth();
    const { dictionary } = useLanguage();
    const [showPicker, setShowPicker] = useState({
        date: true,
        week: false,
        month: false
    });
    const [allowCSV, setAllowCSV] = useState(false);
    const [allowPDF, setAllowPDF] = useState(true);
    const [doUseEffectOnce, setDoUseEffectOnce] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);

    /**
     * Handle the onChange event of the radio group to set the type of the 
     * statistics which should be shown in the end
     * 
     * @param {React.ChangeEvent<HTMLInputElement>} event 
     * @param {string} value 
     */
    const handleStatsTypeChange = (event: any, value: string) => {
        let format = props.formData.format;
        if(value !== 'rawdata' && props.formData.statsType === 'rawdata' && props.formData.format === 'CSV') {
            format = 'web';
        }
        else if(
            value === 'rawdata' && 
            (props.formData.statsType === 'quality_analytics' || props.formData.statsType === 'success_analytics') &&
            props.formData.format === 'PDF'
        ) {
            format = 'web';
        }

        props.setFormData({
            ...props.formData,
            statsType: value,
            format: format
        });

        setAllowCSV(false);
        setAllowPDF(true);
        if(value === 'rawdata') {
            setAllowCSV(true);
            setAllowPDF(false);
        }
    };

    /**
     * Handle the onChange event of the drop down to select the numbers
     * which should be analysed 
     * 
     * @param {React.SyntheticEvent} event 
     * @param {object} value 
     */
    const handleNumberSelection = (event: React.SyntheticEvent<Element, Event>, value: INumbers|null) => {
        // check if we got a value to work with
        if(value != null) {
            // check if we have already added the number to the selected numbers
            const numberFound = props.fieldsetData.filter((number) => number.key === value.id);

            // if we have not added the number to the selected numbers
            if(numberFound.length === 0) {
                setFieldSetAndForm(value);
            }
        }
    };

    const setFieldSetAndForm = useCallback((value: INumbers) => {
        // we will add the number to the state
        props.setFieldsetData([
            // add every number data to the new state
            // if it is empty we will add nothing this happens when we open the page
            ...props.fieldsetData,
            // add a new element to our state array
            {
                key: value.id,
                number: value.number,
                isRange: value.rangeStart !== '' && value.rangeEnd !== '' ? true : false,
                rangeStart: value.rangeStart,
                rangeEnd: value.rangeEnd,
                isRemoveable: value.isRemoveable !== undefined ? value.isRemoveable : true
            }
        ]);

        // add the number to the formdata
        props.setFormData({
            ...props.formData,
            selectedNumbers: [
                ...props.formData.selectedNumbers,
                {
                    id: value.id,
                    number: value.number,
                    extensions: []
                }
            ]
        });
    }, [props]);

    useEffect(() => {
        if(!props.showNumberSelection && props.numbers.length === 1 && doUseEffectOnce) {
            const numberData = props.numbers[0];

            setFieldSetAndForm({
                id: numberData.id,
                number: numberData.number,
                rangeStart: numberData.rangeStart,
                rangeEnd: numberData.rangeEnd,
                isRemoveable: false
            });

            setDoUseEffectOnce(false);
        }
    }, [doUseEffectOnce, props.numbers, props.showNumberSelection, setFieldSetAndForm]);

    /**
     * Handle the onChange event of the radio group to set the format
     * in which the user want's to see his statistics
     * 
     * @param {React.ChangeEvent<HTMLInputElement>} event 
     * @param {string} value 
     */
    const handleFormatSelection = (event: any, value: string) => {
        props.setFormData({
            ...props.formData,
            format: value
        });
    };

    /**
     * Handle the onChange event of the radio group to set the time
     * 
     * @param {React.ChangeEvent<HTMLInputElement>} event 
     * @param {string} value 
     */
    const handleTimeSelectionChange = (event: any, value: string) => {
        if(value === 'day') {
            setShowPicker({
                date: true,
                week: false,
                month: false
            });
        }
        else if(value === 'calendarweek') {
            setShowPicker({
                date: false,
                week: true,
                month: false
            });
        }
        else if(value === 'month') {
            setShowPicker({
                date: false,
                week: false,
                month: true
            });
        }

        props.setFormData({
            ...props.formData,
            time: value
        });
    };

    /**
     * Handle the onChange event of the date picker to set the day
     * 
     * @param {string} value 
     */
    const handleDaySelection = (value: string | null) => {
        if(value !== null) {
            props.setFormData({
                ...props.formData,
                date: new Date(value)
            });
        }
    };

    /**
     * Handle the onChange event of the drop down to select the week
     * which should be analysed 
     * 
     * @param {React.SyntheticEvent} event 
     * @param {object} value 
     */
    const handleWeekSelection = (event: React.SyntheticEvent<Element, Event>, value: IWeekSelectedValue | null) => {
        // check if we got a value to work with
        if(value !== null) {
            props.setFormData({
                ...props.formData,
                week: {
                    selectedValue: value,
                    calendarWeek: value.week,
                    year: value.year
                }
            });
        }
    };

    /**
     * Handle the onChange event of the drop down to select the month
     * which should be analysed 
     * 
     * @param {React.SyntheticEvent} event 
     * @param {object} value 
     */
    const handleMonthSelection = (event: React.SyntheticEvent<Element, Event>, value: IMonthSelectedValue | null) => {
        // check if we got a value to work with
        if(value !== null) {
            props.setFormData({
                ...props.formData,
                month: {
                    selectedValue: value,
                    month: value.month,
                    year: value.year
                }
            });
        }
    };

    /**
     * Build the calendar weeks from the actual week back for 90 days
     * 
     * @returns array<string>
     */
    const buildCalendarWeekData = () => {
        const 
            calendarWeekDay = new Date(moment().subtract(90, 'days').toString()),
            thisWeek = moment(calendarWeekDay).isoWeek(),
            actualWeek = moment().isoWeek(),
            thisYear = moment().year();

        const calendarWeekArray = [];

        // TODO aufpassen wenn wir im Januar 2024 sind dann müssen wir auch zurück nach 2023 rechnen
        for(let i=thisWeek; i<=actualWeek; i++) {
            calendarWeekArray.push({
                week: i, 
                year: thisYear, 
                optionName: 'KW ' +  i + ' ' + thisYear
            });
        }

        return calendarWeekArray;
    };

    /**
     * Build the months of the year from the actual month back for 90 days
     * 
     * @returns array<string>
     */
    const buildMonthData = () => {
        const
            monthNameArray = [
                dictionary.stats.monthNames.Jan, dictionary.stats.monthNames.Feb, dictionary.stats.monthNames.Mrz, 
                dictionary.stats.monthNames.Apr, dictionary.stats.monthNames.Mai, dictionary.stats.monthNames.Jun, 
                dictionary.stats.monthNames.Jul, dictionary.stats.monthNames.Aug, dictionary.stats.monthNames.Sep,
                dictionary.stats.monthNames.Okt, dictionary.stats.monthNames.Nov, dictionary.stats.monthNames.Dez
            ],
            month = new Date(moment().subtract(90, 'days').toString()),
            thisMonth = moment(month).month(),
            actualMonth = moment().month(),
            thisYear = moment().year();

        const monthArray = [];

        // TODO aufpassen wenn wir im Januar 2024 sind dann müssen wir auch zurück nach 2023 rechnen
        for(let i=thisMonth; i<=actualMonth; i++) {
            monthArray.push({
                month: (i+1), 
                year: thisYear, 
                optionName: monthNameArray[i] + ' ' + thisYear
            });
        }

        return monthArray;
    };

    /**
     * Send the form data to the backend and show the analytics data
     */
    const fetchStats = async () => {
        let response = await fetch(BackendRoutes.stats.analyse, {
            method: 'POST',
            headers: getHeader(),
            body: JSON.stringify(props.formData)
        });

        let responseData: IAnalyseResponse = await response.json();

        if(responseData.success) {
            props.setGridData({
                data: responseData.grid.data,
                columns: responseData.grid.columnData.columns,
                columnExtensions: responseData.grid.columnData.columnExtensions,
                totalCount: responseData.grid.data.length
            });
        }
    }

    /**
     * Download a file from the backend with fetch
     * 
     * @see https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
     */
    const downloadStats = () => {
        // change the url and extension depending on what kind of format the 
        // file should have
        let url = BackendRoutes.stats.pdf;
        let ext = '.pdf';
        if(props.formData.format === 'CSV') {
            url = BackendRoutes.stats.csv;
            ext = '.csv';
        }

        // the filename for the file depending on the statsType of the file
        let fileName = '';
        if(props.formData.statsType === 'success_analytics') {
            fileName = dictionary.stats.successAnalysis;
        }
        else if(props.formData.statsType === 'quality_analytics') {
            fileName = dictionary.stats.qualityAnalysis;
        }
        else if(props.formData.statsType === 'rawdata') {
            fileName = dictionary.stats.rawData;
        }
        
        // add the actual date to the filename
        fileName += ' ' + moment().format("YYYY-MM-DD HH:mm:ss") + ext;

        // get the data from the backend
        fetch(url, {
            method: 'POST',
            headers: getHeader(),
            body: JSON.stringify(props.formData)
        })
        // convert the data into a blob
        .then(resp => resp.blob())
        .then(blob => {
            // create a blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            // file name
            link.download = fileName;
            // append to the html page
            document.body.appendChild(link);
            // force download
            link.click();

            // clean up and remove the link
            window.URL.revokeObjectURL(url);
            link?.parentNode?.removeChild(link);
        });
    }

    /**
     * Start the analysis of the entered data
     */
    const handleSubmit = () => {
        if(props.formData.selectedNumbers.length > 0) {
            if(props.formData.format === 'web') {
                if(props.isCollapsed) {
                    props.setIsCollapsed(false);
                }
                else {
                    props.setIsCollapsed(true);
                }

                fetchStats();
            }
            else {
                downloadStats();
            }
        }
        else {
            setDialogOpen(true);
        }
    };

    return (
        <Grid style={{width: '100%'}} container rowSpacing={0} columnSpacing={1}>
            <Grid item xs={5}>
                <FormLabel id="statsTypeRadios">{dictionary.stats.statsType}:</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="statsTypeRadios"
                    value={props.formData.statsType}
                    name="statsType"
                    onChange={handleStatsTypeChange}
                >
                    <FormControlLabel value="success_analytics" control={<Radio />} label={dictionary.stats.successAnalysis}/>
                    <FormControlLabel value="quality_analytics" control={<Radio />} label={dictionary.stats.qualityAnalysis} />
                    <FormControlLabel value="rawdata" control={<Radio />} label={dictionary.stats.rawData} />
                </RadioGroup>
                <br/>
                {
                    props.showNumberSelection ?
                    <Autocomplete
                        id="number_selection"
                        options={props.numbers}
                        sx={{ width: 300 }}
                        noOptionsText={dictionary.noOptionsText}
                        openText={dictionary.openText}
                        closeText={dictionary.closeText}
                        clearText={dictionary.clearText}
                        getOptionLabel={(option) => {
                            if(option.rangeStart !== '' && option.rangeEnd !== '') {
                                return option.number + ' (' + option.rangeStart + ' - ' +  option.rangeEnd + ')';
                            }

                            return option.number;
                        }}
                        renderInput={(params) => <TextField {...params} label={dictionary.stats.phoneNumbers}/>}
                        onChange={handleNumberSelection}
                    />
                    :
                    <FormLabel id="statsTypeRadios">{dictionary.stats.phoneNumbers}: {props.numbers[0].number}</FormLabel>
                }
            </Grid>
            <Grid item xs={4}>
                <FormLabel id="timeSelection">{dictionary.stats.period}:</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="timeSelection"
                    defaultValue="day"
                    name="timeSelection"
                    onChange={handleTimeSelectionChange}
                >
                    <FormControlLabel value="day" control={<Radio />} label={dictionary.stats.day} />
                    <FormControlLabel value="calendarweek" control={<Radio />} label={dictionary.stats.calendarWeek} />
                    <FormControlLabel value="month" control={<Radio />} label={dictionary.stats.month} />
                </RadioGroup>
                <br />
                {
                    showPicker.date && 
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Datum"
                            inputFormat="dd.MM.yyyy"
                            value={props.formData.date}
                            minDate={new Date(moment().subtract(90, 'days').toString()).toDateString()}
                            maxDate={new Date(moment().toString()).toDateString()}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={handleDaySelection}
                        />
                    </LocalizationProvider>
                }
                {
                    showPicker.week &&
                    <Autocomplete
                        id="week_selection"
                        options={buildCalendarWeekData()}
                        sx={{ width: 300 }}
                        value={props.formData.week.selectedValue}
                        isOptionEqualToValue={(option, value) => option.week === value.week && option.year === value.year}
                        renderInput={(params) => <TextField {...params} label="Kalenderwoche" />}
                        getOptionLabel={(option) => option.optionName }
                        onChange={handleWeekSelection}
                    />
                }
                {
                    showPicker.month &&
                    <Autocomplete
                        id="month_selection"
                        options={buildMonthData()}
                        sx={{ width: 300 }}
                        value={props.formData.month.selectedValue}
                        isOptionEqualToValue={(option, value) => option.month === value.month && option.year === value.year}
                        renderInput={(params) => <TextField {...params} label="Monat" />}
                        getOptionLabel={(option) => option.optionName }
                        onChange={handleMonthSelection}
                    />
                }
            </Grid>
            <Grid item xs={3}>
                <FormLabel id="formatSelection">Format:</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="formatSelection"
                    value={props.formData.format}
                    name="formatSelection"
                    onChange={handleFormatSelection}
                >
                    <FormControlLabel value="web" control={<Radio />} label="Web" />
                    <FormControlLabel value="PDF" control={<Radio disabled={!allowPDF} />} label="PDF" />
                    <FormControlLabel value="CSV" control={<Radio disabled={!allowCSV} />} label="CSV" />
                </RadioGroup>
                <br />
                <Grid container item xs={12} justifyContent="flex-end">
                    <Button variant="contained" onClick={handleSubmit} style={{marginTop: '10px'}}>{dictionary.stats.evalulateButton}</Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <br />
                {
                    props.fieldsetData.length > 0 &&
                    <FieldSet 
                        legendText={dictionary.stats.selectedNumbers}
                        width='100%'
                    >
                    {
                        props.fieldsetData.map((fieldset) => {
                            return <StatisticsNumber 
                                key={fieldset.key} 
                                data={fieldset} 
                                completeData={props.fieldsetData} 
                                setFieldsetData={props.setFieldsetData}
                                formData={props.formData}
                                setFormData={props.setFormData}
                                isRemoveable={fieldset.isRemoveable}
                            />;
                        })
                    }
                    </FieldSet>
                }
            </Grid>
            <AlertDialog 
                open={dialogOpen} 
                message={{
                    header:  dictionary.errorText.checkFrom,
                    main: dictionary.errorText.formNotValid
                }}
                handleClose={() => setDialogOpen(false)} 
            />
        </Grid>
    );
}

export default AnalysisSettings;