import React from "react";
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder
} from "@devexpress/dx-react-core";
import { isGroupTableRow } from "@devexpress/dx-grid-core";

// @see: https://github.com/DevExpress/devextreme-reactive/issues/1705
export const ItemCounter = () => (
    <Plugin name="ItemCounter">
        <Template
            name="tableCell"
            predicate={({ tableRow }) => isGroupTableRow(tableRow)}
        >
            {params => (
                <TemplateConnector>
                    {({ getCollapsedRows }) => {
                        const updatedParams = {
                            ...params,
                            tableRow: {
                                ...params.tableRow,
                                row: {
                                    ...params.tableRow.row,
                                    collapsedRows: getCollapsedRows(params.tableRow.row) || []
                                }
                            }
                        };
                        return <TemplatePlaceholder params={updatedParams} />;
                    }}
                </TemplateConnector>
            )}
        </Template>
    </Plugin>
);