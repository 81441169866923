import { Box, Stack, Typography } from "@mui/material";
import { isNotNullOrUndefined } from "../../common/functions";
import SimpleFieldSet from "../../components/SimpleFieldSet";
import { ILocation } from "../../interfaces/customer/ILocation";
import { useOmTranslation } from "../../translations/OmTranslationContext";

interface ISelectedCustomerDetail {
    selectedCustomer: ILocation | null;
    selectedLocation: ILocation | null;
}

const SelectedCustomerDetail = (props: ISelectedCustomerDetail) => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    //#endregion Component state variables

    //#region Fetch requests
    //#endregion Fetch requests

    //#region Component effects
    //#endregion Component effects

    //#region Event handlers
    //#endregion Event handlers

    //#region Markup helper variables
    //#endregion Markup helper variables

    //#region Markup
    return (
        <Box sx={{}}>
            <Stack sx={{ minHeight: "100px" /* marginTop: "15px" */ }} direction="row">
                <Stack sx={{ width: "50%" }} direction="column">
                    <SimpleFieldSet legendText={dictionary.customer.selectedCustomer} size="small">
                        <Stack alignItems="stretch" direction={"column"}>
                            <Typography variant="body1">
                                {props.selectedCustomer?.customerName
                                    ? props.selectedCustomer?.customerName +
                                      (props.selectedCustomer?.erpnumber
                                          ? ` - (${props.selectedCustomer?.erpnumber})`
                                          : "")
                                    : ""}
                            </Typography>
                            <Typography variant="body1">
                                {props.selectedCustomer?.street}
                            </Typography>
                            <Typography variant="body1">
                                {props.selectedCustomer?.town != undefined &&
                                    props.selectedCustomer?.zipcode +
                                        (props.selectedCustomer?.town != undefined
                                            ? ` - ${props.selectedCustomer?.town}`
                                            : "")}
                            </Typography>
                            <Typography variant="body1">
                                {props.selectedCustomer?.country}
                            </Typography>
                        </Stack>
                    </SimpleFieldSet>
                </Stack>
                <Stack sx={{ width: "50%" }} direction="column">
                    <SimpleFieldSet legendText={dictionary.location.selectedLocation} size="small">
                        <Stack alignItems="stretch" direction={"column"}>
                            <Typography variant="body1">
                                {!isNotNullOrUndefined(props.selectedLocation)
                                    ? props.selectedCustomer?.customerName
                                        ? props.selectedCustomer?.customerName +
                                          (props.selectedCustomer?.erpnumber
                                              ? ` - (${props.selectedCustomer?.erpnumber})`
                                              : "")
                                        : ""
                                    : props.selectedLocation?.customerName
                                    ? props.selectedLocation?.customerName +
                                      (props.selectedLocation?.erpnumber
                                          ? ` - (${props.selectedLocation?.erpnumber})`
                                          : "")
                                    : ""}
                            </Typography>
                            <Typography variant="body1">
                                {!isNotNullOrUndefined(props.selectedLocation)
                                    ? props.selectedCustomer?.street
                                    : props.selectedLocation?.street}
                            </Typography>
                            <Typography variant="body1">
                                {!isNotNullOrUndefined(props.selectedLocation)
                                    ? props.selectedCustomer?.town != undefined &&
                                      props.selectedCustomer?.zipcode +
                                          (props.selectedCustomer?.town != undefined
                                              ? ` - ${props.selectedCustomer?.town}`
                                              : "")
                                    : props.selectedLocation?.zipcode +
                                      (props.selectedLocation?.town != undefined
                                          ? ` - ${props.selectedLocation?.town}`
                                          : "")}
                            </Typography>
                            <Typography variant="body1">
                                {!isNotNullOrUndefined(props.selectedLocation)
                                    ? props.selectedCustomer?.country
                                    : props.selectedLocation?.country}
                            </Typography>
                        </Stack>
                    </SimpleFieldSet>
                </Stack>
            </Stack>
        </Box>
    );
    //#endregion Markup
};

export default SelectedCustomerDetail;
