import { useEffect, useState } from 'react';
import { generateKey } from '../common/functions';
import { AppDispatch } from '../common/store';
import { formsActions } from '../features/forms/formsSlice';
import { FieldName, FormName, initialFormField } from '../features/forms/IFormParams';
import { initialFieldOptionData } from '../interfaces/IFieldOption';

export interface IChangeHandlerProps
{
    event: any;
    newValue: any;
    callback?: (event: any, newValue: any) => void;
}

/* export interface IFormProps<Type>
{
    fieldName?: FieldName | any;
    formName?: FormName | any;
    validationCallback: (value: Type) => boolean;
}; */

export interface IFormProps
{
    fieldName?: FieldName | any;
    formName?: FormName | any;
    validationCallback: (value: any) => boolean;
};

//function useAutocomplete<Type>(props: IFormProps<Type>)
function useAutocomplete<Type>(props: IFormProps)
{
    const [inputId, setInputId] = useState("");
    const [enteredValue, setEnteredValue] = useState<Type>(initialFieldOptionData as Type);
    const [isTouched, setIsTouched] = useState(false);

    const valueIsValid = props.validationCallback(enteredValue);
    const hasError = !valueIsValid && isTouched;

    useEffect(() =>
    {
        if (inputId === "")
        {
            const key = generateKey(12);
            if (key !== "")
            {
                setInputId(key);
            }
        }
        return () =>
        {
            if (inputId.length > 0 && props.fieldName && props.fieldName !== FieldName.none && props.formName && props.formName !== FormName.none)
            {
                let tempField1 = { ...initialFormField };
                tempField1.id = inputId;
                AppDispatch(formsActions.UnsetField(tempField1));
            }
        };
    }, []);


    useEffect(() =>
    {
        if (inputId !== "" && props.fieldName && props.fieldName !== FieldName.none && props.formName && props.formName !== FormName.none)
        {
            let tempField = { ...initialFormField };
            tempField.id = inputId;
            tempField.fieldName = props.fieldName;
            tempField.formName = props.formName;
            tempField.hasError = hasError;
            tempField.isValid = valueIsValid;
            tempField.value = enteredValue;
            AppDispatch(formsActions.SetField(tempField));
        }
    }, [enteredValue, valueIsValid, hasError, inputId]);

    function valueChangeHandler(props: IChangeHandlerProps)
    {
        setEnteredValue(props.newValue);
        if (props.callback)
        {
            props.callback(props.event, props.newValue);
        }
    };

    const inputBlurHandler = (event: any, callback?: (event: any) => void) =>
    {
        if (callback)
        {
            callback(event);
        }
        setIsTouched(true);
    };

    const reset = () =>
    {
        setEnteredValue(initialFieldOptionData as Type);
        setIsTouched(false);
    };

    return {
        inputId,
        value: enteredValue,
        setValue: setEnteredValue,
        isValid: valueIsValid,
        hasError,
        valueChangeHandler,
        inputBlurHandler,
        reset
    };
};

export default useAutocomplete;
