import { useEffect } from "react";
import useExitPrompt from "../../../hooks/useExitPrompt";
import { useBeforeunload, Beforeunload } from "react-beforeunload";

type Props = {
    children?: React.ReactNode;
};
export default function OfferExitConfirmation({ children }: Props) {
    /*    const [showExitPrompt, setShowExitPrompt] = useExitPrompt(
        false,
        "wollen Sie wirklich schließen?"
    );

    const handleClick = (e: any) => {
        e.preventDefault();
        setShowExitPrompt(!showExitPrompt);
    };

    //NOTE: this similar to componentWillUnmount()
    useEffect(() => {
        setShowExitPrompt(true);
        return () => {
            setShowExitPrompt(false);
        };
    }, []); */

    return <>{children}</>;
}
