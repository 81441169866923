import { store } from "../../common/store";
import { FilterOption } from "../../interfaces/IFieldOption";
import { IOrderBy } from "../../interfaces/offer/IOfferState";
import { getQueryParams, removeQueryParam, setQueryParam } from "./queryParams";

enum ServerOrderBy
{
    Default = 0,
    OfferNumber = 1,
    OfferType = 2,
    OfferStatus = 3,
    CustomerName = 4,
    CustomerNumber = 5,
    CreatedBy = 6,
    CreatedAt = 7,
    Title = 8
}

const mapOrderBy = (orderBy: IOrderBy) =>
{
    switch (orderBy.field)
    {
        case "createDate":
            return ServerOrderBy.CreatedAt;
            break;
        case "createUser":
            return ServerOrderBy.CreatedBy;
            break;
        case "customer":
            return ServerOrderBy.CustomerName;
            break;
        case "customerNumber":
            return ServerOrderBy.CustomerNumber;
            break;
        case "offerNumber":
            return ServerOrderBy.OfferNumber;
            break;
        case "offerStatus":
            return ServerOrderBy.OfferStatus;
            break;
        case "offerType":
            return ServerOrderBy.OfferType;
            break;
        case "title":
            return ServerOrderBy.Title;
            break;

        default:
            return ServerOrderBy.Default;
            break;
    }
};

export const getOffersQueryParams = (): {} =>
{
    return getQueryParams(() =>
    {
        const offersState = store.getState().offerList;
        if (offersState.offerTypeFilterOption.checked && offersState.offerTypeFilterOption.id > 0)
        {
            setQueryParam("offertypeid", offersState.offerTypeFilterOption.id);
        } else
        {
            removeQueryParam("offertypeid");
        }
        if (
            offersState.offerStatusFilterOption.checked &&
            offersState.offerStatusFilterOption.id > 0
        )
        {
            setQueryParam("offerstatusid", offersState.offerStatusFilterOption.id);
        } else
        {
            removeQueryParam("offerstatusid");
        }
        if (
            offersState.offerCreateUserFilterOption.checked &&
            offersState.offerCreateUserFilterOption.id > 0
        )
        {
            setQueryParam(
                "createdbyuserid",
                offersState.offerCreateUserFilterOption.id
            );
        } else
        {
            removeQueryParam("createdbyuserid");
        }

        if (
            offersState.offerCreateUserFilterOption.checked &&
            offersState.offerCreateUserFilterOption.id == -1
        )
        {
            setQueryParam(
                "showallusers",
                true
            );
        } else
        {
            removeQueryParam("showallusers");
        }

        if (
            offersState.offerSalesUserFilterOption.checked &&
            offersState.offerSalesUserFilterOption.id > 0
        )
        {
            setQueryParam(
                "salesuserid",
                offersState.offerSalesUserFilterOption.id
            );
        } else
        {
            removeQueryParam("salesuserid");
        }

        if (offersState.offerSearchOption.filterOption == FilterOption.offerNumber && offersState.offerSearchOption.searchText.length > 1)
        {
            setQueryParam("offernumber", offersState.offerSearchOption.searchText);
        }
        else
        {
            removeQueryParam("offernumber");
        }

        if (offersState.offerSearchOption.filterOption == FilterOption.customer && offersState.offerSearchOption.searchText.length > 1)
        {
            setQueryParam("customername", offersState.offerSearchOption.searchText);
        }
        else
        {
            removeQueryParam("customername");
        }

        if (offersState.offerSearchOption.filterOption == FilterOption.customerNumber && offersState.offerSearchOption.searchText.length > 1)
        {
            setQueryParam("customernumber", offersState.offerSearchOption.searchText);
        }
        else
        {
            removeQueryParam("customernumber");
        }

        if (offersState.pagination.pageNumber)
        {
            setQueryParam("page", offersState.pagination.pageNumber);
        }
        else
        {
            removeQueryParam("page");
        }

        if (offersState.pagination.rowsPerPage)
        {
            setQueryParam("rowsperpage", offersState.pagination.rowsPerPage);
        }
        else
        {
            removeQueryParam("rowsperpage");
        }

        if (offersState.pagination.orderBy !== undefined && offersState.pagination.orderBy.field !== undefined)
        {
            setQueryParam("orderby", mapOrderBy(offersState.pagination.orderBy));
            setQueryParam("orderbydesc", offersState.pagination.orderBy.sort === "desc" ? "true" : "false");
        }
        else
        {
            removeQueryParam("orderby");
            removeQueryParam("orderbydesc");
        }
    });
}; 