import { 
    IconButton, Switch, FormControlLabel, FormLabel, Box
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FieldSet from './FieldSet';
import { useLanguage } from '../../Contexts/LanguageContext';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import IBadgeFieldSet from '../../Interfaces/Props/IBadgeFieldSet'

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});

const BadgeFieldSet = (props: IBadgeFieldSet) => {
    const { dictionary } = useLanguage();

    return (
        <FieldSet
            legendText={dictionary.userSettings.infoForDocuments}
            width='100%'
        >
            <Box sx={{display: 'inline'}}>
                <CustomWidthTooltip arrow title={dictionary.badgeDescription} placement='bottom' enterDelay={300}>
                    <FormControlLabel 
                        control={
                            <Switch 
                                onChange={(event) => props.handleChange(event, 'showBadgeCounter')}
                                checked={props.formValues.showBadgeCounter}
                            />
                        }
                        label={dictionary.userSettings.badgeCounter}
                    />
                </CustomWidthTooltip>
                {
                    props.showReset !== undefined && props.showReset ?
                    <Box sx={{display: 'inline'}}>
                        <Tooltip title={dictionary.userSettings.resetBadgeCounter} placement='bottom' enterDelay={300}>
                            <span>
                                <IconButton style={{marginRight: 10}} onClick={props.resetBadgeCount}>
                                    <RestartAltIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        { 
                            props.resetTime !== '0000-00-00 00:00:00' ?
                            <FormLabel sx={{fontSize: '10px'}}>{dictionary.userSettings.lastReset + props.resetTime}</FormLabel>
                            : null
                        }
                    </Box>
                    :
                    null
                }
            </Box>
            <br/>
            <FormControlLabel 
                control={
                    <Switch 
                        onChange={(event) => props.handleChange(event, 'sendNewFileNotification')}
                        checked={props.formValues.sendNewFileNotification}
                    />
                }
                label={dictionary.userSettings.emailNotification}
            />
        </FieldSet>
    );
}

export default BadgeFieldSet;