import { SxProps, Theme } from '@mui/material/styles';

const menuButton: SxProps<Theme> = {
    position: 'fixed',
    border: 'none',
    outline: 'none',
    width: '3.333rem',
    height: '3.333rem',
    cursor: 'pointer',
    right: '5%',
    margin: '1rem 0rem 2.5rem 1rem',
    transition: 'linear 0.2s all',
    bottom: 0,
    zIndex: 4,
    backgroundColor: theme => theme.palette.primary.main,
    color: theme => theme.palette.primary.contrastText,
    '> span': {
        height: '0.17rem',
        background: 'currentColor',
        position: 'absolute',
        left: '0.666rem',
        right: '0.666rem',
        top: '50%',
        animation: 'forwards linear 0.4s',
        '&:nth-of-type(2)': {
            transform: 'translateY(-50%)',
            transition: 'linear 0.01s all',
            transitionDelay: '0.2s',
            height: '0.15rem'
        }
    },
    '&:hover': {
        background: theme => theme.palette.primary.main
    }
}

const MenuButtonCloseAnimation: SxProps<Theme> = {
    boxShadow: '0 0 1.11rem rgba(0, 0, 0, 0.2)',
    '>span': { 
        '&:first-of-type': {
            animationName: 'TopBarClose'
        },
        '&:last-child': {
            animationName: 'BottomBarClose'
        }
    },
    '@keyframes TopBarClose': {
        '0%': {
            transform: 'translateY(-50%) rotate(45deg)',
            marginTop: 0
        },
        '50%': {
            transform: 'translateY(-50%)',
            marginTop: 0
        },
        '100%': {
            marginTop: '-0.6rem',
            transform: 'translateY(-50%)'
        }
    },
    '@keyframes BottomBarClose': {
        '0%': {
            transform: 'translateY(-50%) rotate(-45deg)',
            marginTop: 0
        },
        '50%': {
            transform: 'translateY(-50%)',
            marginTop: 0
        },
        '100%': {
            marginTop: '0.6rem',
            transform: 'translateY(-50%)'
        }
    }
}

const MenuButtonOpenAnimation: SxProps<Theme> = {
    '>span': {
        borderRadius: '1rem',
        '&:first-of-type': {
            'animationName': 'TopBarOpen'
        },
        '&:last-child': {
            'animationName': 'BottomBarOpen'
        },
        '&:nth-of-type(2)': {
            opacity: 0
        }
    },
    '@keyframes TopBarOpen': {
        '0%': {
            marginTop: '-0.6rem',
            transform: 'translateY(-50%)'
        },
        '50%': {
            marginTop: 0,
            transform: 'translateY(-50%)'
        },
        '100%': {
            transform: 'translateY(-50%) rotate(45deg)'
        }
    },
    '@keyframes BottomBarOpen': {
        '0%': {
            marginTop: '0.6rem',
            transform: 'translateY(-50%)'
        },
        '50%': {
            marginTop: 0,
            transform: 'translateY(-50%)'
        },
        '100%': {
            transform: 'translateY(-50%) rotate(-45deg)'
        }
    }
}

const blur: SxProps<Theme> = {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    background: 'rgba(255, 255, 255, 0.25)',
    boxShadow: '0 0.444 1.778rem 0 rgba(31, 38, 135, 0.37)',
    backdropFilter: 'blur(0.222rem)',
    webkitBackdropFilter: 'blur(0.222rem)',
    zIndex: 2
}

const menuContainer: SxProps<Theme> = {
    position: 'fixed',
    right: '5%',
    zIndex: 3,
    height: '21.55rem',
    transition: 'linear 0.3s all',
    background: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.primary.contrastText,
    'ul': {
        padding: '2rem',
        zIndex: 3,
        listStyleType: 'none',
        margin: 0,
        display: 'grid',
        alignItems: 'flex-start',
        transition: 'inherit',
        width: '27.27rem',
        gap: '2rem 1.3rem',
        '> li': {
            transition: 'inherit',
            position: 'relative'
        },
        'a': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            'svg': {
                width: '1.38rem',
                height: '1.38rem'
            }
        }
    }
}

const menuElement: SxProps<Theme> = {
    fontSize: '0.9rem',
    textAlign: 'center'
}

const menuClose: SxProps<Theme> = {
    bottom: '-21.55rem',
    '> ul': {
        gridTemplateColumns: 'repeat(4, 1fr)'
    },
    'li': {
        opacity: 0,
        top: '2rem',
        transitionDelay: '1s'
    }
}

const menuOpen: SxProps<Theme> = {
    bottom: 0,
    '> ul': {
        gridTemplateColumns: 'repeat(3, 1fr)'
    },
    'li': {
        top: 0,
        '&:nth-of-type(1)': {
            transitionDelay: '0.3s'
        },
        '&:nth-of-type(2)': {
            transitionDelay: '0.5s'
        },
        '&:nth-of-type(3)': {
            transitionDelay: '0.7s'
        },
        '&:nth-of-type(4)': {
            transitionDelay: '0.9s'
        },
        '&:nth-of-type(5)': {
            transitionDelay: '1.1s'
        },
        '&:nth-of-type(6)': {
            transitionDelay: '1.3s'
        },
        '&:nth-of-type(7)': {
            transitionDelay: '1.5s'
        },
        '&:nth-of-type(8)': {
            transitionDelay: '1.7s'
        },
        '&:nth-of-type(9)': {
            transitionDelay: '1.8s'
        },
        '&:nth-of-type(10)': {
            transitionDelay: '2.0s'
        }
    }
}

export {
    menuButton, MenuButtonCloseAnimation, MenuButtonOpenAnimation, blur,
    menuContainer, menuElement, menuClose, menuOpen
}