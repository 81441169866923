import OfferMakerSection from "../Offermaker/OfferMakerSection";
import { Provider } from "react-redux";
import { store } from "../Offermaker/common/store";

const OfferMakerPage = () => {
    return (
        <Provider store={store}>
            <OfferMakerSection />
        </Provider>
    );
};

export default OfferMakerPage;
