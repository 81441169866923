import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Grid, Button, Box } from '@mui/material';

import { useLanguage } from '../Contexts/LanguageContext';
import { useAuth } from '../Contexts/AuthContext';
import SelectField from '../Components/Form/SelectField';
import TextField from '../Components/Form/TextField';
import FieldSet from '../Components/Form/FieldSet';
import PageHeader from '../Components/Header/PageHeader';
import InfoDialog from '../Components/Dialog/InfoDialog';
import BackendRoutes from '../Constants/BackendRoutes';
import BadgeFieldSet from '../Components/Form/BadgeFieldSet';
import ISettingsFormValues from '../Interfaces/States/ISettingsFormValues';
import ISettingsPostData from '../Interfaces/ISettingsPostData';
import { pageMain, gridPage, loginForm } from '../Theme/LayoutDefinitions';

const SettingsPageMainSection = () => {
    const navigate = useNavigate();
    const { dictionary, changeLanguage } = useLanguage();
    const { currentUser, setCurrentUser, setCookie, deleteCookie, getHeader } = useAuth();
    const [disablePasswordFields, setDisablePasswordFields] = useState(true);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [resetTime, setResetTime] = useState(currentUser.lastBadgeCounterReset)
    const [infoMessage, setInfoMessage] = useState('');
    
    const [formChanged, setFormChanged] = useState({
        firstName: false,
        lastName: false,
        mobilePhoneNumber: false,
        oldPassword: false,
        password: false,
        repeatPassword: false,
        language: false,
        showBadgeCounter: false,
        sendNewFileNotification: false
    });

    let initBadgeCounter = currentUser.showBadgeCounter === 'Y' ? true : false;
    let initSendNewFileNotification = currentUser.sendNewFileNotification === 'Y' ? true : false;
    
    const [formValues, setFormValues] = useState<ISettingsFormValues>({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        mobilePhoneNumber: currentUser.mobilePhoneNumber,
        oldPassword: '',
        password: '',
        repeatPassword: '',
        showBadgeCounter: initBadgeCounter,
        sendNewFileNotification: initSendNewFileNotification,
        language: currentUser.language
    });

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        mobilePhoneNumber: '',
        oldPassword: '',
        password: '',
        repeatPassword: '',
        language: ''
    });

    const languageOptions = [
        {value: 'DE', label: dictionary.languages.german},
        {value: 'EN', label: dictionary.languages.english}
    ];

    const handleChange = (event: any, fieldName: string) => {
        let value = event.target.value;

        if(fieldName === 'showBadgeCounter' || fieldName === 'sendNewFileNotification') {
            value = event.target.checked;
        }

        setFormValues({
            ...formValues,
            [fieldName]: value
        });
        
        setFormChanged({
            ...formChanged,
            [fieldName]: true
        });
    }

    async function updateUser(postData: ISettingsPostData, doLogout: boolean) {
        const header = getHeader();

        const requestOptions = {
            method: 'POST', 
            headers: header,
            body: JSON.stringify(postData)
        };
        // fire the fetch request to the backend
        let response = await fetch(BackendRoutes.changeusersettings + '/' +  currentUser.userId, requestOptions);
        let responseData = await response.json();

        if(responseData.success) {
            const newUser = currentUser;
            newUser.language = formValues.language;
            newUser.firstName = formValues.firstName;
            newUser.lastName = formValues.lastName;
            newUser.mobilePhoneNumber = formValues.mobilePhoneNumber;
            
            newUser.sendNewFileNotification = formValues.sendNewFileNotification ? 'Y' : 'N';
            newUser.showBadgeCounter = formValues.showBadgeCounter ? 'Y' : 'N';

            setFormValues({
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                mobilePhoneNumber: formValues.mobilePhoneNumber,
                oldPassword: '',
                password: '',
                repeatPassword: '',
                language: formValues.language
            });

            if(doLogout) {
                deleteCookie('currentUser');
                setCurrentUser({});
                navigate('/');
            }
            else {
                setCurrentUser(newUser);
                setCookie('currentUser', newUser, 5);
                changeLanguage(formValues.language);
                window.location.reload();
            }
        }
        else {
            setInfoMessage(responseData.error);
            setOpenInfoDialog(true);
        }
    }

    const handleSubmit = () => {
        let postData: ISettingsPostData = {
            firstName: '',
            lastName: '',
            mobilePhoneNumber: '',
            oldPassword: '',
            password: '',
            repeatPassword: '',
            language: 'DE',
            sendNewFileNotification: 'N',
            showBadgeCounter: 'N'
        },
        doLogout = false;

        postData.firstName = formValues.firstName;
        postData.lastName = formValues.lastName;
        postData.mobilePhoneNumber = formValues.mobilePhoneNumber;
        postData.language = formValues.language;

        postData.sendNewFileNotification = formValues.sendNewFileNotification ? 'Y' : 'N';
        postData.showBadgeCounter = formValues.showBadgeCounter ? 'Y' : 'N';

        if(disablePasswordFields) {
            delete postData.oldPassword;
            delete postData.password;
            delete postData.repeatPassword;
            doLogout = false;
        }
        else {
            postData.oldPassword = formValues.oldPassword;
            postData.password = formValues.password;
            postData.repeatPassword = formValues.repeatPassword;
            doLogout = true;
        }

        updateUser(postData, doLogout);
    };

    useEffect(() => {
        // eslint-disable-next-line
        const 
            phoneNumRegEx   = /^[0-9]+$/,
            // eslint-disable-next-line
            passwordRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\@\.\,\?\!\_\-\+\%\/\#\&\%\*])[\da-zA-Z\@\.\,\?\!\_\-\+\%\/\#\&\%\*\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]{12,20}$/;

        if(formChanged.firstName) {
            if(formValues.firstName === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    firstName: dictionary.errorText.empytFirstName
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    firstName: ''
                }));
            }
        }

        if(formChanged.lastName) {
            if(formValues.lastName === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    lastName: dictionary.errorText.emptyLastName
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    lastName: ''
                }));
            }
        }

        if(formChanged.mobilePhoneNumber) {
            if(formValues.mobilePhoneNumber === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    mobilePhoneNumber: dictionary.errorText.noEmptyPhonenumber
                }));
            }
            else if(formValues.mobilePhoneNumber !== '' && !phoneNumRegEx.test(formValues.mobilePhoneNumber)) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    mobilePhoneNumber: dictionary.errorText.noValidPhonenumber
                }));
            }
            else if(
                formValues.mobilePhoneNumber !== '' && !formValues.mobilePhoneNumber.startsWith('0049') &&
                formValues.mobilePhoneNumber[0] === '0' && formValues.mobilePhoneNumber[1] === '0'
            ) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    mobilePhoneNumber: dictionary.otp.onlyGermanNumbers
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    mobilePhoneNumber: ''
                }));
            }
        }

        if(formChanged.oldPassword) {
            if(formValues.oldPassword === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    oldPassword: dictionary.errorText.emptyOldPassword
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    oldPassword: ''
                }));
            }
        }

        if(formChanged.password) {
            if(formValues.password === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    password: dictionary.errorText.emptyPassword
                }));
            }
            else if(formValues.password && !passwordRegEx.test(formValues.password)) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    password: dictionary.errorText.passwordWeak
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    password: ''
                }));
            }
        }

        if(formChanged.repeatPassword) {
            if(formValues.repeatPassword === '') {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: dictionary.errorText.emptyRepeatPassword
                }));
            }
            else if(formValues.repeatPassword && !passwordRegEx.test(formValues.repeatPassword)) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: dictionary.errorText.passwordWeak
                }));
            }
            else if(formValues.repeatPassword !== formValues.password) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: dictionary.errorText.passwordCompareError
                }));
            }
            else {
                setFormErrors((prevState) => ({
                    ...prevState,
                    repeatPassword: ''
                }));
            }
        }
    }, [setFormErrors, formValues, dictionary, formChanged]);

    const resetBadgeCount = async () => {
        const header = getHeader();
        const requestOptions = {
            method: 'POST', 
            headers: header
        };

        let response = await fetch(BackendRoutes.resetBadgeCounter + '/' +  currentUser.userId, requestOptions);
        let responseData = await response.json();

        if(responseData.success) {
            currentUser.lastBadgeCounterReset = responseData.resetTime;
            setResetTime(responseData.resetTime);
        }
    }

    return (
        <Box component='main' sx={pageMain}>
            <PageHeader 
                headerTitle={dictionary.userSettings.userSettingsHeader}
                showAddButtonAndSearch={false}
            />
            <Box sx={gridPage}>
                <Box sx={loginForm}>
                    <Grid style={{width: '100%'}} container rowSpacing={0} columnSpacing={1}>
                        <Grid item xs={12}>
                            <Grid container columnSpacing={1} >
                                <Grid item xs={4}>
                                    <TextField
                                        required={true}
                                        inputId='firstname'
                                        title={dictionary.userForm.firstName}
                                        value={formValues.firstName}
                                        errorText={formErrors.firstName}
                                        onChange={(event) => handleChange(event, 'firstName')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required={true}
                                        inputId='lastname'
                                        title={dictionary.userForm.lastName}
                                        value={formValues.lastName}
                                        errorText={formErrors.lastName}
                                        onChange={(event) => handleChange(event, 'lastName')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        inputId='mobilePhoneNumber'
                                        required={true}
                                        title={dictionary.userForm.phoneNumber}
                                        value={formValues.mobilePhoneNumber}
                                        errorText={formErrors.mobilePhoneNumber}
                                        onChange={(event) => handleChange(event, 'mobilePhoneNumber')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectField 
                                required={true}
                                inputId='language'
                                title={dictionary.userForm.language}
                                value={formValues.language}
                                errorText={formErrors.language}
                                options={languageOptions}
                                onChange={(event: any) => handleChange(event, 'language')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BadgeFieldSet 
                                showReset={true}
                                resetTime={resetTime}
                                formValues={formValues}
                                handleChange={handleChange}
                                resetBadgeCount={resetBadgeCount}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FieldSet
                                legendText={dictionary.userSettings.setNewPassword}
                                checkboxToggle={true}
                                isCollapsible={true}
                                isCollapsed={true}
                                width='100%'
                                checkboxName='passwordSelection'
                                handleCheckboxChange={() => {
                                    setDisablePasswordFields(!disablePasswordFields);
                                }}
                            >
                                <Grid container rowSpacing={0} columnSpacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            inputId='oldPassword'
                                            autoComplete='new-password'
                                            title={dictionary.userSettings.oldPassword}
                                            value={formValues.oldPassword}
                                            errorText={formErrors.oldPassword}
                                            disabled={disablePasswordFields}
                                            type='password'
                                            onChange={(event: any) => handleChange(event, 'oldPassword')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            inputId='password'
                                            autoComplete='new-password'
                                            title={dictionary.userSettings.newPassword}
                                            value={formValues.password}
                                            errorText={formErrors.password}
                                            disabled={disablePasswordFields}
                                            type='password'
                                            onChange={(event: any) => handleChange(event, 'password')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            inputId='repeatPassword'
                                            autoComplete='new-password'
                                            title={dictionary.userSettings.repeatNewPassword}
                                            value={formValues.repeatPassword}
                                            errorText={formErrors.repeatPassword}
                                            disabled={disablePasswordFields}
                                            type='password'
                                            onChange={(event: any) => handleChange(event, 'repeatPassword')}
                                        />
                                    </Grid>
                                </Grid>
                            </FieldSet>
                        </Grid>
                        <Grid container item xs={12} justifyContent="flex-end">
                            <Button variant="contained" onClick={handleSubmit} style={{marginTop: '15px'}}>{dictionary.userForm.save}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <InfoDialog 
                open={openInfoDialog}
                title={'Status'}
                message={infoMessage}
                handleClose={() => setOpenInfoDialog(false)} 
            />
        </Box>
    )
}

export default SettingsPageMainSection;
