import { createRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { OmPageHeader } from "../../common/imports";
import { useComponentHeight } from "../../common/useComponentHeight";
import { uiRoutes } from "../../configuration/offerMakerRoutes";
import { useOmTranslation } from "../../translations/OmTranslationContext";
import { OffersContextProvider, useOffersContext } from "./OffersContext";
import OffersTable from "./OffersTable";

const OffersContainer = () => {
    const { dictionary } = useOmTranslation();
    const location = useLocation();
    const offersContext = useOffersContext();
    const ref = createRef<HTMLElement>();
    const navigate = useNavigate();
    const height = useComponentHeight(ref);
    const addClick = (event: any) => {
        navigate(uiRoutes.newOffer);
    };

    return (
        <OffersContextProvider>
            <main
                ref={ref}
                style={{
                    width: "100%",
                    height: "90%",
                    margin: "50px",
                }}
            >
                <div style={{ width: "100%" }}>
                    <OmPageHeader
                        headerTitle={dictionary.offer.newOffer}
                        addClick={addClick}
                        showAddButtonAndSearch={true}
                    />
                </div>
                <div style={{ height: "550px", width: "100%" }}>
                    <OffersTable parentRef={ref} />
                </div>
            </main>
        </OffersContextProvider>
    );
};

export default OffersContainer;
