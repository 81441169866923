import { ThemeOptions } from '@mui/material/styles';

export const byonLight: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#BC1123',
            light: '#C9404F',
            dark: '#830B18',
            contrastText: '#fff'
        },
        secondary: {
            main: '#F50057',
            light: '#F73378',
            dark: '#AB003C',
            contrastText: '#fff'
        },
        error: {
            main: '#F44336',
            light: '#E57373',
            dark: '#D32F2F',
            contrastText: '#fff'
        },
        warning: {
            main: '#FF9800',
            light: '#FFB74D',
            dark: '#F57C00',
            contrastText: '#000000DE'
        },
        info: {
            main: '#2196F3',
            light: '#64B5F6',
            dark: '#1976D2',
            contrastText: '#fff'
        },
        success: {
            main: '#4CAF50',
            light: '#81C784',
            dark: '#388E3C',
            contrastText: '#000000DE'
        },
        text: {
            primary: '#000000DE',
            secondary: '#0000008A',
            disabled: '#00000061'
        },
        AppCustomColors: {
            backgroundColor: '#ededf7'
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    }
};

export const byonDark: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#BC1123',
            light: '#C9404F',
            dark: '#830B18',
            contrastText: '#fff'
        },
        secondary: {
            main: '#F50057',
            light: '#F73378',
            dark: '#AB003C',
            contrastText: '#fff'
        },
        error: {
            main: '#F44336',
            light: '#E57373',
            dark: '#D32F2F',
            contrastText: '#fff'
        },
        warning: {
            main: '#FF9800',
            light: '#FFB74D',
            dark: '#F57C00',
            contrastText: '#000000DE'
        },
        info: {
            main: '#2196F3',
            light: '#64B5F6',
            dark: '#1976D2',
            contrastText: '#fff'
        },
        success: {
            main: '#4CAF50',
            light: '#81C784',
            dark: '#388E3C',
            contrastText: '#000000DE'
        },
        text: {
            primary: '#fff',
            secondary: '#ffffffB3',
            disabled: '#ffffff80'
        },
        AppCustomColors: {
            backgroundColor: '#ededf7'
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
}

export const consenseLight: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#EB6705',
            light: '#EF8537',
            dark: '#A44803',
            contrastText: '#fff'
        },
        secondary: {
            main: '#F50057',
            light: '#F73378',
            dark: '#AB003C',
            contrastText: '#fff'
        },
        error: {
            main: '#F44336',
            light: '#E57373',
            dark: '#D32F2F',
            contrastText: '#fff'
        },
        warning: {
            main: '#FF9800',
            light: '#FFB74D',
            dark: '#F57C00',
            contrastText: '#000000DE'
        },
        info: {
            main: '#2196F3',
            light: '#64B5F6',
            dark: '#1976D2',
            contrastText: '#fff'
        },
        success: {
            main: '#4CAF50',
            light: '#81C784',
            dark: '#388E3C',
            contrastText: '#000000DE'
        },
        text: {
            primary: '#000000DE',
            secondary: '#0000008A',
            disabled: '#00000061'
        },
        AppCustomColors: {
            backgroundColor: '#ededf7'
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    }
};

export const consenseDark: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#EB6705',
            light: '#EF8537',
            dark: '#A44803',
            contrastText: '#fff'
        },
        secondary: {
            main: '#F50057',
            light: '#F73378',
            dark: '#AB003C',
            contrastText: '#fff'
        },
        error: {
            main: '#F44336',
            light: '#E57373',
            dark: '#D32F2F',
            contrastText: '#fff'
        },
        warning: {
            main: '#FF9800',
            light: '#FFB74D',
            dark: '#F57C00',
            contrastText: '#000000DE'
        },
        info: {
            main: '#2196F3',
            light: '#64B5F6',
            dark: '#1976D2',
            contrastText: '#fff'
        },
        success: {
            main: '#4CAF50',
            light: '#81C784',
            dark: '#388E3C',
            contrastText: '#000000DE'
        },
        text: {
            primary: '#fff',
            secondary: '#ffffffB3',
            disabled: '#ffffff80'
        },
        AppCustomColors: {
            backgroundColor: '#ededf7'
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
}

export const espGroupLight: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#001336',
            light: '#B22052',
            dark: '#9B3255',
            contrastText: '#fff'
        },
        secondary: {
            main: '#F50057',
            light: '#F73378',
            dark: '#AB003C',
            contrastText: '#fff'
        },
        error: {
            main: '#F44336',
            light: '#E57373',
            dark: '#D32F2F',
            contrastText: '#fff'
        },
        warning: {
            main: '#FF9800',
            light: '#FFB74D',
            dark: '#F57C00',
            contrastText: '#000000DE'
        },
        info: {
            main: '#2196F3',
            light: '#64B5F6',
            dark: '#1976D2',
            contrastText: '#fff'
        },
        success: {
            main: '#4CAF50',
            light: '#81C784',
            dark: '#388E3C',
            contrastText: '#000000DE'
        },
        text: {
            primary: '#000000DE',
            secondary: '#0000008A',
            disabled: '#00000061'
        },
        AppCustomColors: {
            backgroundColor: '#ededf7'
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    }
};

export const espGroupDark: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#001336',
            light: '#EF8537',
            dark: '#A44803',
            contrastText: '#fff'
        },
        secondary: {
            main: '#F50057',
            light: '#F73378',
            dark: '#AB003C',
            contrastText: '#fff'
        },
        error: {
            main: '#F44336',
            light: '#E57373',
            dark: '#D32F2F',
            contrastText: '#fff'
        },
        warning: {
            main: '#FF9800',
            light: '#FFB74D',
            dark: '#F57C00',
            contrastText: '#000000DE'
        },
        info: {
            main: '#2196F3',
            light: '#64B5F6',
            dark: '#1976D2',
            contrastText: '#fff'
        },
        success: {
            main: '#4CAF50',
            light: '#81C784',
            dark: '#388E3C',
            contrastText: '#000000DE'
        },
        text: {
            primary: '#fff',
            secondary: '#ffffffB3',
            disabled: '#ffffff80'
        },
        AppCustomColors: {
            backgroundColor: '#ededf7'
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
}