import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import { 
    Dialog, Autocomplete, DialogTitle, DialogActions,
    DialogContent, Button, TextField, ListSubheader,
    Typography, useMediaQuery, autocompleteClasses,
    Popper, Checkbox, Box, Chip, FormControlLabel, Switch
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { useLanguage } from '../../Contexts/LanguageContext';
import { useAuth } from '../../Contexts/AuthContext';
import BackendRoutes from '../../Constants/BackendRoutes';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING = 8; // px

// @ts-ignore
function renderRow(props) {
    const { data, index, style } = props;

    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }
 
    return (
        <Typography component="li" {...dataSet[0]} key={dataSet[0].id} noWrap style={inlineStyle}>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={dataSet[2]}
            />
            <Box
                component="span"
                sx={{
                    width: 14,
                    height: 14,
                    flexShrink: 0,
                    borderRadius: '3px',
                    mr: 1,
                    mt: '2px',
                    fontSize: '13px',
                    textAlign: 'center',
                    color: 'white',
                    verticalAlign: 'text-top'
                }}
                style={{ backgroundColor: dataSet[1].color }}
            >
                {dataSet[1].isPartner ? 'P' : ''}
            </Box>
            {dataSet[1].label}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>((props, ref) =>  {
    // @ts-ignore
    const { children, ...other } = props;
    // @ts-ignore
    const itemData = [];

    // @ts-ignore
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
  
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;
  
    // @ts-ignore
    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }
  
        return itemSize;
    };
  
    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        // @ts-ignore
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };
  
    const gridRef = useResetCache(itemCount);
  
    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={
                        // @ts-ignore
                        itemData
                    }
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(
                        // @ts-ignore
                        itemData[index]
                    )}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    // @ts-ignore
    children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const UserCustomerForm = (props: any) => {
    const { dictionary } = useLanguage();
    const { getHeader } = useAuth();
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [preSelectedClients, setPreSelectedClients] = useState([]);
    const [handleSwitchChange, setHandleSwitchChange] = useState({
        all: true,
        onlyPartner: false,
        onlyCustomers: false
    });
    const [search, setSearch] = useState('');

    const {
        open, handleCancel, submit, handleClose, clients, presetClients, presetCustomers, isAdmin
    } = props;

    const handleSubmit = () => {
        submit(selectedCustomers);
    }

    // @ts-ignore
    const fetchCustomers = useCallback(async (selectedClients, selectCustomers) => {
        setLoading(true);
        setCustomers([]);

        if(selectedClients.length === 0) {
            setLoading(false);
            return;
        }

        // @ts-ignore
        let clientIds = selectedClients.map((client) => client.clientId),
            url = '';

        if(selectedClients[0] === '-1') {
            url = BackendRoutes.customers;
        }
        else if(handleSwitchChange.onlyPartner) {
            url = BackendRoutes.customers + '/' + clientIds.join(',') + '/partner';
        }
        else if(handleSwitchChange.onlyCustomers) {
            url = BackendRoutes.customers + '/' + clientIds.join(',') + '/customers';
        }
        else {
            url = BackendRoutes.customers + '/' + clientIds.join(',');
        }

        let response = await fetch(url, {
            method: 'GET',
            headers: getHeader()
        });
        response = await response.json();

        // @ts-ignore
        setCustomers(response.customersData);
        setLoading(false);

        if(presetCustomers && selectCustomers) {
            // @ts-ignore
            const selectedCustomers = response.customersData.filter((customer) => presetCustomers.indexOf(customer.id) !== -1);
            setSelectedCustomers(selectedCustomers);
        }
    }, [getHeader, setSelectedCustomers, presetCustomers, handleSwitchChange]);
    
    // @ts-ignore
    const handleChange = (event, data) => {
        // @ts-ignore
        fetchCustomers(data);
        setPreSelectedClients(data);
    }
    
    useEffect(() => {
        if(isAdmin) {
            let selectedClients = ['-1'];
            if(presetClients && isAdmin) {
                // @ts-ignore
                selectedClients = clients.filter((client) => presetClients.indexOf(client.clientId) !== -1);
            }

            if(selectedClients.length > 0 && selectedClients[0] !== '-1') {
                fetchCustomers(selectedClients, true);
                // @ts-ignore
                setPreSelectedClients(selectedClients);
            }
        }
        else {
            fetchCustomers(['-1'], false);
        }
    }, [presetClients, clients, fetchCustomers, isAdmin]);

    useEffect(() => {
        fetchCustomers(preSelectedClients, false);
    }, [fetchCustomers, handleSwitchChange, preSelectedClients]);

    // @ts-ignore
    const handleCustomerChange = (event, data) => {
        setSelectedCustomers(data);
    }

    // @ts-ignore
    const onSwitchChange = (event, whichSwitch) => {
        if(whichSwitch === 'onlyPartner') {
            setHandleSwitchChange({
                all: false,
                onlyPartner: true,
                onlyCustomers: false
            });
        }
        else if(whichSwitch === 'onlyCustomers') {
            setHandleSwitchChange({
                all: false,
                onlyPartner: false,
                onlyCustomers: true
            });
        }
        else {
            setHandleSwitchChange({
                all: true,
                onlyPartner: false,
                onlyCustomers: false
            });
        }
    }

    return (
        <Dialog 
            open={open}
            onClose={handleCancel}
            aria-labelledby="rights-form-title"
            fullWidth 
            maxWidth="lg"
        >
            <DialogTitle id="rights-form-title">{dictionary.userForm.assignCustomers}</DialogTitle>
            <DialogContent>
                { 
                    isAdmin && clients.length > 0 && <Autocomplete 
                        multiple
                        defaultValue={[]}
                        noOptionsText={dictionary.noOptionsText}
                        openText={dictionary.openText}
                        closeText={dictionary.closeText}
                        clearText={dictionary.clearText}
                        value={preSelectedClients}
                        style={{marginTop: '5px', marginBottom: '5px'}}
                        options={clients}
                        getOptionLabel={
                            // @ts-ignore
                            (option) => option.clientName
                        }
                        isOptionEqualToValue={
                            // @ts-ignore
                            (option, value) => option.clientId === value.clientId
                        }
                        renderInput={(params) => <TextField {...params} label={dictionary.userForm.clients} />}
                        onChange={handleChange}
                        renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                                <Chip 
                                    {...getTagProps({ index })} 
                                    label={
                                        // @ts-ignore
                                        option.clientName
                                    } 
                                    style={{
                                        // @ts-ignore
                                        backgroundColor: option.clientColor !== '' ? option.clientColor : '#EBEBEB', 
                                        // @ts-ignore
                                        color: option.clientColor !== '' ? '#FFFFFF' : '#000000',
                                        boxSizing: 'border-box',
                                        border: '1px solid'
                                    }}
                                />
                            ));
                        }}
                    />
                }
                {
                    isAdmin && clients.length > 0 && <div>
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={handleSwitchChange.all}
                                    onChange={(event) => onSwitchChange(event, 'all')}
                                />
                            } 
                            label={dictionary.userForm.partnerAndCustomers} 
                        />&nbsp;
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={handleSwitchChange.onlyPartner}
                                    onChange={(event) => onSwitchChange(event, 'onlyPartner')}
                                />
                            } 
                            label={dictionary.userForm.onlyPartner} 
                        />&nbsp;
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={handleSwitchChange.onlyCustomers}
                                    onChange={(event) => onSwitchChange(event, 'onlyCustomers')}
                                />
                            } 
                            label={dictionary.userForm.onlyCustomers} 
                        />
                    </div>
                }
                <Autocomplete 
                    multiple
                    disableCloseOnSelect
                    noOptionsText={dictionary.noOptionsText}
                    openText={dictionary.openText}
                    closeText={dictionary.closeText}
                    clearText={dictionary.clearText}
                    loading={loading}
                    defaultValue={[]}
                    inputValue={search}
                    value={selectedCustomers}
                    disableListWrap
                    style={{marginTop: '10px', marginBottom: '5px'}}
                    PopperComponent={StyledPopper}
                    // @ts-ignore
                    ListboxComponent={ListboxComponent}
                    filterOptions={(options: any, state: any) => {
                        if(state.inputValue === '') {
                            return options;
                        }

                        return options.filter(
                            (option: any) => 
                                option.erpNumber.indexOf(state.inputValue) !== -1 ||
                                option.cubisNumber.indexOf(state.inputValue) !== -1 ||
                                option.label.toLowerCase().indexOf(state.inputValue.toLowerCase()) !== -1
                        );
                    }}
                    options={customers}
                    onClose={(e) => setSearch("")}
                    renderInput={(params) => <TextField {...params} label={dictionary.userForm.customer} onChange={(e) => setSearch(e.target.value)} />}
                    // @ts-ignore
                    renderOption={(props, option, { selected }) => [props, option, selected]}
                    isOptionEqualToValue={
                        // @ts-ignore
                        (option, value) => option.id === value.id 
                    }
                    getOptionDisabled={(option) => {
                        // @ts-ignore
                        return option.disabled;
                    }}
                    onChange={handleCustomerChange}
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })} 
                                label={
                                    // @ts-ignore
                                    option.label
                                } 
                                style={{
                                    // @ts-ignore
                                    border: '2px solid ' + (option.color !== '' ? option.color : '#EBEBEB'),
                                    boxSizing: 'border-box',
                                }}
                            />
                        ));
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>{dictionary.userForm.apply}</Button>
                <Button onClick={handleClose}>{dictionary.userForm.cancel}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserCustomerForm;