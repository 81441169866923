import { omApi } from './omApi';
import { getJwtHeader } from '../features/session/omSessionActions';
import { ILocation } from '../interfaces/customer/ILocation';
import { offerMakerRoutes } from '../configuration/offerMakerRoutes';
import { IProduct, IProductQueryParams } from '../interfaces/product/IProduct';
import IFieldOption from '../interfaces/IFieldOption';

export interface IPositionTypeResult
{
    omPositionTypeId: number,
    description: string,
    enum: string,
    activationDate: string,
    deactivationDate: string,
    createUserId: number,
    createDate: string,
    modifyUserId: number,
    modifyDate: string,
    omOfferPosition: Array<any>,
}

const positionTypeApiTags = ['ompositiontype', 'ompositiontypes'];
export const positionTypeApi = omApi.enhanceEndpoints({ addTagTypes: positionTypeApiTags }).injectEndpoints({

    endpoints: (builder) => ({
        GetPositionTypes: builder.query<Array<IFieldOption>, void>({
            query: () => ({
                url: offerMakerRoutes.ompositionType,
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['ompositiontype'],
        }),
        GetPositionTypeById: builder.query<IPositionTypeResult, any>({
            query: (params: any) => ({
                url: offerMakerRoutes.ompositionTypeById(params),
                method: "GET",
                headers: getJwtHeader(),
            }),
            providesTags: ['ompositiontype'],
        }),
    }),
});


export const {
    useGetPositionTypesQuery,
    useGetPositionTypeByIdQuery,
} = positionTypeApi;