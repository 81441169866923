import { useState, useEffect } from 'react';
import { Column, Grouping } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@mui/material';

import LoadingGridData from '../Components/Grids/LoadingGridData';
import PageHeader from '../Components/Header/PageHeader';
import BackendRoutes from '../Constants/BackendRoutes';
import GroupingGrid from '../Components/Grids/GroupingGrid';
import { useAuth } from '../Contexts/AuthContext';
import { useLanguage } from '../Contexts/LanguageContext';
import IColumnsResponse from '../Interfaces/IColumnsResponse';
import IFileData from '../Interfaces/IFileData';
import { pageMain, gridPage } from '../Theme/LayoutDefinitions';

const ReportsPageMainSection = () => {
    const { dictionary } = useLanguage();
    const { currentUser, getHeader } = useAuth();
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState<Array<Column>>([]);
    const [hiddenColumns, setHiddenColumns] = useState<Array<string>>([]);
    const [columnExtensions, setColumnExtensions] = useState<Array<Table.ColumnExtension>>([]);
    const [fetchOnce, setFetchOnce] = useState(true);
    const [defaultGrouping, setDefaultGrouping] = useState<Array<Grouping>>([{ columnName: 'period' }, { columnName: 'company' }]);
    const [rowData, setRawData] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    // use an effect to load the data from the backend
    useEffect(() => {

        // fetch the columns and the hidden columns for the grid
        // we can't get them while we also get the data for the grid
        async function fetchColumns() {
            // set loading to true so that the grid will have a loading spinner
            setLoading(true);

            let response = await fetch(BackendRoutes.reportColumns, {
                method: 'GET',
                headers: getHeader(),
            });
            let responseData: IColumnsResponse = await response.json();

            const groupConfig = defaultGrouping.map(columnGrouping => ({
                selector: columnGrouping.columnName,
                isExpanded: true,
            }));

            response  = await fetch(BackendRoutes.report + `?group=${JSON.stringify(groupConfig)}`, {
                method: 'GET',
                headers: getHeader(),
            });

            let rowData: any = await response.json();
            
            setRawData(rowData.data);
            setColumns(responseData.columns);
            setHiddenColumns(responseData.hiddenColumns);
            setColumnExtensions(responseData.columnExtensions);
            setDefaultGrouping(responseData.defaultGrouping);
            setLoading(false);
            setFetchOnce(false);
        }

        if(fetchOnce) {
            fetchColumns();
        }
    }, [setLoading, getHeader, fetchOnce, defaultGrouping]);

    // use an effect to load the data when there was a change to the 
    // search parameter
    useEffect(() => {
        async function fetchGridData() {
            const groupConfig = defaultGrouping.map(columnGrouping => ({
                selector: columnGrouping.columnName,
                isExpanded: true,
            }));

            let filter = columns.reduce((acc: Array<string>, { name }) => {
                acc.push(`["${name}", "contains", "${encodeURIComponent(searchValue)}"]`);
                return acc;
            }, []).join(',"or",');
          
            if (columns.length > 1) {
                filter = `[${filter}]`;
            }

            let url = BackendRoutes.report + `?group=${JSON.stringify(groupConfig)}`;
            if(searchValue !== '') {
                url = BackendRoutes.report + `?group=${JSON.stringify(groupConfig)}&filter=${filter}`;
            }

            let response  = await fetch(url, {
                method: 'GET',
                headers: getHeader(),
            });

            let rowData: any = await response.json();
            
            setRawData(rowData.data);
        }

        fetchGridData();
    }, [getHeader, searchValue, defaultGrouping, columns]);

    const downloadFile = (rowData: IFileData, viewFile: boolean) => {
        let downloadUrl = BackendRoutes.reportDownload + '/' + rowData.fileHash + '?jwt=' + currentUser.jwt;
        
        if(viewFile) {
            downloadUrl += '&showfile=true';
        }

        window.open(downloadUrl, '_blank');
    };

    const downloadSelectedFiles = (ids: Array<string | number>) => {
        const downloadUrl = BackendRoutes.reportDownload + '/' + ids.join(',') + '?jwt=' + currentUser.jwt;
        window.open(downloadUrl, '_blank');
    }

    return (
        <Box component='main' sx={pageMain}>
            <PageHeader 
                headerTitle={dictionary.reports}
            />
            <Box sx={gridPage}>
            {
                loading ?
                <LoadingGridData /> 
                :
                <GroupingGrid 
                    columns={columns}
                    rows={rowData}
                    hiddenColumns={hiddenColumns}
                    columnExtensions={columnExtensions}
                    defaultGrouping={defaultGrouping}
                    noGridCustomerSelected={dictionary.noGridCustomerSelected}
                    noGridDataText={dictionary.noGridData}
                    useDownloadActionFormater={true}
                    usePeriodFormater={true}
                    useFileTypeFormatter={true}
                    useDownloadSelection={true}
                    selectByRowClick={true}
                    download={downloadFile}
                    downloadSelectedFiles={downloadSelectedFiles}
                    setSearchValue={setSearchValue}
                />
            }
            </Box>
        </Box>
    )
}

export default ReportsPageMainSection;
