import { Alert, AlertTitle, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useOmTranslation } from "../translations/OmTranslationContext";
const Error = () => {
    const { dictionary } = useOmTranslation();
    return (
        <div style={{ height: 300, width: 500 }}>
            <Alert
                color="error"
                variant="outlined"
                severity="error"
                icon={undefined}
                sx={{ height: "100%", width: "100%", backgroundColor: red[100] }}
            >
                <AlertTitle>{dictionary.error.error}</AlertTitle>
                <Typography variant="h6">{dictionary.error.generalError}</Typography>
            </Alert>
        </div>
    );
};

export default Error;
