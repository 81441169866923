import { useState, useEffect } from 'react';
import { Column } from '@devexpress/dx-react-grid';
import { Grouping } from '@devexpress/dx-react-grid';
import { Box } from '@mui/material';

import BackendRoutes from '../Constants/BackendRoutes';
import { useAuth } from '../Contexts/AuthContext';
import UserForm from '../Components/User/UserForm';
import AlertDialog from '../Components/User/AlertDialog';
import DeleteDialog from '../Components/User/DeleteDialog';
import { useLanguage } from '../Contexts/LanguageContext';
import PageHeader from '../Components/Header/PageHeader';
import GroupingGrid from '../Components/Grids/GroupingGrid';
import { IDialogMessage } from '../Interfaces/Props/IDialog';
import IRightsData, { IRights } from '../Interfaces/User/IRights';
import IClientData, { IClient } from '../Interfaces/User/IClients';
import IRolesData, { IRole } from '../Interfaces/User/IRoles';
import IUserColumns from '../Interfaces/User/IUserColumns';
import IEditRow from '../Interfaces/User/IEditRow';
import IResponse from '../Interfaces/IResponse';
import IUserData from '../Interfaces/User/IUserData';
import LoadingGridData from '../Components/Grids/LoadingGridData';
import { pageMain, gridPage } from '../Theme/LayoutDefinitions';

const UsersPageMainSection = () => {
    const { dictionary } = useLanguage();
    const { currentUser, getHeader } = useAuth();
    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [editData, setEditData] = useState<IEditRow|null>(null);
    const [deleteData, setDeleteData] = useState<IEditRow|null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState<IDialogMessage>({
        header: '',
        main: ''
    });
    const [roles, setRoles] = useState<IRole[]>([]);
    const [clients, setClients] = useState<IClient[]>([]);
    const [fetchOnce, setFetchOnce] = useState(true);
    const [reloadGrid, setReloadGrid] = useState(false);

    const [columns, setColumns] = useState<Column[]>([]);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [rights, setRights] = useState<IRights[]>([]);
    const [rowData, setRawData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [defaultGrouping] = useState<Array<Grouping>>([{ columnName: 'company' }]);

    const [checkUser, setCheckUser] = useState({
        success: false,
        isAdmin: 'N',
        hasMultipleCustomers: 'N',
        rights: {}
    });

    useEffect(() => {
        async function fetchCheckUser() {
            let response = await fetch(BackendRoutes.checkuser, {
                method: 'GET',
                headers: getHeader(),
            });

            let responseData: any = await response.json();

            setCheckUser(responseData);
        }
        
        fetchCheckUser();
    }, [getHeader]);

    // use an effect to load the data from the backend
    useEffect(() => {
        // fetch the columns and the hidden columns for the grid
        // we can't get them while we also get the data for the grid
        async function fetchColumns() {
            // set loading to true so that the grid will have a loading spinner
            setLoading(true);

            let response = await fetch(BackendRoutes.usercolumns, {
                method: 'GET',
                headers: getHeader(),
            });

            let responseData: IUserColumns = await response.json();

            const groupConfig = defaultGrouping.map(columnGrouping => ({
                selector: columnGrouping.columnName,
                isExpanded: true,
            }));

            response  = await fetch(BackendRoutes.user + `?group=${JSON.stringify(groupConfig)}`, {
                method: 'GET',
                headers: getHeader(),
            });

            let rowData: any = await response.json();
            
            setRawData(rowData.data);
            setColumns(responseData.columnData.columns);
            setHiddenColumns(responseData.columnData.hiddenColumns);
            setLoading(false);
            setFetchOnce(false);
            setReloadGrid(false);
        }

        // fetch the rights
        async function fetchRights() {
            let response = await fetch(BackendRoutes.rights, {
                method: 'GET',
                headers: getHeader(),
            });
            let responseData: IRightsData = await response.json();

            setRights(responseData.rightsData);
        }

        // fetch the clients
        async function fetchClients() {
            let response = await fetch(BackendRoutes.clients, {
                method: 'GET',
                headers: getHeader(),
            });
            let responseData: IClientData = await response.json();

            setClients(responseData.clientsData);
        }

        // fetch roles method to get the roles from the backend
        async function fetchRoles() {
            // fire the fetch request to the backend
            let response = await fetch(BackendRoutes.roles, {
                method: 'GET',
                headers: getHeader(),
            });
            let responseData: IRolesData = await response.json();

            // set the roles in the state so that we can use them later
            if(responseData.success) {
                setRoles(responseData.roles);
            }
        }

        // get the roles and columns only once and not every time
        if(fetchOnce) {
            fetchColumns();
            fetchRights();
            fetchRoles();
            if(checkUser.isAdmin === 'Y') {
                fetchClients();
            }
        }

        if(reloadGrid) {
            fetchColumns();
        }

        // the variabled in the array are dependencies for the effect
        // they will also be checked if they have changed and the effect will
        // be used again after one of these is changed
    }, [currentUser, getHeader, fetchOnce, defaultGrouping, reloadGrid, checkUser]);

    // use an effect to load the data when there was a change to the 
    // search parameter
    useEffect(() => {
        async function fetchGridData() {
            const groupConfig = defaultGrouping.map(columnGrouping => ({
                selector: columnGrouping.columnName,
                isExpanded: true,
            }));

            let filter = columns.reduce((acc: Array<string>, { name }) => {
                acc.push(`["${name}", "contains", "${encodeURIComponent(searchValue)}"]`);
                return acc;
            }, []).join(',"or",');
          
            if (columns.length > 1) {
                filter = `[${filter}]`;
            }

            let url = BackendRoutes.user + `?group=${JSON.stringify(groupConfig)}`;
            if(searchValue !== '') {
                url = BackendRoutes.user + `?group=${JSON.stringify(groupConfig)}&filter=${filter}`;
            }

            let response  = await fetch(url, {
                method: 'GET',
                headers: getHeader(),
            });

            let rowData: any = await response.json();
            
            setRawData(rowData.data);
        }

        fetchGridData();
    }, [getHeader, searchValue, defaultGrouping, columns]);

    const addClick = (event: any) => {
        event.stopPropagation(); // don't select this after clicking
        setIsAdd(true);
    }

    const handleEdit = (editRow: IEditRow) => {
        setIsEdit(true);
        setEditData(editRow);
    }

    const handleDelete = (deleteRow: IEditRow) => {
        setIsDelete(true);
        setDeleteData(deleteRow);
    }

    let headerTitle = dictionary.users,
        showAddButtonAndSearch  = true;

    if(isAdd) {
        headerTitle = dictionary.add_user;
        showAddButtonAndSearch = false;
    }
    else if(isEdit) {
        headerTitle = dictionary.edit_user;
        showAddButtonAndSearch = false;
    }

    return (
        <Box component='main' sx={pageMain}>
            <PageHeader 
                headerTitle={headerTitle}
                addClick={addClick}
                showAddButtonAndSearch={showAddButtonAndSearch}
            />
            <Box sx={gridPage}>
                {
                    isEdit ?
                    <UserForm 
                        isEdit={isEdit}
                        editData={editData}
                        roles={roles}
                        defaultRights={rights}
                        clients={clients}
                        checkUser={checkUser}
                        isAdmin={checkUser.isAdmin === 'Y' ? true : false}
                        showErrorDialog={(message: string) => {
                            setDialogMessage({header: dictionary.userForm.add_failed, main: message});
                            setDialogOpen(true);
                        }}
                        presetRights={() => {
                            // check if we found the user and if the user has rights
                            if(editData !== null && editData.rights !== null) {
                                // return the rights of the user
                                return editData.rights;
                            }
                            
                            // otherwise return an empty array
                            return [];
                        }}
                        saveButtonClick={(user: IUserData) => {
                            // send request to backend
                            async function updateUser() {
                                const header = getHeader();

                                const requestOptions = {
                                    method: 'PUT', 
                                    headers: header,
                                    body: JSON.stringify(user)
                                };
                                // fire the fetch request to the backend
                                let response = await fetch(BackendRoutes.user + '/' +  editData?.actionId, requestOptions);
                                let responseData: IResponse = await response.json();

                                if(responseData.success) {
                                    setIsEdit(false);
                                    setReloadGrid(true);
                                    setEditData(null);
                                } 
                                else {
                                    setDialogMessage({
                                        header: dictionary.userForm.edit_failed, 
                                        main: dictionary.userForm.edit_failed_message
                                    });
                                    setDialogOpen(true);
                                }
                            }
                            
                            updateUser();
                        }}
                        cancelButtonClick={() => {
                            setIsEdit(false);
                            setEditData(null);
                            setReloadGrid(true);
                            setSearchValue('');
                        }}
                    />
                    :
                        isAdd ?
                        <UserForm 
                            isEdit={isEdit}
                            editData={editData}
                            defaultRights={rights}
                            roles={roles}
                            clients={clients}
                            checkUser={checkUser}
                            isAdmin={checkUser.isAdmin === 'Y' ? true : false}
                            showErrorDialog={(message: string) => {
                                setDialogMessage({header: dictionary.userForm.add_failed, main: message});
                                setDialogOpen(true);
                            }}
                            saveButtonClick={(user: IUserData) => {
                                // send request to backend
                                async function addUser() {
                                    const header = getHeader();

                                    const requestOptions = {
                                        method: 'POST', 
                                        headers: header,
                                        body: JSON.stringify(user)
                                    };
                                    // fire the fetch request to the backend
                                    let response = await fetch(BackendRoutes.user, requestOptions);
                                    let responseData: IResponse = await response.json();
                                    
                                    if(responseData.success) {
                                        setIsAdd(false);
                                        setReloadGrid(true);
                                    }
                                    else {
                                        setDialogMessage({
                                            header: dictionary.userForm.add_failed, 
                                            // @ts-ignore
                                            main: dictionary.userForm.add_failed_message.replace('{errorCode}', responseData.errorCode)
                                        });
                                        setDialogOpen(true);
                                    }
                                }
                            
                                addUser();
                            }}
                            cancelButtonClick={() => {
                                setIsAdd(false);
                                setReloadGrid(true);
                                setSearchValue('');
                            }}
                        />
                    :
                        loading ?
                        <LoadingGridData /> 
                        :
                        <GroupingGrid 
                            columns={columns}
                            rows={rowData}
                            hiddenColumns={hiddenColumns}
                            defaultGrouping={defaultGrouping}
                            noGridCustomerSelected={dictionary.noGridCustomerSelected}
                            noGridDataText={dictionary.noGridData}
                            useLanguageFormater={true}
                            useYesNoFormater={true}
                            useEditActionFormater={true}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            setSearchValue={setSearchValue}
                        />
                }
            </Box>
            <AlertDialog 
                open={dialogOpen} 
                message={dialogMessage}
                handleClose={() => setDialogOpen(false)} 
            />
            <DeleteDialog 
                open={isDelete}
                title={dictionary.delete_user}
                message={{
                    header: dictionary.delete_user,
                    main: dictionary.should_user + deleteData?.login + dictionary.be_deleted
                }}
                handleDelete={() => {
                    setIsDelete(false);
                    setDeleteData(null);
                    setLoading(true);

                    async function deleteUser() {
                        const requestOptions = {
                            method: 'DELETE',
                            headers: getHeader()
                        };
                        // fire the fetch request to the backend
                        let response = await fetch(BackendRoutes.user + '/' +  deleteData?.actionId, requestOptions);
                        let responseData: IResponse = await response.json();
                        
                        if(responseData.success) {
                            setIsDelete(false);
                            setDeleteData(null);
                            setLoading(false);
                            setReloadGrid(true);
                        } 
                        else {
                            setIsDelete(false);
                            setDeleteData(null);
                        }
                    }

                    deleteUser();
                }}
                handleClose={() => {
                    setIsDelete(false);
                    setDeleteData(null);
                }}
            />
        </Box>
    )
}

export default UsersPageMainSection
