import IOffer, { IOfferResult } from '../interfaces/IOffer';
import { omApi } from './omApi';
import omSessionActions, { getJwtHeader } from '../features/session/omSessionActions';
import IOfferType from '../interfaces/offer/IOfferType';
import IOfferStatus from '../interfaces/offer/IOfferStatus';
import ICreateUser from '../interfaces/user/ICreateUser';
import IFieldOption, { IFieldOptionExtended } from '../interfaces/IFieldOption';
import { AppDispatch, store } from '../common/store';
import { getOffersQueryParams } from './filters/offersQueryFilter';
import dateFormat from "dateformat";
import { getOmTranslation } from '../translations/OmTranslationContext';
import { offerMakerRoutes } from '../configuration/offerMakerRoutes';


const userApiTags = ['salesUser'];
export const userApi = omApi.enhanceEndpoints({ addTagTypes: userApiTags }).injectEndpoints({

    endpoints: (builder) => ({
        GetAllSalesUsers: builder.query<Array<IFieldOption>, void>({
            query: () => ({
                url: offerMakerRoutes.salesUser,
                method: "GET",
                headers: getJwtHeader(),
            }),
            transformResponse: (response: Array<IFieldOption>) =>
            {
                if (response && response.length > 0)
                {
                    const isNotOmSalesWorker = omSessionActions.isOmSalesOperations() || omSessionActions.isOmConsulting();
                    const session = store.getState().session;
                    if (isNotOmSalesWorker)
                    {
                        return response;
                    }
                    else
                    {
                        return [{ id: parseInt(session.omUser?.Id ?? "0"), name: `${session.omUser?.family_name}, ${session.omUser?.given_name}` }];
                    }
                }
                return response;
            },
            providesTags: ['salesUser'],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetAllSalesUsersQuery
} = userApi;



/**
 * resetApiState — In case you want to wipe your whole API’s cached data, 
 * dispatch this action: store.dispatch(api.util.resetApiState());
 */