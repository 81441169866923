import FilterListIcon from "@mui/icons-material/FilterList";
import {
    Autocomplete,
    Badge,
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    MenuList,
    Stack,
    TextField,
    Tooltip,
} from "@mui/material";
import { useState } from "react";

import { useOmUX } from "../../../../common/imports";
import useAutocomplete from "../../../../hooks/useAutocomplete";
import { useGetCategoriesQuery } from "../../../../services/productCategorieApi";
import { useOmTranslation } from "../../../../translations/OmTranslationContext";
import { IProductGroup } from "../../interfaces/IProductGroup";

interface IProductFilter {
    onFilterChange: (group: IProductGroup | null) => void;
}
const ProductFilter = ({ onFilterChange }: IProductFilter) => {
    const { brand, primaryColor } = useOmUX();
    const { dictionary } = useOmTranslation();
    // const offersState = useAppSelector((state) => state.offerList);

    const {
        value: productGroup,
        setValue: setProductGroup,
        isValid: productGroupIsValid,
        hasError: productGroupHasError,
        valueChangeHandler: productGroupChangedHandler,
        inputBlurHandler: productGroupBlurHandler,
        reset: resetProductGroupInput,
    } = useAutocomplete<IProductGroup>({
        validationCallback: (group: IProductGroup) => {
            if (group && group.id && group.id > 0) {
                return true;
            }
            return false;
        },
    });
    const productGroupsResult = useGetCategoriesQuery();

    // FilterButton Variablen
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleGroupChange = (event: any, data: any) => {
        productGroupChangedHandler({
            event,
            newValue: data ?? null,
            callback: (event, data) => {},
        });
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handelResetFilter = () => {
        setIsFilterActive(false);
        resetProductGroupInput();
        onFilterChange(null);
        handleClose();
    };
    const handelApplyFilter = () => {
        if (productGroup) {
            setIsFilterActive(true);
            onFilterChange(productGroup);
        }
        handleClose();
    };

    return (
        <Tooltip title={"Angebote filtern"}>
            <>
                <IconButton
                    aria-label="reloadOffers"
                    color="default"
                    onClick={handleClick}
                    disabled={false}
                    sx={{
                        "&:hover": {
                            backgroundColor: (theme) => `${theme.palette.background.paper}`,
                        },
                        padding: "3px",
                        marginLeft: "unset",
                    }}
                >
                    <Badge
                        sx={{ "& .MuiBadge-badge": { backgroundColor: primaryColor } }}
                        variant="dot"
                        invisible={!isFilterActive}
                    >
                        <FilterListIcon />
                    </Badge>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <MenuList
                        dense
                        sx={{
                            padding: "unset",
                            margin: "unset",
                            "& .MuiFormControlLabel-root": {
                                marginRight: "unset",
                                width: "100%",
                            },
                        }}
                    >
                        <MenuItem>
                            <Autocomplete
                                sx={{ width: "100%", paddingTop: "5px" }}
                                size="small"
                                id="group-dropdown"
                                options={productGroupsResult?.data ?? []}
                                getOptionLabel={(option: IProductGroup) => option.name ?? ""}
                                onChange={handleGroupChange}
                                onBlur={(event) => {
                                    productGroupBlurHandler(event);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={dictionary.product.productGroup}
                                        error={productGroupHasError}
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                    />
                                )}
                                value={productGroup}
                            />
                        </MenuItem>
                    </MenuList>
                    <Stack
                        direction={"row"}
                        p={2}
                        sx={{
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Box>
                            <Button variant="outlined" onClick={handelResetFilter}>
                                {dictionary.reset}
                            </Button>
                        </Box>
                        <Box pl={2}>
                            <Button variant="contained" onClick={handelApplyFilter}>
                                {dictionary.apply}
                            </Button>
                        </Box>
                    </Stack>
                </Menu>
            </>
        </Tooltip>
    );
};

export default ProductFilter;

/*                 offersContext?.setOfferTypeFilterOption((prevState: IFieldOption) => {
                    let tempState: IFieldOption =
                        checkBox.checked == true ? prevState : initialFilterDataOption;
                    return {
                        ...tempState,
                        checked: checkBox.checked,
                    };
                }); */
