import FilterListIcon from "@mui/icons-material/FilterList";
import {
    Autocomplete,
    Badge,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    Menu,
    MenuItem,
    MenuList,
    Stack,
    TextField,
    Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../common/hooks";
import { useOmUX } from "../../common/imports";
import { AppDispatch } from "../../common/store";
import {
    FilterOption,
    IFieldOptionExtended,
    initialFieldOptionExtendedData,
} from "../../interfaces/IFieldOption";
import {
    offersApi,
    useGetOfferCreateUserQuery,
    useGetOfferStatusQuery,
    useGetOfferTypeQuery,
} from "../../services/offersApi";
import { useGetAllSalesUsersQuery } from "../../services/userApi";
import { useOmTranslation } from "../../translations/OmTranslationContext";
import { offersActions } from "./offerListSlice";
import { useOffersContext } from "./OffersContext";

export const useQueryParams = () => {
    const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({});
    const [queryString, setQueryString] = useState<string>("");
    //const offersContext = useOffersContext();
    let queryStart = "?";
    const setQueryParam = (key: string, value: any) => {
        setQueryParams((prevState: any) => {
            return {
                ...prevState,
                [key]: value,
            };
        });
    };
    const removeQueryParam = (removeKey: string) => {
        setQueryParams((prevState: any) => {
            let newState: any = {};
            for (const [key, value] of Object.entries(queryParams)) {
                if (key !== removeKey) {
                    newState[key] = value;
                }
            }
            return {
                ...newState,
            };
        });
    };

    const resetQueryParams = () => {
        setQueryParams({});
    };

    const buildArray = () => {
        let queryStringArr: Array<string> = [];
        for (const [key, value] of Object.entries(queryParams)) {
            const parameter = `${key}=${value}`;
            queryStringArr.push(parameter);
        }
        return queryStringArr;
    };

    const getQueryString = () => {
        const queryStringArr = buildArray();
        if (queryStringArr.length > 1) {
            return queryStart + queryStringArr.join("&");
        } else if (queryStringArr.length > 0) {
            return queryStart + queryStringArr[0];
        } else {
            return "";
        }
    };

    useEffect(() => {
        setQueryString(getQueryString());
    }, [queryParams]);

    return { queryParams, queryString, setQueryParam, removeQueryParam, resetQueryParams };
};

const OffersFilter = () => {
    const { brand, primaryColor } = useOmUX();
    const { dictionary } = useOmTranslation();
    const offersContext = useOffersContext();
    const queryPrams = useQueryParams();
    const offersState = useAppSelector((state) => state.offerList);

    const offerTypeResult = useGetOfferTypeQuery();
    const offerStatusResult = useGetOfferStatusQuery();
    const offerCreateUserResult = useGetOfferCreateUserQuery();
    const offerSalesUserResult = useGetAllSalesUsersQuery();

    // FilterButton Variablen

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checkBox = event.target;
        if (checkBox) {
            if (checkBox.name === FilterOption.offerType) {
                let tempState: IFieldOptionExtended =
                    checkBox.checked == true
                        ? offersState.offerTypeFilterOption
                        : initialFieldOptionExtendedData;
                AppDispatch(
                    offersActions.SetOfferTypeFilterOption({
                        ...tempState,
                        checked: checkBox.checked,
                    })
                );
            } else if (checkBox.name === FilterOption.offerStatus) {
                let tempState: IFieldOptionExtended =
                    checkBox.checked == true
                        ? offersState.offerStatusFilterOption
                        : initialFieldOptionExtendedData;
                AppDispatch(
                    offersActions.SetOfferStatusFilterOption({
                        ...tempState,
                        checked: checkBox.checked,
                    })
                );
            } else if (checkBox.name === FilterOption.createUser) {
                let tempState: IFieldOptionExtended =
                    checkBox.checked == true
                        ? offersState.offerCreateUserFilterOption
                        : initialFieldOptionExtendedData;
                AppDispatch(
                    offersActions.SetOfferCreateUserFilterOption({
                        ...tempState,
                        checked: checkBox.checked,
                    })
                );
            } else if (checkBox.name === FilterOption.salesUser) {
                let tempState: IFieldOptionExtended =
                    checkBox.checked == true
                        ? offersState.offerSalesUserFilterOption
                        : initialFieldOptionExtendedData;
                AppDispatch(
                    offersActions.SetOfferSalesUserFilterOption({
                        ...tempState,
                        checked: checkBox.checked,
                    })
                );
            }
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handelResetFilter = () => {
        AppDispatch(offersActions.ResetFilterOptions());
        handleClose();
        AppDispatch(
            offersApi.endpoints.GetOffers.initiate(undefined, {
                subscribe: false,
                forceRefetch: true,
            })
        );
    };
    const handelApplyFilter = () => {
        AppDispatch(
            offersApi.endpoints.GetOffers.initiate(undefined, {
                subscribe: false,
                forceRefetch: true,
            })
        );
        handleClose();
        return false;
    };

    const isFilterActive = () => {
        if (
            offersState.offerTypeFilterOption.checked ||
            offersState.offerStatusFilterOption.checked ||
            offersState.offerCreateUserFilterOption.checked ||
            offersState.offerSalesUserFilterOption.checked
        ) {
            return true;
        }
        return false;
    };

    return (
        <Tooltip title={"Angebote filtern"}>
            <>
                <IconButton
                    aria-label="reloadOffers"
                    color="default"
                    onClick={handleClick}
                    disabled={false}
                    sx={{
                        "&:hover": {
                            backgroundColor: (theme) => `${theme.palette.background.paper}`,
                        },
                        padding: "3px",
                        marginLeft: "unset",
                    }}
                >
                    <Badge
                        sx={{ "& .MuiBadge-badge": { backgroundColor: primaryColor } }}
                        variant="dot"
                        invisible={!isFilterActive()}
                    >
                        <FilterListIcon />
                    </Badge>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <MenuList
                        dense
                        sx={{
                            padding: "unset",
                            margin: "unset",
                            "& .MuiFormControlLabel-root": {
                                marginRight: "unset",
                                width: "100%",
                            },
                        }}
                    >
                        <MenuItem sx={{}}>
                            <FormControlLabel
                                value="offerType"
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={offersState.offerTypeFilterOption.checked}
                                        onChange={handleCheckBoxChange}
                                        name={FilterOption.offerType}
                                        inputProps={{ "aria-label": "offerType" }}
                                    />
                                }
                                sx={{}}
                                label={
                                    <Stack
                                        direction={"row"}
                                        sx={{
                                            height: "50px",
                                            minWidth: "400px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "180px",
                                            }}
                                        >
                                            {dictionary.offer.offerType}
                                        </Box>
                                        <Box
                                            pl={2}
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Autocomplete
                                                sx={{
                                                    "& .css-ihdtdm": {
                                                        display: "none",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        top: 0,
                                                    },
                                                    width: "250px",
                                                }}
                                                size="small"
                                                id="combo-box-demo"
                                                disabled={false}
                                                options={
                                                    offerTypeResult?.data
                                                        ? offerTypeResult?.data
                                                        : [initialFieldOptionExtendedData]
                                                }
                                                getOptionLabel={(option) =>
                                                    option.name ? option.name : ""
                                                }
                                                renderInput={(params: any) => (
                                                    <TextField {...params} variant="outlined" />
                                                )}
                                                isOptionEqualToValue={(
                                                    option: IFieldOptionExtended,
                                                    value: IFieldOptionExtended
                                                ) => option.id === value.id}
                                                onChange={(event: any, newValue: any) => {
                                                    let tempState: IFieldOptionExtended =
                                                        newValue?.id != null ||
                                                        newValue?.name != null
                                                            ? {
                                                                  ...offersState.offerTypeFilterOption,
                                                                  checked: true,
                                                              }
                                                            : initialFieldOptionExtendedData;
                                                    AppDispatch(
                                                        offersActions.SetOfferTypeFilterOption({
                                                            ...tempState,
                                                            id: newValue?.id,
                                                            name: newValue?.name,
                                                        })
                                                    );
                                                }}
                                                value={offersState.offerTypeFilterOption}
                                            />
                                        </Box>
                                    </Stack>
                                }
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                value="offerStatus"
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={offersState.offerStatusFilterOption.checked}
                                        onChange={handleCheckBoxChange}
                                        name={FilterOption.offerStatus}
                                        inputProps={{ "aria-label": "offerStatus" }}
                                    />
                                }
                                label={
                                    <Stack
                                        direction={"row"}
                                        sx={{
                                            height: "50px",
                                            minWidth: "400px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "180px",
                                            }}
                                        >
                                            {dictionary.offer.offerStatus}
                                        </Box>
                                        <Box
                                            pl={2}
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Autocomplete
                                                sx={{
                                                    "& .css-ihdtdm": {
                                                        display: "none",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        top: 0,
                                                    },
                                                    width: "250px",
                                                }}
                                                size="small"
                                                id="combo-box-demo"
                                                disabled={false}
                                                options={
                                                    offerStatusResult?.data
                                                        ? offerStatusResult?.data
                                                        : [initialFieldOptionExtendedData]
                                                }
                                                getOptionLabel={(option) =>
                                                    option.name ? option.name : ""
                                                }
                                                renderInput={(params: any) => (
                                                    <TextField {...params} variant="outlined" />
                                                )}
                                                isOptionEqualToValue={(
                                                    option: IFieldOptionExtended,
                                                    value: IFieldOptionExtended
                                                ) => option.id === value.id}
                                                onChange={(event: any, newValue: any) => {
                                                    let tempState: IFieldOptionExtended =
                                                        newValue?.id != null ||
                                                        newValue?.name != null
                                                            ? {
                                                                  ...offersState.offerStatusFilterOption,
                                                                  checked: true,
                                                              }
                                                            : initialFieldOptionExtendedData;
                                                    AppDispatch(
                                                        offersActions.SetOfferStatusFilterOption({
                                                            ...tempState,
                                                            id: newValue?.id,
                                                            name: newValue?.name,
                                                        })
                                                    );
                                                }}
                                                value={offersState.offerStatusFilterOption}
                                            />
                                        </Box>
                                    </Stack>
                                }
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                value="createUser"
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={offersState.offerCreateUserFilterOption.checked}
                                        onChange={handleCheckBoxChange}
                                        name={FilterOption.createUser}
                                        inputProps={{ "aria-label": "createUser" }}
                                    />
                                }
                                label={
                                    <Stack
                                        direction={"row"}
                                        sx={{
                                            height: "50px",
                                            minWidth: "400px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "180px",
                                            }}
                                        >
                                            {dictionary.offer.createdBy}
                                        </Box>
                                        <Box
                                            pl={2}
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Autocomplete
                                                sx={{
                                                    "& .css-ihdtdm": {
                                                        display: "none",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        top: 0,
                                                    },
                                                    width: "250px",
                                                }}
                                                size="small"
                                                id="combo-box-demo"
                                                disabled={false}
                                                options={
                                                    offerCreateUserResult?.data
                                                        ? offerCreateUserResult?.data
                                                        : [initialFieldOptionExtendedData]
                                                }
                                                getOptionLabel={(option) =>
                                                    option.name ? option.name : ""
                                                }
                                                renderInput={(params: any) => (
                                                    <TextField {...params} variant="outlined" />
                                                )}
                                                isOptionEqualToValue={(
                                                    option: IFieldOptionExtended,
                                                    value: IFieldOptionExtended
                                                ) => option.id === value.id}
                                                onChange={(event: any, newValue: any) => {
                                                    let tempState: IFieldOptionExtended =
                                                        newValue?.id != null ||
                                                        newValue?.name != null
                                                            ? {
                                                                  ...offersState.offerCreateUserFilterOption,
                                                                  checked: true,
                                                              }
                                                            : initialFieldOptionExtendedData;
                                                    AppDispatch(
                                                        offersActions.SetOfferCreateUserFilterOption(
                                                            {
                                                                ...tempState,
                                                                id: newValue?.id,
                                                                name: newValue?.name,
                                                            }
                                                        )
                                                    );
                                                }}
                                                value={offersState.offerCreateUserFilterOption}
                                            />
                                        </Box>
                                    </Stack>
                                }
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                value="salesUser"
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={offersState.offerSalesUserFilterOption.checked}
                                        onChange={handleCheckBoxChange}
                                        name={FilterOption.salesUser}
                                        inputProps={{ "aria-label": "salesUser" }}
                                    />
                                }
                                label={
                                    <Stack
                                        direction={"row"}
                                        sx={{
                                            height: "50px",
                                            minWidth: "400px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "180px",
                                            }}
                                        >
                                            {dictionary.offer.salesWorker}
                                        </Box>
                                        <Box
                                            pl={2}
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Autocomplete
                                                sx={{
                                                    "& .css-ihdtdm": {
                                                        display: "none",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        top: 0,
                                                    },
                                                    width: "250px",
                                                }}
                                                size="small"
                                                id="combo-box-demo"
                                                disabled={false}
                                                options={
                                                    offerSalesUserResult?.data
                                                        ? offerSalesUserResult?.data.map((user) => {
                                                              return {
                                                                  id: user.id,
                                                                  name: user.name,
                                                                  checked: false,
                                                              };
                                                          })
                                                        : [initialFieldOptionExtendedData]
                                                }
                                                getOptionLabel={(option) =>
                                                    option.name ? option.name : ""
                                                }
                                                renderInput={(params: any) => (
                                                    <TextField {...params} variant="outlined" />
                                                )}
                                                isOptionEqualToValue={(
                                                    option: IFieldOptionExtended,
                                                    value: IFieldOptionExtended
                                                ) => option.id === value.id}
                                                onChange={(event: any, newValue: any) => {
                                                    let tempState: IFieldOptionExtended =
                                                        newValue?.id != null ||
                                                        newValue?.name != null
                                                            ? {
                                                                  ...offersState.offerSalesUserFilterOption,
                                                                  checked: true,
                                                              }
                                                            : initialFieldOptionExtendedData;
                                                    AppDispatch(
                                                        offersActions.SetOfferSalesUserFilterOption(
                                                            {
                                                                ...tempState,
                                                                id: newValue?.id,
                                                                name: newValue?.name,
                                                            }
                                                        )
                                                    );
                                                }}
                                                value={offersState.offerSalesUserFilterOption}
                                            />
                                        </Box>
                                    </Stack>
                                }
                            />
                        </MenuItem>
                    </MenuList>
                    <Stack
                        direction={"row"}
                        p={2}
                        sx={{
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Box>
                            <Button variant="outlined" onClick={handelResetFilter}>
                                {dictionary.reset}
                            </Button>
                        </Box>
                        <Box pl={2}>
                            <Button variant="contained" onClick={handelApplyFilter}>
                                {dictionary.apply}
                            </Button>
                        </Box>
                    </Stack>
                </Menu>
            </>
        </Tooltip>
    );
};

export default OffersFilter;

/*                 offersContext?.setOfferTypeFilterOption((prevState: IFieldOption) => {
                    let tempState: IFieldOption =
                        checkBox.checked == true ? prevState : initialFilterDataOption;
                    return {
                        ...tempState,
                        checked: checkBox.checked,
                    };
                }); */
