export function functionIsDefined(func: any): boolean
{
    if (typeof func === typeof Function)
    {
        return true;
    }
    return false;
}

export function isObject(item: any)
{
    return (typeof item === "object" && !Array.isArray(item) && item !== null);
}

export function isNotNullOrUndefined(data: any)
{
    if (data !== undefined && data !== null)
    {
        return true;
    }
    return false;
}

export function isValidInputString(value: any)
{
    if (value.trim() !== "" && typeof value.trim() === "string" && isNaN(value))
    {
        return true;
    }
    return false;
};

export function generateKey(minLength = 0, acc = ''): string
{
    if (acc.length <= minLength)
    {
        const str = Math.random().toString(36).slice(2);
        return generateKey(minLength, acc.concat(str));
    }

    return acc.slice(0, minLength) as string;
}



export const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    // minimumFractionDigits: 2,
    // maximumFractionDigits: 2,
});




/**
 * Parse value to currency
 * @param {number|string} input
 * @param {string} locale - Desired locale i.e: "en-US" "hr-HR"
 * @param {string} currency - Currency to use "USD" "EUR" "HRK"  
 * @return {object} 
 */
export const parseInput = (input: number | string, locale = "de-DE", currency = "EUR") =>
{
    let fmt = String(input)
        .replace(/(?<=\d)[.,](?!\d+$)/g, "")
        .replace(",", ".");
    const pts = fmt.split(".");
    if (pts.length > 1)
    {
        if (+pts[0] === 0) fmt = pts.join(".");
        else if (pts[1].length === 3) fmt = pts.join("");
    }
    const number = Number(fmt);
    const isValid = isFinite(number);
    const string = number.toFixed(2);
    const intlNFOpts = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
    }).resolvedOptions();
    const output = number.toLocaleString(locale, {
        ...intlNFOpts,
        style: "decimal",
    });
    return {
        input,
        isValid,
        string,
        number,
        currency,
        output,
    };
};




