import Paper from '@mui/material/Paper';
import React, { useState } from 'react'; 
import {
    SortingState,
    IntegratedSorting,
    SummaryState,
    IntegratedSummary,
    SummaryItem
} from '@devexpress/dx-react-grid';
import {
    Grid, VirtualTable, TableHeaderRow, Table, TableSummaryRow
} from '@devexpress/dx-react-grid-material-ui';
import { alpha, styled } from '@mui/material/styles';
import IStatsGrid from '../../Interfaces/Props/IStatsGrid';

import { Template, TemplateConnector } from "@devexpress/dx-react-core";
// @ts-ignore
import { isTotalSummaryTableCell, getColumnSummaries } from "@devexpress/dx-grid-core";
import { gridPaper } from '../../Theme/LayoutDefinitions';

const PREFIX = 'portal';
const PREFIX_TSI = 'TableSummaryItem';

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
    item: `${PREFIX_TSI}-item`,
};

const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
           backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },
    },
}));

const StyledDiv = styled('div')(({ theme }) => ({
  [`&.${classes.item}`]: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(13),
  },
}));

// @ts-ignore
const TableComponent = (props) => (
    <StyledTable
        {...props}
        className={classes.tableStriped}
    />
);

const calulateReadableTimes = (time: number): string => {
    const hours     = Math.floor(time / 3600);
    const minutes   = Math.floor((time - hours*3600) / 60);
    const seconds   = (time - hours*3600 - minutes*60);
    let hoursString     = '' + hours;
    let minutesString   = '' + minutes;
    let secondsString   = '' + seconds;

    if(hours < 10) {
        hoursString = '0' + hours;
    }

    if(minutes < 10) {
        minutesString = '0' + minutes;
    }

    if(seconds < 10) {
        secondsString = '0' + seconds;
    }

    return (
        hours > 0 ? 
        hoursString + ':' :
        '00:'
    ) + 
    (
        minutes > 0 ?
        minutesString + ':' :
        '00:'
    )
    + secondsString;
}

const summaryCalculator = (type: string, rows: any[], getValue: (row: any) => any) => {
    if (type === 'median') {
        if (!rows.length) {
            return null;
        }
        
        const sortedRows = rows.sort((a, b) => getValue(a) - getValue(b));
        if (rows.length % 2 === 1) {
            return getValue(sortedRows[(sortedRows.length + 1) / 2]);
        }
      
        const halfIndex = sortedRows.length / 2;
        
        return (getValue(sortedRows[halfIndex]) + getValue(sortedRows[halfIndex + 1])) / 2;
    }

    if(type === 'sumTime') {
        if (!rows.length) {
            return null;
        }

        // sum up all values
        const sum = rows.reduce((acc, row) => {
            return acc + getValue(row)
        }, 0);

        if(sum > 0) {
            return calulateReadableTimes(sum);
        }

        return '00:00:00';
    }

    if(type === 'maxInMin') {
        if (!rows.length) {
            return null;
        }

        // find the max value in this column
        const max = rows.reduce((acc, row) => {
            return Math.max(acc, getValue(row));
        }, -Infinity);

        return calulateReadableTimes(max);
    }

    if(type === 'sumPercentHandledCalls') {
        if (!rows.length) {
            return null;
        }

        let sumCallTries        = 0;
        let sumCallsHandled     = 0;

        for(var i=0; i<rows.length; i++) {
            sumCallTries    += rows[i]['callTries'];
            sumCallsHandled += rows[i]['callSuccess'];
        }

        let percent = 0;
        if(sumCallTries > 0) {
            percent = sumCallsHandled * 100 / sumCallTries;
        }

        return percent.toFixed(2) + ' %';
    }

    return IntegratedSummary.defaultCalculator(type, rows, getValue);
};

const messages = {
    sumTime: 'Sum',
    maxInMin: 'Max',
    sumPercentHandledCalls: 'Sum'
};

// TODO Add sum row -> https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/summary-row/
//  - check if it is posible to add a sum in every column
const StatsGrid = (props: IStatsGrid) => {
    const [totalSummaryItems] = useState<SummaryItem[]>([
        { columnName: 'time', type: 'count' },
        { columnName: 'callSuccess', type: 'sum' },
        { columnName: 'callTries', type: 'sum' },
        { columnName: 'percentSuccessfullCalls', type: 'sumPercentHandledCalls' },
        { columnName: '10', type: 'sum' },
        { columnName: '20', type: 'sum' },
        { columnName: '30', type: 'sum' },
        { columnName: '40', type: 'sum' },
        { columnName: '50', type: 'sum' },
        { columnName: 'duration', type: 'sumTime' }
    ]);

    return (
        <Paper sx={gridPaper} elevation={0}>
            <Grid
                rows={props.data}
                columns={props.columns}
            >
                <SortingState
                    defaultSorting={props.sorting}
                    sorting={props.sorting}
                    onSortingChange={props.setSorting}
                />
                <IntegratedSorting />
                <SummaryState
                    totalItems={totalSummaryItems}
                />
                <IntegratedSummary 
                    calculator={summaryCalculator}
                />
                <VirtualTable 
                    columnExtensions={props.columnExtensions}
                    tableComponent={TableComponent}
                    messages={{
                        noData: props.noGridDataText
                    }}
                />
                <TableHeaderRow 
                    showSortingControls
                    messages={{
                        sortingHint: props.sortText
                    }}
                />
                <TableSummaryRow 
                    // @ts-ignore
                    messages={messages}
                />
                <Template
                    name="tableCell"
                    predicate={
                        // override template only for total summary cells
                        (predicateParams: any) => isTotalSummaryTableCell(predicateParams.tableRow, predicateParams.tableColumn)
                    }
                >
                    {
                        (params: any) => {
                            return (
                                <TemplateConnector key={"statsTemplateConnector"}>
                                    {
                                        // @ts-ignore
                                        ({ totalSummaryValues, totalSummaryItems }) => {
                                            const columnName = params.tableColumn.column.name;
                                            const summaries = getColumnSummaries(
                                                totalSummaryItems,
                                                columnName,
                                                totalSummaryValues
                                            );

                                            if(columnName === 'interval' || columnName === 'date') {
                                                return (
                                                    <TableSummaryRow.TotalCell {...params}>
                                                        <StyledDiv className={classes.item}>
                                                            Summen:
                                                        </StyledDiv>
                                                    </TableSummaryRow.TotalCell>
                                                );
                                            }

                                            return (
                                                <TableSummaryRow.TotalCell {...params}>
                                                    {
                                                        // @ts-ignore
                                                        summaries.map((summary, index) => {
                                                            return (
                                                                <StyledDiv className={classes.item}>
                                                                    {
                                                                        /* Customize summary item here. */
                                                                        summary.value
                                                                    }
                                                                </StyledDiv>
                                                            )
                                                        })
                                                    }
                                                </TableSummaryRow.TotalCell>
                                            )
                                        }
                                    }
                                </TemplateConnector>
                            );
                        }
                    }
                </Template>
            </Grid>
        </Paper>
    );
}

export default StatsGrid;