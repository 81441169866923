import { CircularProgress, Box} from '@mui/material';
import { loadingGridPage } from '../../Theme/LayoutDefinitions';

const LoadingGridData = () => {
    return (
        <Box sx={loadingGridPage}>
            <CircularProgress /> 
        </Box>
    );
};


export default LoadingGridData;