import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUX } from '../Contexts/UXContext'
import { Box } from '@mui/material';

import byon_logo from '../Theme/Assets/byon-logo.svg'
import consense_logo from '../Theme/Assets/consense_logo.png'
import esp_logo from '../Theme/Assets/esp-logo.png';
import ConfirmationPage from './otp/ConfirmationPage';
import UserDataPage from './otp/UserDataPage';
import BackendRoutes from '../Constants/BackendRoutes';
import Loading from './otp/Loading';
import OtpErrorPage from './otp/OtpErrorPage'
import IOtpJwtCheckResponse from '../Interfaces/otp/IOtpJwtCheckResponse';
import { 
    shadowContainer, innerContainer, loginForm
} from '../Theme/LayoutDefinitions';

const OneTimePassword = () => {
    const { companyName, primaryColor, imprintUri, privacyProtection, logo } = useUX();
    const { token } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const [ errorData, setErrorData ] = useState('');
    const [ codeConfirmation, setCodeConfirmation ] = useState(false);
    const [ loginName, setLoginName ] = useState('');
    const [ checkJwtOnce, setCheckJwtOnce ] = useState(true);

    let defaultColor = primaryColor;
    if(defaultColor === undefined) {
        defaultColor = '#bc1123';
    }

    let defaultCompanyName = companyName;
    if(defaultCompanyName === undefined) {
        defaultCompanyName = 'byon GmbH';
    }let defaultLogo = logo;
    if(defaultLogo === undefined) {
        defaultLogo = 'byon_logo';
    }

    let logoToUse = byon_logo;
    if(defaultLogo === 'consense_logo') {
        logoToUse = consense_logo;
    }
    else if(defaultLogo === 'esp_logo') {
        logoToUse = esp_logo;
    }

    useEffect(() => {
        async function checkJwt() {
            const defaultHeader = new Headers();
            defaultHeader.append('Access-Control-Allow-Origin', '*');
            defaultHeader.append('Content-Type', 'application/json');

            let response = await fetch(BackendRoutes.otp.jwt + '/' + token, {
                method: 'GET',
                headers: defaultHeader,
            });
            let responseData: IOtpJwtCheckResponse = await response.json();

            if(responseData.success) {
                setLoading(false);
                setError(false);
                setErrorData('');
            }
            else {
                setLoading(false);
                setError(true);
                setErrorData(responseData.error);
            }
        }

        if(checkJwtOnce) {
            checkJwt();
            setCheckJwtOnce(false);
        }
    }, [checkJwtOnce, setCheckJwtOnce, token]);

    return (
        <Box sx={shadowContainer}>
            <Box sx={innerContainer}>
                {
                    // css class: login__content
                }
                <Box>
                    <img 
                        src={logoToUse} 
                        alt={`${defaultCompanyName} logo`} 
                        style={{
                            width: '200px'
                        }}
                    />
                </Box>
                {
                    // page content here
                }
                <Box sx={loginForm}>
                    {
                        loading ?
                        <Loading 
                            defaultLogo={defaultLogo} 
                            byon_logo={byon_logo}
                            consense_logo={consense_logo}
                            defaultCompanyName={defaultCompanyName}
                            imprint={imprintUri}
                            privacyProtection={privacyProtection}
                        />
                        :
                        error ?
                        <OtpErrorPage 
                            defaultLogo={defaultLogo}
                            imprint={imprintUri}
                            privacyProtection={privacyProtection}
                            byon_logo={byon_logo}
                            consense_logo={consense_logo}
                            defaultCompanyName={defaultCompanyName}
                            errorData={errorData}
                        />
                        :
                        codeConfirmation ?
                        <UserDataPage 
                            defaultColor={defaultColor}
                            defaultLogo={defaultLogo}
                            imprint={imprintUri}
                            privacyProtection={privacyProtection}
                            byon_logo={byon_logo}
                            consense_logo={consense_logo}
                            defaultCompanyName={defaultCompanyName}
                            loginName={loginName}
                            token={token}
                        />
                        :
                        <ConfirmationPage 
                            defaultColor={defaultColor}
                            defaultLogo={defaultLogo}
                            imprint={imprintUri}
                            privacyProtection={privacyProtection}
                            byon_logo={byon_logo}
                            consense_logo={consense_logo}
                            defaultCompanyName={defaultCompanyName}
                            token={token}
                            setCodeConfirmation={setCodeConfirmation}
                            setLoginName={setLoginName}
                        />
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default OneTimePassword;