import React from "react";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Column, Grouping } from "@devexpress/dx-react-grid";
import { Table } from "@devexpress/dx-react-grid-material-ui";

import styles from "./OffersContainer.module.scss";
import { OmPageHeader, OmTextField } from "../../common/imports";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
    IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useOmTranslation } from "../../translations/OmTranslationContext";
//import SearchCustomerAddressDropdown from "../customer/CustomerDropdown";
import CustomerSelectDialog, { IDialogErrorResult, IMessageResult } from "./SelectCustomerDialog";
import { customerApi } from "../../services/customerApi";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import dateFormat, { masks } from "dateformat";
import CloseIcon from "@mui/icons-material/Close";

const CustomerErrorDetail = (props: IMessageResult) => {
    //#region Component state variables
    const { dictionary } = useOmTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [date, setDate] = useState<Date | null>(null);
    let messages: Array<string> = [];
    //#endregion Component state variables
    //#region Fetch requests
    const [fetchCustomerGetCustomerId, fetchCustomerGetCustomerIdResult] =
        customerApi.endpoints.GetCustomerIdByGwGuid.useLazyQuery();
    //#endregion Fetch requests
    //#region Component effects
    useEffect(() => {
        if (fetchCustomerGetCustomerIdResult?.data) {
            setIsOpen(false);
        }
    }, [fetchCustomerGetCustomerIdResult.data]);

    useEffect(() => {
        if (props) {
            setIsOpen(true);
        }
    }, [props]);
    //#endregion Component effects
    //#region Functions
    //#endregion Functions
    //#region Event handlers
    const handleApply = () => {
        if (date && props?.crmLocationGuidStr) {
            const formatedDateStr = dateFormat(date, "yyyy-mm-dd");
            fetchCustomerGetCustomerId({
                gwguid: props?.crmLocationGuidStr,
                salespartnerchangedate: formatedDateStr,
            });
        }
        // setIsOpen(false);
    };

    const handleClose = (event?: {} | undefined, reason?: "backdropClick" | "escapeKeyDown") => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setIsOpen(false);
            if (props?.setMessages) {
                props?.setMessages(null);
            }
        }
    };

    const isValidForm = () => {
        if (date && date.getTime() === date.getTime()) {
            return true;
        }
        return false;
    };

    const handleDateSelection = (value: string | null) => {
        if (value !== null) {
            let tmpDate = new Date(value);
            if (tmpDate) {
                setDate(tmpDate);
            }
        }
    };

    //#endregion Event Handlers
    //#region Markup helper variables

    //#endregion Markup helper variables
    //#region Markup
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="rights-form-title"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="rights-form-title">
                {dictionary.messages}
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack
                    p={1}
                    direction={"column"}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        minHeight: "150px",
                        width: "100%",
                    }}
                >
                    {props?.data?.messages
                        ? props?.data?.messages.map((message) => {
                              return (
                                  <Stack p={1} sx={{ width: "100%" }}>
                                      <Alert severity="warning">{message}</Alert>
                                  </Stack>
                              );
                          })
                        : null}
                    {props?.data?.needsChangeDate ? (
                        <Stack
                            p={1}
                            alignItems="stretch"
                            direction={"column"}
                            justifyContent="left"
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography variant="body1">
                                {dictionary.offer.salesPartnerChangeDate}:
                            </Typography>
                            <Stack
                                direction={"row"}
                                sx={{
                                    maxWidth: "300px",
                                    paddingTop: "10px",
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Datum"
                                        inputFormat="dd.MM.yyyy"
                                        value={date}
                                        minDate={new Date(
                                            moment().subtract(90, "days").toString()
                                        ).toDateString()}
                                        maxDate={new Date(
                                            moment().add(90, "days").toString()
                                        ).toDateString()}
                                        renderInput={(params) => <TextField {...params} />}
                                        onChange={handleDateSelection}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Stack>
                    ) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                {props?.data?.needsChangeDate && (
                    <LoadingButton
                        loading={
                            fetchCustomerGetCustomerIdResult.isLoading ||
                            fetchCustomerGetCustomerIdResult.isFetching
                        }
                        loadingPosition="start"
                        disabled={!isValidForm()}
                        onClick={() => handleApply()}
                    >
                        {dictionary.apply}
                    </LoadingButton>
                )}
                <Button onClick={() => handleClose()}>
                    {props?.data?.needsChangeDate ? dictionary.cancel : dictionary.closeText}
                </Button>
            </DialogActions>
        </Dialog>
    );
    //#endregion Markup
};
export default CustomerErrorDetail;
