import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormName, IForm, IFormField, IFormList, IOfferForm } from "./IFormParams";



// Define the initial state
export const initialOfferForm: IOfferForm =
{
    //fields: [],
    fields: {},
    isValid: false,
    reset: false,
};

export const initialState: IFormList =
{
};


const formsSlice = createSlice({
    name: 'forms',
    initialState: initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        SetField: (state, action: PayloadAction<IFormField>) =>
        {
            if (state.hasOwnProperty(action.payload.formName))
            {
                state[action.payload.formName].fields[action.payload.fieldName] = action.payload;
            }
            else
            {
                let tempForm: IForm = {
                    formName: action.payload.formName,
                    fields: {},
                    isValid: false,
                    reset: false,
                };
                tempForm.fields[action.payload.fieldName] = action.payload;
                state[action.payload.formName] = tempForm;
            }
        },

        UnsetField: (state, action: PayloadAction<IFormField>) =>
        {
            if (state.hasOwnProperty(action.payload.formName))
            {
                state[action.payload.formName].fields[action.payload.fieldName] = action.payload;

                let newfields: any = {};
                for (const [key, value] of Object.entries(state[action.payload.formName].fields))
                {
                    if (key !== action.payload.id)
                    {
                        newfields[key] = value;
                    }
                }
                state[action.payload.formName].fields = newfields;
            }
        },

        ResetForm: (state, action: PayloadAction<FormName>) =>
        {
            state[action.payload].reset = true;
        },
    }
});

export const formsActions = formsSlice.actions;
export default formsSlice.reducer;
