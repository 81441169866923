import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import {
    TreeDataState, CustomTreeData, DataTypeProvider, SearchState,
    SelectionState, IntegratedSelection, IntegratedFiltering,
    FilteringState
} from '@devexpress/dx-react-grid';
import {
    Grid, TableHeaderRow, TableTreeColumn, Toolbar, 
    TableColumnVisibility, SearchPanel, Table
} from '@devexpress/dx-react-grid-material-ui';
import PreviewIcon from '@mui/icons-material/Preview';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import { useLanguage } from '../../Contexts/LanguageContext';
import DownloadSelection from './DownloadSelection';
import { FileReadFormatterProvider } from './GridDataProvider';
import { useAuth } from '../../Contexts/AuthContext';

import { gridPaper } from '../../Theme/LayoutDefinitions';

const ROOT_ID = '';

const getRowId = row => row.id;

const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter(r => r.parentId === (row ? row.id : ROOT_ID));
    return childRows.length ? childRows : null;
};

// @ts-ignore
const GridRootComponent = props => <Grid.Root {...props} style={{ height: "100%" }} />;

// @see: https://devexpress.github.io/devextreme-reactive/react/grid/demos/featured/tree-data/
const TreeGrid = (props) => {
    const { dictionary } = useLanguage();
    const [rows, setRows] = useState([]);
    const [expandedRowIds, setExpandedRowIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [actionColumns] = useState(['actionId']);
    const [fileReadColumn] = useState(['fileName']);
    const [searchValue, setSearchValue] = useState('');
    const [defaultHiddenColumnNames] = useState(props.hiddenColumns);
    const { getHeader } = useAuth();

    const [selection, setSelection] = useState([]);

    const loadData = () => {
        const rowIdsWithNotLoadedChilds = [ROOT_ID, ...expandedRowIds].filter(rowId => rows.findIndex(row => row.parentId === rowId) === -1);
        
        if (rowIdsWithNotLoadedChilds.length) {
            if (loading) return;

            setLoading(true);

            let filter = props.columns.reduce((acc, { name }) => {
                acc.push(`["${name}", "contains", "${encodeURIComponent(searchValue)}"]`);
                return acc;
            }, []).join(',"or",');
          
            if (props.columns.length > 1) {
                filter = `[${filter}]`;
            }
          
            Promise.all(
                rowIdsWithNotLoadedChilds
                    .map(rowId => fetch(`${props.URL}?parentIds=${rowId}&filter=${filter}`, {
                        method: 'GET',
                        headers: getHeader()
                    })
                    .then(response => response.json()))
            )
            .then((loadedData) => {
                setRows(rows.concat(...loadedData));
                setLoading(false);
            })
            .catch(() => setLoading(false));
        }
    };

    useEffect(() => {
        if (!loading) {
            loadData();
        }
    });

    const DownloadAcionFormatter = ({ value, row }) => {
        let downloadButtons = null;
        if(row.hasItems === false) {
            downloadButtons = <div>
                <IconButton onClick={() => props.download(row, false)}><FileDownloadIcon /></IconButton>
                { 
                    row.fileType === 'PDF' ?
                    <IconButton onClick={() => props.download(row, true)}><PreviewIcon /></IconButton>
                    :
                    null
                }
            </div>;
        }


        return (
            downloadButtons
        );
    }
    
    const DownloadAcionTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={DownloadAcionFormatter}
            {...props}
        />
    );

    return (
        <Paper sx={gridPaper} elevation={0}>
            <Grid
                rows={rows}
                columns={props.columns}
                getRowId={getRowId}
                rootComponent={GridRootComponent}
            >
                <DownloadAcionTypeProvider for={actionColumns} />
                <FileReadFormatterProvider
                    for={fileReadColumn}
                />
                <SearchState 
                    onValueChange={setSearchValue}
                />
                <TreeDataState
                    expandedRowIds={expandedRowIds}
                    onExpandedRowIdsChange={setExpandedRowIds}
                />
                <FilteringState />
                <SelectionState
                    selection={selection}
                    onSelectionChange={setSelection}
                />
                <CustomTreeData
                    getChildRows={getChildRows}
                />
                <IntegratedSelection />
                <IntegratedFiltering />
                <Table
                    columnExtensions={props.columnExtensions || []}
                    messages={{
                        noData: props.noGridDataText
                    }}
                />
                <TableHeaderRow />
                <TableTreeColumn
                    for={props.categroyColumn}
                    showSelectionControls
                />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <Toolbar />
                <DownloadSelection 
                    downloadSelected={() => {
                        props.downloadSelectedFiles(selection);
                        setSelection([]);
                    }}
                    tooltipTitle={dictionary.downloadSelection}
                    activSelection={selection}
                />
                <SearchPanel 
                    messages={{
                        searchPlaceholder: dictionary.search
                    }}
                />
            </Grid>
        </Paper>
    );
};

export default TreeGrid;