import React from 'react';
import {
    Template, TemplatePlaceholder, Plugin, TemplateConnector,
} from '@devexpress/dx-react-core';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IDownloadSelection from '../../Interfaces/Props/IDownloadSelection';

const DownloadSelection = (props: IDownloadSelection) => {
    const { downloadSelected, tooltipTitle, activSelection } = props;

    return (
        <Plugin name="DownloadSelection">
            <Template name="toolbarContent">
                <TemplateConnector>
                    {() => (
                        <React.Fragment>
                            <Tooltip title={tooltipTitle} placement='bottom' enterDelay={300}>
                                <span>
                                    <IconButton onClick={downloadSelected} disabled={activSelection.length > 0 ? false : true}>
                                        <CloudDownloadIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </React.Fragment>
                    )}
                </TemplateConnector>
                <TemplatePlaceholder />
            </Template>
        </Plugin>
    );
}

export default DownloadSelection;