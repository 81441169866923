import { 
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@mui/material';

import IInfoDialog from '../../Interfaces/Props/IInfoDialog';

const InfoDialog = (props: IInfoDialog) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default InfoDialog;