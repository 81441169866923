
let queryParams: any = {};

export const setQueryParam = (key: string, value: any) =>
{
    queryParams[key] = value;
};

export const removeQueryParam = (removeKey: string) =>
{
    let newQueryParams: any = {};
    for (const [key, value] of Object.entries(queryParams))
    {
        if (key !== removeKey)
        {
            newQueryParams[key] = value;
        }
    }
    queryParams = newQueryParams;
};

export const getQueryParams = (callback: Function) =>
{
    queryParams = {};
    callback();
    return queryParams;
}




/* class QueryParams {
    constructor(name) {
      this.speed = 0;
      this.name = name;
    }
    run(speed) {
      this.speed = speed;
      alert(`${this.name} runs with speed ${this.speed}.`);
    }
    stop() {
      this.speed = 0;
      alert(`${this.name} stands still.`);
    }
  }
  
  let animal = new Animal("My animal"); */
