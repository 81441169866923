import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICustomer, initialCustomer } from "../../interfaces/customer/ICustomer";
import { ICustomerSearchOption, ICustomerState } from "../../interfaces/customer/ICustomerState";
import { ILocation, initialLocation } from "../../interfaces/customer/ILocation";


const initialCustomerSearchOption: ICustomerSearchOption = {
    searchText: "",
    onlyMainLocation: true,
    rowsPerPage: 10000,
};

// Define the initial state using that type
const initialState: ICustomerState = {
    customerSearchOption: initialCustomerSearchOption,
    customer: initialCustomer,
    mainLocation: initialLocation,
    installLocation: initialLocation,
};

const customerSlice = createSlice({
    name: 'customerSlice',
    initialState: initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        SetSearchText: (state, action: PayloadAction<string>) =>
        {
            state.customerSearchOption.searchText = action.payload;
        },

        SetPageSize: (state, action: PayloadAction<number>) =>
        {
            state.customerSearchOption.rowsPerPage = action.payload;
        },

        SetOnlyMainLocation: (state, action: PayloadAction<boolean>) =>
        {
            state.customerSearchOption.onlyMainLocation = action.payload;
        },

        SetCustomerSearchOptions: (state, action: PayloadAction<ICustomerSearchOption>) =>
        {
            const tempState: ICustomerState = {
                ...state,
                customerSearchOption: action.payload,
            };
            return tempState;
        },
        ResetCustomerSearchOptions: (state, action: PayloadAction<void>) =>
        {
            state.customerSearchOption = { ...initialCustomerSearchOption };
        },
        /* SetGwMainLocation: (state, action: PayloadAction<IGwLocation>) =>
        {
            state.gwMainLocation = { ...action.payload };
        },
        ResetGwMainLocation: (state, action: PayloadAction<void>) =>
        {
            state.gwMainLocation = { ...initialGwAddress };
        },
        SetGwInstallLocation: (state, action: PayloadAction<IGwLocation>) =>
        {
            state.gwInstallLocation = { ...action.payload };
        },
        ResetGwInstallLocation: (state, action: PayloadAction<void>) =>
        {
            state.gwInstallLocation = { ...initialGwAddress };
        }, */
        SetCustomer: (state, action: PayloadAction<ICustomer>) =>
        {
            state.customer = { ...action.payload };
            if (action.payload?.location)
            {
                state.mainLocation = { ...action.payload?.location };
            }
        },
        SetMainLocation: (state, action: PayloadAction<ILocation>) =>
        {
            state.mainLocation = { ...action.payload };
        },
        SetInstallLocation: (state, action: PayloadAction<ILocation>) =>
        {
            state.installLocation = { ...action.payload };
        },
        ResetCustomerData: (state, action: PayloadAction<void>) =>
        {
            //  state.gwMainLocation = { ...initialGwAddress };
            //state.gwInstallLocation = { ...initialGwAddress };
            state.customer = { ...initialCustomer };
            state.mainLocation = { ...initialLocation };
            state.installLocation = { ...initialLocation };
        },
    }
});

export const customerActions = customerSlice.actions;
export default customerSlice.reducer;